import {
  Question,
  GamePlayData,
  GamePlayPlayerResponse,
} from '../../../../../../shared/shared-game/SharedGame.types';

interface IGamePlayAnswerDisplay {
  question?: Question;
  responses?: GamePlayPlayerResponse[];
  playersStatus: GamePlayData['playersStatus'];
  questionStatus: GamePlayData['questionStatus'];
}

export function GamePlayAnswerDisplay({
  question,
  responses,
  playersStatus,
  questionStatus,
}: IGamePlayAnswerDisplay) {
  return (
    <div>
      <p className="my-4">{question?.question}</p>
      <h2 className="font-bold mt-2">Players</h2>
      <div className="flex flex-col gap-4 my-4">
        {responses?.map(({playerId, durationMs, isCorrect}) => {
          return (
            <div
              key={playerId}
              className="flex gap-2 rounded-lg bg-slate-600 py-2 px-4">
              <div className="flex-1">{playersStatus[playerId].username}</div>
              <div className="flex gap-4">
                <div>{durationMs ? durationMs / 1000 : 0} seconds</div>
                <div>{isCorrect ? '✅' : '❌'}</div>
              </div>
            </div>
          );
        })}
      </div>
      <p className="font-bold">
        {questionStatus === 'ended' ? 'Did Not Respond' : 'Awaiting Responses'}
      </p>
      <div className="flex gap-4 my-4">
        {Object.entries(playersStatus)
          .filter(
            ([id, {active}]) =>
              active && !responses?.find(({playerId}) => playerId === id),
          )
          .map(([playerId, {username}]) => {
            return (
              <div key={playerId} className="rounded-lg bg-slate-600 py-2 px-4">
                <p>{username}</p>
              </div>
            );
          })}
      </div>
      <p className="font-bold">Knocked out</p>
      <div className="flex gap-4 my-4">
        {Object.entries(playersStatus)
          .filter(([_playerId, {active}]) => !active)
          .map(([playerId, {username}]) => {
            return (
              <div key={playerId} className="rounded-lg bg-slate-600 py-2 px-4">
                <p>{username}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
}
