import {useGameShared} from '../../shared/shared-game';
import {gamesAPI} from '../../module/api';
interface IUseGame {
  gameId: string;
  userId: string;
}

export function useGame({gameId, userId}: IUseGame) {
  const gameShared = useGameShared({
    gameId,
    userId,
    gamesAPI,
  });

  return gameShared;
}
