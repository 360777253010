function secondsToTime(seconds: number): {
  hours: number;
  minutes: number;
  seconds: number;
} {
  seconds = Math.round(seconds);
  var hours = Math.floor(seconds / (60 * 60));

  var divisor_for_minutes = seconds % (60 * 60);
  var minutes = Math.floor(divisor_for_minutes / 60);

  var divisor_for_seconds = divisor_for_minutes % 60;
  var secs = Math.ceil(divisor_for_seconds);

  var obj = {
    hours,
    minutes,
    seconds: secs,
  };
  return obj;
}

function pad(num: number) {
  if (num < 10) {
    return `0${num}`;
  }
  return `${num}`;
}

const labels = {
  hour: 'hr',
  hours: 'hrs',
  minute: 'min',
  minutes: 'mins',
};

export function getDurationDisplay({
  durationMs,
  includeSeconds,
}: {
  durationMs: number;
  includeSeconds?: boolean;
}): string {
  const durationInSeconds = durationMs / 1000;
  const time = secondsToTime(durationInSeconds);
  let totalMins: number | string = time.hours
    ? time.hours * 60 + time.minutes
    : time.minutes;
  const totalMinsLabel = !includeSeconds
    ? totalMins === 1
      ? `${labels.minute}`
      : `${labels.minutes}`
    : '';
  let secondsDisplay = '';
  if (includeSeconds) {
    secondsDisplay = `:${pad(time.seconds)}`;
    totalMins = pad(totalMins);
  }
  return `${totalMins}${totalMinsLabel}${secondsDisplay}`;
}

export function TimeCountDown({
  currentTimeMs,
  timeLimitMs,
}: {
  currentTimeMs: number;
  timeLimitMs: number;
}) {
  const remainingTime = timeLimitMs - currentTimeMs;
  return (
    <div>
      <span>
        {getDurationDisplay({
          durationMs: remainingTime,
          includeSeconds: true,
        })}
      </span>
    </div>
  );
}
