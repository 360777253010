import {RoadSignRodeoLogo} from '../components/RoadSignRodeoLogo';
import {useGameComplete, IUseGameComplete} from './useGameComplete';

export function GameComplete(args: IUseGameComplete) {
  const {gameDate, gameLeaderBoard} = useGameComplete(args);
  return (
    <div className="flex flex-col justify-center items-center">
      <h1 className="text-4xl font-bold mb-6 mt-4">Road Sign Rodeo</h1>
      <RoadSignRodeoLogo />
      <p className="py-6">{gameDate?.toDateString()}</p>
      <h2 className="text-2xl font-bold mb-6">Final Standings</h2>
      {gameLeaderBoard.map(
        ({id, username, questionsCorrect, averageResponseTime}, index) => {
          const responseTimeSeconds = averageResponseTime
            ? Math.round(averageResponseTime / 1000)
            : 0;
          let responseTimeDisplay =
            responseTimeSeconds === 1
              ? `${responseTimeSeconds} second`
              : `${responseTimeSeconds} seconds`;
          return (
            <div key={id} className="rounded-lg bg-slate-600 py-2 px-4 mb-4">
              {index + 1} {' - '} {username} SCORE: {questionsCorrect} (average:{' '}
              {responseTimeDisplay})
            </div>
          );
        },
      )}
    </div>
  );
}
