import {useCallback, useState, useEffect, useMemo} from 'react';

import {
  GamesAPI,
  Game,
  GamePlayers,
  JoinGameErrorCode,
} from '../SharedGame.types';

function delay(delayMs = 2000) {
  return new Promise<void>(resolve => {
    setTimeout(() => {
      resolve();
    }, delayMs);
  });
}

type State = {
  scene:
    | 'fetching-game-details'
    | 'invalid-game-id'
    | 'game-link-expired'
    | 'join-game'
    | 'game-lobby'
    | 'game-in-progress'
    | 'game-complete';
  username?: string;
  joiningGame: boolean;
  gameId?: string;
  errorJoiningGameCode?: JoinGameErrorCode;
  game?: Game;
  gamePlayers?: GamePlayers;
};

const initialState: State = {
  scene: 'fetching-game-details',
  username: undefined,
  joiningGame: false,
  gameId: undefined,
  errorJoiningGameCode: undefined,
};

interface IUseGame {
  gameId: string;
  userId: string;
  logger?: {
    error: (message: string, error?: Error) => void;
  };
  animateNextLayout?: (arg: any) => void;
  gamesAPI: GamesAPI;
}

export function useGameShared({
  gameId,
  userId,
  logger,
  animateNextLayout,
  gamesAPI,
}: IUseGame) {
  const [state, setState] = useState<State>(initialState);

  const onJoinGame = useCallback(
    async ({username}: {username: string}) => {
      try {
        if (!userId) {
          throw new Error('No user ID');
        }
        setState(currentState => ({
          ...currentState,
          joiningGame: true,
        }));
        const {success, errorCode} = await gamesAPI.joinGame({
          userId,
          username,
          gameId,
        });
        if (success) {
          setState(currentState => ({
            ...currentState,
            scene: 'game-lobby',
            username,
            gameId,
            joiningGame: false,
          }));
        } else {
          setState(currentState => ({
            ...currentState,
            errorJoiningGameCode: errorCode || 'other-error',
            joiningGame: false,
          }));
        }
      } catch (error) {
        logger && logger.error('Error in onJoinGame', error as Error);
        setState(currentState => ({
          ...currentState,
          errorJoiningGameCode: 'other-error',
          joiningGame: false,
        }));
      }
    },
    [userId, gameId, logger, gamesAPI],
  );

  const onStartGame = useCallback(() => {
    if (state.gameId) {
      gamesAPI.startGame && gamesAPI.startGame({gameId: state.gameId});
    }
  }, [state.gameId, gamesAPI]);

  const onGameStateChange = useCallback((game: Game) => {
    setState(currentState => ({
      ...currentState,
      game,
    }));
  }, []);

  const onGamePlayerStateChange = useCallback((gamePlayers: GamePlayers) => {
    setState(currentState => ({
      ...currentState,
      gamePlayers,
    }));
  }, []);

  const waitingForMorePlayers = useMemo(() => {
    return Object.keys(state.gamePlayers || {}).length < 2;
  }, [state.gamePlayers]);

  const isGameCreator = useMemo(() => {
    return state.game?.createdBy === userId;
  }, [state.game, userId]);

  const checkGameIdExists = useCallback(async () => {
    try {
      // add a minimum 1 second comforting delay
      await delay(1000);
      const gameExists = await gamesAPI.checkGameExists(gameId);
      if (gameExists) {
        setState(currentState => ({
          ...currentState,
          gameId,
        }));
      } else {
        setState(currentState => ({
          ...currentState,
          scene: 'invalid-game-id',
        }));
      }
    } catch (error) {
      setState(currentState => ({
        ...currentState,
        scene: 'invalid-game-id',
      }));
    }
  }, [gameId, gamesAPI]);

  useEffect(() => {
    if (gameId) {
      checkGameIdExists();
    }
  }, [gameId, checkGameIdExists]);

  useEffect(() => {
    if (state.game && state.gamePlayers && userId) {
      if (!state.gamePlayers?.[userId]) {
        if (state.game.status === 'waiting-for-players') {
          setState(currentState => ({
            ...currentState,
            scene: 'join-game',
          }));
        } else {
          setState(currentState => ({
            ...currentState,
            scene: 'game-link-expired',
          }));
        }
      } else {
        if (state.game.status === 'waiting-for-players') {
          animateNextLayout && animateNextLayout({platform: 'both'});
          // if user is in the game already send them to the lobby,
          // else take them to the join-game screen
          setState(currentState => ({
            ...currentState,
            scene: 'game-lobby',
          }));
        } else if (state.game.status === 'in-progress') {
          animateNextLayout && animateNextLayout({platform: 'both'});
          setState(currentState => ({
            ...currentState,
            scene: 'game-in-progress',
          }));
        } else if (state.game.status === 'complete') {
          animateNextLayout && animateNextLayout({platform: 'both'});
          setState(currentState => ({
            ...currentState,
            scene: 'game-complete',
          }));
        }
      }
    }
  }, [state.game, state.gamePlayers, userId, animateNextLayout]);

  useEffect(() => {
    let gameListener: any;
    if (state.gameId) {
      gameListener = gamesAPI.listenToGame({
        gameId: state.gameId,
        onGameStateChange,
        onGamePlayerStateChange,
        onError: error => {
          logger && logger.error('Error in game data listener', error);
        },
      });
    }
    return () => {
      if (gameListener) {
        gameListener();
      }
    };
  }, [
    state.gameId,
    onGameStateChange,
    onGamePlayerStateChange,
    logger,
    gamesAPI,
  ]);

  return {
    ...state,
    isGameCreator,
    waitingForMorePlayers,
    onStartGame,
    onJoinGame,
  };
}
