import {initializeApp} from 'firebase/app';
import {getAuth, Auth} from 'firebase/auth';
import {getFirestore, Firestore} from 'firebase/firestore';
import {getStorage, FirebaseStorage} from 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDQ_CLOEThr1_MrRj9eLMOMRW4wngMPHKM',
  authDomain: 'uk-driving-theory-339609.firebaseapp.com',
  databaseURL:
    'https://uk-driving-theory-339609-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'uk-driving-theory-339609',
  storageBucket: 'uk-driving-theory-339609.appspot.com',
  messagingSenderId: '474187158421',
  appId: '1:474187158421:web:32590f7cd722f80826dc9c',
  measurementId: 'G-VEE8NP97QN',
};

export let auth: Auth;
export let firestore: Firestore;
export let firebaseStorage: FirebaseStorage;

export function init() {
  const app = initializeApp(firebaseConfig);
  auth = getAuth(app);
  firestore = getFirestore(app);
  firebaseStorage = getStorage(app);
}
