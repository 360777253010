import {RoadSignRodeoLogo} from '../components/RoadSignRodeoLogo';
import {useJoinGame, IJoinGame} from './useJoinGame';

export function JoinGame(args: IJoinGame) {
  const {
    game,
    gamePlayers,
    username,
    onSetUsername,
    errorJoiningGameCode,
    onJoinGame,
  } = useJoinGame(args);

  const createdByPlayerUsername =
    game && gamePlayers ? gamePlayers[game.createdBy]?.username : '';

  return (
    <div>
      {errorJoiningGameCode ? (
        <p>{errorJoiningGameCode}</p>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-4xl font-bold mb-6 mt-4">Road Sign Rodeo</h1>
          <RoadSignRodeoLogo />
          <h2 className="text-2xl font-bold mt-6">Your Name</h2>
          <div>
            <input
              type="text"
              className="input input-bordered input-primary w-full mt-4"
              value={username}
              placeholder="Username"
              onChange={({target}) => {
                onSetUsername(target.value);
              }}
            />
            <div className="label">
              <span className="label-text-alt">
                This will be seen by other players in the game
              </span>
            </div>
          </div>
          <div className="my-4">
            <p>Join {createdByPlayerUsername}'s game</p>
          </div>
          <button
            className="btn btn-primary btn-wide mt-6"
            onClick={onJoinGame}>
            JOIN
          </button>
        </div>
      )}
    </div>
  );
}
