import {useState, useCallback} from 'react';

import {
  Game,
  GamePlayers,
  JoinGameErrorCode,
} from '../../../../shared/shared-game/SharedGame.types';

export interface IJoinGame {
  gameId: string;
  game?: Game;
  gamePlayers?: GamePlayers;
  joiningGame: boolean;
  errorJoiningGameCode?: JoinGameErrorCode;
  onJoinGame: (arg: {username: string; gameId: string}) => void;
  onCancel?: () => void;
}

export function useJoinGame(args: IJoinGame) {
  const {gameId, onJoinGame: onJoinGameProp} = args;
  const [username, setUsername] = useState('');

  const onJoinGame = useCallback(() => {
    if (username.length) {
      onJoinGameProp({username, gameId});
    }
  }, [username, gameId, onJoinGameProp]);

  const onSetUsername = useCallback((value: string) => {
    setUsername(value);
  }, []);

  return {
    ...args,
    username,
    onSetUsername,
    onJoinGame,
  };
}
