import {Timestamp} from 'firebase/firestore';

import {FBDate} from './SharedGameLocalTypes.types';

export function isFireBaseTimeStamp(fbDate: FBDate): fbDate is Timestamp {
  return (fbDate as Timestamp).nanoseconds !== undefined;
}

function asDate(item: FBDate | string | null | undefined) {
  if (item === null || item === undefined) {
    return item;
  } else if (typeof item === 'string') {
    return new Date(item);
  } else if (isFireBaseTimeStamp(item)) {
    return new Date(item.seconds * 1000);
  } else if (item instanceof Date) {
    return item;
  }
  return undefined;
}

function asDurationMs(start?: FBDate, end?: FBDate) {
  if (!start || !end) {
    return undefined;
  } else {
    const startDate = asDate(start);
    const endDate = asDate(end);
    if (startDate && endDate) {
      return endDate.getTime() - startDate.getTime();
    }
    return undefined;
  }
}

export {asDurationMs, asDate};
