import {useMemo, useCallback} from 'react';

import {
  IPlayersStatus,
  Question,
  QuestionAnswerOption,
} from '../SharedGame.types';

type PlayersStatus = {
  [playerId: string]: {active?: boolean; username?: string};
};

export function usePlayersStatusShared({
  game,
  gamePlayers,
  activeQuestion,
  questions,
}: IPlayersStatus): {
  playersStatus: PlayersStatus;
} {
  const gameQuestions = useMemo((): Question[] => {
    // convert questionId string to an array of questions
    const qs: Question[] = [];
    if (game && questions) {
      const {questionIds = ''} = game;
      const questionIdsArray = questionIds.split(',');
      questionIdsArray.forEach(id => {
        if (questions[id]) {
          qs.push(questions[id]);
        }
      });
    }
    return qs;
  }, [game, questions]);

  const getIsCorrectAnswer = useCallback(
    ({
      questionId,
      answer,
    }: {
      questionId: string;
      answer?: QuestionAnswerOption;
    }) => {
      return questions ? questions[questionId].answer === answer : false;
    },
    [questions],
  );

  const getPlayerIdsWithCorrectAnswer = useCallback(
    ({questionId}: {questionId: string}) => {
      const playersWithCorrectAnswer: string[] = [];
      Object.entries(gamePlayers || {}).forEach(([playerId, player]) => {
        const gotThisQuestionCorrect = getIsCorrectAnswer({
          questionId,
          answer: player.responses?.[questionId]?.answer,
        });
        if (gotThisQuestionCorrect) {
          playersWithCorrectAnswer.push(playerId);
        }
      });
      return playersWithCorrectAnswer;
    },
    [getIsCorrectAnswer, gamePlayers],
  );

  const playersStatus = useMemo((): PlayersStatus => {
    const status: PlayersStatus = {};
    if (gamePlayers) {
      Object.entries(gamePlayers).forEach(
        ([playerId, {username, responses = {}}]) => {
          let hasMissingOrIncorrectAnswer = false;
          // players are active if they have answered ALL previous questions correctly
          // OR they got a "by" as everyone else got that question wrong OR did not answer
          gameQuestions.some(({id}) => {
            if (id === activeQuestion?.id) {
              // return early if this is the current question
              return true;
            }
            const isCorrectAnswer = getIsCorrectAnswer({
              questionId: id,
              answer: responses[id]?.answer,
            });
            const playersIdsWithCorrectAnswer = getPlayerIdsWithCorrectAnswer({
              questionId: id,
            });
            if (!isCorrectAnswer && playersIdsWithCorrectAnswer.length > 0) {
              hasMissingOrIncorrectAnswer = true;
            }
            // return early if we have at least one missing or incorrect answer
            return hasMissingOrIncorrectAnswer;
          });
          status[playerId] = {
            username,
            active: !hasMissingOrIncorrectAnswer,
          };
        },
      );
    }
    return status;
  }, [
    gamePlayers,
    gameQuestions,
    activeQuestion,
    getIsCorrectAnswer,
    getPlayerIdsWithCorrectAnswer,
  ]);

  return {
    playersStatus,
  };
}
