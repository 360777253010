import {useState, useEffect, useRef} from 'react';
import {
  onAuthStateChanged,
  Unsubscribe,
  signInAnonymously,
} from 'firebase/auth';

import {auth} from '../../module/firebase';

type UserState = {
  authStateKnown: boolean;
  user: {id: string} | null;
};

export function useAuth() {
  const [userState, setUserState] = useState<UserState>({
    authStateKnown: false,
    user: null,
  });
  const onAuthStateChangedRef = useRef<Unsubscribe | null>(null);

  useEffect(() => {
    onAuthStateChangedRef.current = onAuthStateChanged(auth, user => {
      if (user) {
        setUserState({
          authStateKnown: true,
          user: {
            id: user.uid,
          },
        });
      } else {
        setUserState({
          authStateKnown: true,
          user: null,
        });
      }
    });
    return () => {
      if (onAuthStateChangedRef.current) {
        onAuthStateChangedRef.current();
      }
    };
  }, []);

  useEffect(() => {
    if (userState.authStateKnown && !userState.user) {
      signInAnonymously(auth);
    }
  }, [userState]);

  return {
    ...userState,
  };
}
