import {
  doc,
  onSnapshot,
  getDoc,
  collection,
  getDocs,
  setDoc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';

import {firestore} from '../../module/firebase';

import {
  GamesAPI,
  Game,
  GamePlayers,
  GamePlayer,
} from '../../shared/shared-game/SharedGame.types';

export const gamesAPI: GamesAPI = {
  checkGameExists: async gameId => {
    const gameRef = doc(firestore, 'games', gameId);
    const gameSnapshot = await getDoc(gameRef);
    return !!gameSnapshot.exists();
  },

  joinGame: async ({
    userId = '',
    username = '',
    gameId = '',
    isGameCreator = false,
  }) => {
    const gameRef = doc(firestore, 'games', gameId);
    const gameSnapshot = await getDoc(gameRef);
    // check that gameId exists
    if (!gameSnapshot.exists()) {
      return {success: false, errorCode: 'game-id-invalid'};
    } else {
      const gameData = gameSnapshot.data() as Game;
      // check that existing game is still waiting for players
      if (gameData.status !== 'waiting-for-players') {
        return {success: false, errorCode: 'game-already-started'};
      }
      // check the provided username has not already been used
      const existingUserIds: string[] = [];
      const gamePlayersRef = collection(firestore, `games/${gameId}/players`);
      const gamePlayers = await getDocs(gamePlayersRef);
      gamePlayers.forEach(player => {
        const playerData = player.data() as GamePlayer;
        if (playerData.username) {
          existingUserIds.push(playerData.username.trim());
        }
      });
      if (existingUserIds.includes(username.trim())) {
        return {success: false, errorCode: 'username-clash'};
      }
    }
    // add this user to the players collection
    await setDoc(doc(firestore, `games/${gameId}/players`, userId), {
      username,
      joinedGameAt: serverTimestamp(),
      isGameCreator,
    });
    return {success: true};
  },

  answerQuestion: async ({
    userId,
    gameId,
    questionId,
    seenAt,
    answer,
    answeredAt,
  }) => {
    const ref = doc(firestore, `games/${gameId}/players`, userId);
    await updateDoc(ref, {
      [`responses.${questionId}`]: {
        seenAt,
        answer,
        answeredAt,
      },
    });
  },

  listenToGame: ({
    gameId,
    onGameStateChange,
    onGamePlayerStateChange,
    onError,
  }) => {
    const gameListener = onSnapshot(
      doc(firestore, 'games', gameId),
      doc => {
        const gameData = doc.data() as Game;
        onGameStateChange(gameData);
      },
      error => {
        onError(error);
      },
    );

    const playersListener = onSnapshot(
      collection(firestore, `games/${gameId}/players`),
      snapshot => {
        const gamePlayers: GamePlayers = {};
        if (snapshot && !snapshot.empty) {
          snapshot.forEach(playerSnapshot => {
            const playerData = playerSnapshot.data() as GamePlayer;
            gamePlayers[playerSnapshot.id] = playerData;
          });
        }
        onGamePlayerStateChange(gamePlayers);
      },
      error => {
        onError(error);
      },
    );

    const unsubscribe = () => {
      gameListener();
      playersListener();
    };
    return unsubscribe;
  },

  startQuestion: async ({userId, gameId, questionId}) => {
    const ref = doc(firestore, `games`, gameId);
    await updateDoc(ref, {
      [`questionStatus.${questionId}.startedAtPlayerMap.${userId}`]:
        serverTimestamp(),
    });
  },

  endQuestion: async ({userId, gameId, questionId}) => {
    const ref = doc(firestore, `games`, gameId);
    await updateDoc(ref, {
      [`questionStatus.${questionId}.endedAtPlayerMap.${userId}`]:
        serverTimestamp(),
    });
  },

  endGame: async ({gameId}) => {
    const ref = doc(firestore, `games`, gameId);
    await updateDoc(ref, {
      status: 'complete',
    });
  },
};
