import {useGamePlayShared} from '../../../../shared/shared-game';
import {IUseGamePlay as IUseSharedGamePlay} from '../../../../shared/shared-game/SharedGame.types';
import {gamesAPI} from '../../../../module/api';

import {useQuestions} from '../../../../store/hooks';

export interface IUseGamePlay extends IUseSharedGamePlay {
  userId: string;
}

export function useGamePlay(props: IUseGamePlay) {
  const {questions} = useQuestions();
  return useGamePlayShared({
    ...props,
    questions,
    gamesAPI,
  });
}
