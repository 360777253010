import {IUseGamePlay, useGamePlay} from './useGamePlay';

import {GamePlayQuestionDisplay, GamePlayAnswerDisplay} from './components';
import {TimeCountDown} from '../components/TimeCountDown';

export function GamePlay(props: IUseGamePlay) {
  const {
    scene,
    activeQuestion,
    isActivePlayer,
    activeQuestionPlayerAnswer,
    questionStatus,
    questionTimer,
    questionTimeLimitMS,
    playersStatus,
    playerResponses,
    onAnswerQuestion,
  } = useGamePlay(props);

  let SceneDisplay = null;
  switch (scene) {
    case 'initialising':
      SceneDisplay = <p>Initialising</p>;
      break;

    case 'question-display':
      SceneDisplay = (
        <GamePlayQuestionDisplay
          isActivePlayer={isActivePlayer}
          playerAnswer={activeQuestionPlayerAnswer}
          question={activeQuestion}
          questionTimer={questionTimer}
          questionTimeLimitMS={questionTimeLimitMS}
          onAnswerQuestion={onAnswerQuestion}
        />
      );
      break;

    case 'answer-display':
      SceneDisplay = (
        <GamePlayAnswerDisplay
          question={activeQuestion}
          questionStatus={questionStatus}
          responses={playerResponses}
          playersStatus={playersStatus}
        />
      );

      break;
    default:
      break;
  }

  return (
    <div className="flex flex-col justify-center items-center">
      {['question-display', 'answer-display'].includes(scene) &&
        !!questionTimeLimitMS &&
        questionStatus === 'started' && (
          <TimeCountDown
            currentTimeMs={questionTimer}
            timeLimitMs={questionTimeLimitMS}
          />
        )}
      {SceneDisplay}
    </div>
  );
}
