import {useMemo} from 'react';
import _orderBy from 'lodash/orderBy';

import {
  Game,
  GamePlayers,
  Questions,
  LeaderBoardPlayer,
} from '../../shared-game/SharedGame.types';

import {asDurationMs, asDate} from '../../shared-game-setup';

export interface IUseGameCompleteShared {
  game?: Game;
  gamePlayers?: GamePlayers;
  questions?: Questions;
  onCancel?: () => void;
}

export function useGameCompleteShared({
  game,
  gamePlayers,
  onCancel,
  questions,
}: IUseGameCompleteShared) {
  const gameDate = asDate(game?.createdAt) || undefined;

  const gameLeaderBoard = useMemo(() => {
    const playerStats: LeaderBoardPlayer[] = [];
    Object.entries(gamePlayers || {}).forEach(([id, {username, responses}]) => {
      let questionsAnswered = 0;
      let questionsCorrect = 0;
      let totalResponseTimeMs = 0;
      Object.entries(responses || {}).forEach(([questionId, response]) => {
        const {answer, seenAt, answeredAt} = response;
        questionsAnswered += 1;
        if (
          questions?.[questionId] &&
          answer === questions[questionId].answer
        ) {
          questionsCorrect += 1;
          if (seenAt && answeredAt) {
            totalResponseTimeMs += asDurationMs(seenAt, answeredAt) || 0;
          }
        }
      });
      let averageResponseTime = 0;
      if (questionsAnswered > 0) {
        averageResponseTime = totalResponseTimeMs / questionsAnswered;
      }
      playerStats.push({
        id,
        username,
        questionsAnswered,
        questionsCorrect,
        totalResponseTimeMs,
        averageResponseTime,
      });
    });
    return _orderBy(
      playerStats,
      ['questionsCorrect', 'averageResponseTime'],
      ['desc', 'asc'],
    );
  }, [gamePlayers, questions]);

  const winnerIds = useMemo(() => {
    // just do first item for now
    // TODO: factor in a "draw" if score and timestamps
    return gameLeaderBoard[0] ? [gameLeaderBoard[0].id] : [];
  }, [gameLeaderBoard]);

  return {
    gameDate,
    gameLeaderBoard,
    winnerIds,
    onCancel,
  };
}
