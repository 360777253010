import {useState, useCallback, useRef, useEffect} from 'react';

// NOTE: the timer interval runs every intervalMS but only reports state updates
// when the accuracy threshold has been met (eg 1000 = every second) in order
// to limit un-necessary state updates / renders

export function useTimer({
  accuracy = 1000,
  intervalMS = 200,
}: {
  accuracy?: number;
  intervalMS?: number;
}) {
  const [timer, setTimerState] = useState(0);
  const timerInterval = useRef<number | null>(null);
  const lastIntervalRef = useRef<number | null>(null);
  const accuracyDiffAccumulator = useRef<number>(0);

  const startTimer = useCallback(() => {
    if (timerInterval.current) {
      window.clearInterval(timerInterval.current);
    }
    // capture time of initial interval request
    lastIntervalRef.current = new Date().getTime();
    timerInterval.current = window.setInterval(() => {
      // add the time diff between now and the last interval request time
      const nowDate = new Date();
      const now = nowDate.getTime();
      const timeSinceLastInterval = Math.round(
        now - Number(lastIntervalRef.current),
      );
      accuracyDiffAccumulator.current += timeSinceLastInterval;
      if (accuracyDiffAccumulator.current >= accuracy) {
        setTimerState(
          currentTimer => currentTimer + accuracyDiffAccumulator.current,
        );
        accuracyDiffAccumulator.current = 0;
      }
      // update time of last interval
      lastIntervalRef.current = now;
    }, intervalMS);
  }, [accuracy, intervalMS]);

  const stopTimer = useCallback(() => {
    if (timerInterval.current) {
      window.clearInterval(timerInterval.current);
    }
  }, []);

  const resetTimer = useCallback((offset: {timeMs?: number} = {}) => {
    const {timeMs = 0} = offset;
    let initialTimerState = timeMs;
    if (timerInterval.current) {
      window.clearInterval(timerInterval.current);
    }
    setTimerState(initialTimerState);
  }, []);

  useEffect(() => {
    return () => {
      if (timerInterval.current) {
        window.clearInterval(timerInterval.current);
      }
    };
  }, []);

  return {
    timer,
    startTimer,
    stopTimer,
    resetTimer,
  };
}
