import {useGame} from '../../store/hooks';

import {JoinGame, GameLobby, GamePlay, GameComplete} from './components';

export function Game({userId, gameId}: {userId: string; gameId: string}) {
  const {
    scene,
    joiningGame,
    errorJoiningGameCode,
    game,
    gamePlayers,
    isGameCreator,
    waitingForMorePlayers,
    onStartGame,
    onJoinGame,
  } = useGame({
    userId,
    gameId,
  });

  let Scene = null;

  switch (scene) {
    case 'fetching-game-details':
      Scene = (
        <div className="flex flex-col justify-center items-center">
          <p>Fetching game details...</p>
        </div>
      );
      break;

    case 'invalid-game-id':
      Scene = (
        <div className="flex flex-col justify-center items-center">
          <p>Game not found...</p>
        </div>
      );
      break;

    case 'game-link-expired':
      Scene = (
        <div className="flex flex-col justify-center items-center">
          <p>Game link expired</p>
        </div>
      );
      break;

    case 'join-game':
      Scene = gameId ? (
        <JoinGame
          gameId={gameId}
          game={game}
          gamePlayers={gamePlayers}
          joiningGame={joiningGame}
          errorJoiningGameCode={errorJoiningGameCode}
          onJoinGame={onJoinGame}
        />
      ) : null;
      break;

    case 'game-lobby':
      Scene = (
        <GameLobby
          gameLink={game?.linkDetails?.dynamicLink}
          gamePlayers={gamePlayers}
          isGameCreator={isGameCreator}
          waitingForMorePlayers={waitingForMorePlayers}
          onStartGame={onStartGame}
        />
      );
      break;

    case 'game-in-progress':
      Scene = (
        <GamePlay
          userId={userId}
          gameId={gameId}
          game={game}
          gamePlayers={gamePlayers}
        />
      );
      break;

    case 'game-complete':
      Scene = <GameComplete game={game} gamePlayers={gamePlayers} />;
      break;

    default:
      break;
  }

  return <div>{Scene}</div>;
}
