import {useState, useMemo, useEffect, useRef, useCallback} from 'react';

import {asDurationMs, asDate, useTimer} from '../../shared-game-setup';

import {usePlayersStatusShared} from './usePlayersStatusShared';
import {
  IUseGamePlayShared,
  IGamePlay,
  GamePlayData,
  Question,
  QuestionAnswerOption,
} from '../SharedGame.types';

const timerProps = {accuracy: 1000, intervalMS: 100};

type QuestionTimerStatus = {
  questionTimerExceeded: boolean;
};

type QuestionTimersState = {[questionId: string]: QuestionTimerStatus};

export function useGamePlayShared({
  userId,
  questions,
  gameId,
  game,
  gamePlayers,
  logger,
  gamesAPI,
  onCancel,
}: IUseGamePlayShared): IGamePlay {
  const [questionTimersState, setQuestionTimersState] =
    useState<QuestionTimersState>();
  const activeQuestionIdRef = useRef('');
  const [questionDuration, setQuestionDuration] = useState(0);

  const {
    timer: questionTimer,
    startTimer: startQuestionTimer,
    stopTimer: stopQuestionTimer,
    resetTimer: resetQuestionTimer,
  } = useTimer(timerProps);

  const onGameEnd = useCallback(() => {
    if (gameId) {
      try {
        gamesAPI.endGame({
          gameId: gameId,
        });
      } catch (error) {
        logger && logger.error('Error in onGameEnd', error as Error);
      }
    }
  }, [gameId, logger, gamesAPI]);

  const onQuestionEnd = useCallback(
    ({questionId}: {questionId: string}) => {
      if (gameId && userId) {
        try {
          gamesAPI.endQuestion({
            userId,
            gameId: gameId,
            questionId,
          });
        } catch (error) {
          logger && logger.error('Error in onQuestionEnd', error as Error);
        }
      }
    },
    [gameId, logger, userId, gamesAPI],
  );

  const onQuestionStart = useCallback(
    ({questionId}: {questionId: string}) => {
      if (gameId && userId) {
        try {
          gamesAPI.startQuestion({
            userId,
            gameId: gameId,
            questionId,
          });
        } catch (error) {
          logger && logger.error('Error in onQuestionStart', error as Error);
        }
      }
    },
    [gameId, logger, userId, gamesAPI],
  );

  const onAnswerQuestion: IGamePlay['onAnswerQuestion'] = useCallback(
    ({questionId, response}) => {
      if (userId && gameId) {
        const {seenAt, answer, answeredAt} = response;
        const seenAtDate = asDate(seenAt);
        const answeredAtDate = asDate(answeredAt);
        if (seenAtDate && answeredAtDate) {
          try {
            gamesAPI.answerQuestion({
              userId,
              gameId,
              questionId,
              seenAt: seenAtDate,
              answer,
              answeredAt: answeredAtDate,
            });
          } catch (error) {
            logger && logger.error('Error in onAnswerQuestion', error as Error);
          }
        }
      }
    },
    [userId, gameId, logger, gamesAPI],
  );

  const getIsCorrectAnswer = useCallback(
    ({
      questionId,
      answer,
    }: {
      questionId: string;
      answer?: QuestionAnswerOption;
    }) => {
      return questions ? questions[questionId].answer === answer : false;
    },
    [questions],
  );

  const gameQuestions = useMemo((): Question[] => {
    // convert questionId string to an array of questions
    const qs: Question[] = [];
    if (game && questions) {
      const {questionIds = ''} = game;
      const questionIdsArray = questionIds.split(',');
      questionIdsArray.forEach(id => {
        if (questions[id]) {
          qs.push(questions[id]);
        }
      });
    }
    return qs;
  }, [game, questions]);

  const activeQuestion = useMemo((): Question | undefined => {
    // the first question in the array that has a 'startedAt' entry but no 'endedAt' entry
    let activeQ: Question | undefined;
    if (game?.questionStatus) {
      gameQuestions.some(question => {
        if (
          game?.questionStatus?.[question.id] &&
          game?.questionStatus?.[question.id]?.startedAtPlayerMap &&
          !game?.questionStatus?.[question.id]?.endedAtPlayerMap
        ) {
          activeQ = question;
          return true;
        }
      });
    }
    return activeQ;
  }, [gameQuestions, game?.questionStatus]);

  const nextQuestion = useMemo((): Question | undefined => {
    // the first question in the array that does not have a 'startedAt' entry
    let nextQ: Question | undefined;
    if (game?.questionStatus) {
      gameQuestions.some(question => {
        if (
          !game?.questionStatus?.[question.id] ||
          !game?.questionStatus?.[question.id]?.startedAtPlayerMap
        ) {
          nextQ = question;
          return true;
        }
      });
    } else {
      nextQ = gameQuestions[0];
    }
    return nextQ;
  }, [gameQuestions, game?.questionStatus]);

  const playerResponses = useMemo((): GamePlayData['playerResponses'] => {
    const thisQuestionResponses: GamePlayData['playerResponses'] = [];
    if (activeQuestion && gamePlayers) {
      const {id} = activeQuestion;
      Object.entries(gamePlayers).forEach(([playerId, player]) => {
        if (player.responses?.[id]) {
          const {answer, seenAt, answeredAt} = player.responses[id];
          thisQuestionResponses.push({
            playerId,
            answer,
            isCorrect: getIsCorrectAnswer({
              questionId: id,
              answer,
            }),
            durationMs: asDurationMs(seenAt, answeredAt),
          });
        }
      });
    }
    return thisQuestionResponses;
  }, [gamePlayers, activeQuestion, getIsCorrectAnswer]);

  const questionStatus = useMemo((): GamePlayData['questionStatus'] => {
    let status: GamePlayData['questionStatus'] = 'not-started';
    if (activeQuestion) {
      if (game?.questionStatus?.[activeQuestion.id]?.endedAtPlayerMap) {
        status = 'ended';
      } else if (
        game?.questionStatus?.[activeQuestion.id]?.startedAtPlayerMap
      ) {
        status = 'started';
      }
    }
    return status;
  }, [game, activeQuestion]);

  const {playersStatus} = usePlayersStatusShared({
    game,
    gamePlayers,
    activeQuestion,
    questions: questions || undefined,
  });

  const activePlayers = useMemo(() => {
    return Object.entries(playersStatus)
      .filter(([_playerId, {active}]) => !!active)
      .map(([playerId]) => playerId);
  }, [playersStatus]);

  const isActivePlayer = useMemo(() => {
    return !!userId && !!playersStatus[userId]?.active;
  }, [userId, playersStatus]);

  const activeQuestionPlayerAnswer =
    useMemo((): GamePlayData['activeQuestionPlayerAnswer'] => {
      if (userId && activeQuestion && gamePlayers) {
        return gamePlayers[userId]?.responses?.[activeQuestion.id]?.answer;
      }
      return undefined;
    }, [userId, gamePlayers, activeQuestion]);

  const scene = useMemo((): GamePlayData['scene'] => {
    if (!activeQuestion) {
      return 'initialising';
    }
    // show answers scene if player is no longer active OR they have answered the question
    if (
      !isActivePlayer ||
      (userId && gamePlayers?.[userId]?.responses?.[activeQuestion?.id])
    ) {
      return 'answer-display';
    }
    return 'question-display';
  }, [activeQuestion, isActivePlayer, gamePlayers, userId]);

  // ***************************************
  // *********** GAME ENGINE ***************
  // ***************************************

  // A. START QUESTION IF
  // - game is in progress
  // - there is no currently active question
  useEffect(() => {
    if (game?.status === 'in-progress' && nextQuestion && !activeQuestion) {
      onQuestionStart({
        questionId: nextQuestion?.id,
      });
    }
  }, [game, activeQuestion, nextQuestion, onQuestionStart]);

  // B. END QUESTION IF
  // - game is in progress
  // - there is an active question
  //    - the active question timer has expired OR
  //    - all active players have responded to the active question
  useEffect(() => {
    if (
      game?.status === 'in-progress' &&
      activeQuestion &&
      questionTimersState?.[activeQuestion.id]
    ) {
      let endReason:
        | 'questionTimerExceeded'
        | 'allActivePlayersHaveAnswered'
        | undefined;
      let shouldEndQuestion = false;
      if (questionTimersState[activeQuestion.id].questionTimerExceeded) {
        endReason = 'questionTimerExceeded';
        shouldEndQuestion = true;
      } else {
        let allActivePlayersHaveAnswered = true;
        activePlayers.some(playerId => {
          if (!gamePlayers?.[playerId]?.responses?.[activeQuestion.id]) {
            allActivePlayersHaveAnswered = false;
            return true;
          }
        });
        if (allActivePlayersHaveAnswered) {
          endReason = 'allActivePlayersHaveAnswered';
          shouldEndQuestion = true;
        }
      }
      if (shouldEndQuestion) {
        // send me to analytics
        console.log('** shouldEndQuestion **', {endReason});
        onQuestionEnd({
          questionId: activeQuestion?.id,
        });
      }
    }
  }, [
    game,
    gamePlayers,
    questionTimersState,
    activeQuestion,
    nextQuestion,
    activePlayers,
    onQuestionEnd,
  ]);

  // C. END GAME IF
  // - game is in progress
  //    - there is no next question OR
  //    - there is only one active player left
  useEffect(() => {
    if (game?.status === 'in-progress' && activeQuestion) {
      const shouldEndGame = !nextQuestion || activePlayers.length === 1;
      if (shouldEndGame) {
        onGameEnd();
      }
    }
  }, [game, activeQuestion, nextQuestion, activePlayers, onGameEnd]);

  // TIMER LISTENERS
  useEffect(() => {
    if (activeQuestion && activeQuestionIdRef.current !== activeQuestion.id) {
      const startedAtPlayerMap =
        game?.questionStatus?.[activeQuestion.id]?.startedAtPlayerMap;
      let startedAt: Date | undefined;
      Object.values(startedAtPlayerMap || {}).forEach(fbDate => {
        const dt = asDate(fbDate);
        if (dt) {
          if (!startedAt || dt < startedAt) {
            startedAt = dt;
          }
        }
      });
      console.log({
        startedAt,
      });
      if (startedAt) {
        activeQuestionIdRef.current = activeQuestion.id;
        const timeMs = new Date().getTime() - startedAt.getTime();
        if (game?.gameRules.questionTimeLimitMS) {
          setQuestionDuration(game.gameRules.questionTimeLimitMS - timeMs);
        }
        resetQuestionTimer({timeMs});
        startQuestionTimer();
        setQuestionTimersState(currentState => ({
          ...currentState,
          [activeQuestion.id]: {
            questionTimerExceeded: false,
          },
        }));
      }
    }
  }, [
    game,
    activeQuestion,
    resetQuestionTimer,
    stopQuestionTimer,
    startQuestionTimer,
  ]);

  useEffect(() => {
    if (activeQuestion && questionTimersState?.[activeQuestion.id]) {
      const {questionTimerExceeded: questionTimerExceededState} =
        questionTimersState?.[activeQuestion.id];
      const questionTimerExceeded =
        game && questionTimer > game.gameRules.questionTimeLimitMS;
      if (questionTimerExceededState !== questionTimerExceeded) {
        setQuestionTimersState(currentState => ({
          ...currentState,
          [activeQuestion.id]: {
            questionTimerExceeded: true,
          },
        }));
      }
    }
  }, [game, activeQuestion, questionTimer, questionTimersState]);

  return {
    scene,
    activeQuestion,
    questionDuration,
    isActivePlayer,
    activeQuestionPlayerAnswer,
    questionStatus,
    questionTimer,
    questionTimeLimitMS: game?.gameRules.questionTimeLimitMS,
    playersStatus,
    playerResponses,
    onAnswerQuestion,
    onQuitGame: onCancel,
  };
}
