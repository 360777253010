import {
  Game,
  GamePlayers,
} from '../../../../shared/shared-game/SharedGame.types';
import {useGameCompleteShared} from '../../../../shared/shared-game/';
import {useQuestions} from '../../../../store/hooks';

export interface IUseGameComplete {
  game?: Game;
  gamePlayers?: GamePlayers;
  onCancel?: () => void;
}

export function useGameComplete({
  game,
  gamePlayers,
  onCancel,
}: IUseGameComplete) {
  const {questions} = useQuestions();
  return useGameCompleteShared({
    game,
    gamePlayers,
    questions,
    onCancel,
  });
}
