import {useState, useEffect, useCallback, useRef} from 'react';
import {getDownloadURL, ref} from 'firebase/storage';

import {
  GamePlayerQuestionResponse,
  Question,
  QuestionAnswerOption,
} from '../../../../../../shared/shared-game/SharedGame.types';
import {firebaseStorage} from '../../../../../../module/firebase';
import {getRandom, delay} from '../../../../../../module/utils';

interface IGamePlayQuestionDisplay {
  isActivePlayer?: boolean;
  question?: Question;
  playerAnswer?: QuestionAnswerOption;
  questionTimer: number;
  questionTimeLimitMS?: number;
  onAnswerQuestion?: (args: {
    questionId: string;
    response: GamePlayerQuestionResponse;
  }) => void;
}

type QuestionData = {
  question: string;
  questionImageSrc: string;
  options: {text?: string; imgSrc?: string; option: QuestionAnswerOption}[];
} | null;

export function GamePlayQuestionDisplay({
  isActivePlayer,
  question,
  playerAnswer,
  questionTimer,
  questionTimeLimitMS,
  onAnswerQuestion,
}: IGamePlayQuestionDisplay) {
  const questionIdRef = useRef('');
  const seenAtRef = useRef(new Date());
  const questionTimerRef = useRef(questionTimer);
  questionTimerRef.current = questionTimer;
  const [selectedOption, setSelectedOption] = useState<
    QuestionAnswerOption | undefined
  >(playerAnswer);
  const [gettingQuestionData, setGettingQuestionData] = useState(false);
  const [questionData, setQuestionData] = useState<QuestionData>(null);

  const getAndSetQuestionData = useCallback(async () => {
    setGettingQuestionData(true);
    try {
      let nextQuestionData: QuestionData = null;
      if (question) {
        nextQuestionData = {
          question: question.question,
          questionImageSrc: '',
          options: [],
        };
        if (question?.image?.src) {
          try {
            const questionImageSrc = await getDownloadURL(
              ref(firebaseStorage, question.image.src),
            );
            nextQuestionData.questionImageSrc = questionImageSrc;
          } catch (error) {
            console.log({error});
          }
        }
        const options: QuestionAnswerOption[] = getRandom(
          ['A', 'B', 'C', 'D'],
          4,
        );
        // get answer option text
        options.forEach(option => {
          if (question.options[option]) {
            nextQuestionData?.options.push({
              text: question.options[option],
              option,
            });
          }
        });
        if (!nextQuestionData?.options.length) {
          // if no text options then attempt to get any image options
          await Promise.all(
            options.map(async option => {
              if (question.optionImages?.[option]) {
                const optionImageSrc = await getDownloadURL(
                  ref(firebaseStorage, question.optionImages[option]?.src),
                );
                nextQuestionData?.options.push({
                  imgSrc: optionImageSrc,
                  option,
                });
              }
            }),
          );
        }
      }
      setQuestionData(nextQuestionData);
      setGettingQuestionData(false);
    } catch (error) {
      setGettingQuestionData(false);
    }
  }, [question]);

  useEffect(() => {
    if (question && question.id !== questionIdRef.current) {
      questionIdRef.current = question.id;
      seenAtRef.current = new Date();
      getAndSetQuestionData();
    }
  }, [question, getAndSetQuestionData]);

  const onSelectOption = useCallback(
    async (answerOption: QuestionAnswerOption) => {
      if (!selectedOption && isActivePlayer) {
        setSelectedOption(answerOption);
        await delay(1000);
        if (onAnswerQuestion) {
          const seenAt = seenAtRef.current;
          const answeredAt = new Date(seenAt);
          answeredAt.setMilliseconds(
            seenAt.getMilliseconds() + questionTimerRef.current,
          );
          const response = {
            seenAt,
            answeredAt,
            answer: answerOption,
          };
          if (question) {
            onAnswerQuestion({
              questionId: question.id,
              response,
            });
          }
        }
      }
    },
    [selectedOption, question, isActivePlayer, onAnswerQuestion],
  );

  return (
    <div className="flex flex-col justify-center items-center">
      {!!gettingQuestionData && <p>Loading....</p>}
      {!gettingQuestionData && !!questionData && (
        <div className="flex flex-col justify-center items-center">
          <h3 className="mt-4 font-bold">{questionData.question}</h3>
          {!!questionData.questionImageSrc && (
            <div className="bg-white p-4 my-6">
              <img style={{width: 200}} src={questionData.questionImageSrc} />
            </div>
          )}
          <div className="flex flex-col py-6">
            {questionData.options.map(({text, imgSrc, option}) => {
              return (
                <div key={option}>
                  <button
                    className={
                      imgSrc
                        ? ''
                        : selectedOption === option
                        ? 'btn mb-4 btn-primary'
                        : 'btn mb-4'
                    }
                    onClick={() => {
                      onSelectOption(option);
                    }}>
                    {!!text && text}
                    {!!imgSrc && (
                      <img
                        className="object-contain p-2 bg-white"
                        style={{width: 100, height: 100}}
                        src={imgSrc}
                      />
                    )}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
