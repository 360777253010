import {Questions} from '../../shared/shared-game-setup';

export function useQuestions(): {
  questions: Questions;
} {
  // TODO: get these from the CMS
  // - limited to the ones exposed in questions
  // - ideally ICED up to the max
  return {
    questions: {
      AB2001: {
        id: 'AB2001',
        version: 'v2',
        question: 'What should you do before making a U-turn?',
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'C',
        additionalInfo:
          "If you have to make a U-turn, slow down and ensure that the road is clear in both directions. Make sure that the road is wide enough for you to carry out the manoeuvre safely. Use your mirrors and look round to check it's safe before turning across the road.",
        options: {
          A: 'Give an arm signal as well as using your indicators',
          B: 'Check road markings to see that U-turns are permitted',
          C: 'Look over your shoulder for a final check',
          D: 'Select a higher gear than normal',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2003: {
        id: 'AB2003',
        version: 'v2',
        question: 'What should you do as you approach this bridge?',
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: {
          fileName: 'IMG_1263.jpg',
          width: 800,
          height: 533,
          size: 107318,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1263.jpg?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "You should slow down and be cautious. Hump bridges are often narrow and there may not be enough room for you to pass an oncoming vehicle at this point. Also, there's no footpath, so be aware of pedestrians in the road.",
        options: {
          A: 'Move to the right',
          B: 'Slow down',
          C: 'Change gear',
          D: 'Keep to 30 mph',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2005: {
        id: 'AB2005',
        version: 'v2',
        question: 'Where should you avoid overtaking?',
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'D',
        additionalInfo:
          "Oncoming vehicles or other hazards can be hidden from view by dips in the road. If you can't see into the dip, wait until you have a clear view and can see that it's safe before starting to overtake.",
        options: {
          A: 'Just after a bend',
          B: 'In a one-way street',
          C: 'On a 30 mph road',
          D: 'Approaching a dip in the road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2007: {
        id: 'AB2007',
        version: 'v2',
        question: 'What does this curved arrow road marking mean?',
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: {
          fileName: 'IMG_1887.jpg',
          width: 800,
          height: 533,
          size: 99238,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1887.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "In this picture, the road marking shows that overtaking drivers or riders need to return to the left. These markings show the direction drivers must pass hatch markings or solid double white lines. They're also used to show the route that high vehicles should take under a low arched bridge.",
        options: {
          A: 'Heavy vehicles should take the next road on the left to avoid a weight limit',
          B: 'The road ahead bends to the left',
          C: 'Overtaking traffic should move back to the left',
          D: 'The road ahead has a camber to the left',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2008: {
        id: 'AB2008',
        version: 'v2',
        question:
          "What should you do if your mobile phone rings while you're driving or riding?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'C',
        additionalInfo:
          "It's illegal to use a hand-held mobile or similar device when driving or riding, except in a genuine emergency. The safest option is to switch off your mobile phone before you set off, and use a message service. If you've forgotten to switch your phone off and it rings, you should leave it. When you've stopped in a safe place, you can see who called and return the call if necessary.",
        options: {
          A: 'Stop immediately',
          B: 'Answer it immediately',
          C: 'Leave it until you have stopped in a safe place',
          D: 'Pull up at the nearest kerb',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2009: {
        id: 'AB2009',
        version: 'v2',
        question: 'Why are these yellow lines painted across the road?',
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: {
          fileName: '85BW9937.jpg',
          width: 800,
          height: 532,
          size: 98342,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW9937.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'These lines are often found on the approach to a roundabout or a dangerous junction. They give you extra warning to adjust your speed. Look well ahead and do this in good time.',
        options: {
          A: 'To help you choose the correct lane',
          B: 'To help you keep the correct separation distance',
          C: 'To make you aware of your speed',
          D: 'To tell you the distance to the roundabout',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2501: {
        id: 'AB2501',
        version: 'v2',
        question:
          "What should you do when you're approaching traffic lights that have been green for some time?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'C',
        additionalInfo:
          "The longer traffic lights have been green, the sooner they'll change. Allow for this as you approach traffic lights that you know have been green for a while. They're likely to change soon, so you should be prepared to stop.",
        options: {
          A: 'Accelerate hard',
          B: 'Maintain your speed',
          C: 'Be ready to stop',
          D: 'Brake hard',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2636: {
        id: 'AB2636',
        version: 'v2',
        question:
          'What should you do before slowing down or stopping your vehicle?',
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'B',
        additionalInfo:
          "Before slowing down or stopping, check the mirrors to see what's happening behind you. Also assess what's ahead and make sure you give the correct signal if it will help other road users.",
        options: {
          A: 'Sound the horn',
          B: 'Use the mirrors',
          C: 'Select a higher gear',
          D: 'Flash the headlights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2702: {
        id: 'AB2702',
        version: 'v2',
        question:
          "You're following a large vehicle. Why should you stay a safe distance behind it?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'C',
        additionalInfo:
          "If you're following a large vehicle but are so close to it that you can't see its exterior mirrors, the driver won't be able to see you. Keeping well back will also allow you to see the road ahead by looking past the large vehicle.",
        options: {
          A: "You'll be able to corner more quickly",
          B: "You'll help the large vehicle to stop more easily",
          C: "You'll give the driver a chance to see you in their mirrors",
          D: "You'll keep out of the wind better",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2828: {
        id: 'AB2828',
        version: 'v2',
        question:
          'Why should you use your mirrors when you see a hazard ahead?',
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'B',
        additionalInfo:
          "You should be constantly scanning the road for clues about what's going to happen next. Check your mirrors regularly, particularly as soon as you spot a hazard. What's happening behind may affect how you respond to hazards ahead.",
        options: {
          A: "Because you'll need to accelerate out of danger",
          B: 'To assess how your actions will affect the traffic behind',
          C: "Because you'll need to brake sharply and stop",
          D: "To check what's happening on the road ahead",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2829: {
        id: 'AB2829',
        version: 'v2',
        question:
          "You're waiting to turn right at the end of a road. What should you do if your view is obstructed by parked vehicles?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'A',
        additionalInfo:
          "At junctions, your view is often restricted by buildings, trees or parked cars. You need to be able to see in order to judge a safe gap. Edge forward slowly and keep looking all the time. Don't cause other road users to change speed or direction as you emerge.",
        options: {
          A: 'Stop and then move forward slowly and carefully for a clear view',
          B: 'Move quickly to where you can see so you only block traffic from one direction',
          C: "Wait for a pedestrian to let you know when it's safe for you to emerge",
          D: 'Turn your vehicle around immediately and find another junction to use',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1002: {
        id: 'BB1002',
        version: 'v2',
        question:
          'There are objects hanging from your interior mirror. Why could this be a hazard?',
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'A',
        additionalInfo:
          'Ensure that you can see clearly through the windscreen of your vehicle. Stickers or hanging objects could obstruct your view or draw your attention away from the road.',
        options: {
          A: 'Your view could be obstructed',
          B: 'Your sun visor might get tangled',
          C: 'Your radio reception might be affected',
          D: 'Your windscreen could mist up more easily',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1005: {
        id: 'BB1005',
        version: 'v2',
        question:
          "You're on a long motorway journey. What should you do if you start to feel sleepy?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'D',
        additionalInfo:
          "If you feel sleepy, you should leave the motorway at a service area or at the next exit and stop in a safe place to rest. A supply of fresh air can help to keep you alert before you reach the exit, but it isn't a substitute for stopping and resting.",
        options: {
          A: 'Play some loud music',
          B: 'Stop on the hard shoulder for a rest',
          C: 'Drive faster to complete your journey sooner',
          D: 'Leave the motorway and stop in a safe place',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1007: {
        id: 'BB1007',
        version: 'v2',
        question:
          'Why should you switch your headlights on when it first starts to get dark?',
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'B',
        additionalInfo:
          'Your headlights and tail lights help others on the road to see you. It may be necessary to turn on your headlights during the day if visibility is reduced; for example, due to heavy rain. In these conditions, the light might fade before the street lights are timed to switch on. Be seen to be safe.',
        options: {
          A: 'To make your dials easier to see',
          B: 'So others can see you more easily',
          C: 'So that you blend in with other drivers',
          D: 'Because the street lights are lit',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1287: {
        id: 'BB1287',
        version: 'v2',
        question: "What's most likely to distract you while you're driving?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'A',
        additionalInfo:
          "Except for emergencies, it's illegal to use a hand-held mobile phone while you're driving. Even using a hands-free kit can severely distract your attention.",
        options: {
          A: 'Using a mobile phone',
          B: 'Using the windscreen wipers',
          C: 'Using the demisters',
          D: 'Using the mirrors',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1294: {
        id: 'BB1294',
        version: 'v2',
        question:
          "You're driving your car. When may you use a hand-held mobile phone?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'B',
        additionalInfo:
          "It's illegal to use a hand-held mobile phone while you're driving, except in a genuine emergency. Even using a hands-free kit can distract your attention. Park in a safe and convenient place before receiving or making a call or using text messaging. Then you'll also be free to take notes or refer to papers.",
        options: {
          A: "When you're receiving a call",
          B: "When you've parked safely",
          C: "When you're driving at less than 30 mph",
          D: 'When your car has automatic transmission',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1366: {
        id: 'BB1366',
        version: 'v2',
        question:
          "You're driving on a wet road. What should you do if you have to stop your vehicle in an emergency?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'B',
        additionalInfo:
          "As you drive, look well ahead and all around so that you're ready for any hazards that might develop. If you have to stop in an emergency, react as soon as you can while keeping control of the vehicle. Keep both hands on the steering wheel so you can control the vehicle's direction of travel.",
        options: {
          A: 'Apply the parking brake and footbrake together',
          B: 'Keep both hands on the steering wheel',
          C: 'Select reverse gear',
          D: 'Give an arm signal',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1390: {
        id: 'BB1390',
        version: 'v2',
        question:
          'What should you do when you move off from behind a parked car?',
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'B',
        additionalInfo:
          "Before moving off, you should use both the interior and exterior mirrors to check that the road is clear. Look around to check the blind spots and, if necessary, give a signal to warn other road users of your intentions. Also look well ahead as you'll have to steer out into the road to pass the parked car.",
        options: {
          A: 'Give a signal after moving off',
          B: 'Look around before moving off',
          C: 'Look around after moving off',
          D: 'Use the exterior mirrors only',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1439: {
        id: 'BB1439',
        version: 'v2',
        question:
          "You're travelling along this road. How should you pass the cyclist?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: {
          fileName: 'IMG_2018.jpg',
          width: 800,
          height: 533,
          size: 88945,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_2018.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Allow the cyclist plenty of room in case they wobble or swerve around a pothole or raised drain. Look well ahead before you start to overtake, because you'll need to cross the hazard line. Look for entrances where vehicles could be waiting to pull out.",
        options: {
          A: 'Sound your horn as you pass',
          B: 'Keep close to them as you pass',
          C: 'Leave them plenty of room as you pass',
          D: 'Change down one gear before you pass',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1573: {
        id: 'BB1573',
        version: 'v2',
        question:
          'When do windscreen pillars cause a serious obstruction to your view?',
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'D',
        additionalInfo:
          'Windscreen pillars can obstruct your view, particularly at bends and junctions. Look out for other road users - especially cyclists, motorcyclists and pedestrians who can easily be overlooked.',
        options: {
          A: "When you're driving on a motorway",
          B: "When you're driving on a dual carriageway",
          C: "When you're approaching a one-way street",
          D: "When you're approaching bends and junctions",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1595: {
        id: 'BB1595',
        version: 'v2',
        question:
          "What should you do if you can't see clearly behind when you're reversing?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'D',
        additionalInfo:
          "If you want to turn your car around, try to find a place where you have good all-round vision. If this isn't possible, and you're unable to see clearly, then get someone to guide you.",
        options: {
          A: 'Open the window to look behind',
          B: 'Open the door to look behind',
          C: 'Look in the nearside mirror',
          D: 'Ask someone to guide you',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1626: {
        id: 'BB1626',
        version: 'v2',
        question: "What does the term 'blind spot' mean?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'D',
        additionalInfo:
          "Modern vehicles provide the driver with a good view of both the road ahead and behind using well-positioned mirrors. However, the mirrors can't see every angle of the scene behind and to the sides of the vehicle. It's essential that you know when and how to check the vehicle's blind spots, so that you're aware of any hidden hazards.",
        options: {
          A: 'An area covered by your right-hand mirror',
          B: 'An area not covered by your headlights',
          C: 'An area covered by your left-hand mirror',
          D: 'An area not visible to the driver',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1629: {
        id: 'BB1629',
        version: 'v2',
        question:
          "What's likely to happen if you use a hands-free phone while you're driving?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'D',
        additionalInfo:
          "Talking to someone while you're driving can distract you and, unlike when someone is in the car with you, the person on the other end of a mobile phone is unable to see the traffic situations you're dealing with. They won't stop speaking to you even if you're approaching a hazardous situation. You need to concentrate on your driving at all times.",
        options: {
          A: 'It will improve your safety',
          B: 'It will increase your concentration',
          C: 'It will reduce your view',
          D: 'It will divert your attention',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1632: {
        id: 'BB1632',
        version: 'v2',
        question:
          "You're turning right onto a dual carriageway. What should you do before emerging?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: {
          fileName: 'IMG_2815.jpg',
          width: 800,
          height: 533,
          size: 108650,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_2815.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Before emerging right onto a dual carriageway, make sure that the central reservation is deep enough to protect your vehicle. If it isn't, you should treat the dual carriageway as one road and check that it's clear in both directions before pulling out. Neglecting to do this could place part or all of your vehicle in the path of approaching traffic and cause a collision.",
        options: {
          A: 'Stop, apply the parking brake and then select a low gear',
          B: 'Position your vehicle well to the left of the side road',
          C: 'Check that the central reservation is wide enough for your vehicle',
          D: 'Make sure that you leave enough room for a vehicle behind',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1633: {
        id: 'BB1633',
        version: 'v2',
        question:
          "You're waiting to emerge from a junction. The windscreen pillar is restricting your view. What should you be particularly aware of?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: {
          fileName: 'VC1505.png',
          width: 587,
          height: 390,
          size: 393236,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FVC1505.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Windscreen pillars can completely block your view of pedestrians, motorcyclists and cyclists. You should make a particular effort to look for these road users; don't just rely on a quick glance.",
        options: {A: 'Lorries', B: 'Buses', C: 'Motorcyclists', D: 'Coaches'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1726: {
        id: 'BB1726',
        version: 'v2',
        question:
          "How can you make sure that a satellite navigation (satnav) system doesn't distract you when you're driving?",
        questionSetId: 'ALERTNESS',
        questionSetTitle: 'Alertness',
        image: null,
        answer: 'D',
        additionalInfo:
          "Satnavs can be useful when driving on unfamiliar routes. However, they can also distract you and cause you to lose control if you look at or adjust them while you're driving. Set the satnav before starting your journey, or pull up in a safe place before making any changes to it.",
        options: {
          A: "Turn it off while you're driving in built-up areas",
          B: 'Choose a voice that you find calming',
          C: "Only set the destination when you're lost",
          D: 'Set it before starting your journey',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2012: {
        id: 'AB2012',
        version: 'v2',
        question:
          'What must you do when the amber light is flashing at a pelican crossing?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'D',
        additionalInfo:
          'Pelican crossings are signal-controlled crossings operated by pedestrians. Push-button controls change the signals. Pelican crossings have no red-and-amber stage before green; instead, they have a flashing amber light. This means you must give way to pedestrians who are on the crossing but if the crossing is clear you can continue.',
        options: {
          A: 'Stop and wait for the green light',
          B: 'Stop and wait for the red light',
          C: 'Give way to pedestrians waiting to cross',
          D: 'Give way to pedestrians already on the crossing',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2015: {
        id: 'AB2015',
        version: 'v2',
        question:
          'Why should you never wave people across at pedestrian crossings?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'A',
        additionalInfo:
          "If people are waiting to use a pedestrian crossing, slow down and be prepared to stop. Don't wave them across the road, because another driver may not have seen them, may not have seen your signal, and may not be able to stop safely.",
        options: {
          A: 'Another vehicle may be coming',
          B: 'They may not be looking',
          C: "It's safer for you to carry on",
          D: 'They may not be ready to cross',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2022: {
        id: 'AB2022',
        version: 'v2',
        question:
          'Why is it dangerous to drive too close to the vehicle ahead?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'C',
        additionalInfo:
          "'Tailgating' is the term used when a driver or rider follows the vehicle in front too closely. It's dangerous because it restricts your view of the road ahead and leaves no safety margin if the vehicle in front needs to slow down or stop suddenly. Tailgating is often the underlying cause of rear-end collisions or multiple pile-ups.",
        options: {
          A: 'Your engine will overheat',
          B: 'Your mirrors will need adjusting',
          C: 'Your view of the road ahead will be restricted',
          D: 'Your satnav will be confused',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2023: {
        id: 'AB2023',
        version: 'v2',
        question: 'What will happen if you follow this vehicle too closely?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: {
          fileName: 'IMG_1279.jpg',
          width: 800,
          height: 533,
          size: 87291,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1279.jpg?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'Staying back will increase your view of the road ahead. This will help you to see any hazards that might occur and give you more time to react.',
        options: {
          A: 'Your brakes will overheat',
          B: 'Your fuel consumption will be increased',
          C: 'Your engine will overheat',
          D: 'Your view ahead will be reduced',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2025: {
        id: 'AB2025',
        version: 'v2',
        question:
          "What's the minimum time gap you should leave when following a vehicle on a wet road?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'D',
        additionalInfo:
          "Water will reduce your tyres' grip on the road. The safe separation gap of at least two seconds in dry conditions should be doubled, to at least four seconds, in wet weather.",
        options: {
          A: 'One second',
          B: 'Two seconds',
          C: 'Three seconds',
          D: 'Four seconds',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2028: {
        id: 'AB2028',
        version: 'v2',
        question:
          "You're being overtaken by a long, heavily laden lorry. What should you do if it's taking a long time for it to overtake?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'B',
        additionalInfo:
          'A long lorry with a heavy load will need more time to pass you than a car, especially on an uphill stretch of road. Slow down and allow the lorry to pass.',
        options: {
          A: 'Speed up',
          B: 'Slow down',
          C: 'Hold your speed',
          D: 'Change direction',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2029: {
        id: 'AB2029',
        version: 'v2',
        question: 'Which vehicle will use a blue flashing beacon?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'B',
        additionalInfo:
          "Emergency vehicles use blue flashing lights. If you see or hear one, move out of its way as soon as it's safe and legal to do so.",
        options: {
          A: 'Motorway maintenance',
          B: 'Bomb disposal',
          C: 'Snow plough',
          D: 'Breakdown recovery',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2031: {
        id: 'AB2031',
        version: 'v2',
        question:
          "What should you do if you're being followed by an ambulance showing flashing blue lights?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'A',
        additionalInfo:
          'Pull over in a place where the ambulance can pass safely. Check that there are no bollards or obstructions in the road that will prevent it from passing.',
        options: {
          A: "Pull over as soon as it's safe to do so",
          B: 'Accelerate hard to get away from it',
          C: 'Maintain your speed and course',
          D: 'Brake harshly and stop well out into the road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2033: {
        id: 'AB2033',
        version: 'v2',
        question:
          'What type of emergency vehicle is fitted with a green flashing beacon?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'D',
        additionalInfo:
          "A green flashing beacon on a vehicle means the driver or passenger is a doctor on an emergency call. Give way to them if it's safe to do so. Be aware that the vehicle may be travelling quickly or may stop in a hurry.",
        options: {
          A: 'Fire engine',
          B: 'Road gritter',
          C: 'Ambulance',
          D: "Doctor's car",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2036: {
        id: 'AB2036',
        version: 'v2',
        question: 'Who should obey diamond-shaped traffic signs?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: {
          fileName: 'TS4023.png',
          width: 235,
          height: 390,
          size: 40061,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4023.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "These signs apply only to tram drivers, but you should know their meaning so that you're aware of the priorities and are able to anticipate the actions of the driver.",
        options: {
          A: 'Tram drivers',
          B: 'Bus drivers',
          C: 'Lorry drivers',
          D: 'Taxi drivers',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2037: {
        id: 'AB2037',
        version: 'v2',
        question:
          'On a road where trams operate, which vehicles will be most at risk from the tram rails?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'B',
        additionalInfo:
          'The narrow wheels of a bicycle can become stuck in the tram rails, causing the cyclist to stop suddenly, wobble or even lose balance altogether. The tramlines are also slippery, which could cause a cyclist to slide or fall off.',
        options: {A: 'Cars', B: 'Cycles', C: 'Buses', D: 'Lorries'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2039: {
        id: 'AB2039',
        version: 'v2',
        question: "When should you use your vehicle's horn?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'A',
        additionalInfo:
          "You mustn't use your vehicle's horn between 11.30 pm and 7 am in a built-up area or when you're stationary, unless a moving vehicle poses a danger. Its function is to alert other road users to your presence.",
        options: {
          A: 'To alert others to your presence',
          B: 'To allow you right of way',
          C: 'To greet other road users',
          D: 'To signal your annoyance',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2040: {
        id: 'AB2040',
        version: 'v2',
        question:
          "You're in a one-way street and want to turn right. Where should you position your vehicle when there are two lanes?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'A',
        additionalInfo:
          "When you're in a one-way street and want to turn right, you should take up a position in the right-hand lane. This will allow other road users, not wishing to turn, to pass on the left. Indicate your intention and take up the correct position in good time.",
        options: {
          A: 'In the right-hand lane',
          B: 'In the left-hand lane',
          C: 'In either lane, depending on the traffic',
          D: 'Just left of the centre line',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2041: {
        id: 'AB2041',
        version: 'v2',
        question:
          'You wish to turn right ahead. Why should you take up the correct position in good time?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'C',
        additionalInfo:
          "If you wish to turn right into a side road, take up your position in good time. Move to the centre of the road when it's safe to do so. This will allow vehicles to pass you on the left. Early planning will show other traffic what you intend to do.",
        options: {
          A: 'To allow other drivers to pull out in front of you',
          B: "To give a better view into the road that you're joining",
          C: 'To help other road users know what you intend to do',
          D: 'To allow drivers to pass you on the right',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2503: {
        id: 'AB2503',
        version: 'v2',
        question:
          'Which type of crossing allows cyclists to ride across while pedestrians are also crossing?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'A',
        additionalInfo:
          'A toucan crossing is designed to allow pedestrians and cyclists to cross at the same time. Look out for cyclists approaching the crossing at speed.',
        options: {A: 'Toucan', B: 'Puffin', C: 'Pelican', D: 'Zebra'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2620: {
        id: 'AB2620',
        version: 'v2',
        question:
          "You're travelling at the legal speed limit. What should you do if the vehicle behind approaches quickly, flashing its headlights?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'D',
        additionalInfo:
          "Don't enforce the speed limit by blocking another vehicle's progress. This will only cause frustration. Allow the other vehicle to pass when you can do so safely.",
        options: {
          A: 'Accelerate to make a gap behind you',
          B: 'Touch the brakes sharply to show your brake lights',
          C: 'Maintain your speed to prevent the vehicle from overtaking',
          D: 'Allow the vehicle to overtake',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2643: {
        id: 'AB2643',
        version: 'v2',
        question: 'When should you flash your headlights at other road users?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'D',
        additionalInfo:
          "You should only flash your headlights to warn others of your presence. Don't use them to greet others, show impatience or give priority to other road users, because they could misunderstand your signal.",
        options: {
          A: "When showing that you're giving way",
          B: "When showing that you're about to turn",
          C: 'When telling them that you have right of way',
          D: "When letting them know that you're there",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2644: {
        id: 'AB2644',
        version: 'v2',
        question:
          "You're approaching an unmarked crossroads. How should you deal with the junction?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'D',
        additionalInfo:
          'Be cautious, especially when your view is restricted by hedges, bushes, walls, large vehicles, etc. In the summer months, these junctions can become more difficult to deal with, because growing foliage may further obscure your view.',
        options: {
          A: 'Accelerate and keep to the middle',
          B: 'Slow down and keep to the right',
          C: 'Accelerate and look to the left',
          D: 'Slow down and look both ways',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2699: {
        id: 'AB2699',
        version: 'v2',
        question:
          "The conditions are good and dry. When should you use the 'two-second rule'?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'B',
        additionalInfo:
          "In good conditions, the 'two-second rule' can be used to check the distance between your vehicle and the one in front. This technique works on roads carrying faster traffic. Choose a fixed object, such as a bridge, sign or tree. When the vehicle ahead passes this object, say to yourself 'Only a fool breaks the two-second rule.' If you reach the object before you finish saying this, you're too close.",
        options: {
          A: 'Before restarting the engine after it has stalled',
          B: 'When checking your gap from the vehicle in front',
          C: "Before using the 'Mirrors - Signal - Manoeuvre' routine",
          D: 'When traffic lights change to green',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2703: {
        id: 'AB2703',
        version: 'v2',
        question: 'Which colour follows the green signal at a puffin crossing?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'C',
        additionalInfo:
          "Puffin crossings have infra-red sensors that detect when pedestrians are crossing and hold the red traffic signal until the crossing is clear. The use of a sensor means there's no flashing amber phase as there is with a pelican crossing.",
        options: {
          A: 'Steady red',
          B: 'Flashing amber',
          C: 'Steady amber',
          D: 'Flashing green',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2704: {
        id: 'AB2704',
        version: 'v2',
        question:
          "You're in a line of traffic. What action should you take if the driver behind is following very closely?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'B',
        additionalInfo:
          "If the driver behind is following too closely, there's a danger they'll collide with the back of your vehicle if you stop suddenly. You can reduce this risk by slowing down and increasing the safety margin in front of you. This reduces the chance that you'll have to stop suddenly and allows you to spread your braking over a greater distance. This is an example of defensive driving.",
        options: {
          A: 'Ignore the driver behind and continue to travel within the speed limit',
          B: 'Slow down, gradually increasing the gap between you and the vehicle in front',
          C: 'Signal left and wave the driver behind to come past',
          D: 'Move over to a position just left of the centre line of the road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1011: {
        id: 'BB1011',
        version: 'v2',
        question:
          "You're driving on a clear night. Which lights should you use if the national speed limit applies and there's a steady stream of oncoming traffic?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'C',
        additionalInfo:
          "Use the full-beam headlights only when you can be sure that you won't dazzle other road users.",
        options: {
          A: 'Full-beam headlights',
          B: 'Sidelights',
          C: 'Dipped headlights',
          D: 'Fog lights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1012: {
        id: 'BB1012',
        version: 'v2',
        question:
          "You're driving behind a large goods vehicle. What should you do if it signals left but steers to the right?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'A',
        additionalInfo:
          "Large, long vehicles need extra room when making turns at junctions. They may move out to the right in order to make a left turn. Keep well back and don't attempt to pass them on their left.",
        options: {
          A: 'Slow down and let the vehicle turn',
          B: 'Drive on, keeping to the left',
          C: 'Overtake on the right of it',
          D: 'Hold your speed and sound your horn',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1013: {
        id: 'BB1013',
        version: 'v2',
        question:
          "You're driving along this road. What should you do if the red car cuts in close in front of you?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: {
          fileName: '85BW1457.jpg',
          width: 800,
          height: 532,
          size: 72623,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW1457.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'There are times when other drivers make incorrect or ill-judged decisions. Be tolerant and try not to retaliate or react aggressively. Always consider the safety of other road users, your passengers and yourself.',
        options: {
          A: 'Accelerate to get closer to the red car',
          B: 'Give a long blast on the horn',
          C: 'Drop back to leave the correct separation distance',
          D: 'Flash your headlights several times',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1014: {
        id: 'BB1014',
        version: 'v2',
        question:
          "You're waiting in a traffic queue at night. How can you avoid dazzling drivers behind you?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'A',
        additionalInfo:
          "In queuing traffic, your brake lights can dazzle drivers behind you. If you apply your parking brake, you can take your foot off the footbrake. This will turn off the brake lights so that they can't dazzle the driver behind you.",
        options: {
          A: 'Use the parking brake and release the footbrake',
          B: 'Keep your foot on the footbrake',
          C: 'Balance the clutch with the accelerator',
          D: 'Use the parking brake and footbrake together',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1016: {
        id: 'BB1016',
        version: 'v2',
        question:
          "You're driving in traffic at the speed limit for the road. What should you do if the driver behind is trying to overtake?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'C',
        additionalInfo:
          "Keep a steady course to give the driver behind an opportunity to overtake safely. If necessary, slow down. Reacting incorrectly to another driver's impatience can lead to danger.",
        options: {
          A: 'Move closer to the car ahead, so the driver behind has no room to overtake',
          B: "Wave the driver behind to overtake when it's safe",
          C: 'Keep a steady course and allow the driver behind to overtake',
          D: 'Accelerate to get away from the driver behind',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1022: {
        id: 'BB1022',
        version: 'v2',
        question:
          'What does it mean if the signs at a bus lane show no times of operation?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: {
          fileName: 'Bus Lane_no_times.png',
          width: 800,
          height: 777,
          size: 56609,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FBus%20Lane_no_times.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'Bus-lane signs show the vehicles allowed to use the lane and its times of operation. Where no times are shown, the bus lane is in operation 24 hours a day.',
        options: {
          A: "The lane isn't in operation",
          B: 'The lane is only in operation at peak times',
          C: 'The lane is in operation 24 hours a day',
          D: 'The lane is only in operation in daylight hours',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1026: {
        id: 'BB1026',
        version: 'v2',
        question:
          'What should you do when a person herding sheep asks you to stop?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'B',
        additionalInfo:
          'If someone in charge of animals asks you to stop, you should do so and switch off your engine. Animals are unpredictable and startle easily; they could turn and run into your path or into the path of another moving vehicle.',
        options: {
          A: 'Ignore them as they have no authority',
          B: 'Stop and switch off your engine',
          C: 'Continue on but drive slowly',
          D: 'Try to get past quickly',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1027: {
        id: 'BB1027',
        version: 'v2',
        question:
          "What should you do when you're overtaking a horse and rider?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'D',
        additionalInfo:
          "Horses can be startled by the sound of a car engine or the rush of air caused by a vehicle passing too closely. Keep well back and only pass when it's safe. Leave them plenty of room; you may have to use the other side of the road to go past safely.",
        options: {
          A: 'Sound your horn as a warning',
          B: 'Go past as quickly as possible',
          C: 'Flash your headlights as a warning',
          D: 'Go past slowly and carefully',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1440: {
        id: 'BB1440',
        version: 'v2',
        question:
          "You're approaching a zebra crossing. What should you do if pedestrians are waiting to cross?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'B',
        additionalInfo:
          "As you approach a zebra crossing, look for pedestrians waiting to cross. Where you can see them, slow down and prepare to stop. Be especially careful of children and older people, who may have difficulty judging when it's safe to cross.",
        options: {
          A: 'Give way to older and infirm people only',
          B: 'Slow down and prepare to stop',
          C: 'Use your headlights to indicate they can cross',
          D: 'Wave at them to cross the road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1444: {
        id: 'BB1444',
        version: 'v2',
        question:
          'What should you do if a vehicle pulls out in front of you at a junction?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'C',
        additionalInfo:
          "Try to anticipate what other drivers might do. Look and plan ahead so that you're ready to respond safely if a hazard develops. Be tolerant of road users who make mistakes.",
        options: {
          A: 'Swerve past it and sound your horn',
          B: 'Flash your headlights and drive up close behind',
          C: 'Slow down and be ready to stop',
          D: 'Accelerate past it immediately',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1555: {
        id: 'BB1555',
        version: 'v2',
        question:
          "You're approaching a red light at a puffin crossing. Pedestrians are on the crossing. When will the red light change?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'B',
        additionalInfo:
          "A sensor will automatically detect that the pedestrians have reached a safe position. Don't drive on until the green light shows and it's safe for you to do so.",
        options: {
          A: 'When you start to edge forward onto the crossing',
          B: 'When the pedestrians have cleared the crossing',
          C: 'When the pedestrians push the button on the far side of the crossing',
          D: 'When a driver from the opposite direction reaches the crossing',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1591: {
        id: 'BB1591',
        version: 'v2',
        question:
          'Which instrument-panel warning light would show that headlights are on main beam?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'A',
        additionalInfo:
          "You should be aware of all the warning lights and visual aids on the vehicle you're driving. If you're driving a vehicle for the first time, you should familiarise yourself with all the controls, warning lights and visual aids before you set off.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4035f.png',
            width: 210,
            height: 210,
            size: 34388,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4035f.png?alt=media',
          },
          B: {
            fileName: 'TS4035c.png',
            width: 210,
            height: 210,
            size: 24823,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4035c.png?alt=media',
          },
          C: {
            fileName: 'TS4035i.png',
            width: 208,
            height: 208,
            size: 40040,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4035i.png?alt=media',
          },
          D: {
            fileName: 'TS4039.png',
            width: 400,
            height: 393,
            size: 10754,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4039.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      BB1636: {
        id: 'BB1636',
        version: 'v2',
        question:
          'When should you leave a two-second gap between your vehicle and the one in front?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'B',
        additionalInfo:
          'In good, dry conditions, a driver needs to keep a distance of at least two seconds from the car in front. This should allow enough space for you to stop if the driver in front has to stop suddenly.',
        options: {
          A: "When it's raining",
          B: "When it's dry",
          C: "When it's icy",
          D: "When it's foggy",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1637: {
        id: 'BB1637',
        version: 'v2',
        question:
          "You're driving at night on an unlit road. What should you do if you're following another vehicle?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'B',
        additionalInfo:
          'If you follow another vehicle with your headlights on full beam, they could dazzle the driver. Leave a safe distance and make sure that the light from your dipped beam falls short of the vehicle in front.',
        options: {
          A: 'Flash your headlights',
          B: 'Use dipped headlights',
          C: 'Switch off your headlights',
          D: 'Use full-beam headlights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1638: {
        id: 'BB1638',
        version: 'v2',
        question:
          "What should you do if you're driving a slow-moving vehicle on a narrow winding road?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'C',
        additionalInfo:
          "If you're driving a slow-moving vehicle along a narrow road, try not to hold up faster traffic. If you see vehicles following behind, pull over in a safe place and let the traffic pass before continuing. Don't wave other traffic past - this could be dangerous if you or they haven't noticed a hazard ahead.",
        options: {
          A: 'Keep well out to stop vehicles overtaking dangerously',
          B: 'Wave the vehicles behind to come past you if you think they can overtake quickly',
          C: 'Pull in when you can, to let the vehicles behind overtake',
          D: "Give a left signal when it's safe for vehicles to overtake you",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1642: {
        id: 'BB1642',
        version: 'v2',
        question:
          "You're driving a car that has a diesel engine. What can a loose filler cap on your fuel tank cause?",
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'B',
        additionalInfo:
          "Diesel fuel can spill out if your filler cap isn't secured properly. This is most likely to occur on bends, junctions and roundabouts, where it will make the road slippery, especially if it's wet. At the end of a spell of dry weather, road surfaces may be especially slippery where diesel has been spilled but it hasn't been washed away by rain.",
        options: {
          A: 'It can make the engine difficult to start',
          B: 'It can make the roads slippery for other road users',
          C: "It can improve your vehicle's fuel consumption",
          D: 'It can increase the level of exhaust emissions',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1643: {
        id: 'BB1643',
        version: 'v2',
        question: 'What should you do to avoid fuel spillage?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'D',
        additionalInfo:
          "When learning to drive, it's a good idea to practise filling your car with fuel. Ask your instructor if you can use a petrol station and fill the fuel tank yourself. You need to know where the filler cap is on the car you're driving, so you know which side of the pump to park at. Take care not to overfill the tank and make sure you secure the filler cap correctly, so that no fuel leaks onto the road while you're driving.",
        options: {
          A: 'Check that your tank is only three-quarters full',
          B: "Check that you've used a locking filler cap",
          C: 'Check that your fuel gauge is working',
          D: 'Check that your filler cap is securely fastened',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1725: {
        id: 'BB1725',
        version: 'v2',
        question: 'What style of driving causes increased risk to everyone?',
        questionSetId: 'ATTITUDE',
        questionSetTitle: 'Attitude',
        image: null,
        answer: 'C',
        additionalInfo:
          "Competitive driving increases the risks to everyone and is the opposite of responsible, considerate and defensive driving. Defensive driving is about questioning the actions of other road users and being prepared for the unexpected. Don't be taken by surprise.",
        options: {
          A: 'Considerate',
          B: 'Defensive',
          C: 'Competitive',
          D: 'Responsible',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2045: {
        id: 'AB2045',
        version: 'v2',
        question: 'How would under-inflated tyres affect your vehicle?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          'Your tyres are your only contact with the road. To prevent problems with braking and steering, keep your tyres free from defects; they must have sufficient tread depth and be correctly inflated. Correct tyre pressures help reduce the risk of skidding and provide a safer and more comfortable drive or ride.',
        options: {
          A: "The vehicle's stopping distance would increase",
          B: "The flash rate of the vehicle's indicators would increase",
          C: "The vehicle's gear change mechanism would become stiff",
          D: "The vehicle's headlights would aim high",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2056: {
        id: 'AB2056',
        version: 'v2',
        question: "When are you not allowed to sound your vehicle's horn?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          "Every effort must be made to prevent excessive noise, especially in built-up areas at night. Don't rev your engine or sound the horn unnecessarily. It's illegal to sound your horn in a built-up area between 11.30 pm and 7.00 am, except when another road user poses a danger.",
        options: {
          A: 'Between 10.00 pm and 6.00 am in a built-up area',
          B: 'At any time in a built-up area',
          C: 'Between 11.30 pm and 7.00 am in a built-up area',
          D: 'Between 11.30 pm and 6.00 am on any road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2060: {
        id: 'AB2060',
        version: 'v2',
        question:
          "What makes the vehicle in the picture 'environmentally friendly'?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: {
          fileName: 'TS4681.png',
          width: 800,
          height: 498,
          size: 25028,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4681.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Trams are powered by electricity and therefore don't emit exhaust fumes. They ease traffic congestion by offering drivers an alternative to using their car, particularly in busy cities and towns.",
        options: {
          A: "It's powered by gravity",
          B: "It's powered by diesel",
          C: "It's powered by electricity",
          D: "It's powered by unleaded petrol",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2062: {
        id: 'AB2062',
        version: 'v2',
        question: "Why have 'red routes' been introduced in major cities?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          'Inconsiderate parking can obstruct the flow of traffic and so make traffic congestion worse. Red routes are designed to prevent this by enforcing strict parking restrictions. Driving slowly in traffic increases fuel consumption and causes a build-up of exhaust fumes.',
        options: {
          A: 'To raise the speed limits',
          B: 'To help the traffic flow',
          C: 'To provide better parking',
          D: 'To allow lorries to load more freely',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2064: {
        id: 'AB2064',
        version: 'v2',
        question: "What's the purpose of road humps, chicanes and narrowings?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'Traffic-calming measures help to keep vehicle speeds low in congested areas where there are pedestrians and children. A pedestrian is much more likely to survive a collision with a vehicle travelling at 20 mph than they are with a vehicle travelling at 40 mph.',
        options: {
          A: 'To separate lanes of traffic',
          B: 'To increase traffic speed',
          C: 'To allow pedestrians to cross',
          D: 'To reduce traffic speed',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2508: {
        id: 'AB2508',
        version: 'v2',
        question: "What's the purpose of a catalytic converter?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          'Catalytic converters reduce a large percentage of harmful exhaust emissions. They work more efficiently when the engine has reached its normal working temperature.',
        options: {
          A: 'To reduce fuel consumption',
          B: 'To reduce the risk of fire',
          C: 'To reduce harmful exhaust gases',
          D: 'To reduce engine wear',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2550: {
        id: 'AB2550',
        version: 'v2',
        question: 'When should tyre pressures be checked?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'Check the tyre pressures when the tyres are cold. This will give you a more accurate reading. The heat generated on a long journey will raise the pressure inside the tyre.',
        options: {
          A: 'After any lengthy journey',
          B: 'After travelling at high speed',
          C: 'When tyres are hot',
          D: 'When tyres are cold',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2637: {
        id: 'AB2637',
        version: 'v2',
        question: 'When will your vehicle use more fuel?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          "Check your tyre pressures frequently - normally once a week. If they're lower than those recommended by the manufacturer, there will be more 'rolling resistance'. The engine will have to work harder to overcome this, leading to increased fuel consumption.",
        options: {
          A: 'When its tyres are under-inflated',
          B: 'When its tyres are of different makes',
          C: 'When its tyres are over-inflated',
          D: 'When its tyres are new',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2638: {
        id: 'AB2638',
        version: 'v2',
        question: 'How should you dispose of a used vehicle battery?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          'Batteries contain acid, which is hazardous, and they must be disposed of safely. This means taking them to an appropriate disposal site.',
        options: {
          A: 'Bury it in your garden',
          B: 'Put it in the dustbin',
          C: 'Take it to a local-authority disposal site',
          D: 'Leave it on waste land',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2641: {
        id: 'AB2641',
        version: 'v2',
        question: "What's most likely to increase fuel consumption?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'Accelerating and braking gently and smoothly will help to save fuel and reduce wear on your vehicle. This makes it better for the environment too.',
        options: {
          A: 'Poor steering control',
          B: 'Accelerating around bends',
          C: 'Staying in high gears',
          D: 'Harsh braking and accelerating',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2647: {
        id: 'AB2647',
        version: 'v2',
        question:
          'The fluid level in your battery is low. What fluid should you use to top it up?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          'Some modern batteries are maintenance free. Check your vehicle handbook and, if necessary, make sure that the plates in each battery cell are covered with fluid.',
        options: {
          A: 'Battery acid',
          B: 'Distilled water',
          C: 'Engine oil',
          D: 'Engine coolant',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2689: {
        id: 'AB2689',
        version: 'v2',
        question:
          "You're parked on the road at night. When must you use parking lights?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          'When parking at night, park in the direction of the traffic. This will enable other road users to see the reflectors on the rear of your vehicle. Use your parking lights if the speed limit is over 30 mph.',
        options: {
          A: 'When there are continuous white lines in the middle of the road',
          B: 'When the speed limit exceeds 30 mph',
          C: "When you're facing oncoming traffic",
          D: "When you're near a bus stop",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2707: {
        id: 'AB2707',
        version: 'v2',
        question:
          'How can you reduce the environmental harm caused by your motor vehicle?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "Engines that burn fossil fuels produce exhaust emissions that are harmful to health. The harder you make the engine work, the more emissions it will produce. Engines also use more fuel and produce higher levels of emissions when they're cold. Anything you can do to reduce your use of fossil fuels will help the environment.",
        options: {
          A: 'Only use it for short journeys',
          B: "Don't service it",
          C: 'Drive faster than normal',
          D: 'Keep engine revs low',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2708: {
        id: 'AB2708',
        version: 'v2',
        question: 'What can cause excessive or uneven tyre wear?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          'If you see that parts of the tread on your tyres are wearing before others, it may indicate a brake, suspension or wheel-alignment fault. Regular servicing will help to detect faults at an early stage and this will avoid the risk of minor faults becoming serious or even dangerous.',
        options: {
          A: 'A faulty gearbox',
          B: 'A faulty braking system',
          C: 'A faulty electrical system',
          D: 'A faulty exhaust system',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2710: {
        id: 'AB2710',
        version: 'v2',
        question:
          'You need to top up your battery with distilled water. What level should you fill it to?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'Top up the battery with distilled water and make sure each cell plate is covered.',
        options: {
          A: 'The top of the battery',
          B: 'Halfway up the battery',
          C: 'Just below the cell plates',
          D: 'Just above the cell plates',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2800: {
        id: 'AB2800',
        version: 'v2',
        question: 'How can you plan your route before starting a long journey?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          'Various route planners are available on the internet. Most of them give you several options, allowing you to choose between the most direct route and quieter roads. They may also identify rest and fuel stops. Print off the directions and take them with you.',
        options: {
          A: 'Check your vehicle handbook',
          B: 'Ask your local garage',
          C: 'Use a route planner on the internet',
          D: 'Consult a travel agent',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2802: {
        id: 'AB2802',
        version: 'v2',
        question:
          'Why is it a good idea to plan your journey to avoid busy times?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          'No-one likes to spend time in traffic queues. Try to avoid busy times related to school or work travel.',
        options: {
          A: "You'll have an easier journey",
          B: "You'll have a more stressful journey",
          C: 'Your journey time will be longer',
          D: 'It will cause more traffic congestion',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2805: {
        id: 'AB2805',
        version: 'v2',
        question:
          'How will your journey be affected by travelling outside the busy times of day?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "If possible, avoid the early morning, late afternoon and early evening 'rush hour'. Doing this should allow you to have a better journey, with fewer delays. This should help you to arrive at your destination feeling less stressed.",
        options: {
          A: 'Your journey will use more fuel',
          B: 'Your journey will take longer',
          C: 'Your journey will be more hazardous',
          D: 'Your journey will have fewer delays',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2813: {
        id: 'AB2813',
        version: 'v2',
        question:
          'You plan your route before starting a journey. Why should you also plan an alternative route?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          "It can be frustrating and worrying to find your planned route is blocked by roadworks or diversions. If you've planned an alternative, you'll feel less stressed and more able to concentrate fully on your driving or riding. If your original route is mostly on motorways, it's a good idea to plan an alternative using non-motorway roads. Always carry a map with you just in case you need to refer to it.",
        options: {
          A: 'Your original route may be blocked',
          B: 'Your maps may have different scales',
          C: 'You may find you have to pay a congestion charge',
          D: 'You may get held up by a tractor',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2818: {
        id: 'AB2818',
        version: 'v2',
        question:
          'You have to arrive on time for an appointment. How should you plan for the journey?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          "Always allow plenty of time for your journey in case of unforeseen problems. Anything can happen; for example, punctures, breakdowns, road closures, diversions and delays. You'll feel less stressed and less inclined to take risks if you aren't 'pushed for time'.",
        options: {
          A: 'Allow plenty of time for the trip',
          B: 'Plan to travel at busy times',
          C: 'Avoid roads with the national speed limit',
          D: 'Prevent other drivers from overtaking',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2830: {
        id: 'AB2830',
        version: 'v2',
        question:
          'What can you expect if you drive using rapid acceleration and heavy braking?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          'Using the controls smoothly can reduce fuel consumption by about 15%, as well as reducing wear and tear on your vehicle. Plan ahead and anticipate changes of speed well in advance. This will reduce the need to accelerate rapidly or brake sharply.',
        options: {
          A: 'Reduced pollution',
          B: 'Increased fuel consumption',
          C: 'Reduced exhaust emissions',
          D: 'Increased road safety',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2940: {
        id: 'AB2940',
        version: 'v2',
        question:
          'What could cause you to crash if the level is allowed to get too low?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          'You should carry out frequent checks on all fluid levels but particularly brake fluid. As the brake pads or shoes wear down, the brake-fluid level will drop. If it drops below the minimum mark on the fluid reservoir, air could enter the hydraulic system and lead to a loss of braking efficiency or even complete brake failure.',
        options: {
          A: 'Anti-freeze level',
          B: 'Brake-fluid level',
          C: 'Battery-water level',
          D: 'Radiator-coolant level',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1036: {
        id: 'BB1036',
        version: 'v2',
        question:
          'What should you do if your anti-lock brakes (ABS) warning light stays on?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "Consult the vehicle handbook or a garage before driving the vehicle any further. Only drive to a garage if it's safe to do so. If you aren't sure, get expert help.",
        options: {
          A: 'Check the brake-fluid level',
          B: 'Check the footbrake free play',
          C: 'Check that the parking brake is released',
          D: 'Have the brakes checked immediately',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1039: {
        id: 'BB1039',
        version: 'v2',
        question:
          "What does it mean if this light comes on while you're driving?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: {
          fileName: 'TS4035i.png',
          width: 208,
          height: 208,
          size: 40040,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4035i.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'If this light comes on, you should have the brake system checked immediately. A faulty braking system could have dangerous consequences.',
        options: {
          A: 'A fault in the braking system',
          B: 'The engine oil is low',
          C: 'A rear light has failed',
          D: "Your seat belt isn't fastened",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1043: {
        id: 'BB1043',
        version: 'v2',
        question:
          "Why is it important to wear suitable shoes when you're driving?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "When you're going to drive, make sure that you're wearing suitable clothing.\r\nComfortable shoes will ensure that you have proper control of the foot pedals.",
        options: {
          A: 'To prevent wear on the pedals',
          B: 'To maintain control of the pedals',
          C: 'To enable you to adjust your seat',
          D: 'To enable you to walk for assistance if you break down',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1045: {
        id: 'BB1045',
        version: 'v2',
        question:
          "If you're involved in a collision, what will reduce the risk of neck injury?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "If you're involved in a collision, head restraints will reduce the risk of neck injury. They must be properly adjusted. Make sure they aren't positioned too low: in a crash, this could cause damage to the neck.",
        options: {
          A: 'An air-sprung seat',
          B: 'Anti-lock brakes',
          C: 'A collapsible steering wheel',
          D: 'A properly adjusted head restraint',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1048: {
        id: 'BB1048',
        version: 'v2',
        question:
          'What does it mean if your vehicle keeps bouncing after you sharply press down and release on the bodywork over a wheel?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'If you find that your vehicle bounces as you drive around a corner or bend in the road, the shock absorbers might be worn. To test your shock absorbers, sharply press down and release above each wheel. If the vehicle continues to bounce, take it to be checked by a qualified mechanic.',
        options: {
          A: 'The tyres are worn',
          B: 'The tyres are under inflated',
          C: 'The vehicle is on soft ground',
          D: 'The shock absorbers are worn',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1052: {
        id: 'BB1052',
        version: 'v2',
        question: 'How will a roof rack affect your car?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "A roof rack increases your car's wind resistance. This will cause an increase in fuel consumption, so you should remove it when it isn't being used. An aerodynamically designed roof rack or box will help reduce wind resistance to a minimum, but the rack or box should still be removed when it isn't in use.",
        options: {
          A: 'There will be less wind noise',
          B: 'The engine will use more oil',
          C: 'The car will accelerate faster',
          D: 'Fuel consumption will increase',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1278: {
        id: 'BB1278',
        version: 'v2',
        question: 'What makes your tyres illegal?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "Your tyres may be of different treads and makes. They can even be second-hand, as long as they're in good condition. They must, however, be intact, without cuts or tears. When checking the side walls for cuts and bulges, don't forget to check the side of the tyre that's hidden from view, under the car.",
        options: {
          A: 'If they were bought second-hand',
          B: 'If they have any large, deep cuts in the side wall',
          C: "If they're of different makes",
          D: 'If they have different tread patterns',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1279: {
        id: 'BB1279',
        version: 'v2',
        question: "What's the legal minimum depth of tread for car tyres?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          'Car tyres must have sufficient depth of tread to give them a good grip on the road surface. The legal minimum for cars is 1.6 mm. This depth should be across the central three-quarters of the breadth of the tyre and around the entire circumference.',
        options: {A: '1 mm', B: '1.6 mm', C: '2.5 mm', D: '4 mm'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1280: {
        id: 'BB1280',
        version: 'v2',
        question:
          "You're carrying two 13-year-old children and their parents in your car. Who's responsible for seeing that the children wear seat belts?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "Seat belts save lives and reduce the risk of injury. If you're carrying passengers under 14 years old, it's your responsibility as the driver to ensure that their seat belts are fastened or they're seated in an approved child restraint.",
        options: {
          A: "The children's parents",
          B: 'You, the driver',
          C: 'The front-seat passenger',
          D: 'The children',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1301: {
        id: 'BB1301',
        version: 'v2',
        question: 'How can drivers help the environment?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          'Rapid acceleration and heavy braking lead to increased \r\n• fuel consumption \r\n• wear on your vehicle. \r\nHaving your vehicle serviced regularly will maintain its efficiency, produce cleaner emissions and reduce the risk of a breakdown.',
        options: {
          A: 'By accelerating harshly',
          B: 'By accelerating gently',
          C: 'By using leaded fuel',
          D: 'By driving faster',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1302: {
        id: 'BB1302',
        version: 'v2',
        question: 'How can you avoid wasting fuel?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          "If you don't have your vehicle serviced regularly, the engine will gradually become less efficient. This will cause increased fuel consumption and, in turn, an increase in the amount of harmful emissions it produces.",
        options: {
          A: 'By having your vehicle serviced regularly',
          B: 'By revving the engine in the lower gears',
          C: 'By keeping an empty roof rack on your vehicle',
          D: 'By driving at higher speeds where possible',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1303: {
        id: 'BB1303',
        version: 'v2',
        question:
          'What could you do to reduce the volume of traffic on the roads?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          'Try not to use your car as a matter of routine. For shorter journeys, consider walking or cycling instead - this is much better for both you and the environment.',
        options: {
          A: 'Drive in a bus lane',
          B: 'Use a car with a smaller engine',
          C: 'Walk or cycle on short journeys',
          D: 'Travel by car at all times',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1304: {
        id: 'BB1304',
        version: 'v2',
        question: "What's most likely to waste fuel?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "Wasting fuel costs you money and also causes unnecessary pollution. Ensuring your tyres are correctly inflated, avoiding carrying unnecessary weight and removing a roof rack that's not in use will all help to reduce your fuel consumption.",
        options: {
          A: 'Reducing your speed',
          B: 'Driving on motorways',
          C: 'Using different brands of fuel',
          D: 'Under-inflated tyres',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1347: {
        id: 'BB1347',
        version: 'v2',
        question:
          'What part of the car does the law require you to keep in good condition?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "Unless exempt, you and your passengers must wear a seat belt (or suitable child restraint). The seat belts in your car must be in good condition and working properly; they'll be checked during its MOT test.",
        options: {
          A: 'The gearbox',
          B: 'The transmission',
          C: 'The door locks',
          D: 'The seat belts',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1348: {
        id: 'BB1348',
        version: 'v2',
        question:
          'How much more fuel will you use by driving at 70 mph, compared with driving at 50 mph?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "Your vehicle will use less fuel if you avoid heavy acceleration. The higher the engine revs, the more fuel you'll use. Using the same gear, and covering the same distance, a vehicle travelling at 70 mph will use about 15% more fuel than it would at 50 mph. However, don't travel so slowly that you inconvenience or endanger other road users.",
        options: {
          A: 'About 5%',
          B: 'About 15%',
          C: 'About 75%',
          D: 'About 100%',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1368: {
        id: 'BB1368',
        version: 'v2',
        question:
          'What should you do if your vehicle pulls to one side when you use the brakes?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          'The brakes on your vehicle must be effective and properly adjusted. If your vehicle pulls to one side when braking, take it to be checked by a qualified mechanic as soon as you can.',
        options: {
          A: 'Increase the pressure in your tyres',
          B: 'Have the brakes checked as soon as possible',
          C: 'Change gear and pump the brake pedal',
          D: 'Use your parking brake at the same time',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1392: {
        id: 'BB1392',
        version: 'v2',
        question: "What will happen if your car's wheels are unbalanced?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "If your wheels are out of balance, it will cause the steering to vibrate at certain speeds. This isn't a fault that will put itself right, so take your vehicle to a garage or tyre fitter to have the wheels rebalanced.",
        options: {
          A: 'The steering will pull to one side',
          B: 'The steering will vibrate',
          C: 'The brakes will fail',
          D: 'The tyres will deflate',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1393: {
        id: 'BB1393',
        version: 'v2',
        question:
          "What can be damaged if you turn the steering wheel when the car isn't moving?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "Turning the steering wheel when the car isn't moving is known as dry steering. It can cause unnecessary wear to the tyres and steering mechanism.",
        options: {
          A: 'The gearbox',
          B: 'The engine',
          C: 'The brakes',
          D: 'The tyres',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1414: {
        id: 'BB1414',
        version: 'v2',
        question:
          "What's the safest thing to do if you have to leave valuables in your car?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          'If you have to leave valuables in your car, lock them out of sight. This is the best way to deter an opportunist thief.',
        options: {
          A: 'Put them in a carrier bag',
          B: 'Park near a school entrance',
          C: 'Lock them out of sight',
          D: 'Park near a bus stop',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1416: {
        id: 'BB1416',
        version: 'v2',
        question: 'What may help to deter a thief from stealing your car?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'Having your car registration number etched on all your windows is a cheap and effective way to deter professional car thieves.',
        options: {
          A: 'Always keeping the headlights on',
          B: 'Fitting reflective glass windows',
          C: 'Always keeping the interior light on',
          D: 'Etching the registration number on the windows',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1418: {
        id: 'BB1418',
        version: 'v2',
        question:
          'What should you remove from your car before leaving it unattended?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'Never leave the vehicle registration document inside your car. This document would help a thief to dispose of your car more easily.',
        options: {
          A: "The car dealer's details",
          B: "The owner's manual",
          C: 'The service record',
          D: 'The vehicle registration document',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1430: {
        id: 'BB1430',
        version: 'v2',
        question:
          'What should you do when leaving your vehicle parked and unattended?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          'An unlocked car is an open invitation to thieves. Leaving the keys in the ignition not only makes your car easy to steal but could also invalidate your insurance.',
        options: {
          A: 'Park near a busy junction',
          B: 'Park in a housing estate',
          C: 'Lock it and remove the key',
          D: 'Leave the left indicator on',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1500: {
        id: 'BB1500',
        version: 'v2',
        question: 'What will reduce fuel consumption?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          "Harsh braking, frequent gear changes and harsh acceleration increase fuel consumption. A car uses less fuel when travelling at a constant low speed in an appropriate high gear. \r\nYou need to look well ahead so you're able to anticipate hazards early. Easing off the accelerator and timing your approach at junctions, for example, can reduce the fuel consumption of your vehicle.",
        options: {
          A: 'Driving more slowly',
          B: 'Accelerating rapidly',
          C: 'Late and heavy braking',
          D: 'Staying in lower gears',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1508: {
        id: 'BB1508',
        version: 'v2',
        question:
          'You service your own vehicle. How should you dispose of the old engine oil?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          "It's illegal to pour engine oil down any drain. Oil is a pollutant and harmful to wildlife. Dispose of it safely at an authorised site.",
        options: {
          A: 'Take it to a local-authority site',
          B: 'Pour it down a drain',
          C: 'Tip it into a hole in the ground',
          D: 'Put it in your dustbin',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1511: {
        id: 'BB1511',
        version: 'v2',
        question: 'Why do MOT tests include an exhaust emission test?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "Emission tests are carried out to make sure your vehicle's engine is operating efficiently. This ensures the pollution produced by the engine is kept to a minimum. If your vehicle isn't serviced regularly, it may fail the MOT emissions test.",
        options: {
          A: 'To recover the cost of expensive garage equipment',
          B: 'To help protect the environment against pollution',
          C: 'To discover which fuel supplier is used the most',
          D: 'To make sure diesel and petrol engines emit the same fumes',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1512: {
        id: 'BB1512',
        version: 'v2',
        question:
          'How can you reduce the damage your vehicle causes to the environment?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'By looking well ahead and recognising hazards in good time, you can avoid late and heavy braking. Watch the traffic flow and look well ahead for potential hazards so you can control your speed in good time. Avoid over-revving the engine and accelerating harshly, as this increases wear to the engine and uses more fuel.',
        options: {
          A: 'Use narrow side streets',
          B: 'Brake heavily',
          C: 'Use busy routes',
          D: 'Anticipate well ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1557: {
        id: 'BB1557',
        version: 'v2',
        question:
          "How will you benefit from following the manufacturer's service schedule for your vehicle?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          "All vehicles need to be serviced to keep working efficiently. An efficient engine uses less fuel and produces fewer harmful emissions than an engine that's running inefficiently. Keeping the vehicle serviced to the manufacturer's schedule should also keep it reliable and reduce the chance of it breaking down.",
        options: {
          A: 'Your vehicle will be cheaper to insure',
          B: 'Your vehicle tax will be lower',
          C: 'Your vehicle will remain reliable',
          D: 'Your journey times will be reduced',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1558: {
        id: 'BB1558',
        version: 'v2',
        question:
          "How should you drive when you're driving along a road that has road humps?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: {
          fileName: 'IMG_1747.jpg',
          width: 800,
          height: 533,
          size: 118000,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1747.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Road humps are there for a reason - to protect vulnerable road users by reducing the speed of traffic. Don't accelerate harshly between the humps. Put the safety of others first and maintain a reduced speed throughout the zone.",
        options: {
          A: 'Maintain a reduced speed throughout',
          B: 'Accelerate quickly between the humps',
          C: 'Always keep to the maximum legal speed',
          D: 'Drive slowly at school times only',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1602: {
        id: 'BB1602',
        version: 'v2',
        question: 'When should you check the engine oil level?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          'An engine can use more oil during long journeys than on shorter trips. Insufficient engine oil is potentially dangerous: it can lead to excessive wear, mechanical breakdown and expensive repairs.\r\nMost cars have a dipstick to allow the oil level to be checked. If not, you should refer to the vehicle handbook.',
        options: {
          A: 'Before a long journey',
          B: 'When the engine is hot',
          C: 'Early in the morning',
          D: 'Every time you drive the car',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1603: {
        id: 'BB1603',
        version: 'v2',
        question:
          "You're having difficulty finding a parking space in a busy town. Can you park on the zigzag lines of a zebra crossing?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "It's an offence to park on the zigzag lines of a zebra crossing. You'll be causing an obstruction by obscuring the view of both pedestrians and drivers.",
        options: {
          A: 'No, not unless you stay with your car',
          B: 'Yes, in order to drop off a passenger',
          C: "Yes, if you don't block people from crossing",
          D: 'No, not under any circumstances',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1604: {
        id: 'BB1604',
        version: 'v2',
        question:
          'What should you do when you leave your car unattended for a few minutes?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          "Always switch off the engine, remove the key and lock your car, even if you're only leaving it for a few minutes.",
        options: {
          A: 'Leave the engine running',
          B: 'Switch the engine off but leave the key in',
          C: 'Lock it and remove the key',
          D: 'Park near a traffic warden',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1607: {
        id: 'BB1607',
        version: 'v2',
        question: 'Why should you try and park in a secure car park?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          'Whenever possible, leave your car in a secure car park. This will help deter thieves.',
        options: {
          A: 'It makes it easy to find your car',
          B: 'It helps deter thieves',
          C: 'It stops the car being exposed to bad weather',
          D: "It doesn't cost anything to park here",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1609: {
        id: 'BB1609',
        version: 'v2',
        question: 'Where would parking your vehicle cause an obstruction?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "Don't park your vehicle where it may obstruct access to a business or property. Think carefully before you slow down and stop. Look at road markings and signs to ensure that you aren't parking illegally.",
        options: {
          A: 'Alongside a parking meter',
          B: 'In front of a property entrance',
          C: 'On your driveway',
          D: 'In a marked parking space',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1640: {
        id: 'BB1640',
        version: 'v2',
        question:
          "What's the most important reason for having a properly adjusted head restraint?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          'In a collision, rapid deceleration will violently throw vehicle occupants forward and then backwards as the vehicle stops. Seat belts and airbags protect occupants against the forward movement. Head restraints should be adjusted so they give maximum protection to the head and neck during the backward movement.',
        options: {
          A: 'To make you more comfortable',
          B: 'To help you avoid neck injury',
          C: 'To help you relax',
          D: 'To help you maintain your driving position',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1644: {
        id: 'BB1644',
        version: 'v2',
        question:
          'What can you do to reduce environmental damage caused by your vehicle?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'Avoid using your car for short journeys. On a short journey, the engine is unlikely to warm up fully and will therefore be running less efficiently. This will result in the car using more fuel and emitting higher levels of harmful emissions.',
        options: {
          A: 'Avoid using the cruise control',
          B: 'Use the air conditioning whenever you drive',
          C: 'Use the gears to slow the vehicle',
          D: 'Avoid making a lot of short journeys',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1646: {
        id: 'BB1646',
        version: 'v2',
        question:
          'What can people who live or work in towns and cities do to help reduce urban pollution levels?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          "Using a vehicle for short journeys means the engine doesn't have time to reach its normal operating temperature. When an engine is running below its normal operating temperature, it produces increased amounts of pollution. Walking and cycling don't create pollution and have health benefits as well.",
        options: {
          A: 'Drive more quickly',
          B: 'Over-rev in a low gear',
          C: 'Walk or cycle',
          D: 'Drive short journeys',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1647: {
        id: 'BB1647',
        version: 'v2',
        question:
          'How can you reduce the chances of your car being broken into when leaving it unattended?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          'When leaving your car, take all valuables with you if you can. Otherwise, lock them out of sight.',
        options: {
          A: 'Take all valuables with you',
          B: 'Park near a taxi rank',
          C: 'Place any valuables on the floor',
          D: 'Park near a fire station',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1648: {
        id: 'BB1648',
        version: 'v2',
        question: 'How can you help to prevent your car radio being stolen?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "A security-coded radio can deter thieves, as it's likely to be of little use when removed from the vehicle.",
        options: {
          A: 'Park in an unlit area',
          B: 'Leave the radio turned on',
          C: 'Park near a busy junction',
          D: 'Install a security-coded radio',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1653: {
        id: 'BB1653',
        version: 'v2',
        question:
          'How can you reduce the risk of your vehicle being broken into at night?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          'Having your vehicle broken into or stolen can be very distressing and inconvenient. Avoid leaving your vehicle unattended in poorly lit areas.',
        options: {
          A: 'Leave it in a well-lit area',
          B: 'Park in a quiet side road',
          C: "Don't engage the steering lock",
          D: 'Park in a poorly lit area',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1654: {
        id: 'BB1654',
        version: 'v2',
        question: 'What will help you to keep your car secure?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "The Vehicle Watch scheme helps to reduce the risk of your car being stolen. By displaying high-visibility Vehicle Watch stickers in your car, you're inviting the police to stop your vehicle if it's seen in use between midnight and 5 am.",
        options: {
          A: 'Being a member of a vehicle breakdown organisation',
          B: 'Registering with a Vehicle Watch scheme',
          C: 'Passing an advanced driving test',
          D: 'Taking car maintenance classes',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1655: {
        id: 'BB1655',
        version: 'v2',
        question: 'On a vehicle, where would you find a catalytic converter?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'Although carbon dioxide is still produced, a catalytic converter fitted to the exhaust system reduces the toxic and polluting gases by up to 90%.',
        options: {
          A: 'In the fuel tank',
          B: 'In the air filter',
          C: 'On the cooling system',
          D: 'On the exhaust system',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1710: {
        id: 'BB1710',
        version: 'v2',
        question: 'What can you achieve if you drive smoothly?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          "By driving smoothly, you'll not only save about 15% of your fuel but will also reduce the amount of wear and tear on your vehicle and the level of pollution it produces. You're also likely to feel more relaxed and have a more pleasant journey.",
        options: {
          A: 'Reduction in journey times by about 15%',
          B: 'Increase in fuel consumption by about 15%',
          C: 'Reduction in fuel consumption by about 15%',
          D: 'Increase in journey times by about 15%',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1711: {
        id: 'BB1711',
        version: 'v2',
        question: 'Which driving technique can help you save fuel?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'Missing out intermediate gears, when appropriate, helps to reduce the amount of time spent accelerating and decelerating - the times when your vehicle uses the most fuel.',
        options: {
          A: 'Using lower gears as often as possible',
          B: 'Accelerating sharply in each gear',
          C: 'Using each gear in turn',
          D: 'Missing out some gears',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1722: {
        id: 'BB1722',
        version: 'v2',
        question:
          'How can driving in a fuel-efficient manner help protect the environment?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'Fuel-efficient driving is all about looking and planning further ahead. This helps raise your hazard awareness and reduces the need for late and heavy braking. This will make your journeys more comfortable, as well as considerably reducing your fuel bills and reducing emissions that can damage the environment.',
        options: {
          A: 'Through the legal enforcement of speed regulations',
          B: 'By increasing the number of cars on the road',
          C: 'Through increased fuel bills',
          D: 'By reducing exhaust emissions',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1723: {
        id: 'BB1723',
        version: 'v2',
        question: 'What does fuel-efficient driving achieve?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "The emphasis is on hazard awareness and planning ahead. By looking well ahead, you'll have plenty of time to deal with hazards safely and won't need to brake sharply. This will also reduce damage to the environment.",
        options: {
          A: 'Increased fuel consumption',
          B: 'Improved road safety',
          C: 'Damage to the environment',
          D: 'Increased exhaust emissions',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1737: {
        id: 'BB1737',
        version: 'v2',
        question:
          "What's the legal minimum tread depth for tyres on your trailer or caravan?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "Trailers and caravans may be left in storage over the winter months, and tyres can deteriorate. It's important to check their tread depth and also their pressures and general condition. The legal tread depth of 1.6 mm applies to the central three-quarters of a tyre's breadth, over its entire circumference.",
        options: {A: '1 mm', B: '1.6 mm', C: '2 mm', D: '2.6 mm'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1741: {
        id: 'BB1741',
        version: 'v2',
        question: 'When is fuel consumption at its highest?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          'Accelerating uses a lot of fuel, so always try to use the accelerator smoothly. Taking your foot off the accelerator allows the momentum of the car to take you forward, especially when going downhill. This can save a considerable amount of fuel without any loss of control over the vehicle.',
        options: {
          A: "When you're braking",
          B: "When you're coasting",
          C: "When you're accelerating",
          D: "When you're turning sharply",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1752: {
        id: 'BB1752',
        version: 'v2',
        question:
          'When may a passenger travel in a car without wearing a seat belt?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "If you have adult passengers, it's their responsibility to wear a seat belt, but you should still remind them to use one as they get in the car. It's your responsibility to make sure that all children in your car are secured with an appropriate restraint. Exemptions are allowed for those with a medical exemption certificate.",
        options: {
          A: "When they're under 14 years old",
          B: "When they're under 1.5 metres (5 feet) in height",
          C: "When they're sitting in the rear seat",
          D: "When they're exempt for medical reasons",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1755: {
        id: 'BB1755',
        version: 'v2',
        question:
          "You're driving a friend's children home from school. They're both under 14 years old. Who's responsible for making sure they wear a seat belt or approved child restraint where required?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          "Passengers should always be secured and safe. Children should be encouraged to fasten their seat belts or approved restraints themselves from an early age, so that it becomes a matter of routine. As the driver, you must check that they're fastened securely. It's your responsibility.",
        options: {
          A: 'An adult passenger',
          B: 'The children',
          C: 'You, the driver',
          D: 'Your friend',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1756: {
        id: 'BB1756',
        version: 'v2',
        question:
          "What's likely to happen if you put too much oil in your engine?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'Too much oil will lead to increased pressure in the engine. This could damage oil seals and lead to oil leaks. Any excess oil should be drained off.',
        options: {
          A: 'The clutch pedal will lock',
          B: 'The air intake will become blocked',
          C: 'The timing belt will slip',
          D: 'The oil seals will leak',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1763: {
        id: 'BB1763',
        version: 'v2',
        question:
          "You have to make an unexpected journey. You're carrying a five-year-old child on the back seat of your car. They're under 1.35 metres (4 feet 5 inches) tall. How should you seat them if a correct child restraint isn't available?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "In journeys of unexpected necessity, and when a correct child restraint isn't available, the child must sit on the rear seat and use an adult seat belt. In a collision, unrestrained objects and people can cause serious injury or even death.",
        options: {
          A: 'Behind the passenger seat',
          B: 'Using an adult seat belt',
          C: 'Sharing a belt with an adult',
          D: 'Between two other children',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1765: {
        id: 'BB1765',
        version: 'v2',
        question:
          "You're carrying an 11-year-old child on the front seat of your car. They're under 1.35 metres (4 feet 5 inches) tall. What seat belt security must be in place?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          "As the driver, it's your responsibility to make sure that children are secure and safe in your vehicle. Make yourself familiar with the rules. When children are carried on the back seat, there are a few very exceptional cases when an adult seat belt can be used instead of a correct child restraint.",
        options: {
          A: 'They must use an adult seat belt',
          B: 'They must be able to fasten their own seat belt',
          C: 'They must use a suitable child restraint',
          D: 'They must be able to see clearly out of the front window',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1769: {
        id: 'BB1769',
        version: 'v2',
        question:
          "You're stopped at the side of the road. What must you do if you'll be waiting there for some time?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          "If your vehicle is stationary and is likely to remain so for some time, you must switch off the engine unless you're stationary in traffic or diagnosing a fault.",
        options: {
          A: 'Switch off the engine',
          B: 'Apply the steering lock',
          C: 'Switch off the radio',
          D: 'Use your headlights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1770: {
        id: 'BB1770',
        version: 'v2',
        question:
          'You want to put a rear-facing baby seat on the front passenger seat. What must you do if the passenger seat is protected by a frontal airbag?',
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          "It's illegal to fit a rear-facing baby seat into a passenger seat protected by an active frontal airbag. If the airbag activates, it could cause serious injury or even death to the child. You must secure it in a different seat or deactivate the relevant airbag. Follow the manufacturer's advice when fitting a baby seat.",
        options: {
          A: 'Deactivate the airbag',
          B: 'Turn the seat to face sideways',
          C: 'Ask a passenger to hold the baby',
          D: 'Put the child in an adult seat belt',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1777: {
        id: 'BB1777',
        version: 'v2',
        question:
          "You're leaving your vehicle parked on a road and unattended. When may you leave the engine running?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'When you leave your vehicle parked on a road, switch off the engine and secure the vehicle. Make sure no valuables are visible, shut all the windows, lock the vehicle, and set the alarm if the vehicle has one.',
        options: {
          A: "If you'll be parking for less than five minutes",
          B: 'If the battery keeps going flat',
          C: 'When parked in a 20 mph zone',
          D: "Never if you're away from the vehicle",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB4417: {
        id: 'BB4417',
        version: 'v2',
        question:
          "What does it mean if the Electronic Stability Control (ESC) indicator lamp lights up while you're driving?",
        questionSetId: 'SAFETY-AND-YOUR-VEHICLE',
        questionSetTitle: 'Safety and your vehicle',
        image: {
          fileName: 'ESC lamp.jpg',
          width: 800,
          height: 800,
          size: 23889,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FESC%20lamp.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "ESC is a computer controlled technology that detects reduced traction and automatically makes corrective adjustments to prevent loss of control. The ESC lamp comes on to alert the driver that the system has activated and the car is approaching its handling limits. It's a powerful driver aid but it cannot save a car once its traction limits have been exceeded.",
        options: {
          A: 'The ESC system has activated',
          B: 'The ESC system has a fault',
          C: 'The ESC system is running a routine test',
          D: 'The ESC system is switched off',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2076: {
        id: 'AB2076',
        version: 'v2',
        question: 'How much can stopping distances increase in icy conditions?',
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'D',
        additionalInfo:
          'Tyre grip is greatly reduced in icy conditions. For this reason, you need to allow up to ten times the stopping distance you would allow on dry roads.',
        options: {
          A: 'Two times',
          B: 'Three times',
          C: 'Five times',
          D: 'Ten times',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2080: {
        id: 'AB2080',
        version: 'v2',
        question:
          "What requires extra care when you're driving or riding in windy conditions?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'D',
        additionalInfo:
          "Always give cyclists plenty of room when overtaking them. You need to give them even more room when it's windy. A sudden gust could easily blow them off course and into your path.",
        options: {
          A: 'Using the brakes',
          B: 'Moving off on a hill',
          C: 'Turning into a narrow road',
          D: 'Passing pedal cyclists',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2624: {
        id: 'AB2624',
        version: 'v2',
        question:
          'Why should you keep well to the left as you approach a right-hand bend?',
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: {
          fileName: 'TS4636.png',
          width: 400,
          height: 354,
          size: 13863,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4636.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'Keeping to the left as you approach right-hand bends will give you an earlier view around the bend and enable you to see any hazards sooner. It also reduces the risk of collision with any oncoming vehicle that may have drifted over the centre line while taking the bend.',
        options: {
          A: 'To improve your view of the road',
          B: "To overcome the effect of the road's slope",
          C: 'To let faster traffic from behind overtake',
          D: 'To be positioned safely if you skid',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2639: {
        id: 'AB2639',
        version: 'v2',
        question:
          "You've just gone through flood water. What should you do to make sure your brakes are working properly?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'B',
        additionalInfo:
          'Water on the brakes will act as a lubricant, causing them to work less efficiently. Using the brakes lightly as you go along will quickly dry them out.',
        options: {
          A: 'Accelerate and keep to a high speed for a short time',
          B: 'Go slowly while gently applying the brakes',
          C: 'Avoid using the brakes at all for a few miles',
          D: 'Stop for at least an hour to allow them time to dry',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2712: {
        id: 'AB2712',
        version: 'v2',
        question:
          'What will be affected if the road surface becomes soft in very hot weather?',
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'D',
        additionalInfo:
          'If the road surface becomes very hot, it can soften. Tyres are unable to grip a soft surface as well as they can a firm dry one. Take care when cornering and braking.',
        options: {
          A: 'The suspension',
          B: 'The exhaust emissions',
          C: 'The fuel consumption',
          D: 'The tyre grip',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2713: {
        id: 'AB2713',
        version: 'v2',
        question:
          'Where is your vehicle most likely to be affected by side winds?',
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'B',
        additionalInfo:
          'In windy conditions, care must be taken on exposed roads. A strong gust of wind can blow you off course. Watch out for other road users who are particularly likely to be affected, such as cyclists, motorcyclists, high-sided lorries and vehicles towing trailers.',
        options: {
          A: 'On a narrow country lane',
          B: 'On an open stretch of road',
          C: 'On a busy stretch of road',
          D: 'On a long, straight road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2740: {
        id: 'AB2740',
        version: 'v2',
        question:
          "You're following a vehicle on a wet road. You stay a safe distance behind it. What should you do if a driver overtakes you and pulls into the gap you've left?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'C',
        additionalInfo:
          "Wet weather will affect the time it takes for you to stop and can affect your control. Your speed should allow you to stop safely and in good time. If another vehicle pulls into the gap you've allowed, ease back until you've regained your stopping distance.",
        options: {
          A: 'Flash your headlights as a warning',
          B: 'Try to overtake safely as soon as you can',
          C: 'Drop back to regain a safe distance',
          D: 'Stay close to the other vehicle until it moves on',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2911: {
        id: 'AB2911',
        version: 'v2',
        question:
          "You're travelling on the motorway. How can you lower the risk of a collision when the vehicle behind is following too closely?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'A',
        additionalInfo:
          "On busy roads, traffic may still travel at high speeds. Don't follow the vehicle in front too closely. If a driver behind seems to be 'pushing' you, gradually increase your distance from the vehicle in front by slowing down gently. This will give you more space in front if you have to brake, and will reduce the risk of a collision involving several vehicles.",
        options: {
          A: 'Increase your distance from the vehicle in front',
          B: 'Brake sharply',
          C: 'Switch on your hazard warning lights',
          D: 'Move onto the hard shoulder and stop',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2942: {
        id: 'AB2942',
        version: 'v2',
        question:
          "You're following other vehicles in fog. You have your headlights on dipped beam. What else can you do to reduce the chances of being in a collision?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'D',
        additionalInfo:
          "When it's foggy, use your headlights on dipped beam. This will help you see and be seen by other road users. If visibility is seriously reduced, consider using front and rear fog lights if you have them. Keep to a sensible speed and don't follow the vehicle in front too closely. If the road is wet and slippery, you'll need to allow twice the normal stopping distance.",
        options: {
          A: 'Keep close to the vehicle in front',
          B: 'Use main beam instead of dipped headlights',
          C: 'Keep up with the faster vehicles',
          D: 'Keep a safe distance from the vehicle in front',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2944: {
        id: 'AB2944',
        version: 'v2',
        question: "What should you do when you're using a contraflow system?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'A',
        additionalInfo:
          "In a contraflow system, you'll be travelling close to oncoming traffic and sometimes in narrow lanes. You should get into the correct lane in good time, obey any temporary speed-limit signs and keep a safe separation distance from the vehicle ahead.",
        options: {
          A: 'Choose an appropriate lane in good time',
          B: 'Switch lanes to make better progress',
          C: 'Increase speed to get through the contraflow more quickly',
          D: 'Follow other motorists closely to avoid long queues',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1059: {
        id: 'BB1059',
        version: 'v2',
        question:
          "How can you avoid wheelspin when you're driving on an icy road?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'A',
        additionalInfo:
          "If you're travelling on an icy road, extra caution will be required to avoid loss of control. Keeping your speed down and using the highest gear possible will reduce the risk of the tyres losing their grip on this slippery surface.",
        options: {
          A: 'Drive at a slow speed in the highest gear possible',
          B: 'Use the parking brake if the wheels start to slip',
          C: 'Brake gently and repeatedly',
          D: 'Drive in a low gear at all times',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1060: {
        id: 'BB1060',
        version: 'v2',
        question: "What's the main cause of skidding?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'B',
        additionalInfo:
          'Skidding is usually caused by driver error. You should always adjust your driving to take account of the road and weather conditions.',
        options: {
          A: 'The weather',
          B: 'The driver',
          C: 'The vehicle',
          D: 'The road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1062: {
        id: 'BB1062',
        version: 'v2',
        question:
          "You're driving in freezing conditions. What should you do as you approach a sharp bend?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'D',
        additionalInfo:
          "Harsh use of the accelerator, brakes or steering is likely to lead to skidding, especially on slippery surfaces. Avoid steering and braking at the same time. In icy conditions, it's very important that you constantly assess what's ahead so that you can take appropriate action in plenty of time.",
        options: {
          A: 'Coast into the bend',
          B: 'Apply your parking brake',
          C: 'Firmly use your footbrake',
          D: 'Slow down gently',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1066: {
        id: 'BB1066',
        version: 'v2',
        question:
          "You're about to start a journey in freezing weather. What part of your vehicle should you clear of ice and snow?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'B',
        additionalInfo:
          'Driving in bad weather increases your risk of having a collision. If you absolutely have to travel, clear your lights, mirrors, number plates and windows of any snow or ice, so that you can see and be seen.',
        options: {
          A: 'The aerial',
          B: 'The windows',
          C: 'The bumper',
          D: 'The boot',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1068: {
        id: 'BB1068',
        version: 'v2',
        question: 'What will help you to move off on a snowy surface?',
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'B',
        additionalInfo:
          "If you attempt to move off in a low gear, there'll be more torque (turning force) at the driven wheels than if you use a higher gear. More torque makes it easier for the tyres to lose grip and so spin the wheels.",
        options: {
          A: "Using the car's lowest gear",
          B: 'Using a higher gear than normal',
          C: 'Using a high engine speed',
          D: 'Using the parking brake and footbrake together',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1069: {
        id: 'BB1069',
        version: 'v2',
        question: "What should you do when you're driving in snowy conditions?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'D',
        additionalInfo:
          "In snowy conditions, be careful with the steering, accelerator and brakes. Braking sharply while you're driving on snow is likely to make your car skid.",
        options: {
          A: 'Brake firmly and quickly',
          B: 'Be ready to steer sharply',
          C: 'Use sidelights only',
          D: 'Brake gently in plenty of time',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1070: {
        id: 'BB1070',
        version: 'v2',
        question:
          "What's the main benefit of driving a four-wheel-drive vehicle?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'A',
        additionalInfo:
          "By driving all four wheels, the vehicle has maximum grip on the road. This grip is especially helpful when travelling on slippery or uneven surfaces. However, having four-wheel drive doesn't replace the skills you need to drive safely.",
        options: {
          A: 'Improved grip on the road',
          B: 'Lower fuel consumption',
          C: 'Shorter stopping distances',
          D: 'Improved passenger comfort',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1073: {
        id: 'BB1073',
        version: 'v2',
        question:
          "You're about to go down a steep hill. What should you do to control the speed of your vehicle?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'C',
        additionalInfo:
          "When driving down a steep hill, gravity will cause your vehicle to speed up. This will make it more difficult for you to stop. To help keep your vehicle's speed under control, select a lower gear to give you more engine braking and make careful use of the brakes.",
        options: {
          A: 'Select a high gear and use the brakes carefully',
          B: 'Select a high gear and use the brakes firmly',
          C: 'Select a low gear and use the brakes carefully',
          D: 'Select a low gear and avoid using the brakes',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1075: {
        id: 'BB1075',
        version: 'v2',
        question: 'What should you do when you park a car facing downhill?',
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'A',
        additionalInfo:
          "Turning the wheels towards the kerb will allow them to act as a chock, preventing any forward movement of the vehicle. It will also help to leave your car in gear, or select 'Park' if you have an automatic.",
        options: {
          A: 'Turn the steering wheel towards the kerb',
          B: 'Park close to the bumper of another car',
          C: 'Park with two wheels on the kerb',
          D: 'Turn the steering wheel away from the kerb',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1076: {
        id: 'BB1076',
        version: 'v2',
        question:
          "You're driving in a built-up area that has traffic-calming measures. What should you do when you approach a road hump?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: {
          fileName: '85BW9958.jpg',
          width: 800,
          height: 532,
          size: 100961,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW9958.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Many towns have road humps as part of traffic-calming measures, designed to slow down traffic. Reduce your speed when driving over them. If you go too fast, you could lose control or damage your car. Look out for pedestrians or cyclists while you're driving in these areas.",
        options: {
          A: 'Move across to the left-hand side of the road',
          B: 'Wait for any pedestrians to cross',
          C: 'Check your mirror and slow down',
          D: 'Stop and check both pavements',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1317: {
        id: 'BB1317',
        version: 'v2',
        question:
          'On what type of road surface may anti-lock brakes be ineffective?',
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'B',
        additionalInfo:
          'Poor contact with the road surface could cause one or more of the tyres to lose grip on the road. This is more likely to happen when braking in poor weather conditions and when the road has a loose, slippery or uneven surface.',
        options: {A: 'Dry', B: 'Loose', C: 'Firm', D: 'Smooth'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1322: {
        id: 'BB1322',
        version: 'v2',
        question:
          "You're driving a vehicle that has anti-lock brakes. How should you apply the footbrake when you need to stop in an emergency?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'D',
        additionalInfo:
          'You may have to stop in an emergency due to a misjudgement by another driver or a hazard arising suddenly, such as a child running out into the road. If your vehicle has anti-lock brakes, you should apply the brakes immediately and keep them firmly applied until you stop.',
        options: {
          A: 'Slowly and gently',
          B: 'Slowly but firmly',
          C: 'Rapidly and gently',
          D: 'Rapidly and firmly',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1351: {
        id: 'BB1351',
        version: 'v2',
        question:
          "You're driving along a country road. You see this sign. What should you do after dealing safely with the hazard?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: {
          fileName: 'TS4516.png',
          width: 537,
          height: 475,
          size: 22398,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4516.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "If your brakes have been thoroughly soaked, you should check that they're working properly before you build up speed again. Before you do this, remember to check your mirrors and consider what's behind you.",
        options: {
          A: 'Check your tyre pressures',
          B: 'Switch on your hazard warning lights',
          C: 'Accelerate briskly',
          D: 'Test your brakes',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1396: {
        id: 'BB1396',
        version: 'v2',
        question: "What would suggest you're driving on an icy road?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'B',
        additionalInfo:
          'Drive extremely carefully when the roads are icy. When travelling on ice, tyres make virtually no noise and the steering feels light and unresponsive.\r\nIn icy conditions, be very gentle when braking, accelerating and steering.',
        options: {
          A: "There's less wind noise",
          B: "There's less tyre noise",
          C: "There's less transmission noise",
          D: "There's less engine noise",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1405: {
        id: 'BB1405',
        version: 'v2',
        question:
          "You're driving along a wet road. How can you tell if your vehicle's tyres are losing their grip on the surface?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'D',
        additionalInfo:
          "If you drive at speed in very wet conditions, your steering may suddenly feel lighter than usual. This means that the tyres have lifted off the surface of the road and are floating on the surface of the water. This is known as aquaplaning. Reduce speed but don't brake until your steering returns to normal.",
        options: {
          A: 'The engine will stall',
          B: 'The steering will feel very heavy',
          C: 'The engine noise will increase',
          D: 'The steering will feel very light',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1448: {
        id: 'BB1448',
        version: 'v2',
        question:
          'In which conditions will your overall stopping distance increase?',
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'A',
        additionalInfo:
          'Extra care should be taken in wet weather. On wet roads, your stopping distance could be double that in dry conditions.',
        options: {
          A: 'In the rain',
          B: 'In fog',
          C: 'At night',
          D: 'In strong winds',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1657: {
        id: 'BB1657',
        version: 'v2',
        question:
          "You're driving on an open road in dry weather. What distance should you keep from the vehicle in front?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'A',
        additionalInfo:
          "One way of checking there's a safe distance between you and the vehicle in front is to use the two-second rule. To check for a two-second time gap, choose a stationary object ahead, such as a bridge or road sign. When the car in front passes the object, say 'Only a fool breaks the two-second rule'. If you reach the object before you finish saying the phrase, you're too close and need to increase the gap.",
        options: {
          A: 'A two-second time gap',
          B: 'One car length',
          C: 'Two metres (6 feet 6 inches)',
          D: 'Two car lengths',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1659: {
        id: 'BB1659',
        version: 'v2',
        question: "How can you use your vehicle's engine as a brake?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'A',
        additionalInfo:
          'When driving on downhill stretches of road, selecting a lower gear gives increased engine braking. This will prevent excessive use of the brakes, which become less effective if they overheat.',
        options: {
          A: 'By changing to a lower gear',
          B: 'By selecting reverse gear',
          C: 'By changing to a higher gear',
          D: 'By selecting neutral gear',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1660: {
        id: 'BB1660',
        version: 'v2',
        question:
          'How should you use anti-lock brakes when you need to stop in an emergency?',
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'C',
        additionalInfo:
          'If you have ABS and need to stop in an emergency, keep your foot firmly on the brake pedal until the vehicle has stopped. When the ABS operates, you may hear a grating sound and feel vibration through the brake pedal. This is normal and you should maintain pressure on the brake pedal until the vehicle stops.',
        options: {
          A: 'Keep pumping the footbrake to prevent skidding',
          B: 'Brake normally but grip the steering wheel tightly',
          C: "Brake promptly and firmly until you've stopped",
          D: 'Apply the parking brake to reduce the stopping distance',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1681: {
        id: 'BB1681',
        version: 'v2',
        question:
          "What lights should you use when you're driving on a wet motorway and vehicles are throwing up surface spray?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'B',
        additionalInfo:
          'When surface spray reduces visibility, switch on your headlights on dipped beam. This will help other road users to see you.',
        options: {
          A: 'Hazard warning lights',
          B: 'Dipped headlights',
          C: 'Rear fog lights',
          D: 'Sidelights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1742: {
        id: 'BB1742',
        version: 'v2',
        question:
          'What can result when you travel for long distances in neutral (known as coasting)?',
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'C',
        additionalInfo:
          "Coasting is the term used when the clutch is held down, or the gear lever is in neutral, and the vehicle is allowed to freewheel. This reduces the driver's control of the vehicle. When you coast, the engine can't drive the wheels to stabilise you through a corner, or give the assistance of engine braking to help slow the car.",
        options: {
          A: 'Improvement in control',
          B: 'Easier steering',
          C: 'Reduction in control',
          D: 'Increased fuel consumption',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1774: {
        id: 'BB1774',
        version: 'v2',
        question:
          'What should you do before starting a journey in foggy weather?',
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'C',
        additionalInfo:
          "Don't venture out if your journey isn't necessary. If you have to travel and someone is expecting you at the other end, let them know that you'll be taking longer than usual for your journey. This will stop them worrying if you don't turn up on time and will also take the pressure off you, so you don't feel you have to rush.",
        options: {
          A: 'Wear a hi-visibility jacket',
          B: 'Have a caffeinated drink',
          C: 'Allow more time',
          D: 'Reduce your tyre pressures',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB2894: {
        id: 'BB2894',
        version: 'v2',
        question:
          "What should you do when you're overtaking a motorcyclist on a windy day?",
        questionSetId: 'SAFETY-MARGINS',
        questionSetTitle: 'Safety margins',
        image: null,
        answer: 'C',
        additionalInfo:
          "In strong winds, riders of two-wheeled vehicles are particularly vulnerable. When you overtake them, allow plenty of room. Check to the left as you pass to make sure they're safe.",
        options: {
          A: 'Pass closely',
          B: 'Pass very slowly',
          C: 'Pass widely',
          D: 'Pass immediately',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2085: {
        id: 'AB2085',
        version: 'v2',
        question: 'Where would you expect to see these markers?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'TS4027.png',
          width: 488,
          height: 629,
          size: 22042,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4027.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "These markers must be fitted to vehicles over 13 metres long, large goods vehicles, and rubbish skips placed in the road. They're reflective to make them easier to see in the dark.",
        options: {
          A: 'On a motorway sign',
          B: 'On a railway bridge',
          C: 'On a large goods vehicle',
          D: 'On a diversion sign',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2087: {
        id: 'AB2087',
        version: 'v2',
        question: "What's the main hazard shown in this picture?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'J7512.png',
          width: 584,
          height: 390,
          size: 465180,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FJ7512.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Look at the picture carefully and try to imagine you're there. The cyclist in this picture appears to be trying to cross the road. You must be able to deal with the unexpected, especially when you're approaching a hazardous junction. Look well ahead to give yourself time to deal with any hazards.",
        options: {
          A: 'Vehicles turning right',
          B: 'Vehicles doing U-turns',
          C: 'The cyclist crossing the road',
          D: 'Parked cars around the corner',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2088: {
        id: 'AB2088',
        version: 'v2',
        question: 'Which road user has caused a hazard?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'R8515.png',
          width: 671,
          height: 390,
          size: 365080,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FR8515.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'The car arrowed A is parked within the area marked by zigzag lines at the pedestrian crossing. Parking here is illegal. It also\r\n• blocks the view for pedestrians wishing to cross the road\r\n• restricts the view of the crossing for approaching traffic.',
        options: {
          A: 'The parked car (arrowed A)',
          B: 'The pedestrian waiting to cross (arrowed B)',
          C: 'The moving car (arrowed C)',
          D: 'The car turning (arrowed D)',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2089: {
        id: 'AB2089',
        version: 'v2',
        question:
          'What should the driver of the car approaching the crossing do?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'P5505.png',
          width: 587,
          height: 390,
          size: 449349,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FP5505.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "Look well ahead to see whether any hazards are developing. This will give you more time to deal with them in the correct way. The man in the picture is clearly intending to cross the road. You should be travelling at a speed that allows you to check your mirror, slow down and stop in good time. You shouldn't have to brake harshly.",
        options: {
          A: 'Continue at the same speed',
          B: 'Sound the horn',
          C: 'Drive through quickly',
          D: 'Slow down and get ready to stop',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2091: {
        id: 'AB2091',
        version: 'v2',
        question:
          'What should the driver of the grey car be especially aware of?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1196.jpg',
          width: 800,
          height: 533,
          size: 105117,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1196.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "When passing parked cars, there's a risk that a driver or passenger may not check before opening the door into the road. A defensive driver will drive slowly and be looking for people who may be about to get out of their car.",
        options: {
          A: 'The uneven road surface',
          B: 'Traffic following behind',
          C: 'Doors opening on parked cars',
          D: 'Empty parking spaces',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2098: {
        id: 'AB2098',
        version: 'v2',
        question: 'What should you expect if you see this sign ahead?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'TS4577.png',
          width: 400,
          height: 96,
          size: 4059,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4577.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'This sign indicates that the road will bend sharply to the left. Slow down in plenty of time and select the correct gear before you start to turn. Braking hard and late, while also sharply changing direction, is likely to cause a skid.',
        options: {
          A: 'The road will go steeply uphill',
          B: 'The road will go steeply downhill',
          C: 'The road will bend sharply to the left',
          D: 'The road will bend sharply to the right',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2099: {
        id: 'AB2099',
        version: 'v2',
        question: 'What should you do as you approach this cyclist?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_5169.jpg',
          width: 800,
          height: 533,
          size: 78652,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_5169.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Keep well back and give the cyclist time and room to turn safely. Don't intimidate them by getting too close or trying to squeeze past.",
        options: {
          A: 'Try to overtake before the cyclist gets to the junction',
          B: 'Flash your headlights at the cyclist',
          C: 'Slow down and allow the cyclist to turn',
          D: "Rev your engine so the cyclist knows you're following behind",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2100: {
        id: 'AB2100',
        version: 'v2',
        question:
          'Why must you take great care when emerging from this junction?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1742.jpg',
          width: 800,
          height: 533,
          size: 77391,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1742.jpg?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "You may have to pull forward slowly until you can see up and down the road. Be aware that the traffic approaching the junction can't see you either. If you don't know that it's clear, don't go.",
        options: {
          A: 'The road surface is poor',
          B: 'The footpath is narrow',
          C: 'The kerbs are high',
          D: 'The view is restricted',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2102: {
        id: 'AB2102',
        version: 'v2',
        question:
          'Which type of vehicle should you be ready to give way to as you approach this bridge?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_9457.jpg',
          width: 800,
          height: 533,
          size: 119670,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_9457.jpg?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "A bus or high-sided lorry will have to take a position in the centre of the road to clear the bridge. There's normally a sign to show this. Look well ahead, past the bridge and be ready to stop and give way to large oncoming vehicles.",
        options: {A: 'Bicycles', B: 'Buses', C: 'Motorcycles', D: 'Cars'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2103: {
        id: 'AB2103',
        version: 'v2',
        question:
          'What type of vehicle could you expect to meet in the middle of the road?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_9457.jpg',
          width: 800,
          height: 533,
          size: 119670,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_9457.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'The highest point of the bridge is in the centre, so a large vehicle might have to move to the centre of the road to have enough room to pass safely under the bridge.',
        options: {A: 'Lorry', B: 'Bicycle', C: 'Car', D: 'Motorcycle'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2105: {
        id: 'AB2105',
        version: 'v2',
        question: 'What must you do at this junction?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1286.jpg',
          width: 800,
          height: 518,
          size: 81985,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1286.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "The 'stop' sign has been put here because the view into the main road is poor. You must stop because it won't be possible to take proper observation while you're moving.",
        options: {
          A: 'Stop behind the line, then edge forward to see clearly',
          B: 'Stop beyond the line, at a point where you can see clearly',
          C: "Stop only if there's traffic on the main road",
          D: "Stop only if you're turning right",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2114: {
        id: 'AB2114',
        version: 'v2',
        question:
          'What should you do if a driver pulls out of a side road in front of you, causing you to brake hard?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'A',
        additionalInfo:
          "Be tolerant if a vehicle emerges and you have to brake quickly. Anyone can make a mistake, so don't react aggressively. Be alert where there are side roads and be especially careful where there are parked vehicles, because these can make it difficult for emerging drivers to see you.",
        options: {
          A: 'Ignore the error and stay calm',
          B: 'Flash your lights to show your annoyance',
          C: 'Sound your horn to show your annoyance',
          D: 'Overtake as soon as possible',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2116: {
        id: 'AB2116',
        version: 'v2',
        question: "How would age affect an older person's driving ability?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'C',
        additionalInfo:
          'As people age, their reaction time gets slower. The rate of decline varies from person to person but you can expect them to take longer to react to a hazard and they may be hesitant in some situations - for example, at a junction.',
        options: {
          A: "They won't be able to obtain car insurance",
          B: "They'll need glasses to read road signs",
          C: "They'll take longer to react to hazards",
          D: "They won't signal at junctions",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2558: {
        id: 'AB2558',
        version: 'v2',
        question:
          "Do you need to plan rest stops when you're planning a long journey?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'B',
        additionalInfo:
          "Try to plan your journey so that you can take rest stops. It's recommended that you take a break of at least 15 minutes after every two hours of driving or riding. This should help to maintain your concentration.",
        options: {
          A: 'Yes, you should plan to stop every half an hour',
          B: 'Yes, regular stops help concentration',
          C: "No, you'll be less tired if you get there as soon as possible",
          D: 'No, only fuel stops will be needed',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2596: {
        id: 'AB2596',
        version: 'v2',
        question:
          'What should you do if the red lights start flashing as you approach a level crossing?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: '85BW4460.jpg',
          width: 800,
          height: 532,
          size: 109633,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW4460.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "At level crossings, the red lights flash before and while the barrier is down. At most crossings, an amber light will precede the red lights. You must stop behind the white line unless you've already crossed it when the amber light comes on. Don't be tempted to zigzag around half-barriers.",
        options: {
          A: 'Go over it quickly',
          B: 'Go over it carefully',
          C: 'Stop before the barrier',
          D: 'Switch on your hazard warning lights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2651: {
        id: 'AB2651',
        version: 'v2',
        question:
          "You're approaching a crossroads. What should you do if the traffic lights have failed?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'D',
        additionalInfo:
          'When approaching a junction where the traffic lights have failed, you should proceed with caution. Treat the situation as an unmarked junction and be prepared to stop.',
        options: {
          A: 'Brake and stop only for large vehicles',
          B: 'Brake sharply to a stop before looking',
          C: 'Be prepared to brake sharply to a stop',
          D: 'Be prepared to stop for any traffic',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2657: {
        id: 'AB2657',
        version: 'v2',
        question: 'What should the driver of the red car (arrowed) do?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'P5504.png',
          width: 587,
          height: 390,
          size: 400744,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FP5504.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "Some people might take a long time to cross the road. They may be older or have a disability. Be patient and don't hurry them by showing your impatience. If pedestrians are standing at the side of the road, don't signal or wave them to cross. Other road users might not have seen your signal and this could lead the pedestrians into a hazardous situation.",
        options: {
          A: 'Wave towards the pedestrians who are waiting to cross',
          B: 'Wait for the pedestrian in the road to cross',
          C: 'Quickly drive behind the pedestrian in the road',
          D: "Tell the pedestrian in the road she shouldn't have crossed",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2658: {
        id: 'AB2658',
        version: 'v2',
        question:
          "You're following a slower-moving vehicle. What should you do if there's a junction just ahead on the right?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'B',
        additionalInfo:
          'You should never overtake as you approach a junction. If a vehicle emerged from the junction while you were overtaking, a dangerous situation could develop very quickly.',
        options: {
          A: 'Overtake after checking your mirrors and signalling',
          B: "Only consider overtaking when you're past the junction",
          C: 'Accelerate quickly to overtake before reaching the junction',
          D: 'Slow down and prepare to overtake on the left',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2659: {
        id: 'AB2659',
        version: 'v2',
        question: 'What should you do as you approach this overhead bridge?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'R8543.png',
          width: 547,
          height: 390,
          size: 445995,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FR8543.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Oncoming large vehicles may need to move to the middle of the road to pass safely under the bridge. There won't be enough room for you to continue, so you should be ready to stop and wait.",
        options: {
          A: 'Move out to the centre of the road before going through',
          B: 'Find another route; this one is only for high vehicles',
          C: 'Be prepared to give way to large vehicles in the middle of the road',
          D: 'Move across to the right-hand side before going through',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2687: {
        id: 'AB2687',
        version: 'v2',
        question: 'Why are vehicle mirrors often slightly curved (convex)?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'A',
        additionalInfo:
          "Although a convex mirror gives a wide view of the scene behind, you should be aware that it won't show you everything behind or to the side of your vehicle. Before you move off, you'll need to look over your shoulder to check for anything not visible in the mirrors.",
        options: {
          A: 'They give a wider field of vision',
          B: 'They totally cover blind spots',
          C: 'They make it easier to judge the speed of the traffic behind',
          D: 'They make the traffic behind look bigger',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2701: {
        id: 'AB2701',
        version: 'v2',
        question:
          "You're on a three-lane motorway. How should you overtake a slow-moving lorry in the middle lane if it's showing this sign?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'TS4058.png',
          width: 517,
          height: 582,
          size: 22152,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4058.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'This sign is found on slow-moving or stationary works vehicles. If you wish to overtake it, do so on the left, as indicated. Be aware that there might be workmen in the area.',
        options: {
          A: 'Cautiously approach the lorry, then overtake on either side',
          B: 'Follow the lorry until you can leave the motorway',
          C: 'Use the right-hand lane and overtake the lorry normally',
          D: 'Approach with care and overtake on the left of the lorry',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2716: {
        id: 'AB2716',
        version: 'v2',
        question:
          'What should you do if you think the driver of the vehicle in front has forgotten to cancel their right indicator?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'D',
        additionalInfo:
          "Be cautious and don't attempt to overtake. The driver may be unsure of the location of a junction and may turn suddenly.",
        options: {
          A: 'Flash your lights to alert the driver',
          B: 'Sound your horn before overtaking',
          C: "Overtake on the left if there's room",
          D: "Stay behind and don't overtake",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2717: {
        id: 'AB2717',
        version: 'v2',
        question:
          "What's the main hazard the driver of the red car (arrowed) should be aware of?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'R8518.png',
          width: 600,
          height: 390,
          size: 519372,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FR8518.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'If you can do so safely, give way to buses signalling to move off at bus stops. Try to anticipate the actions of other road users around you. The driver of the red car should be prepared for the bus pulling out. As you approach a bus stop, look to see how many passengers are waiting to board. If the last one has just got on, the bus is likely to move off.',
        options: {
          A: "Glare from the sun may affect the driver's vision",
          B: 'The black car may stop suddenly',
          C: 'The bus may move out into the road',
          D: 'Oncoming vehicles will assume the driver is turning right',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2718: {
        id: 'AB2718',
        version: 'v2',
        question: 'What type of vehicle displays this yellow sign?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'TS4546.png',
          width: 481,
          height: 481,
          size: 24069,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4546.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'Buses which carry children to and from school may stop at places other than scheduled bus stops. Be aware that they might pull over at any time to allow children to get on or off. This will normally be when traffic is heavy during rush hour.',
        options: {
          A: 'A broken-down vehicle',
          B: 'A school bus',
          C: 'An ice-cream van',
          D: 'A private ambulance',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2719: {
        id: 'AB2719',
        version: 'v2',
        question:
          'What hazard should you be aware of when travelling along this street?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1291.jpg',
          width: 800,
          height: 533,
          size: 97932,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1291.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'On roads where there are many parked vehicles, you might not be able to see children between parked cars and they may run out into the road without looking.',
        options: {
          A: 'Glare from the sun',
          B: 'Lack of road markings',
          C: 'Children running out between vehicles',
          D: 'Large goods vehicles',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2720: {
        id: 'AB2720',
        version: 'v2',
        question:
          "What's the main hazard you should be aware of when following this cyclist?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1294.jpg',
          width: 800,
          height: 533,
          size: 113520,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1294.jpg?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "When following a cyclist, be aware that they have to deal with the hazards around them. They may wobble or swerve to avoid a pothole in the road or see a potential hazard and change direction suddenly. Don't follow them too closely or rev your engine impatiently.",
        options: {
          A: 'The cyclist may move to the left and dismount',
          B: 'The cyclist may swerve into the road',
          C: 'The cyclist may get off and push their bicycle',
          D: 'The cyclist may wish to turn right at the end of the road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2721: {
        id: 'AB2721',
        version: 'v2',
        question:
          "A driver's behaviour has upset you. How can you get over this incident safely?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'A',
        additionalInfo:
          'If you feel yourself becoming tense or upset, stop in a safe place and take a break. Tiredness can make things worse and may cause a different reaction to upsetting situations.',
        options: {
          A: 'Stop and take a break',
          B: 'Shout abusive language',
          C: 'Gesture to them with your hand',
          D: 'Follow them, flashing your headlights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2723: {
        id: 'AB2723',
        version: 'v2',
        question:
          'How should you drive in areas with traffic-calming measures?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1292.jpg',
          width: 800,
          height: 533,
          size: 104843,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1292.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Traffic-calming measures such as road humps, chicanes and narrowings are intended to slow drivers down to protect vulnerable road users. Don't speed up until you reach the end of the traffic-calmed zone.",
        options: {
          A: 'At a reduced speed',
          B: 'At the speed limit',
          C: 'In the centre of the road',
          D: 'With headlights on dipped beam',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2724: {
        id: 'AB2724',
        version: 'v2',
        question: 'Why should you slow down as you approach this hazard?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'R8531.png',
          width: 693,
          height: 390,
          size: 531517,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FR8531.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'You should be slowing down and selecting the correct gear in case you have to stop at the level crossing. Look for the signals and be prepared to stop if necessary.',
        options: {
          A: 'Because of the level crossing',
          B: "Because it's hard to see to the right",
          C: 'Because of approaching traffic',
          D: 'Because of animals crossing',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2725: {
        id: 'AB2725',
        version: 'v2',
        question: 'Why are place names painted on the road surface?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'C',
        additionalInfo:
          'The names of towns and cities may be painted on the road at busy junctions and complex road systems. They guide you into the correct lane in good time, allowing traffic to flow more freely.',
        options: {
          A: 'To restrict the flow of traffic',
          B: 'To warn of oncoming traffic',
          C: 'To help you select the correct lane in good time',
          D: 'To prevent you from changing lanes',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2736: {
        id: 'AB2736',
        version: 'v2',
        question:
          'Some two-way roads are divided into three lanes. Why are they particularly dangerous?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'A',
        additionalInfo:
          "If you intend to overtake, you must consider that approaching traffic could be planning the same manoeuvre. When you've considered the situation and decided it's safe, indicate your intentions early. This will show the approaching traffic that you intend to pull out.",
        options: {
          A: 'Traffic in both directions can use the middle lane to overtake',
          B: 'Traffic can travel faster in poor weather conditions',
          C: 'Traffic can overtake on the left',
          D: 'Traffic uses the middle lane for emergencies only',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2844: {
        id: 'AB2844',
        version: 'v2',
        question:
          'What type of vehicle uses an amber flashing beacon on a dual carriageway?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'D',
        additionalInfo:
          "An amber flashing beacon on a vehicle indicates that it's moving slowly or stopped and a possible hazard. Look well ahead on a dual carriageway and you should be able to see and respond to these vehicles in good time.",
        options: {
          A: 'An ambulance',
          B: 'A fire engine',
          C: 'A doctor on call',
          D: 'A tractor',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2847: {
        id: 'AB2847',
        version: 'v2',
        question:
          'What does this signal from a police officer mean to oncoming traffic?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: '85BW0962.jpg',
          width: 800,
          height: 532,
          size: 25421,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW0962.jpg?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'Police officers may need to direct traffic; for example, at a junction where the traffic lights have broken down. Check your copy of The Highway Code for the signals that they use.',
        options: {A: 'Go ahead', B: 'Stop', C: 'Turn left', D: 'Turn right'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2848: {
        id: 'AB2848',
        version: 'v2',
        question:
          'Why should you be cautious when going past this bus waiting at a bus stop?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1986.jpg',
          width: 800,
          height: 533,
          size: 82412,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1986.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'A bus at a bus stop can hide pedestrians who might try to cross the road just in front of it. Drive at a speed that will enable you to respond safely if you have to.',
        options: {
          A: "There's a zebra crossing ahead",
          B: 'There are driveways on the left',
          C: 'People may cross the road in front of it',
          D: 'The road surface will be slippery',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2871: {
        id: 'AB2871',
        version: 'v2',
        question: 'Where would it be unsafe to overtake?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'C',
        additionalInfo:
          "You should overtake only when it's really necessary and you can see it's clear ahead. Look out for road signs and markings that show it's illegal or would be unsafe to overtake; for example, approaching junctions or bends. In many cases, overtaking is unlikely to significantly improve your journey time.",
        options: {
          A: 'On a single carriageway',
          B: 'On a one-way street',
          C: 'Approaching a junction',
          D: 'Travelling up a long hill',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2902: {
        id: 'AB2902',
        version: 'v2',
        question: 'How can drinking alcohol affect your ability to drive?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'A',
        additionalInfo:
          "Alcohol will severely reduce your ability to drive or ride safely and there are serious consequences if you're caught over the drink-drive limit. It's known that alcohol can\r\n•  affect your judgement\r\n•  cause overconfidence\r\n•  reduce coordination and control.",
        options: {
          A: 'Your ability to judge speed will be reduced',
          B: 'Your confidence will be reduced',
          C: 'Your reactions will be faster',
          D: 'Your awareness of danger will be improved',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1081: {
        id: 'BB1081',
        version: 'v2',
        question:
          'What does the solid white line at the side of the road indicate?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'DSCF2715.jpg',
          width: 800,
          height: 533,
          size: 102426,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FDSCF2715.jpg?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "The continuous white line shows the edge of the carriageway. It can be especially useful when visibility is restricted, such as at night or in bad weather. It's discontinued in some places; for example, at  junctions, lay-bys, entrances or other openings.",
        options: {
          A: 'Traffic lights ahead',
          B: 'Edge of the carriageway',
          C: 'Footpath on the left',
          D: 'Cycle path',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1085: {
        id: 'BB1085',
        version: 'v2',
        question:
          "You're driving towards this level crossing. What would be the first warning of an approaching train?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: '85BW4461.jpg',
          width: 800,
          height: 532,
          size: 100874,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW4461.jpg?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'The steady amber light will be followed by twin flashing red lights that mean you must stop. An alarm will also sound to alert you to the fact that a train is approaching.',
        options: {
          A: 'Both half-barriers down',
          B: 'A steady amber light',
          C: 'One half-barrier down',
          D: 'Twin flashing red lights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1087: {
        id: 'BB1087',
        version: 'v2',
        question:
          "You're behind this cyclist. When the traffic lights change, what should you do?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1983.jpg',
          width: 800,
          height: 533,
          size: 117644,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1983.jpg?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'Hold back and allow the cyclist to move off. Some junctions have special areas marked across the front of the traffic lane. These allow cyclists to wait for the lights to change and move off ahead of other traffic.',
        options: {
          A: 'Try to move off before the cyclist',
          B: 'Allow the cyclist time and room',
          C: 'Turn right but give the cyclist room',
          D: 'Tap your horn and drive through first',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1095: {
        id: 'BB1095',
        version: 'v2',
        question:
          'You intend to turn left at the traffic lights. What should you do just before turning?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: '85BW6802.jpg',
          width: 800,
          height: 532,
          size: 90584,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW6802.jpg?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "If you've been in a queue of traffic and are about to turn left, check your nearside for cyclists as they often filter past on the nearside of slow-moving or stationary vehicles.",
        options: {
          A: 'Check your right mirror',
          B: 'Move up closer to the car ahead',
          C: 'Move out to the right',
          D: 'Check for bicycles on your left',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1096: {
        id: 'BB1096',
        version: 'v2',
        question: 'Why should you reduce your speed here?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_2355.jpg',
          width: 800,
          height: 533,
          size: 95212,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_2355.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'Traffic could be turning off or pulling out ahead of you, to the left or right. Vehicles turning left will be slowing down before the junction, and any vehicles turning right may have to stop to allow oncoming traffic to clear. Be prepared for this, as you might have to slow down or stop behind them.',
        options: {
          A: 'A staggered junction is ahead',
          B: 'A low bridge is ahead',
          C: 'The road surface changes ahead',
          D: 'The road narrows ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1099: {
        id: 'BB1099',
        version: 'v2',
        question: 'What might you expect to happen in this situation?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1758.jpg',
          width: 800,
          height: 533,
          size: 114426,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1758.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'Be courteous and allow the traffic to merge into the left-hand lane.',
        options: {
          A: 'Traffic will move into the right-hand lane',
          B: 'Traffic speed will increase',
          C: 'Traffic will move into the left-hand lane',
          D: "Traffic won't need to change position",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1100: {
        id: 'BB1100',
        version: 'v2',
        question:
          "You're driving on a road with several lanes. What do these signs above the lanes mean?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'TS4064.png',
          width: 800,
          height: 234,
          size: 25261,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4064.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'On some busy roads, lane control signals are used to vary the number of lanes available to give priority to the main traffic flow. A green arrow indicates that the lane is available to traffic facing the signal. A white diagonal arrow means that the lane is closed ahead and traffic should move to the next lane on the left. A red cross means that the lane is closed to traffic facing the signal.',
        options: {
          A: 'The two right lanes are open',
          B: 'The two left lanes are open',
          C: 'Traffic in the left lanes should stop',
          D: 'Traffic in the right lanes should stop',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1109: {
        id: 'BB1109',
        version: 'v2',
        question:
          "You're invited to a pub lunch. What should you do if you know that you'll have to drive in the evening?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'B',
        additionalInfo:
          'Alcohol will stay in your body for several hours and may make you unfit to drive later in the day. Drinking during the day will also affect your performance at work or study.',
        options: {
          A: 'Avoid mixing your alcoholic drinks',
          B: "Don't drink any alcohol at all",
          C: 'Have some milk before drinking alcohol',
          D: 'Eat a hot meal with your alcoholic drinks',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1112: {
        id: 'BB1112',
        version: 'v2',
        question:
          "What will become more expensive after you've been convicted of driving while unfit through drink or drugs?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'B',
        additionalInfo:
          "You've shown that you're a risk to yourself and others on the road. For this reason, insurance companies may charge you a higher premium.",
        options: {
          A: 'Road fund licence',
          B: 'Insurance premiums',
          C: 'Vehicle test certificate',
          D: 'Driving licence',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1113: {
        id: 'BB1113',
        version: 'v2',
        question:
          'What advice should you give to a driver who has had a few alcoholic drinks at a party?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'C',
        additionalInfo:
          "Drinking black coffee or waiting a few hours won't make any difference. Alcohol takes time to leave the body. \r\nA driver who has been drinking should go home by public transport or taxi. They might even be unfit to drive the following morning.",
        options: {
          A: 'Have a strong cup of coffee and then drive home',
          B: 'Drive home carefully and slowly',
          C: 'Go home by public transport',
          D: 'Wait a short while and then drive home',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1115: {
        id: 'BB1115',
        version: 'v2',
        question:
          "What should you do about driving if you've been taking medicine that causes drowsiness?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'D',
        additionalInfo:
          "You aren't fit to drive if you're taking medicine that makes you drowsy. Check with your doctor if you're unsure. You mustn't put other road users, your passengers or yourself at risk.",
        options: {
          A: 'Only drive if your journey is necessary',
          B: 'Drive on quiet roads',
          C: 'Ask someone to come with you',
          D: 'Avoid driving and check with your doctor',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1116: {
        id: 'BB1116',
        version: 'v2',
        question:
          'What should you do if a doctor prescribes drugs that are likely to affect your driving?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'C',
        additionalInfo:
          "You shouldn't drive if you're taking medicine that could cause you to feel drowsy at the wheel. Ask someone else to drive or, if that isn't possible, find another way to get home.",
        options: {
          A: 'Only drive if someone is with you',
          B: 'Avoid driving on motorways',
          C: 'Get someone else to drive',
          D: 'Never drive at more than 30 mph',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1117: {
        id: 'BB1117',
        version: 'v2',
        question:
          'What must you do if your ability to drive is impaired during a period of illness?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'C',
        additionalInfo:
          "Only drive if you're fit to do so. Driving when you're ill or taking some medicines can affect your concentration and judgement. It may also cause you to become drowsy or even fall asleep.",
        options: {
          A: 'See your doctor each time before you drive',
          B: 'Take smaller doses of any medicines',
          C: "Stop driving until you're fit to drive again",
          D: 'Take all your medicines with you when you drive',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1120: {
        id: 'BB1120',
        version: 'v2',
        question:
          "What should you do if you begin to feel drowsy while you're driving?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'A',
        additionalInfo:
          "You'll be putting other road users at risk if you continue to drive when you're drowsy. Pull over and stop in a safe place for a rest. Caffeinated drinks and a short nap can temporarily help counter sleepiness. If you're driving a long distance, think about finding some accommodation so you can rest for longer before continuing your journey.",
        options: {
          A: 'Stop and rest as soon as possible',
          B: 'Turn the heater up to keep you warm and comfortable',
          C: 'Close the car windows to help you concentrate',
          D: 'Continue with your journey but drive more slowly',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1121: {
        id: 'BB1121',
        version: 'v2',
        question:
          "What should you do if you become tired while you're driving on a motorway?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'B',
        additionalInfo:
          "If you feel yourself becoming tired or sleepy, you should leave the motorway at the next exit or services and stop for a rest. If you have to drive a long way, leave earlier and plan your journey to include rest stops. That way, you're less likely to become tired while driving and you'll still arrive in good time.",
        options: {
          A: 'Pull up on the hard shoulder and change drivers',
          B: 'Leave the motorway at the next exit and rest',
          C: 'Increase your speed and turn up the radio volume',
          D: 'Close all your windows and set the heating to warm',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1123: {
        id: 'BB1123',
        version: 'v2',
        question:
          "You're about to drive home. What should you do if you feel very tired and have a severe headache?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'A',
        additionalInfo:
          "All of your concentration should be on your driving. Any pain you feel will distract you, and you should avoid driving when drowsy. The safest course of action is to wait until you've rested and are feeling better before starting your journey.",
        options: {
          A: "Wait until you're fit and well before driving",
          B: 'Drive home, but take a tablet for headaches',
          C: 'Drive home if you can stay awake for the journey',
          D: 'Wait for a short time, then drive home slowly',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1127: {
        id: 'BB1127',
        version: 'v2',
        question:
          'What can you do to help prevent tiredness on a long journey?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'B',
        additionalInfo:
          'Long-distance driving can be boring. This, coupled with a stuffy, warm vehicle, can make you feel tired and sleepy. Make sure you take rest breaks to help you stay awake and alert. Stop in a safe place before you get to the stage of fighting sleep.',
        options: {
          A: 'Eat a large meal before driving',
          B: 'Take regular refreshment breaks',
          C: 'Play loud music in the car',
          D: 'Complete the journey without stopping',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1284: {
        id: 'BB1284',
        version: 'v2',
        question:
          'You take some cough medicine given to you by a friend. What should you do before driving your car?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'C',
        additionalInfo:
          "If you've taken medicine, never drive without first checking what the side effects might be; they might affect your judgement and perception, and therefore endanger lives.",
        options: {
          A: 'Ask your friend if taking the medicine affected their driving',
          B: 'Drink some strong coffee one hour before driving',
          C: 'Check the label to see if the medicine will affect your driving',
          D: 'Drive a short distance to see if the medicine is affecting your driving',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1353: {
        id: 'BB1353',
        version: 'v2',
        question:
          "You're driving on a one-way street. What should you do if you realise you've taken the wrong route?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'TS4693.png',
          width: 400,
          height: 599,
          size: 13040,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4693.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Never reverse or turn your vehicle around in a one-way street. It's illegal and could even cause a collision. If you've taken a wrong turn, carry on along the one-way street and find another route, checking the direction signs as you drive. Stop in a safe place if you need to check a map.",
        options: {
          A: 'Reverse out of the road',
          B: 'Turn around in a side road',
          C: 'Continue and find another route',
          D: 'Reverse into a driveway',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1380: {
        id: 'BB1380',
        version: 'v2',
        question: "What will be a serious distraction while you're driving?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'A',
        additionalInfo:
          "Looking at road maps while driving is very dangerous. If you aren't sure of your route, stop in a safe place and check the map. You must not allow anything to take your attention away from the road while you're driving.",
        options: {
          A: 'Looking at road maps',
          B: 'Switching on your demister',
          C: 'Using your windscreen washers',
          D: 'Looking in your door mirror',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1397: {
        id: 'BB1397',
        version: 'v2',
        question:
          'What should you do if the vehicle starts reversing off the driveway?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1798.jpg',
          width: 800,
          height: 533,
          size: 93018,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1798.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "White lights at the rear of a car show that the driver has selected reverse gear. The driver is hidden from view so can't see you approaching. Sound your horn to warn of your presence, and be ready to stop if the car reverses into your path.",
        options: {
          A: 'Move to the opposite side of the road',
          B: 'Drive through as you have priority',
          C: 'Sound your horn and be prepared to stop',
          D: 'Speed up and drive through quickly',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1398: {
        id: 'BB1398',
        version: 'v2',
        question:
          "You've been involved in an argument that's made you feel angry. What should you do before starting your journey?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'D',
        additionalInfo:
          "If you're feeling upset or angry, you'll find it much more difficult to concentrate on your driving. You should wait until you've calmed down before starting a journey.",
        options: {
          A: 'Open a window',
          B: 'Turn on your radio',
          C: 'Have an alcoholic drink',
          D: 'Calm down',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1454: {
        id: 'BB1454',
        version: 'v2',
        question:
          "You're driving on this dual carriageway. Why may you need to slow down?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1776.jpg',
          width: 800,
          height: 533,
          size: 102859,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1776.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Look well ahead and read any road signs as you drive. They're there to inform you of what's ahead. In this case, you may need to slow down and change direction. \r\nCheck your mirrors so you know what's happening around you before you change speed or direction.",
        options: {
          A: "There's a broken white line in the centre",
          B: 'There are solid white lines on either side',
          C: 'There are roadworks ahead of you',
          D: 'There are no footpaths',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1455: {
        id: 'BB1455',
        version: 'v2',
        question:
          "You've just been overtaken by this motorcyclist. What should you do if the rider cuts in sharply?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'R8525.png',
          width: 569,
          height: 382,
          size: 386849,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FR8525.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'If another vehicle cuts in sharply, ease off the accelerator and drop back to allow a safe separation distance. Try not to overreact by braking sharply or swerving, as you could lose control. If vehicles behind you are too close or unprepared, it could lead to a crash.',
        options: {
          A: 'Sound the horn',
          B: 'Brake firmly',
          C: 'Keep a safe gap',
          D: 'Flash your lights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1457: {
        id: 'BB1457',
        version: 'v2',
        question:
          "You're about to drive your car. What should you do if you can't find the glasses you need to wear?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'D',
        additionalInfo:
          "If you need to wear glasses for driving, it's illegal to drive without them. You must be able to see clearly when you're driving.",
        options: {
          A: 'Drive home slowly, keeping to quiet roads',
          B: "Borrow a friend's glasses and use those",
          C: 'Drive home at night, so that the lights will help you',
          D: 'Find a way of getting home without driving',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1459: {
        id: 'BB1459',
        version: 'v2',
        question: 'How does drinking alcohol affect your driving behaviour?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'B',
        additionalInfo:
          "Alcohol can increase confidence to a point where your driving behaviour might become 'out of character'. Sensible behaviour might change to risk-taking behaviour. Never let yourself or your friends get into this situation.",
        options: {
          A: 'It improves judgement skills',
          B: 'It increases confidence',
          C: 'It leads to faster reactions',
          D: 'It increases concentration',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1461: {
        id: 'BB1461',
        version: 'v2',
        question:
          'Why should you check the information leaflet before taking any medicine?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'C',
        additionalInfo:
          "Always check the label or information leaflet for any medication you take. The medicine might affect your driving. If you aren't sure, ask your doctor or pharmacist.",
        options: {
          A: 'Drug companies want customer feedback on their products',
          B: 'You may have to let your insurance company know about the medicine',
          C: 'Some types of medicine can affect your ability to drive safely',
          D: 'The medicine you take may affect your hearing',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1514: {
        id: 'BB1514',
        version: 'v2',
        question:
          'You need glasses to read a vehicle number plate at the required distance. When must you wear them?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'B',
        additionalInfo:
          'Have your eyesight tested before you start your practical training. Then, throughout your driving life, have checks periodically, as your vision may change.',
        options: {
          A: 'Only in bad weather conditions',
          B: "Whenever you're driving",
          C: "When you think it's necessary",
          D: 'Only at night time',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1546: {
        id: 'BB1546',
        version: 'v2',
        question:
          'Which type of glasses would make driving at night more difficult?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'D',
        additionalInfo:
          "If you're driving at night or in poor visibility, tinted lenses will reduce the efficiency of your vision by reducing the amount of light reaching your eyes.",
        options: {A: 'Half-moon', B: 'Round', C: 'Bifocal', D: 'Tinted'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1564: {
        id: 'BB1564',
        version: 'v2',
        question: 'What can seriously reduce your ability to concentrate?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'A',
        additionalInfo:
          "Both recreational drugs and prescribed medicine can affect your concentration. It's also an offence to drive with certain drugs in your body and a positive test could lead to a conviction.",
        options: {
          A: 'Drugs',
          B: 'Busy roads',
          C: 'Tinted windows',
          D: 'Weather conditions',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1568: {
        id: 'BB1568',
        version: 'v2',
        question:
          "What must you do if your eyesight has become very poor and you're no longer able to meet the driver's eyesight requirements?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'A',
        additionalInfo:
          "Having very poor eyesight will have a serious effect on your ability to drive safely. If you can't meet the driver's eyesight requirements, you must tell DVLA (or DVA in Northern Ireland).",
        options: {
          A: 'Tell the driver licensing authority',
          B: 'Tell your doctor',
          C: 'Tell the police',
          D: 'Tell your optician',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1575: {
        id: 'BB1575',
        version: 'v2',
        question: 'When should you use hazard warning lights?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'D',
        additionalInfo:
          "Hazard warning lights are an important safety feature and should be used if you've broken down and are causing an obstruction. Don't use them as an excuse to park illegally. You may also use them on motorways to warn traffic behind you of danger ahead.",
        options: {
          A: "When you're double-parked on a two-way road",
          B: "When your direction indicators aren't working",
          C: 'When warning oncoming traffic that you intend to stop',
          D: 'When your vehicle has broken down and is causing an obstruction',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1576: {
        id: 'BB1576',
        version: 'v2',
        question:
          'You want to turn left at this junction. What should you do if your view of the main road is restricted?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1732.jpg',
          width: 800,
          height: 533,
          size: 81384,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1732.jpg?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "You should slow right down, and stop if necessary, at any junction where your view is restricted. Edge forward until you can see properly. Only then can you decide whether it's safe to go.",
        options: {
          A: 'Stay well back and wait to see if anything comes',
          B: 'Build up your speed so that you can emerge quickly',
          C: 'Stop and apply the parking brake even if the road is clear',
          D: 'Approach slowly and edge out until you can see more clearly',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1596: {
        id: 'BB1596',
        version: 'v2',
        question:
          "You're driving a car fitted with automatic transmission. When would you use 'kick down'?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'B',
        additionalInfo:
          "'Kick down' selects a lower gear, enabling the vehicle to accelerate faster.",
        options: {
          A: 'To engage cruise control',
          B: 'To accelerate quickly',
          C: 'To brake progressively',
          D: 'To improve fuel economy',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1664: {
        id: 'BB1664',
        version: 'v2',
        question:
          "What should you do if it's raining and you're following this lorry on a motorway?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1897.jpg',
          width: 800,
          height: 533,
          size: 34207,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1897.jpg?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "The usual two-second time gap increases to four seconds when the roads are wet. If you stay well back, you'll\r\n• be able to see past the vehicle\r\n• be out of the spray thrown up by the lorry's tyres\r\n• give yourself more time to stop if the need arises\r\n• increase your chances of being seen by the lorry driver.",
        options: {
          A: 'Allow a two-second separation gap',
          B: 'Switch your headlights onto main beam',
          C: 'Move into a lane that has less spray',
          D: 'Be aware of spray reducing your vision',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1665: {
        id: 'BB1665',
        version: 'v2',
        question:
          "You're driving towards this left-hand bend. What danger should you be anticipating?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1218.jpg',
          width: 800,
          height: 533,
          size: 108281,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1218.jpg?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "Pedestrians walking on a road with no pavement should walk against the direction of the traffic. You can't see around this bend and if pedestrians are in the road you need to be able to deal with the situation safely. Always keep this in mind and give yourself time to react if a hazard does appear.",
        options: {
          A: 'A vehicle overtaking you',
          B: 'Mud on the road',
          C: 'The road getting narrower',
          D: 'Pedestrians walking towards you',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1666: {
        id: 'BB1666',
        version: 'v2',
        question:
          'What should you do if the traffic in the left-hand lane is slowing?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'IMG_1758.jpg',
          width: 800,
          height: 533,
          size: 114426,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1758.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'Allow the traffic to merge into the left-hand lane. Leave enough room so that you can maintain a safe separation distance, even if vehicles pull in ahead of you.',
        options: {
          A: 'Slow down, keeping a safe separation distance',
          B: 'Accelerate past the vehicles in the left-hand lane',
          C: 'Pull up on the left-hand verge',
          D: 'Move across and continue in the right-hand lane',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1670: {
        id: 'BB1670',
        version: 'v2',
        question: 'When may you use hazard warning lights?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'A',
        additionalInfo:
          'Hazard warning lights are an important safety feature. Use them when driving on a motorway to warn traffic behind you of danger ahead. \r\nYou should also use them if your vehicle has broken down and is causing an obstruction.',
        options: {
          A: 'When driving on a motorway to warn traffic behind of a hazard ahead',
          B: "When you're double-parked on a two-way road",
          C: "When your direction indicators aren't working",
          D: 'When warning oncoming traffic that you intend to stop',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1727: {
        id: 'BB1727',
        version: 'v2',
        question:
          "You're waiting to emerge at a junction. Your view is restricted by parked vehicles. What can help you to see traffic on the road you're joining?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'B',
        additionalInfo:
          "You must be completely sure it's safe to emerge. Try to look for traffic through the windows of the parked cars or in the reflections in windows. Keep looking in all directions as you slowly edge forwards until you can see it's safe.",
        options: {
          A: 'Looking for traffic behind you',
          B: 'Reflections of traffic in windows',
          C: 'Making eye contact with other road users',
          D: 'Checking for traffic in your interior mirror',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1743: {
        id: 'BB1743',
        version: 'v2',
        question: 'What must you do if poor health affects your driving?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'D',
        additionalInfo:
          "You must tell DVLA (or DVA in Northern Ireland) if your health is likely to affect your ability to drive. The licensing authority will investigate your situation and then make a decision on whether you're fit enough to drive safely.",
        options: {
          A: 'Inform your local police',
          B: 'Avoid using motorways',
          C: 'Always drive accompanied',
          D: 'Inform the licensing authority',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1748: {
        id: 'BB1748',
        version: 'v2',
        question: 'Why should the junction on the left be kept clear?',
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: {
          fileName: 'R8532.png',
          width: 690,
          height: 390,
          size: 508489,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FR8532.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "You should always try to keep junctions clear. If you're in queuing traffic, make sure that when you stop you leave enough space for traffic to flow in and out of the junction.",
        options: {
          A: 'To allow vehicles to enter and emerge',
          B: 'To allow the bus to reverse',
          C: 'To allow vehicles to make a U-turn',
          D: 'To allow vehicles to park',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1779: {
        id: 'BB1779',
        version: 'v2',
        question:
          "What should you do if you start to feel drowsy while you're driving on a motorway?",
        questionSetId: 'HAZARD-AWARENESS',
        questionSetTitle: 'Hazard awareness',
        image: null,
        answer: 'B',
        additionalInfo:
          "Never stop on the hard shoulder to rest. If there's no service area for several miles, leave the motorway at the next exit and find somewhere safe and legal to pull over.",
        options: {
          A: 'Stop on the hard shoulder for a sleep',
          B: "Open a window and stop as soon as it's safe and legal",
          C: 'Speed up to arrive at your destination sooner',
          D: 'Slow down and let other drivers overtake',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2117: {
        id: 'AB2117',
        version: 'v2',
        question:
          'Which sign means that there may be people walking along the road?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          'Always check the road signs. Triangular signs are warning signs: they inform you about hazards ahead and help you to anticipate any problems. There are a number of different signs showing pedestrians. Learn the meaning of each one.',
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4672.png',
            width: 400,
            height: 400,
            size: 24849,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4672.png?alt=media',
          },
          B: {
            fileName: 'TS4703.png',
            width: 400,
            height: 400,
            size: 40500,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4703.png?alt=media',
          },
          C: {
            fileName: 'TS4646.png',
            width: 400,
            height: 354,
            size: 20427,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4646.png?alt=media',
          },
          D: {
            fileName: 'TS4647.png',
            width: 400,
            height: 354,
            size: 20764,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4647.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2119: {
        id: 'AB2119',
        version: 'v2',
        question:
          'What should you do if you want to turn left at a junction where pedestrians have started to cross?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'P5506.png',
          width: 600,
          height: 390,
          size: 431081,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FP5506.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "When you're turning into a side road, pedestrians who are crossing have priority. You should wait to allow them to finish crossing safely. Be patient if they're slow or unsteady. Don't try to rush them by sounding your horn, flashing your lights, revving your engine or giving any other inappropriate signal.",
        options: {
          A: 'Go around them, leaving plenty of room',
          B: 'Stop and wave at them to cross',
          C: 'Sound your horn and proceed',
          D: 'Give way to them',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2122: {
        id: 'AB2122',
        version: 'v2',
        question:
          "What hazard should you be especially aware of if you're turning left into a side road?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'B',
        additionalInfo:
          "Make sure that you've reduced your speed and are in the correct gear for the turn. Look into the road before you turn and always give way to any pedestrians who are crossing.",
        options: {
          A: 'One-way street',
          B: 'Pedestrians',
          C: 'Traffic congestion',
          D: 'Parked vehicles',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2123: {
        id: 'AB2123',
        version: 'v2',
        question:
          'Why should you check for motorcyclists just before turning right into a side road?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          'Never attempt to change direction to the right without first checking your right-hand mirror and blind spot. A motorcyclist might not have seen your signal and could be hidden by other traffic. This observation should become a matter of routine.',
        options: {
          A: 'They may be overtaking on your left',
          B: 'They may be following you closely',
          C: 'They may be emerging from the side road',
          D: 'They may be overtaking on your right',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2124: {
        id: 'AB2124',
        version: 'v2',
        question: 'Why is a toucan crossing different from other crossings?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          "Toucan crossings are shared by pedestrians and cyclists, who are permitted to cycle across. They're shown the green light together. The signals are push-button-operated and there's no flashing amber phase.",
        options: {
          A: 'Moped riders can use it',
          B: "It's controlled by a traffic warden",
          C: "It's controlled by two flashing lights",
          D: 'Cyclists can use it',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2128: {
        id: 'AB2128',
        version: 'v2',
        question: 'How will a school crossing patrol signal you to stop?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'C',
        additionalInfo:
          "If a school crossing patrol steps out into the road with a 'stop' sign, you must stop. Don't wave anyone across the road and don't get impatient or rev your engine.",
        options: {
          A: 'By pointing to children waiting to cross',
          B: 'By displaying a red light',
          C: "By displaying a 'stop' sign",
          D: 'By giving you an arm signal',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2129: {
        id: 'AB2129',
        version: 'v2',
        question: 'Where would you see this sign?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'TS4546.png',
          width: 481,
          height: 481,
          size: 24069,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4546.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "Vehicles that are used to carry children to and from school will be travelling at busy times of the day. If you're following a vehicle with this sign, be prepared for it to make frequent stops. It might pick up or set down passengers in places other than normal bus stops.",
        options: {
          A: 'In the window of a car taking children to school',
          B: 'At the side of the road',
          C: 'At playground areas',
          D: 'On the rear of a school bus or coach',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2134: {
        id: 'AB2134',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'TS4702.png',
          width: 800,
          height: 800,
          size: 102066,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4702.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'This sign shows a shared route for pedestrians and cyclists: when it ends, the cyclists will be rejoining the main road.',
        options: {
          A: 'No route for pedestrians and cyclists',
          B: 'A route for pedestrians only',
          C: 'A route for cyclists only',
          D: 'A route for pedestrians and cyclists',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2136: {
        id: 'AB2136',
        version: 'v2',
        question:
          'You see a pedestrian carrying a white stick that also has a red band. What does this mean?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'IMG_0822.jpg',
          width: 800,
          height: 533,
          size: 94834,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_0822.jpg?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "When someone is deaf as well as blind, they may carry a white stick with a red reflective band or bands. They may not be aware that you're approaching and they may not be able to hear anything; so, for example, your horn would be ineffective as a warning to them.",
        options: {
          A: 'They have limited mobility',
          B: "They're deaf",
          C: "They're blind",
          D: "They're deaf and blind",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2138: {
        id: 'AB2138',
        version: 'v2',
        question:
          'What would you do if you see older people crossing the road ahead?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'TS4648.png',
          width: 400,
          height: 354,
          size: 20441,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4648.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "Be aware that older people might take a long time to cross the road. They might also be hard of hearing and not hear you approaching. Don't hurry older people across the road by getting too close to them or revving your engine.",
        options: {
          A: "Wave them across so they know that you've seen them",
          B: 'Be patient and allow them to cross in their own time',
          C: "Rev the engine to let them know that you're waiting",
          D: "Tap the horn in case they're hard of hearing",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2139: {
        id: 'AB2139',
        version: 'v2',
        question:
          'What should you do when you see an older person about to cross the road ahead?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          'Older people may have impaired hearing, vision, concentration and judgement. They may also walk slowly and so could take a long time to cross the road.',
        options: {
          A: 'Expect them to wait for you to pass',
          B: 'Speed up to get past them quickly',
          C: 'Stop and wave them across the road',
          D: 'Be careful; they may misjudge your speed',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2143: {
        id: 'AB2143',
        version: 'v2',
        question:
          "You're approaching a roundabout. What should you do if a cyclist ahead is signalling to turn right?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          "If you're following a cyclist who's signalling to turn right at a roundabout, leave plenty of room. Give them space and time to get into the correct lane.",
        options: {
          A: 'Overtake on the right',
          B: 'Give a warning with your horn',
          C: 'Signal the cyclist to move across',
          D: 'Give the cyclist plenty of room',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2147: {
        id: 'AB2147',
        version: 'v2',
        question:
          'Which vehicle should you allow extra room as you overtake them?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'C',
        additionalInfo:
          "Don't pass cyclists too closely, as they may\r\n• need to veer around a pothole, drain or other hazard\r\n• be buffeted by side wind \r\n• be made unsteady by your vehicle. \r\nAlways leave as much room as you would for a car, and don't cut in front of them.",
        options: {A: 'Lorry', B: 'Tractor', C: 'Bicycle', D: 'Road-sweeper'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2150: {
        id: 'AB2150',
        version: 'v2',
        question:
          'Why should you look carefully for motorcyclists and cyclists at junctions?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'C',
        additionalInfo:
          'Cyclists and motorcyclists are smaller than other vehicles and so are more difficult to see. They can easily be hidden from your view by cars parked near a junction.',
        options: {
          A: 'They may want to turn into the side road',
          B: 'They may slow down to let you turn',
          C: "They're harder to see",
          D: 'They might not see you turn',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2151: {
        id: 'AB2151',
        version: 'v2',
        question:
          "You're waiting to come out of a side road. Why should you look carefully for motorcycles?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'C',
        additionalInfo:
          "If you're waiting to emerge from a side road, look carefully for motorcycles: they can be difficult to see. Be especially careful if there are parked vehicles or other obstructions restricting your view.",
        options: {
          A: 'Motorcycles are usually faster than cars',
          B: 'Police patrols often use motorcycles',
          C: 'Motorcycles can easily be hidden behind obstructions',
          D: 'Motorcycles have right of way',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2153: {
        id: 'AB2153',
        version: 'v2',
        question: 'Why do motorcyclists use dipped headlights in daylight?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'A',
        additionalInfo:
          'A motorcycle can be lost from sight behind another vehicle. The use of headlights helps to make it more conspicuous and therefore more easily seen.',
        options: {
          A: 'So that the rider can be seen more easily',
          B: 'To stop the battery overcharging',
          C: "To improve the rider's vision",
          D: 'The rider is inviting you to proceed',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2156: {
        id: 'AB2156',
        version: 'v2',
        question: 'Why do motorcyclists wear bright clothing?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          'Motorcycles and scooters are generally smaller than other vehicles and can be difficult to see. Wearing bright clothing makes it easier for other road users to see a motorcyclist approaching, especially at junctions.',
        options: {
          A: 'They must do so by law',
          B: 'It helps keep them cool in summer',
          C: 'The colours are popular',
          D: 'To make them more visible',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2160: {
        id: 'AB2160',
        version: 'v2',
        question:
          'Why do motorcyclists often look round over their right shoulder just before turning right?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          "When you see a motorcyclist take a glance over their shoulder, they're probably about to change direction. Recognising a clue like this helps you to anticipate their next action. This can improve road safety for you and others.",
        options: {
          A: 'To listen for traffic behind them',
          B: "Motorcycles don't have mirrors",
          C: 'It helps them balance as they turn',
          D: 'To check for traffic in their blind area',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2161: {
        id: 'AB2161',
        version: 'v2',
        question: 'Which is the most vulnerable road user?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          'Pedestrians and riders on two wheels can be harder to see than other road users. Make sure you look for them, especially at junctions. Effective observation, coupled with appropriate action, can save lives.',
        options: {
          A: 'Car driver',
          B: 'Tractor driver',
          C: 'Lorry driver',
          D: 'Motorcyclist',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2168: {
        id: 'AB2168',
        version: 'v2',
        question:
          "You're approaching a roundabout. What should you do if there are horses being ridden in front of you?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'C',
        additionalInfo:
          "Horse riders often keep to the outside of the roundabout even if they're turning right. Give them plenty of room and remember that they may have to cross lanes of traffic.",
        options: {
          A: 'Sound your horn as a warning',
          B: 'Treat them like any other vehicle',
          C: 'Give them plenty of room',
          D: 'Accelerate past as quickly as possible',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2491: {
        id: 'AB2491',
        version: 'v2',
        question:
          'As you approach a pelican crossing, the lights change to green. What should you do if older people are still crossing?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          "If the lights turn to green, wait for any pedestrians to clear the crossing. Allow them to finish crossing the road in their own time, and don't try to hurry them by revving your engine.",
        options: {
          A: 'Wave them to cross as quickly as they can',
          B: 'Rev your engine to make them hurry',
          C: "Flash your lights in case they haven't noticed you",
          D: 'Wait patiently while they cross',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2516: {
        id: 'AB2516',
        version: 'v2',
        question:
          'What action should you take when you see flashing amber lights under a school warning sign?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: '85BW5908.jpg',
          width: 800,
          height: 1203,
          size: 140419,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW5908.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'The flashing amber lights are switched on to warn you that children may be crossing near a school. Slow down and take extra care, as you may have to stop.',
        options: {
          A: "Reduce speed until you're clear of the area",
          B: 'Keep up your speed and sound the horn',
          C: 'Increase your speed to clear the area quickly',
          D: 'Wait at the lights until they stop flashing',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2545: {
        id: 'AB2545',
        version: 'v2',
        question: 'Why should these road markings be kept clear?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'TS4715.png',
          width: 800,
          height: 509,
          size: 35600,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4715.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'The markings are there to show that the area should be kept clear. This is to allow an unrestricted view for\r\n• approaching drivers and riders\r\n• children wanting to cross the road.',
        options: {
          A: 'To allow children to be dropped off at school',
          B: 'To allow teachers to park',
          C: 'To allow children to be picked up after school',
          D: "To allow children to see and be seen when they're crossing the road",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2559: {
        id: 'AB2559',
        version: 'v2',
        question: 'Where would you see this sign?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'TS4546.png',
          width: 481,
          height: 481,
          size: 24069,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4546.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "School buses can stop to pick up or drop off schoolchildren at places that aren't designated bus stops. Watch out for children crossing the road to catch the bus or from the far side of the bus if they've just been dropped off.",
        options: {
          A: 'Near a school crossing',
          B: 'At a playground entrance',
          C: 'On a school bus',
          D: "At a 'pedestrians only' area",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2561: {
        id: 'AB2561',
        version: 'v2',
        question:
          "You're following two cyclists as they approach a roundabout in the left-hand lane. Where would you expect the cyclists to go?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'C',
        additionalInfo:
          'Cyclists approaching a roundabout in the left-hand lane may be turning right but may not have been able to get into the correct lane due to heavy traffic. They may also feel safer keeping to the left all the way around the roundabout. Be aware of them and give them plenty of room.',
        options: {
          A: 'Left',
          B: 'Right',
          C: 'Any direction',
          D: 'Straight ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2562: {
        id: 'AB2562',
        version: 'v2',
        question:
          "You're travelling behind a moped. What should you do if you want to turn left a short distance ahead?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          'Passing the moped and turning into the junction could mean that you cut across the front of the rider. This might force them to slow down, stop or even lose control. Stay behind the moped until it has passed the junction and then you can turn without affecting the rider.',
        options: {
          A: 'Overtake the moped before the junction',
          B: 'Pull alongside the moped and stay level until just before the junction',
          C: 'Sound your horn as a warning and pull in front of the moped',
          D: 'Stay behind until the moped has passed the junction',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2564: {
        id: 'AB2564',
        version: 'v2',
        question:
          "You see a horse rider as you approach a roundabout. What should you do if they're signalling right but keeping well to the left?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          "Allow the horse rider to enter and exit the roundabout in their own time. They may feel safer keeping to the left all the way around the roundabout. Don't get up close behind or alongside them, because that would probably upset the horse and create a dangerous situation.",
        options: {
          A: 'Proceed as normal',
          B: 'Keep close to them',
          C: 'Cut in front of them',
          D: 'Stay well back',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2565: {
        id: 'AB2565',
        version: 'v2',
        question: 'How should you react to inexperienced drivers?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'B',
        additionalInfo:
          "Learners might not have confidence when they first start to drive. Allow them plenty of room and don't react adversely to their hesitation. We all learn from experience, but new drivers will have had less practice in dealing with all the situations that might occur.",
        options: {
          A: 'Sound your horn to warn them of your presence',
          B: 'Be patient and prepare for them to react more slowly',
          C: "Flash your headlights to indicate that it's safe for them to proceed",
          D: 'Overtake them as soon as possible',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2566: {
        id: 'AB2566',
        version: 'v2',
        question:
          "What should you do when you're following a learner driver who stalls at a junction?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'A',
        additionalInfo:
          "Learning to drive is a process of practice and experience. Try to understand this and tolerate those who make mistakes while they're learning.",
        options: {
          A: 'Be patient, as you expect them to make mistakes',
          B: 'Stay very close behind and flash your headlights',
          C: 'Start to rev your engine if they take too long to restart',
          D: 'Immediately steer around them and drive on',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2587: {
        id: 'AB2587',
        version: 'v2',
        question:
          "You're on a country road that has no pavement. What should you anticipate finding on your side of the road?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'C',
        additionalInfo:
          "On a quiet country road, always be aware that there may be a hazard just around the next bend, such as a slow-moving vehicle or pedestrians. Pedestrians are advised to walk on the right-hand side of the road if there's no pavement, so they may be walking towards you on your side of the road.",
        options: {
          A: 'Motorcycles',
          B: 'Bicycles',
          C: 'Pedestrians',
          D: 'Horse riders',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2601: {
        id: 'AB2601',
        version: 'v2',
        question:
          "You're following a cyclist. What should you do when you wish to turn left a short distance ahead?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'DSCF2611.jpg',
          width: 800,
          height: 533,
          size: 110367,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FDSCF2611.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Make allowances for cyclists, and give them plenty of room. Don't overtake and then immediately turn left. Be patient and turn behind them when they've passed the junction.",
        options: {
          A: 'Overtake the cyclist before you reach the junction',
          B: 'Pull alongside the cyclist and stay level until after the junction',
          C: 'Hold back until the cyclist has passed the junction',
          D: 'Go around the cyclist on the junction',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2602: {
        id: 'AB2602',
        version: 'v2',
        question:
          'A horse rider is in the left-hand lane approaching a roundabout. Where should you expect the rider to go?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'A',
        additionalInfo:
          "Horses and their riders move more slowly than other road users. They might not have time to cut across heavy traffic to take up a position in the right-hand lane. For this reason, a horse and rider may approach a roundabout in the left-hand lane even though they're turning right.",
        options: {
          A: 'In any direction',
          B: 'To the right',
          C: 'To the left',
          D: 'Straight ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2660: {
        id: 'AB2660',
        version: 'v2',
        question:
          "Powered vehicles used by disabled people are small and can be hard to see. What must they display if they're travelling on a dual carriageway?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          'Powered vehicles used by disabled people are small and low making them hard to see on the road. They also travel very slowly. On an unrestricted dual carriageway, they must display a flashing amber beacon to warn other road users of their presence.',
        options: {
          A: 'Flashing red beacon',
          B: 'Flashing green beacon',
          C: 'Flashing blue beacon',
          D: 'Flashing amber beacon',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2696: {
        id: 'AB2696',
        version: 'v2',
        question:
          'What does it mean when a moving vehicle is showing a flashing amber beacon?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'A',
        additionalInfo:
          "Different coloured beacons warn of different types of vehicle needing special attention. Blue beacons are used on emergency vehicles that need priority. Green beacons are found on doctors' cars. Amber beacons generally denote slower moving vehicles, which are often large. These vehicles are usually involved in road maintenance or local amenities and make frequent stops.",
        options: {
          A: 'The vehicle is slow moving',
          B: 'The vehicle has broken down',
          C: "The vehicle is a doctor's car",
          D: 'The vehicle belongs to a school crossing patrol',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2726: {
        id: 'AB2726',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'TS4706.png',
          width: 800,
          height: 366,
          size: 34331,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4706.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "Usually, a picture of a cycle will also be painted on the road, and sometimes the lane will have a different coloured surface. Leave these areas clear for cyclists and don't pass too closely when you overtake.",
        options: {
          A: 'Contraflow cycle lane',
          B: 'With-flow cycle lane',
          C: 'Cycles and buses only',
          D: 'No cycles or buses',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2727: {
        id: 'AB2727',
        version: 'v2',
        question:
          'What should you do when you see these horse riders in front?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'IMG_6187.jpg',
          width: 800,
          height: 571,
          size: 104654,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_6187.jpg?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'Be particularly careful when approaching horse riders - slow down and be prepared to stop. Always pass wide and slowly, and look out for signals given by the riders. Horses are unpredictable: always treat them as potential hazards and take great care when passing them.',
        options: {
          A: 'Pull out to the middle of the road',
          B: 'Slow down and be ready to stop',
          C: 'Switch on your hazard warning lights',
          D: 'Give a right-turn signal',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2729: {
        id: 'AB2729',
        version: 'v2',
        question: "What's the purpose of these road markings?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'TS4715.png',
          width: 800,
          height: 509,
          size: 35600,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4715.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "These markings are found on the road outside schools. Don't stop or park on them, even to set down or pick up children. The markings are there to ensure that drivers, riders, children and other pedestrians have a clear view of the road in all directions.",
        options: {
          A: "To ensure children can see and be seen when they're crossing the road",
          B: 'To enable teachers to have clear access to the school',
          C: 'To ensure delivery vehicles have easy access to the school',
          D: 'To enable parents to pick up or drop off children safely',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2730: {
        id: 'AB2730',
        version: 'v2',
        question:
          'What should you do if the left-hand pavement is closed due to street repairs?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'A',
        additionalInfo:
          'Where street repairs have closed off pavements, proceed carefully and slowly, as pedestrians might have to walk in the road.',
        options: {
          A: 'Watch out for pedestrians walking in the road',
          B: 'Use your right-hand mirror more often',
          C: 'Speed up to get past the roadworks more quickly',
          D: 'Position close to the left-hand kerb',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2731: {
        id: 'AB2731',
        version: 'v2',
        question:
          "What should you do when you're following a motorcyclist along a road that has a poor surface?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'C',
        additionalInfo:
          'To avoid being unbalanced, a motorcyclist might swerve to avoid potholes and bumps in the road. Be prepared for this and allow them extra space.',
        options: {
          A: 'Follow closely so they can see you in their mirrors',
          B: 'Overtake immediately to avoid delays',
          C: 'Allow extra room in case they swerve to avoid potholes',
          D: 'Allow the same room as normal to avoid wasting road space',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2840: {
        id: 'AB2840',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'TS4683.png',
          width: 800,
          height: 707,
          size: 50730,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4683.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'More people are cycling today and cycle routes are being extended in our towns and cities to provide safe cycling routes. Respect the presence of cyclists on the road and give them plenty of room if you need to pass.',
        options: {
          A: 'No cycling',
          B: 'Cycle route ahead',
          C: 'Cycle parking only',
          D: 'End of cycle route',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2849: {
        id: 'AB2849',
        version: 'v2',
        question:
          "You're approaching this roundabout. What should you do when a cyclist is keeping to the left while signalling to turn right?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: '85BW2956.jpg',
          width: 800,
          height: 532,
          size: 65165,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW2956.jpg?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "Cycling in today's heavy traffic can be hazardous. Some cyclists may not feel safe crossing the path of traffic to take up a position in an outside lane. Be aware of this and understand that, although they're in the left-hand lane, the cyclist might be turning right.",
        options: {
          A: 'Sound your horn',
          B: 'Overtake them',
          C: "Assume they're turning left",
          D: 'Allow them space to turn',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2850: {
        id: 'AB2850',
        version: 'v2',
        question: "What should you do when you're approaching this crossing?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'R8014.png',
          width: 586,
          height: 390,
          size: 400401,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FR8014.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Be courteous and prepare to stop. Don't wave people across, because this could be dangerous if another vehicle is approaching the crossing.",
        options: {
          A: 'Prepare to slow down and stop',
          B: 'Stop and wave the pedestrians across',
          C: 'Speed up and pass by quickly',
          D: 'Continue unless the pedestrians step out',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2851: {
        id: 'AB2851',
        version: 'v2',
        question:
          'What does it mean if you see a pedestrian with a dog that has a yellow or burgundy coat?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          'Dogs trained to help deaf people have a yellow or burgundy coat. If you see one, you should take extra care, as the pedestrian may not be aware of vehicles approaching.',
        options: {
          A: 'The pedestrian is an older person',
          B: 'The pedestrian is a dog trainer',
          C: 'The pedestrian is colour-blind',
          D: 'The pedestrian is deaf',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2854: {
        id: 'AB2854',
        version: 'v2',
        question: 'Who may use toucan crossings?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          "There are some crossings where cycle routes lead cyclists to cross at the same place as pedestrians. These are called toucan crossings. Always look out for cyclists, as they're likely to be approaching faster than pedestrians.",
        options: {
          A: 'Motorcyclists and cyclists',
          B: 'Motorcyclists and pedestrians',
          C: 'Only cyclists',
          D: 'Cyclists and pedestrians',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2860: {
        id: 'AB2860',
        version: 'v2',
        question:
          "This junction, controlled by traffic lights, has a marked area between two stop lines. What's this for?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'IMG_1360.jpg',
          width: 800,
          height: 533,
          size: 100117,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1360.jpg?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "These are known as advanced stop lines. When the lights are red (or about to become red), you should stop at the first white line. However, if you've crossed that line as the lights change, you must stop at the second line even if it means you're in the area reserved for cyclists.",
        options: {
          A: 'To allow taxis to position in front of other traffic',
          B: 'To allow people with disabilities to cross the road',
          C: 'To allow cyclists and pedestrians to cross the road together',
          D: 'To allow cyclists to position in front of other traffic',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2896: {
        id: 'AB2896',
        version: 'v2',
        question:
          "You're about to overtake a cyclist. Why should you leave them as much room as you would give to a car?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'C',
        additionalInfo:
          "Before overtaking, assess the situation. Look well ahead to see whether the cyclist will need to change direction. Be especially aware of a cyclist approaching parked vehicles, as they'll need to alter course. Don't pass too closely or cut in sharply as this could unsettle the rider.",
        options: {
          A: 'The cyclist might speed up',
          B: 'The cyclist might get off their bicycle',
          C: 'The cyclist might be unsettled if you pass too near them',
          D: 'The cyclist might have to make a left turn',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2908: {
        id: 'AB2908',
        version: 'v2',
        question:
          "What should you do when you're passing loose sheep on the road?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'IMG_2983.jpg',
          width: 800,
          height: 533,
          size: 97534,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_2983.jpg?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "Slow down and be ready to stop if you see animals in the road ahead. Animals are easily frightened by noise and vehicles passing too close to them. Stop if you're signalled to do so by the person in charge.",
        options: {
          A: 'Briefly sound your horn',
          B: 'Go very slowly',
          C: 'Pass quickly but quietly',
          D: 'Herd them to the side of the road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2941: {
        id: 'AB2941',
        version: 'v2',
        question:
          'At night, what does it mean if you see a pedestrian wearing reflective clothing and carrying a bright red light?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'B',
        additionalInfo:
          "The people on the walk should be keeping to the left, but don't assume this. Pass carefully, making sure you have time to do so safely. Be aware that the pedestrians have their backs to you and may not know that you're there.",
        options: {
          A: "You're approaching roadworks",
          B: "You're approaching an organised walk",
          C: "You're approaching a slow-moving vehicle",
          D: "You're approaching a traffic danger spot",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2943: {
        id: 'AB2943',
        version: 'v2',
        question:
          "You've just passed your driving test. How can you reduce your risk of being involved in a collision?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          'New drivers and riders are often involved in a collision or incident early in their driving career. Due to a lack of experience, they may not react to hazards appropriately. Approved training courses are offered by driver and rider training schools for people who have passed their test but want extra training.',
        options: {
          A: 'By always staying close to the vehicle in front',
          B: 'By never going over 40 mph',
          C: 'By staying in the left-hand lane on all roads',
          D: 'By taking further training',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1131: {
        id: 'BB1131',
        version: 'v2',
        question:
          "You want to reverse into a side road. What should you do if you aren't sure that the area behind your car is clear?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'B',
        additionalInfo:
          "If you can't tell whether there's anything behind you, it's always safest to check before reversing. There may be a small child or a low obstruction close behind your car.",
        options: {
          A: 'Look through the rear window only',
          B: 'Get out and check',
          C: 'Check the mirrors only',
          D: "Carry on, assuming it's clear",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1132: {
        id: 'BB1132',
        version: 'v2',
        question:
          "You're about to reverse into a side road. What should you do if a pedestrian is waiting to cross behind your car?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'B',
        additionalInfo:
          "If you need to reverse into a side road, try to find a place that's free from traffic and pedestrians. Look all around before and during the manoeuvre. Stop and give way to any pedestrians who want to cross behind you. Avoid waving them across, sounding the horn, flashing your lights or giving any signals that could mislead them and create a dangerous situation.",
        options: {
          A: 'Wave to the pedestrian to stop',
          B: 'Give way to the pedestrian',
          C: 'Sound your horn to warn the pedestrian',
          D: 'Reverse before the pedestrian starts to cross',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1133: {
        id: 'BB1133',
        version: 'v2',
        question:
          "Which road users are most difficult to see when you're reversing your car?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          "It may not be possible to see a small child through the rear windscreen of your vehicle. Be aware of this before you reverse. If there are children about, get out and check that it's clear before reversing.",
        options: {
          A: 'Motorcyclists',
          B: 'Car drivers',
          C: 'Cyclists',
          D: 'Children',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1135: {
        id: 'BB1135',
        version: 'v2',
        question:
          'You want to turn right from a junction. What should you do if your view is restricted by parked vehicles?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'C',
        additionalInfo:
          "If you want to turn right from a junction and your view is restricted, stop. Ease forward until you can see - something might be approaching.\r\nIf you don't know, don't go.",
        options: {
          A: 'Move out quickly, but be prepared to stop',
          B: "Sound your horn and pull out if there's no reply",
          C: 'Stop, then move forward slowly until you have a clear view',
          D: 'Stop, get out and look along the main road to check',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1136: {
        id: 'BB1136',
        version: 'v2',
        question:
          "You're at the front of a queue of traffic waiting to turn right into a side road. Why is it important to check your right mirror just before turning?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'B',
        additionalInfo:
          "A motorcyclist could be riding along the outside of the queue. Always check your mirror before turning, as situations behind you can change while you've been waiting to turn.",
        options: {
          A: 'To look for pedestrians about to cross',
          B: 'To check for overtaking vehicles',
          C: 'To make sure the side road is clear',
          D: 'To check for emerging traffic',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1139: {
        id: 'BB1139',
        version: 'v2',
        question:
          "You've driven up to a pelican crossing. What must you do while the amber light is flashing?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'C',
        additionalInfo:
          'The flashing amber light allows pedestrians already on the crossing to get to the other side before a green light shows to the traffic. Be aware that some pedestrians, such as older people and young children, need longer to cross. Let them do this at their own pace.',
        options: {
          A: 'Signal the pedestrian to cross',
          B: 'Always wait for the green light before proceeding',
          C: 'Give way to any pedestrians on the crossing',
          D: 'Wait for the red-and-amber light before proceeding',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1140: {
        id: 'BB1140',
        version: 'v2',
        question:
          "You've stopped at a pelican crossing. What should you do if a disabled person is crossing slowly in front of you and the lights change to green?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'A',
        additionalInfo:
          "At a pelican crossing, the green light means you may proceed as long as the crossing is clear. If someone hasn't finished crossing, be patient and wait for them, whether they're disabled or not.",
        options: {
          A: 'Wait for them to finish crossing',
          B: 'Drive in front of them',
          C: 'Edge forward slowly',
          D: 'Sound your horn',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1142: {
        id: 'BB1142',
        version: 'v2',
        question:
          "You're driving past a line of parked cars. What should you do if a ball bounces out into the road ahead?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'IMG_1290.jpg',
          width: 800,
          height: 533,
          size: 104421,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1290.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Beware of children playing in the street and running out into the road. If a ball bounces out from the pavement, slow down and be prepared to stop. Don't encourage anyone to retrieve it. Other road users may not see your signal and you might lead a child into a dangerous situation.",
        options: {
          A: 'Continue driving at the same speed and sound your horn',
          B: 'Continue driving at the same speed and flash your headlights',
          C: 'Slow down and be prepared to stop for children',
          D: 'Stop and wave the children across to fetch their ball',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1145: {
        id: 'BB1145',
        version: 'v2',
        question:
          'You want to turn right from a main road into a side road. What should you do just before turning?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'C',
        additionalInfo:
          "In some circumstances, your indicators may be difficult to see and another road user may not realise you're about to turn. A final check in your mirror and blind spot can help you to see an overtaking vehicle, so that you can avoid turning across their path.",
        options: {
          A: 'Cancel your right-turn signal',
          B: 'Select first gear',
          C: 'Check for traffic overtaking on your right',
          D: 'Stop and set the parking brake',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1147: {
        id: 'BB1147',
        version: 'v2',
        question:
          "You're driving in a slow-moving queue of traffic. What should you do just before changing lane?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'B',
        additionalInfo:
          'In queuing traffic, motorcyclists could be passing you on either side. Use your mirrors and check your blind area before changing lanes or changing direction.',
        options: {
          A: 'Sound the horn and flash your lights',
          B: 'Look for motorcyclists filtering through the traffic',
          C: "Give a 'slowing down' arm signal",
          D: 'Change down to first gear',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1148: {
        id: 'BB1148',
        version: 'v2',
        question:
          "You're driving in town. Why should you be careful if there's a bus at a bus stop on the other side of the road?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'B',
        additionalInfo:
          "If you see a bus ahead, watch out for pedestrians. They might not be able to see you if they're behind the bus.",
        options: {
          A: 'The bus might have broken down',
          B: 'Pedestrians might come from behind the bus',
          C: 'The bus might move off suddenly',
          D: 'The bus might remain stationary',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1149: {
        id: 'BB1149',
        version: 'v2',
        question: 'How should you overtake horse riders?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          'When you decide to overtake a horse rider, make sure you can do so safely before you move out. Leave them plenty of room and pass slowly. Passing too close could startle the horse and unseat the rider.',
        options: {
          A: 'Drive up close and overtake as soon as possible',
          B: "Speed isn't important but allow plenty of room",
          C: 'Use your horn just once to warn them',
          D: 'Drive slowly and leave plenty of room',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1516: {
        id: 'BB1516',
        version: 'v2',
        question:
          'Why should you allow extra room while overtaking a motorcyclist on a windy day?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'B',
        additionalInfo:
          "If you're driving in high winds, be aware that the conditions might make a motorcyclist (or cyclist) swerve or wobble. Take this into consideration if you're following or wish to overtake a two-wheeled vehicle.",
        options: {
          A: 'The rider may turn off suddenly to get out of the wind',
          B: 'The rider may be blown in front of you',
          C: 'The rider may stop suddenly',
          D: 'The rider may be travelling faster than normal',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1563: {
        id: 'BB1563',
        version: 'v2',
        question:
          'Where should you take particular care to look for motorcyclists and cyclists?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'B',
        additionalInfo:
          "Motorcyclists and cyclists are often more difficult to see at junctions. They're easily hidden from view and you may not be able to see them approaching a junction if your view is partially blocked; for example, by other traffic.",
        options: {
          A: 'On dual carriageways',
          B: 'At junctions',
          C: 'At zebra crossings',
          D: 'On one-way streets',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1577: {
        id: 'BB1577',
        version: 'v2',
        question:
          'The road outside this school is marked with yellow zigzag lines. What do these lines mean?',
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: {
          fileName: 'IMG_1964.jpg',
          width: 800,
          height: 533,
          size: 121690,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1964.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Parking here would block other road users' view of the school entrance and would endanger the lives of children on their way to and from school.",
        options: {
          A: 'You may park on the lines when dropping off schoolchildren',
          B: 'You may park on the lines when picking up schoolchildren',
          C: "You shouldn't wait or park your vehicle here",
          D: 'You must stay with your vehicle if you park here',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1672: {
        id: 'BB1672',
        version: 'v2',
        question:
          "You're driving past parked cars. What should you do if you see a bicycle wheel sticking out between the cars?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          "Scan the road as you drive. Try to anticipate hazards by being aware of the places where they're likely to occur. You'll then be able to react in good time.",
        options: {
          A: 'Accelerate past quickly and sound your horn',
          B: 'Slow down and wave the cyclist across',
          C: 'Brake sharply and flash your headlights',
          D: 'Slow down and be prepared to stop for a cyclist',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1674: {
        id: 'BB1674',
        version: 'v2',
        question:
          "You're driving at night. What should you do if you're dazzled by a vehicle behind you?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'A',
        additionalInfo:
          "The interior mirror of most vehicles can be set to an anti-dazzle position. You'll still be able to see the lights of the traffic behind you, but the dazzle will be greatly reduced.",
        options: {
          A: 'Set your mirror to the anti-dazzle position',
          B: 'Set your mirror to dazzle the other driver',
          C: 'Brake sharply to a stop',
          D: 'Switch your rear lights on and off',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1678: {
        id: 'BB1678',
        version: 'v2',
        question:
          "You're driving towards a zebra crossing. What should you do if a person in a wheelchair is waiting to cross?",
        questionSetId: 'VULNERABLE-ROAD-USERS',
        questionSetTitle: 'Vulnerable road users',
        image: null,
        answer: 'D',
        additionalInfo:
          "You should slow down and be prepared to stop, as you would for an able-bodied person. Don't wave them across, as other traffic may not stop.",
        options: {
          A: 'Continue on your way',
          B: 'Wave to the person to cross',
          C: 'Wave to the person to wait',
          D: 'Be prepared to stop',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2176: {
        id: 'AB2176',
        version: 'v2',
        question:
          "You're about to overtake a slow-moving motorcyclist. Which sign would make you take special care?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          'In windy weather, watch out for motorcyclists and also cyclists, as they can be blown sideways into your path. When you pass them, leave plenty of room and check their position in your mirror before pulling back in.',
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4518.png',
            width: 400,
            height: 353,
            size: 15872,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4518.png?alt=media',
          },
          B: {
            fileName: 'TS4664.png',
            width: 400,
            height: 400,
            size: 22233,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4664.png?alt=media',
          },
          C: {
            fileName: 'TS4693.png',
            width: 400,
            height: 599,
            size: 13040,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4693.png?alt=media',
          },
          D: {
            fileName: 'TS4691.png',
            width: 400,
            height: 400,
            size: 21379,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4691.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2177: {
        id: 'AB2177',
        version: 'v2',
        question:
          "You're waiting to turn right out of a minor road. It's clear to the left but a lorry is coming from the right. Why should you wait, even if you have enough time to turn?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          "Large vehicles can hide other vehicles that are overtaking - especially motorcycles. You need to be aware of the possibility of hidden vehicles and not assume that it's safe to turn.",
        options: {
          A: 'Anything overtaking the lorry will be hidden from view',
          B: 'The lorry could suddenly speed up',
          C: 'The lorry might be slowing down',
          D: 'The load on the lorry might be unstable',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2179: {
        id: 'AB2179',
        version: 'v2',
        question:
          "You're following a long vehicle as it approaches a crossroads. What should you do if it signals left but moves out to the right?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "A long vehicle may need to swing out in the opposite direction as it approaches a turn, to allow the rear wheels to clear the kerb. Don't try to filter through if you see a gap; as the lorry turns, the gap will close.",
        options: {
          A: 'Get closer in order to pass it quickly',
          B: 'Stay well back and give it room',
          C: "Assume the signal is wrong and that it's turning right",
          D: 'Overtake it as it starts to slow down',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2180: {
        id: 'AB2180',
        version: 'v2',
        question:
          "You're following a long vehicle approaching a crossroads. What should you do if the driver signals right but moves close to the left-hand kerb?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "When a long vehicle is going to turn right, it may need to keep close to the left-hand kerb. This is to prevent the rear end of the trailer cutting the corner. You need to be aware of how long vehicles behave in such situations. Don't overtake the lorry, because it could turn as you're alongside. Stay behind and wait for it to turn.",
        options: {
          A: 'Warn the driver about the wrong signal',
          B: 'Wait behind the long vehicle',
          C: 'Report the driver to the police',
          D: 'Overtake on the right-hand side',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2181: {
        id: 'AB2181',
        version: 'v2',
        question:
          "You're approaching a mini-roundabout. What should you do if a long vehicle in front signals left but positions over to the right?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "At mini-roundabouts, there isn't much room for a long vehicle to manoeuvre. It will have to swing out wide so that it can complete the turn safely. Keep well back and don't try to move up alongside it.",
        options: {
          A: 'Sound your horn',
          B: 'Overtake on the left',
          C: 'Follow the same course as the lorry',
          D: 'Keep well back',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2184: {
        id: 'AB2184',
        version: 'v2',
        question:
          "You're driving on a single carriageway road. Why should you keep well back while you're following a large vehicle?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "When following a large vehicle, keep well back. If you're too close, you won't be able to see the road ahead and the driver of the long vehicle might not be able to see you in their mirrors.",
        options: {
          A: 'To give yourself acceleration space if you decide to overtake',
          B: 'To get the best view of the road ahead',
          C: 'To leave a gap in case the vehicle stops and rolls back',
          D: 'To offer other drivers a safe gap if they want to overtake you',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2189: {
        id: 'AB2189',
        version: 'v2',
        question:
          "You're travelling behind a bus. What should you do if it pulls up at a bus stop?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "People may be running to catch the bus or passengers leaving the bus might wish to cross the road in front of the bus. Look out for them if you intend to go past it. Consider how many people are waiting to get on the bus - check the queue if you can. The bus might move off straight away if no-one is waiting to get on.\r\nIf a bus is signalling to pull out, give it priority if it's safe to do so.",
        options: {
          A: 'Accelerate past the bus',
          B: 'Look for pedestrians',
          C: 'Sound your horn',
          D: 'Pull in closely behind the bus',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2193: {
        id: 'AB2193',
        version: 'v2',
        question:
          "You're following a lorry on a wet road. What should you do when spray makes it difficult to see the road ahead?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: {
          fileName: 'IMG_1940.jpg',
          width: 800,
          height: 533,
          size: 48786,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1940.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Large vehicles can throw up a lot of spray when it's wet. This makes it difficult for drivers behind to see the road ahead. You'll be able to see more by dropping back further, out of the spray. This will also increase your separation distance, giving you more room to stop if you have to.",
        options: {
          A: 'Drop back until you can see better',
          B: 'Put your headlights on full beam',
          C: 'Keep close to the lorry, away from the spray',
          D: 'Speed up and overtake quickly',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2568: {
        id: 'AB2568',
        version: 'v2',
        question:
          "You're leaving a safe gap as you follow a large vehicle. What should you do if a car moves into this gap?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "Sometimes your separation distance is shortened by a driver moving into the gap you've allowed. When this happens, react positively, stay calm and drop further back to re-establish a safe following distance.",
        options: {
          A: 'Sound your horn',
          B: 'Drop back further',
          C: 'Flash your headlights',
          D: 'Start to overtake',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2733: {
        id: 'AB2733',
        version: 'v2',
        question:
          "What should you do when you're approaching a bus that's signalling to move away from a bus stop?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          "Try to give way to buses if you can do so safely, especially when the driver signals to pull away from a bus stop. Look out for people getting off the bus or running to catch it, because they may cross the road without looking. Don't accelerate to get past the bus, and don't flash your lights, as this could mislead other road users.",
        options: {
          A: 'Get past before it moves',
          B: "Allow it to pull away, if it's safe to do so",
          C: 'Flash your headlights as you approach',
          D: 'Signal left and wave the bus on',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2734: {
        id: 'AB2734',
        version: 'v2',
        question:
          'What should you do if you want to overtake a long, slow-moving vehicle on a busy road?',
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "When you're following a long vehicle, stay well back so that you can get a better view of the road ahead. The closer you get, the less you'll be able to see of the road. Be patient and don't take a gamble. Only overtake when you're certain that you can complete the manoeuvre safely.",
        options: {
          A: 'Follow it closely and keep moving out to see the road ahead',
          B: 'Flash your headlights for the oncoming traffic to give way',
          C: 'Stay behind until the driver waves you past',
          D: 'Keep well back so that you get a good view of the road ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2735: {
        id: 'AB2735',
        version: 'v2',
        question:
          'Which vehicles are least likely to be affected by side wind?',
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'Although cars are the least likely to be affected, side winds can take anyone by surprise. This is most likely to happen after overtaking a large vehicle, when passing gaps between hedges or buildings, and on exposed sections of road.',
        options: {
          A: 'Cyclists',
          B: 'Motorcyclists',
          C: 'High-sided vehicles',
          D: 'Cars',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2737: {
        id: 'AB2737',
        version: 'v2',
        question: 'What should you do as you approach this lorry?',
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: {
          fileName: 'VL2011.png',
          width: 570,
          height: 390,
          size: 327665,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FVL2011.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'When turning, long vehicles need much more room on the road than other vehicles. At junctions, they may take up the whole of the road space, so be patient and allow them the room they need.',
        options: {
          A: 'Slow down and be prepared to wait',
          B: 'Make the lorry wait for you',
          C: 'Flash your lights at the lorry',
          D: 'Move to the right-hand side of the road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2739: {
        id: 'AB2739',
        version: 'v2',
        question:
          "You're following a large vehicle as it approaches a crossroads. What should you do if the driver signals to turn left?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "Hold back and wait until the vehicle has turned before proceeding. Don't overtake, because the vehicle turning left could hide another vehicle emerging from the same junction.",
        options: {
          A: 'Overtake if you can leave plenty of room',
          B: 'Overtake if there are no oncoming vehicles',
          C: 'Wait for the driver to cancel his signal',
          D: 'Wait for the vehicle to finish turning',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2881: {
        id: 'AB2881',
        version: 'v2',
        question:
          'Why is it more difficult to overtake a large vehicle than a car?',
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'A',
        additionalInfo:
          "Depending on relative speed, it will usually take you longer to pass a lorry than other vehicles. Hazards to watch for include oncoming traffic, junctions ahead, bends or dips that could restrict your view, and signs or road markings that prohibit overtaking. Make sure you can see that it's safe to complete the manoeuvre before you start to overtake.",
        options: {
          A: 'It will take longer to overtake a large vehicle',
          B: 'A large vehicle will be fitted with a speed limiter',
          C: 'A large vehicle will have air brakes',
          D: 'It will take longer for a large vehicle to accelerate',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1157: {
        id: 'BB1157',
        version: 'v2',
        question:
          "It's very windy. What should you do if you're behind a motorcyclist who's overtaking a high-sided vehicle?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          'Windy weather affects motorcyclists more than other vehicles. In windy conditions, high-sided vehicles cause air turbulence. You should keep well back, as the motorcyclist could be blown off course.',
        options: {
          A: 'Overtake the motorcyclist immediately',
          B: 'Keep well back',
          C: 'Stay level with the motorcyclist',
          D: 'Keep close to the motorcyclist',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1162: {
        id: 'BB1162',
        version: 'v2',
        question:
          "What should you do if there's a bus at a bus stop ahead of you?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'C',
        additionalInfo:
          "As you approach, look out for any signal the driver might make. If you pass the vehicle, watch out for pedestrians attempting to cross the road from behind the bus. They'll be hidden from view until the last moment.",
        options: {
          A: 'Flash your lights to warn the driver of your presence',
          B: 'Continue at the same speed but sound your horn as a warning',
          C: 'Watch carefully for the sudden appearance of pedestrians',
          D: 'Pass the bus as quickly as you possibly can',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1399: {
        id: 'BB1399',
        version: 'v2',
        question: 'What should you be prepared to do in this situation?',
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: {
          fileName: 'VL2012.png',
          width: 595,
          height: 390,
          size: 417692,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FVL2012.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'Sometimes, large vehicles may need more space than other road users. If a vehicle needs more time and space to turn, be prepared to stop and wait.',
        options: {
          A: 'Sound your horn and continue',
          B: 'Slow down and give way',
          C: 'Report the driver to the police',
          D: 'Squeeze through the gap',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1578: {
        id: 'BB1578',
        version: 'v2',
        question:
          'Why should drivers be more careful on roads where trams also operate?',
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "You should take extra care when you first encounter trams. You'll have to get used to dealing with a different traffic system. \r\nBe aware that trams can accelerate and travel very quickly, and they can't change direction to avoid obstructions.",
        options: {
          A: "Because trams don't have a horn",
          B: "Because trams can't stop for cars",
          C: "Because trams don't have lights",
          D: "Because trams can't steer to avoid obstructions",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1679: {
        id: 'BB1679',
        version: 'v2',
        question:
          "You're towing a caravan. Which is the safest type of rear-view mirror to use?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'B',
        additionalInfo:
          'Towing a large trailer or caravan can greatly reduce your view of the road behind. You may need to fit extended-arm side mirrors so that you can see clearly behind and down both sides of the caravan or trailer.',
        options: {
          A: 'Interior wide-angle mirror',
          B: 'Extended-arm side mirrors',
          C: 'Ordinary door mirrors',
          D: 'Ordinary interior mirror',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1680: {
        id: 'BB1680',
        version: 'v2',
        question:
          "You're driving in heavy traffic on a wet road. Which lights should you use if there's a lot of surface spray?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          "You must make sure that other road users can see you, but you don't want to dazzle them. Use your dipped headlights during the day if visibility is poor. If visibility falls below 100 metres (328 feet), you may use your rear fog lights, but don't forget to turn them off when the visibility improves.",
        options: {
          A: 'Main-beam headlights',
          B: 'Sidelights only',
          C: 'Rear fog lights if visibility is more than 100 metres (328 feet)',
          D: 'Dipped headlights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1720: {
        id: 'BB1720',
        version: 'v2',
        question:
          "What should you do if you overtake a cyclist when it's very windy?",
        questionSetId: 'OTHER-TYPES-OF-VEHICLE',
        questionSetTitle: 'Other types of vehicle',
        image: null,
        answer: 'D',
        additionalInfo:
          'Cyclists, and motorcyclists, are very vulnerable in high winds. They can easily be blown well off course and veer into your path. Always allow plenty of room when overtaking them. Passing too close could cause a draught and unbalance the rider.',
        options: {
          A: 'Overtake very slowly',
          B: 'Keep close as you pass',
          C: 'Sound your horn repeatedly',
          D: 'Allow extra room',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2199: {
        id: 'AB2199',
        version: 'v2',
        question: 'When may you overtake another vehicle on their left?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'A',
        additionalInfo:
          'You may pass slower vehicles on their left while travelling along a one-way street. Be aware of drivers who may need to change lanes and may not expect faster traffic passing on their left.',
        options: {
          A: "When you're in a one-way street",
          B: "When approaching a motorway slip road where you'll be turning off",
          C: 'When the vehicle in front is signalling to turn left',
          D: 'When a slower vehicle is travelling in the right-hand lane of a dual carriageway',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2201: {
        id: 'AB2201',
        version: 'v2',
        question:
          "You're travelling in very heavy rain. How is this likely to affect your overall stopping distance?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'A',
        additionalInfo:
          "The road will be very wet and spray from other vehicles will reduce your visibility. Tyre grip will also be reduced, increasing your stopping distance. You should at least double your separation distance to make sure you can stop safely in the space you've allowed.",
        options: {
          A: 'It will be doubled',
          B: 'It will be halved',
          C: 'It will be ten times greater',
          D: 'It will be no different',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2208: {
        id: 'AB2208',
        version: 'v2',
        question: "What should you do when you're overtaking at night?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'D',
        additionalInfo:
          "Don't overtake if there's a possibility of a road junction, bend or brow of a bridge or hill ahead. There are many hazards that are difficult to see in the dark. Only overtake if you're certain that the road ahead is clear. Don't take a chance.",
        options: {
          A: 'Wait until a bend so that you can see oncoming headlights',
          B: 'Sound your horn twice before moving out',
          C: 'Go past slowly so that you can react to unseen hazards',
          D: 'Beware of bends in the road ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2211: {
        id: 'AB2211',
        version: 'v2',
        question: 'When may you wait in a box junction?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: {
          fileName: 'J7003.png',
          width: 800,
          height: 440,
          size: 27979,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FJ7003.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "The purpose of a box junction is to keep the junction clear by preventing vehicles from stopping in the path of crossing traffic. \r\nYou mustn't enter a box junction unless your exit is clear. However, you may enter the box and wait if you want to turn right and are only prevented from doing so by oncoming traffic.",
        options: {
          A: "When you're stationary in a queue of traffic",
          B: 'When approaching a pelican crossing',
          C: 'When approaching a zebra crossing',
          D: 'When oncoming traffic prevents you turning right',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2212: {
        id: 'AB2212',
        version: 'v2',
        question: 'Which plate may appear with this road sign?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: {
          fileName: 'TS4602.png',
          width: 800,
          height: 707,
          size: 30678,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4602.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Road humps are used to slow down traffic. They're found in places where there are often pedestrians, such as\r\n• shopping areas\r\n• near schools\r\n• residential areas. \r\nWatch out for people close to the kerb or crossing the road.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4654.png',
            width: 400,
            height: 189,
            size: 12792,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4654.png?alt=media',
          },
          B: {
            fileName: 'TS4090.png',
            width: 400,
            height: 189,
            size: 9903,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4090.png?alt=media',
          },
          C: {
            fileName: 'TS4065.png',
            width: 400,
            height: 189,
            size: 8760,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4065.png?alt=media',
          },
          D: {
            fileName: 'TS4067.png',
            width: 400,
            height: 189,
            size: 10874,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4067.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2524: {
        id: 'AB2524',
        version: 'v2',
        question: "What's the reason for traffic-calming measures?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'C',
        additionalInfo:
          'Traffic-calming measures make the roads safer for vulnerable road users, such as cyclists, pedestrians and children. These can be designed as chicanes, road humps or other obstacles that encourage drivers and riders to slow down.',
        options: {
          A: 'To stop road rage',
          B: 'To make overtaking easier',
          C: 'To slow traffic down',
          D: 'To make parking easier',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2570: {
        id: 'AB2570',
        version: 'v2',
        question:
          'What colour are the reflective studs along the left-hand edge of the motorway?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'C',
        additionalInfo:
          "Reflective studs are used to help you in poor visibility. Different colours are used so that you'll know which lane you're in. These are\r\n• red on the left-hand edge of the carriageway\r\n• white between lanes \r\n• amber on the right-hand edge of the carriageway\r\n• green between the carriageway and slip roads.",
        options: {A: 'Green', B: 'Amber', C: 'Red', D: 'White'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2572: {
        id: 'AB2572',
        version: 'v2',
        question: "What's a rumble device designed to do?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: {
          fileName: '85BW9940.jpg',
          width: 800,
          height: 532,
          size: 116608,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW9940.jpg?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'A rumble device consists of raised markings or strips, designed to give drivers an audible, visual and tactile warning. These devices are used in various locations, including in the line separating the hard shoulder and the left-hand lane on the motorway and on the approach to some hazards, to alert drivers to the need to slow down.',
        options: {
          A: 'Give directions',
          B: 'Prevent cattle escaping',
          C: 'Alert you to low tyre pressure',
          D: 'Alert you to a hazard',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2605: {
        id: 'AB2605',
        version: 'v2',
        question:
          'What should you do if you have to make a journey in foggy conditions?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'C',
        additionalInfo:
          "If you're planning to make a journey when it's foggy, listen to the weather reports. If visibility is very poor, avoid making unnecessary journeys. If you do travel, leave plenty of time - and if someone is waiting for you to arrive, let them know that your journey will take longer than normal. This will also take off any pressure you may feel to rush.",
        options: {
          A: "Follow other vehicles' tail lights closely",
          B: 'Avoid using dipped headlights',
          C: 'Leave plenty of time for your journey',
          D: 'Keep two seconds behind the vehicle ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2606: {
        id: 'AB2606',
        version: 'v2',
        question: "What must you do when you're overtaking a car at night?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'D',
        additionalInfo:
          "To prevent your headlights from dazzling the driver of the car in front, wait until you've passed them before switching to main beam.",
        options: {
          A: 'Flash your headlights before overtaking',
          B: 'Select a higher gear',
          C: 'Switch your headlights to main beam before overtaking',
          D: "Make sure you don't dazzle other road users",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2640: {
        id: 'AB2640',
        version: 'v2',
        question:
          "You're travelling on a road that has road humps. What should you do when the driver in front is travelling more slowly than you?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: {
          fileName: 'TS4602.png',
          width: 800,
          height: 707,
          size: 30678,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4602.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "Be patient and stay behind the car in front. You shouldn't normally overtake other vehicles in areas subject to traffic calming. If you overtake here, you may easily exceed the speed limit, defeating the purpose of the traffic-calming measures.",
        options: {
          A: 'Sound your horn',
          B: 'Overtake as soon as you can',
          C: 'Flash your headlights',
          D: 'Slow down and stay behind',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2665: {
        id: 'AB2665',
        version: 'v2',
        question:
          "What's the purpose of the yellow lines painted across the road?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: {
          fileName: '85BW9937.jpg',
          width: 800,
          height: 532,
          size: 98342,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW9937.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'These lines may be painted on the road on the approach to a roundabout, a village or a particular hazard. The lines are raised and painted yellow, and their purpose is to make you aware of your speed. Reduce your speed in good time so that you avoid having to brake harshly over the last few metres before reaching the junction.',
        options: {
          A: 'To show a safe distance between vehicles',
          B: 'To keep the area clear of traffic',
          C: 'To make you aware of your speed',
          D: 'To warn you to change direction',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2742: {
        id: 'AB2742',
        version: 'v2',
        question:
          'What should you do when you meet an oncoming vehicle on a single-track road?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'C',
        additionalInfo:
          'Take care when using single-track roads. It can be difficult to see around bends, because of hedges or fences, so expect to meet oncoming vehicles. Drive carefully and be ready to pull into or stop opposite a passing place, where you can pass each other safely.',
        options: {
          A: 'Reverse back to the main road',
          B: 'Carry out an emergency stop',
          C: 'Stop at a passing place',
          D: 'Switch on your hazard warning lights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2820: {
        id: 'AB2820',
        version: 'v2',
        question:
          'The road is wet. Why would a motorcyclist steer around drain covers while they were cornering?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'B',
        additionalInfo:
          'Other drivers or riders may have to change course due to the size or characteristics of their vehicle. Understanding this will help you to anticipate their actions. Motorcyclists and cyclists will be checking the road ahead for uneven or slippery surfaces, especially in wet weather. They may need to move across their lane to avoid surface hazards such as potholes and drain covers.',
        options: {
          A: 'To avoid puncturing the tyres on the edge of the drain covers',
          B: 'To prevent the motorcycle sliding on the metal drain covers',
          C: 'To help judge the bend using the drain covers as marker points',
          D: 'To avoid splashing pedestrians on the pavement',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2821: {
        id: 'AB2821',
        version: 'v2',
        question: 'Why should you test your brakes after this hazard?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: {
          fileName: 'TS4516.png',
          width: 537,
          height: 475,
          size: 22398,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4516.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "A ford is a crossing over a stream that's shallow enough to drive or ride through. After you've gone through a ford or deep puddle, your brakes will be wet and they won't work as well as usual. To dry them out, apply a light brake pressure while moving slowly. Don't travel at normal speeds until you're sure your brakes are working properly again.",
        options: {
          A: "You'll be on a slippery road",
          B: 'Your brakes will be wet',
          C: "You'll be going down a long hill",
          D: "You'll have just crossed a long bridge",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2822: {
        id: 'AB2822',
        version: 'v2',
        question:
          "Why should you reduce your speed when you're driving or riding in fog?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'D',
        additionalInfo:
          "You won't be able to see as far ahead in fog as you can on a clear day. You'll need to reduce your speed so that, if a hazard looms out of the fog, you have the time and space to take avoiding action.\r\nTravelling in fog is hazardous. If you can, try to delay your journey until it has cleared.",
        options: {
          A: "The brakes don't work as well",
          B: "You'll be dazzled by other headlights",
          C: 'The engine will take longer to warm up',
          D: "It's more difficult to see what's ahead",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1166: {
        id: 'BB1166',
        version: 'v2',
        question:
          'What will happen to your car when you drive up a steep hill?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'D',
        additionalInfo:
          "The engine will need more power to pull the vehicle up the hill. When approaching a steep hill you should select a lower gear to help maintain your speed. You should do this without hesitation, so that you don't lose too much speed before engaging the lower gear.",
        options: {
          A: 'The high gears will pull better',
          B: 'The steering will feel heavier',
          C: 'Overtaking will be easier',
          D: 'The engine will work harder',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1168: {
        id: 'BB1168',
        version: 'v2',
        question:
          "You're driving on the motorway in windy conditions. What should you do as you overtake a high-sided vehicle?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'B',
        additionalInfo:
          'The draught caused by other vehicles - particularly those with high sides - could be strong enough to push you out of your lane. Be prepared for a sudden gust of wind as you overtake large vehicles. Keep both hands on the steering wheel to help you keep full control.',
        options: {
          A: 'Increase your speed',
          B: 'Be wary of a sudden gust',
          C: 'Drive alongside very closely',
          D: 'Expect normal conditions',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1177: {
        id: 'BB1177',
        version: 'v2',
        question:
          "You're driving in fog. Why should you keep well back from the vehicle in front?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'C',
        additionalInfo:
          "If you're following another road user in fog, stay well back. The driver in front won't be able to see hazards until they're close and might need to brake suddenly. Also, the road surface is likely to be wet and could be slippery.",
        options: {
          A: 'In case it changes direction suddenly',
          B: 'In case its fog lights dazzle you',
          C: 'In case it stops suddenly',
          D: 'In case its brake lights dazzle you',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1181: {
        id: 'BB1181',
        version: 'v2',
        question: "What should you do if you park on the road when it's foggy?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'A',
        additionalInfo:
          "If you have to park your vehicle in foggy conditions, try to find a place to park off the road. If this isn't possible, park on the road facing in the same direction as the traffic. Leave your parking lights switched on and make sure they're clean.",
        options: {
          A: 'Leave parking lights switched on',
          B: 'Leave dipped headlights and fog lights switched on',
          C: 'Leave dipped headlights switched on',
          D: 'Leave main-beam headlights switched on',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1186: {
        id: 'BB1186',
        version: 'v2',
        question:
          "You're driving at night. What should you do if you're dazzled by headlights coming towards you?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'B',
        additionalInfo:
          "If the headlights of an oncoming vehicle dazzle you, slow down or, if necessary, stop. Don't close your eyes or swerve, as you'll increase your chances of having a collision. Don't flash your headlights either, as this could dazzle other drivers and make the situation worse.",
        options: {
          A: 'Pull down your sun visor',
          B: 'Slow down or stop',
          C: 'Flash your main-beam headlights',
          D: 'Shade your eyes with your hand',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1325: {
        id: 'BB1325',
        version: 'v2',
        question: 'When may front fog lights be used?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: {
          fileName: '85BW3359.jpg',
          width: 800,
          height: 533,
          size: 12772,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW3359.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Your fog lights must only be used when visibility is reduced to 100 metres (328 feet) or less. You need to be familiar with the layout of your dashboard so you're aware if your fog lights have been switched on in error, or you've forgotten to switch them off.",
        options: {
          A: 'When visibility is seriously reduced',
          B: "When they're fitted above the bumper",
          C: "When they aren't as bright as the headlights",
          D: 'When an audible warning device is used',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1330: {
        id: 'BB1330',
        version: 'v2',
        question:
          "You're driving with your front fog lights switched on. What should you do if the fog has cleared?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'B',
        additionalInfo:
          'Switch off your fog lights if the weather improves, but be prepared to use them again if visibility reduces to less than 100 metres (328 feet).',
        options: {
          A: 'Leave them on if other drivers have their lights on',
          B: 'Switch them off as long as visibility remains good',
          C: "Flash them to warn oncoming traffic that it's foggy",
          D: 'Drive with them on instead of your headlights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1339: {
        id: 'BB1339',
        version: 'v2',
        question:
          'Why should you switch off your rear fog lights when the fog has cleared?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'D',
        additionalInfo:
          "Don't forget to switch off your fog lights when the weather improves. You could be prosecuted for driving with them on in good visibility. The high intensity of rear fog lights can dazzle drivers behind and make your brake lights difficult to notice.",
        options: {
          A: 'To allow your headlights to work',
          B: 'To stop draining the battery',
          C: 'To stop the engine losing power',
          D: 'To prevent dazzling drivers behind',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1343: {
        id: 'BB1343',
        version: 'v2',
        question:
          'What will happen if you use rear fog lights in good conditions?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'C',
        additionalInfo:
          'Rear fog lights shine more brightly than normal rear lights, so that they show up in reduced visibility. When the visibility improves, you must switch them off; this stops them dazzling the driver behind.',
        options: {
          A: "They'll make it safer when towing a trailer",
          B: "They'll protect you from larger vehicles",
          C: "They'll dazzle other drivers",
          D: "They'll make drivers behind keep back",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1400: {
        id: 'BB1400',
        version: 'v2',
        question: 'Why would you fit chains to your wheels?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'C',
        additionalInfo:
          "Chains can be fitted to your wheels in snowy conditions. They can help you to move off without wheelspin, or to keep moving in deep snow. You'll still need to adjust your driving to suit these conditions.",
        options: {
          A: 'To help prevent damage to the road surface',
          B: 'To help prevent wear to the tyres',
          C: 'To help prevent skidding in deep snow',
          D: 'To help prevent the brakes locking',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1408: {
        id: 'BB1408',
        version: 'v2',
        question:
          "How can you use your vehicle's engine to control your speed?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'A',
        additionalInfo:
          'You should brake and slow down before selecting a lower gear. The gear can then be used to keep the speed low and help you control the vehicle. This is particularly helpful on long downhill stretches, where brake fade can occur if the brakes overheat.',
        options: {
          A: 'By changing to a lower gear',
          B: 'By selecting reverse gear',
          C: 'By changing to a higher gear',
          D: 'By selecting neutral',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1433: {
        id: 'BB1433',
        version: 'v2',
        question:
          "Why could it be dangerous to keep the clutch down, or select neutral, for long periods of time while you're driving?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'C',
        additionalInfo:
          'Letting your vehicle roll or coast in neutral reduces your control over steering and braking. This can be dangerous on downhill slopes, where your vehicle could pick up speed very quickly.',
        options: {
          A: 'Fuel spillage will occur',
          B: 'Engine damage may be caused',
          C: "You'll have less steering and braking control",
          D: 'It will wear tyres out more quickly',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1470: {
        id: 'BB1470',
        version: 'v2',
        question:
          "You're driving on an icy road. What distance from the car in front should you drive?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'D',
        additionalInfo:
          "Don't travel in icy or snowy weather unless your journey is essential.\r\nDrive extremely carefully when roads are or may be icy. Stopping distances can be ten times greater than on dry roads.",
        options: {
          A: 'Four times the normal distance',
          B: 'Six times the normal distance',
          C: 'Eight times the normal distance',
          D: 'Ten times the normal distance',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1473: {
        id: 'BB1473',
        version: 'v2',
        question:
          "Which lights must you use if you're driving on a well-lit motorway at night?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'B',
        additionalInfo:
          "If you're driving on a motorway at night or in poor visibility, you must always use your headlights, even if the road is well lit. Other road users must be able to see you, but you should avoid causing dazzle.",
        options: {
          A: 'Use only your sidelights',
          B: 'Use your headlights',
          C: 'Use rear fog lights',
          D: 'Use front fog lights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1474: {
        id: 'BB1474',
        version: 'v2',
        question:
          "You're driving on a motorway at night. Which lights should you have on if there are other vehicles just ahead of you?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'D',
        additionalInfo:
          "If you're driving behind other traffic on the motorway at night, use dipped headlights. Main-beam headlights will dazzle the other drivers. Your headlights' dipped beam should fall short of the vehicle in front.",
        options: {
          A: 'Front fog lights',
          B: 'Main-beam headlights',
          C: 'Sidelights only',
          D: 'Dipped headlights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1518: {
        id: 'BB1518',
        version: 'v2',
        question: "What will affect your vehicle's stopping distance?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'D',
        additionalInfo:
          "Having tyres correctly inflated and in good condition will ensure they have maximum grip on the road; how well your tyres grip the road has a significant effect on your car's stopping distance.",
        options: {
          A: 'The speed limit',
          B: 'The street lighting',
          C: 'The time of day',
          D: 'The condition of the tyres',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1579: {
        id: 'BB1579',
        version: 'v2',
        question: 'When will you feel the effects of engine braking?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'C',
        additionalInfo:
          "When you take your foot off the accelerator, engines have a natural resistance to turn, caused mainly by the cylinder compression. Changing to a lower gear requires the engine to turn faster and so it will have greater resistance than when it's made to turn more slowly. When going downhill, changing to a lower gear will therefore help to keep the vehicle's speed in check.",
        options: {
          A: 'When you only use the parking brake',
          B: "When you're in neutral",
          C: 'When you change to a lower gear',
          D: 'When you change to a higher gear',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1597: {
        id: 'BB1597',
        version: 'v2',
        question:
          'Which lights should you switch on when daytime visibility is poor but not seriously reduced?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'C',
        additionalInfo:
          'Only use your fog lights when visibility is seriously reduced. Use dipped headlights in poor conditions because this helps other road users to see you without the risk of causing dazzle.',
        options: {
          A: 'Headlights and fog lights',
          B: 'Front fog lights',
          C: 'Dipped headlights',
          D: 'Rear fog lights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1598: {
        id: 'BB1598',
        version: 'v2',
        question: 'Why are vehicles fitted with rear fog lights?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'C',
        additionalInfo:
          "Rear fog lights make it easier to spot a vehicle ahead in foggy conditions. Avoid the temptation to use other vehicles' lights as a guide, as they may give you a false sense of security.",
        options: {
          A: 'To make them more visible when driving at high speed',
          B: "To show when they've broken down in a dangerous position",
          C: 'To make them more visible in thick fog',
          D: 'To warn drivers following closely to drop back',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1621: {
        id: 'BB1621',
        version: 'v2',
        question:
          "There's been a heavy fall of snow. What should you consider before driving in these conditions?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'D',
        additionalInfo:
          "Consider whether the increased risk is worth it. If the weather conditions are bad and your journey isn't essential, then don't drive. If you have to drive, make sure you're well prepared in case you get stuck.",
        options: {
          A: 'Whether you should fit an amber flashing beacon to your car',
          B: 'Whether you should drive without wearing your seat belt',
          C: 'Whether you should wear sunglasses to reduce the glare',
          D: 'Whether your journey is essential',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1682: {
        id: 'BB1682',
        version: 'v2',
        question:
          'What should you check before you start a journey in foggy weather?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'C',
        additionalInfo:
          'If you have to drive in fog, switch your dipped headlights on and keep all your windows clear. You should always be able to pull up within the distance you can see ahead.',
        options: {
          A: 'The radiator has enough anti-freeze',
          B: 'You have a warning triangle in the vehicle',
          C: 'The windows and lights are clean and clear',
          D: 'You have a mobile phone with you',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1683: {
        id: 'BB1683',
        version: 'v2',
        question:
          "You've been driving in fog. What must you do when the visibility improves?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'A',
        additionalInfo:
          'You must turn off your fog lights if visibility is more than 100 metres (328 feet). Be prepared for the fact that the fog may be patchy and you may need to turn them on again if the fog returns.',
        options: {
          A: 'Switch off your fog lights',
          B: 'Keep your rear fog lights switched on',
          C: 'Keep your front fog lights switched on',
          D: 'Leave your fog lights switched on in case the fog returns',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1685: {
        id: 'BB1685',
        version: 'v2',
        question:
          'Why is it dangerous to leave rear fog lights switched on after the fog has cleared?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'A',
        additionalInfo:
          "If your rear fog lights are left on when it isn't foggy, the glare they cause makes it difficult for road users behind to know whether you're braking or you've just forgotten to turn off your rear fog lights. This can be a particular problem on wet roads and on motorways. If you leave your rear fog lights on at night, road users behind you are likely to be dazzled and this could put them at risk.",
        options: {
          A: 'They may be confused with brake lights',
          B: 'The bulbs would fail',
          C: 'Electrical systems could be overloaded',
          D: 'Direction indicators may not work properly',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1686: {
        id: 'BB1686',
        version: 'v2',
        question:
          'What will happen if you hold the clutch pedal down or roll in neutral for too long?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'C',
        additionalInfo:
          "Holding the clutch down or staying in neutral for too long will cause your vehicle to freewheel. This is known as 'coasting' and it's dangerous because it reduces your control of the vehicle.",
        options: {
          A: 'It will use more fuel',
          B: 'It will cause the engine to overheat',
          C: 'It will reduce your control',
          D: 'It will improve tyre wear',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1717: {
        id: 'BB1717',
        version: 'v2',
        question:
          "Why is it bad technique to coast when you're driving downhill?",
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'D',
        additionalInfo:
          "Coasting is when you allow the vehicle to freewheel in neutral or with the clutch pedal depressed. When travelling downhill, this will cause the vehicle to gain speed more quickly as you lose the benefits of engine braking; it may even lead to a loss of control. You shouldn't coast, especially when approaching hazards such as junctions or bends and when travelling downhill.",
        options: {
          A: 'The fuel consumption will increase',
          B: 'The engine will overheat',
          C: 'The tyres will wear more quickly',
          D: 'The vehicle will gain speed more quickly',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1718: {
        id: 'BB1718',
        version: 'v2',
        question: 'What should you do when dealing with this hazard?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: {
          fileName: 'TS4516.png',
          width: 537,
          height: 475,
          size: 22398,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4516.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "In normal conditions, a ford can be crossed quite safely by driving through it slowly. The water may affect your brakes, so when you're clear of the ford, test them before you resume normal driving.",
        options: {
          A: 'Switch on your hazard warning lights',
          B: 'Use a low gear and drive slowly',
          C: 'Use a high gear to prevent wheelspin',
          D: 'Switch on your windscreen wipers',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1744: {
        id: 'BB1744',
        version: 'v2',
        question:
          'Why is travelling in neutral for long distances (known as coasting) bad driving technique?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'D',
        additionalInfo:
          'Try to look and plan well ahead. Plan your approach to hazards and select the correct gear in good time. This will help give you the control you need to deal with anything unexpected that may occur.',
        options: {
          A: 'It will cause the car to skid',
          B: 'It will make the engine stall',
          C: 'The engine will run faster',
          D: "There won't be any engine braking",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1749: {
        id: 'BB1749',
        version: 'v2',
        question: 'When must you use dipped headlights during the day?',
        questionSetId: 'VEHICLE-HANDLING',
        questionSetTitle: 'Vehicle handling',
        image: null,
        answer: 'C',
        additionalInfo:
          'You must use dipped headlights when daytime visibility is seriously reduced, generally to 100 metres (328 feet) or less. You may also use front or rear fog lights, but they must be switched off when visibility improves.',
        options: {
          A: "All the time you're driving",
          B: "When you're driving along narrow streets",
          C: "When you're driving in poor visibility",
          D: "When you're parking",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2219: {
        id: 'AB2219',
        version: 'v2',
        question:
          "You're joining a motorway from a slip road. How should you deal with traffic already on the motorway?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'D',
        additionalInfo:
          "You should give way to traffic already on the motorway. Where possible, traffic may move over to let you in, but don't force your way into the traffic stream. Traffic could be travelling at high speed, so try to match your speed to filter in without affecting the traffic flow.",
        options: {
          A: 'Carry on along the hard shoulder until you see a safe gap',
          B: 'Stop at the end of the slip road and look for a safe gap',
          C: "Use the slip road to accelerate until you're moving much faster than the motorway traffic",
          D: 'Match your speed to traffic in the left-hand lane and filter into a safe gap',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2222: {
        id: 'AB2222',
        version: 'v2',
        question:
          "What's the national speed limit on motorways for cars and motorcycles?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'D',
        additionalInfo:
          "Travelling at the national speed limit doesn't allow you to hog the right-hand lane. Always use the left-hand lane whenever possible. When leaving a motorway, get into the left-hand lane well before your exit. Reduce your speed on the slip road and look out for sharp bends or curves and traffic queuing at roundabouts.",
        options: {A: '30 mph', B: '50 mph', C: '60 mph', D: '70 mph'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2223: {
        id: 'AB2223',
        version: 'v2',
        question:
          'Which vehicles should use the left-hand lane on a three-lane motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'A',
        additionalInfo:
          "On a motorway, all traffic should use the left-hand lane unless overtaking. When overtaking a number of slower vehicles, move back to the left-hand lane when you're safely past. Check your mirrors frequently and don't stay in the middle or right-hand lane if the left-hand lane is free.",
        options: {
          A: "Any vehicle that isn't overtaking",
          B: 'Large vehicles only',
          C: 'Emergency vehicles only',
          D: 'Slow vehicles only',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2225: {
        id: 'AB2225',
        version: 'v2',
        question:
          "Which vehicles aren't allowed to use the right-hand lane of a three-lane motorway?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'C',
        additionalInfo:
          "On the motorway, any vehicle towing a trailer is restricted to 60 mph. It isn't allowed in the right-hand lane, as it might hold up faster-moving traffic that wishes to overtake in that lane.",
        options: {
          A: 'Small delivery vans',
          B: 'Motorcycles',
          C: 'Vehicles towing a trailer',
          D: 'Motorcycle and sidecar outfits',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2232: {
        id: 'AB2232',
        version: 'v2',
        question:
          'Your vehicle breaks down on a motorway and you need to call for help. Why might it be better to use an emergency roadside telephone rather than a mobile phone?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'C',
        additionalInfo:
          "On a motorway, it's best to use a roadside emergency telephone so that the emergency services are able to find you easily. The location of the nearest telephone is shown by an arrow on marker posts at the edge of the hard shoulder. If you use a mobile, find out the number on the nearest marker post before you call. This number will let the operator know where you are and in which direction you're travelling.",
        options: {
          A: 'It connects you to a local garage',
          B: 'Using a mobile phone will distract other drivers',
          C: 'It allows easy location by the emergency services',
          D: "Mobile phones don't work on motorways",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2236: {
        id: 'AB2236',
        version: 'v2',
        question:
          'Your vehicle broke down on the hard shoulder of a motorway, but has now been repaired. How should you rejoin the main carriageway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'C',
        additionalInfo:
          "Signal your intention and build up sufficient speed on the hard shoulder so that you can filter into a safe gap in the traffic. Don't push your way into a small gap or cause other traffic to alter speed or direction.",
        options: {
          A: 'Move out onto the carriageway, then build up your speed',
          B: 'Move out onto the carriageway using your hazard warning lights',
          C: 'Gain speed on the hard shoulder before moving out onto the carriageway',
          D: 'Wait on the hard shoulder until someone flashes their headlights at you',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2237: {
        id: 'AB2237',
        version: 'v2',
        question:
          "You're travelling along a motorway. Where would you find a crawler or climbing lane?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: {
          fileName: 'TS4503.png',
          width: 783,
          height: 682,
          size: 30864,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4503.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'Large, slow-moving vehicles can hinder the progress of other traffic. On a steep gradient, an extra crawler lane may be provided for slow-moving vehicles to allow faster-moving traffic to flow more easily.',
        options: {
          A: 'On a steep gradient',
          B: 'Before a service area',
          C: 'Before a junction',
          D: 'Along the hard shoulder',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2241: {
        id: 'AB2241',
        version: 'v2',
        question: 'What do these motorway signs mean?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: {
          fileName: 'TS4049.png',
          width: 800,
          height: 707,
          size: 315962,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4049.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'The exit from a motorway is indicated by countdown markers. These are positioned 90 metres (100 yards) apart, the first being 270 metres (300 yards) from the start of the slip road. Move into the left-hand lane well before you reach the start of the slip road.',
        options: {
          A: "They're countdown markers to a bridge",
          B: "They're distance markers to the next telephone",
          C: "They're countdown markers to the next exit",
          D: 'They warn of a police control ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2242: {
        id: 'AB2242',
        version: 'v2',
        question: 'Where are amber reflective studs found on a motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'C',
        additionalInfo:
          "On motorways, reflective studs of various colours are fixed in the road between the lanes. These help you to identify which lane you're in when it's dark or in poor visibility. Amber-coloured studs are found on the right-hand edge of the main carriageway, next to the central reservation.",
        options: {
          A: 'Between the hard shoulder and the carriageway',
          B: 'Between the acceleration lane and the carriageway',
          C: 'Between the central reservation and the carriageway',
          D: 'Between each pair of lanes',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2243: {
        id: 'AB2243',
        version: 'v2',
        question:
          'What colour are the reflective studs between the lanes on a motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'C',
        additionalInfo:
          'White studs are found between the lanes on motorways. They reflect back the light from your headlights. This is especially useful in bad weather, when visibility is restricted.',
        options: {A: 'Green', B: 'Amber', C: 'White', D: 'Red'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2244: {
        id: 'AB2244',
        version: 'v2',
        question:
          'What colour are the reflective studs between a motorway and a slip road?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'C',
        additionalInfo:
          "The studs between the carriageway and the hard shoulder are normally red. These change to green where there's a slip road, helping you to identify slip roads when visibility is poor or when it's dark.",
        options: {A: 'Amber', B: 'White', C: 'Green', D: 'Red'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2525: {
        id: 'AB2525',
        version: 'v2',
        question:
          'Your vehicle has broken down on a motorway. In which direction should you walk to find the nearest emergency telephone?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'C',
        additionalInfo:
          'Along the hard shoulder there are marker posts at 100-metre intervals. These will direct you to the nearest emergency telephone.',
        options: {
          A: 'With the traffic flow',
          B: 'Facing oncoming traffic',
          C: 'In the direction shown on the marker posts',
          D: 'In the direction of the nearest exit',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2573: {
        id: 'AB2573',
        version: 'v2',
        question:
          'Why is it important to make full use of the slip road as you join a motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'C',
        additionalInfo:
          'Try to join the motorway without affecting the progress of the traffic in the left-hand lane and always give way to traffic already on the motorway. At busy times, you may have to slow down to merge into slow-moving traffic.',
        options: {
          A: "Because there's space available to turn round if you need to",
          B: 'To allow you direct access to the overtaking lanes',
          C: 'To allow you to fit safely into the traffic flow in the left-hand lane',
          D: 'Because you can continue on the hard shoulder',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2575: {
        id: 'AB2575',
        version: 'v2',
        question:
          'How should you position yourself when you use the emergency telephone on a motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'B',
        additionalInfo:
          "Traffic is passing you at speed. If the draught from a large lorry catches you by surprise, it could blow you off balance and even onto the carriageway. By facing the oncoming traffic, you can see approaching lorries and so be prepared for their draught. You'll also be in a position to see any other hazards approaching.",
        options: {
          A: 'Stay close to the carriageway',
          B: 'Face the oncoming traffic',
          C: 'Keep your back to the traffic',
          D: 'Stand on the hard shoulder',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2576: {
        id: 'AB2576',
        version: 'v2',
        question:
          'What colour are the reflective studs between the hard shoulder and the left-hand lane of a motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'B',
        additionalInfo:
          'Red studs are placed between the edge of the carriageway and the hard shoulder. Where slip roads leave or join the motorway, the studs are green.',
        options: {A: 'Green', B: 'Red', C: 'White', D: 'Amber'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2609: {
        id: 'AB2609',
        version: 'v2',
        question:
          "On a three-lane motorway, which lane should you use if there's no traffic ahead?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'A',
        additionalInfo:
          "On a three-lane motorway, you should travel in the left-hand lane unless you're overtaking. This applies regardless of the speed at which you're travelling.",
        options: {
          A: 'Left',
          B: 'Right',
          C: 'Centre',
          D: 'Either the right or centre',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2611: {
        id: 'AB2611',
        version: 'v2',
        question:
          'What should you do when going through a contraflow system on a motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: {
          fileName: 'TS4578.png',
          width: 616,
          height: 729,
          size: 44927,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4578.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "At roadworks, and especially where a contraflow system is operating, a speed restriction is likely to be in place. Keep to the lower speed limit and don't\r\n• switch lanes\r\n• get too close to the vehicle in front of you.\r\nBe aware that there will be no permanent barrier between you and the oncoming traffic.",
        options: {
          A: 'Use dipped headlights',
          B: 'Keep a good distance from the vehicle ahead',
          C: 'Switch lanes to keep the traffic flowing',
          D: 'Stay close to the vehicle ahead to reduce queues',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2612: {
        id: 'AB2612',
        version: 'v2',
        question:
          "You're on a three-lane motorway. Which lane are you in if there are red reflective studs on your left and white ones to your right?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'D',
        additionalInfo:
          'The colours of the reflective studs on the motorway and their locations are\r\n• red - between the hard shoulder and the carriageway\r\n• white - between lanes\r\n• amber - between the carriageway and the central reservation\r\n• green - along slip-road exits and entrances\r\n• bright green/yellow - at roadworks and contraflow systems.',
        options: {
          A: 'In the right-hand lane',
          B: 'In the middle lane',
          C: 'On the hard shoulder',
          D: 'In the left-hand lane',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2694: {
        id: 'AB2694',
        version: 'v2',
        question:
          "What should you do when you're approaching roadworks on a motorway?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'C',
        additionalInfo:
          'Be aware of reduced speed limits at roadworks. Speed limits shown inside a red circle are mandatory and cameras are often used to enforce the reduced limit. Slow down in good time and keep your distance from the vehicle in front.',
        options: {
          A: 'Speed up to clear the area quickly',
          B: 'Always use the hard shoulder',
          C: 'Obey the speed limit',
          D: 'Stay very close to the vehicle in front',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2743: {
        id: 'AB2743',
        version: 'v2',
        question: 'Which vehicles are prohibited from using the motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'A',
        additionalInfo:
          "Motorways mustn't be used by pedestrians, cyclists, motorcycles under 50 cc, certain slow-moving vehicles without permission, and powered wheelchairs/mobility scooters.",
        options: {
          A: 'Powered mobility scooters',
          B: 'Motorcycles over 50 cc',
          C: 'Double-deck buses',
          D: 'Cars with automatic transmission',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2744: {
        id: 'AB2744',
        version: 'v2',
        question:
          "What should you do while you're driving or riding along a motorway?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'A',
        additionalInfo:
          'Traffic on motorways usually travels faster than on other roads. You need to be looking further ahead to give yourself more time to react to any hazard that may develop.',
        options: {
          A: 'Look much further ahead than you would on other roads',
          B: 'Travel much faster than you would on other roads',
          C: 'Maintain a shorter separation distance than you would on other roads',
          D: 'Concentrate more than you would on other roads',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2745: {
        id: 'AB2745',
        version: 'v2',
        question: 'What should you do immediately after joining a motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'D',
        additionalInfo:
          "When you've just joined a motorway, stay in the left-hand lane long enough to get used to the higher speeds of motorway traffic before considering overtaking.",
        options: {
          A: 'Try to overtake',
          B: 'Re-adjust your mirrors',
          C: 'Position your vehicle in the centre lane',
          D: 'Stay in the left-hand lane',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2746: {
        id: 'AB2746',
        version: 'v2',
        question:
          'When would you use the right-hand lane on a three-lane motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'B',
        additionalInfo:
          'The right-hand lane of the motorway is for overtaking. Sometimes you may be directed into a right-hand lane as a result of roadworks or a traffic incident. This will be indicated by signs or officers directing the traffic.',
        options: {
          A: "When you're turning right",
          B: "When you're overtaking",
          C: "When you're travelling above the speed limit",
          D: "When you're trying to save fuel",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2747: {
        id: 'AB2747',
        version: 'v2',
        question:
          "You're on a motorway that isn't subject to smart motorway regulations. When should you use the hard shoulder?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'A',
        additionalInfo:
          "Don't use the hard shoulder for stopping unless it's an emergency. If you want to stop for any other reason, go to the next exit or service area.",
        options: {
          A: "When you're stopping in an emergency",
          B: "When you're leaving the motorway",
          C: "When you're stopping for a rest",
          D: "When you're joining the motorway",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2748: {
        id: 'AB2748',
        version: 'v2',
        question:
          "You're in the right-hand lane of a three-lane motorway. What do these overhead signs mean?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: {
          fileName: 'TS4063.png',
          width: 800,
          height: 360,
          size: 70348,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4063.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "You must obey these signs even if there appear to be no problems ahead. There could be queuing traffic or another hazard that you can't see yet.",
        options: {
          A: 'Move to the left and reduce your speed to 50 mph',
          B: 'There are roadworks 50 metres (55 yards) ahead',
          C: "Use the hard shoulder until you've passed the hazard",
          D: 'Leave the motorway at the next exit',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2749: {
        id: 'AB2749',
        version: 'v2',
        question: 'When are you allowed to stop on a motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'C',
        additionalInfo:
          "You must stop if overhead gantry signs show a red cross above every lane on the motorway. If any of the other lanes doesn't show a red cross, you may move into that lane and continue if it's safe to do so.",
        options: {
          A: 'When you need to walk and get fresh air',
          B: 'When you wish to pick up hitchhikers',
          C: "When you're signalled to do so by traffic signals",
          D: 'When you need to use a mobile telephone',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2750: {
        id: 'AB2750',
        version: 'v2',
        question:
          "You're travelling in the left-hand lane of a three-lane motorway. How should you react to traffic joining from a slip road?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'B',
        additionalInfo:
          "Plan well ahead when approaching a slip road. If you see traffic joining the motorway, be prepared to adjust your speed or move to another lane if it's safe to do so. This can help the flow of traffic joining the motorway, especially at peak times.",
        options: {
          A: 'Increase your speed to ensure they join behind you',
          B: 'Adjust your speed or change lane if you can do so safely',
          C: 'Maintain a steady speed',
          D: 'Switch on your hazard warning lights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2751: {
        id: 'AB2751',
        version: 'v2',
        question: 'How should you use the lanes on a motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'B',
        additionalInfo:
          "You should normally travel in the left-hand lane unless you're overtaking a slower-moving vehicle. When you've finished overtaking, move back into the left-hand lane, but don't cut across in front of the vehicle that you've overtaken.",
        options: {
          A: 'Use the lane that has the least traffic',
          B: "Keep to the left-hand lane unless you're overtaking",
          C: "Overtake using the lane that's clearest",
          D: 'Stay in one lane until you reach your exit',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2752: {
        id: 'AB2752',
        version: 'v2',
        question:
          "You're travelling along a motorway. When are you allowed to overtake on the left?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'D',
        additionalInfo:
          "Never overtake on the left, unless the traffic is moving in queues and the queue on your right is moving more slowly than the one you're in.",
        options: {
          A: 'When you can see well ahead that the hard shoulder is clear',
          B: 'When the traffic in the right-hand lane is signalling right',
          C: 'When you warn drivers behind by signalling left',
          D: 'When in queues and traffic to your right is moving more slowly than you are',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2786: {
        id: 'AB2786',
        version: 'v2',
        question:
          'When would you use an emergency refuge area on a smart motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'A',
        additionalInfo:
          "On smart motorways, emergency refuge areas are built at the side of the hard shoulder. If you break down, try to get your vehicle into the refuge, where there's an emergency telephone. The phone connects directly to a control centre. Remember to take care when rejoining the motorway, especially if the hard shoulder is being used as a running lane.",
        options: {
          A: 'In cases of emergency or breakdown',
          B: "If you think you'll be involved in a road rage incident",
          C: 'To stop and check where you are',
          D: 'To make a private phone call',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2788: {
        id: 'AB2788',
        version: 'v2',
        question:
          'Traffic officers operate on motorways and some primary routes in England and Wales. What are they authorised to do?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: {
          fileName: 'IMG_1666.jpg',
          width: 800,
          height: 533,
          size: 115272,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1666.jpg?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "Traffic officers don't have enforcement powers but are able to stop and direct people on motorways and some 'A' class roads. They operate in England and Wales and work in partnership with the police at incidents, providing a highly trained and visible service. They're recognised by an orange-and-yellow jacket and their vehicle has yellow-and-black markings.",
        options: {
          A: 'Stop and arrest drivers who break the law',
          B: 'Repair broken-down vehicles on the motorway',
          C: 'Issue fixed penalty notices',
          D: 'Stop and direct anyone on a motorway',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2789: {
        id: 'AB2789',
        version: 'v2',
        question:
          "You're on a smart motorway. What does it mean when a red cross is displayed above the hard shoulder?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: {
          fileName: 'TS4748.png',
          width: 800,
          height: 538,
          size: 195771,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4748.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "Some motorways have been redesigned as smart motorways. At certain times, the hard shoulder will be open as a running lane. However, a red cross above the hard shoulder shows that it isn't open as a running lane and should only be used for emergencies and breakdowns.",
        options: {
          A: 'Pull up in this lane to answer your mobile phone',
          B: 'Use this lane as a running lane',
          C: 'This lane can be used if you need a rest',
          D: "You shouldn't travel in this lane",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2790: {
        id: 'AB2790',
        version: 'v2',
        question:
          "You're on a smart motorway. What does it mean when a mandatory speed limit is displayed above the hard shoulder?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: {
          fileName: 'TS4747.png',
          width: 800,
          height: 538,
          size: 132714,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4747.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'A mandatory speed-limit sign above the hard shoulder shows that this part of the road can be used as a running lane between junctions. You must stay within the speed limit. Look out for vehicles that may have broken down and could be blocking the hard shoulder.',
        options: {
          A: "You shouldn't travel in this lane",
          B: 'The hard shoulder can be used as a running lane',
          C: 'You can park on the hard shoulder if you feel tired',
          D: 'You can pull up in this lane to answer a mobile phone',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2792: {
        id: 'AB2792',
        version: 'v2',
        question: 'How do smart motorways prevent traffic bunching?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'D',
        additionalInfo:
          'When a smart motorway is operating, you must follow the mandatory signs on the gantries above each lane, including the hard shoulder. Variable speed limits help keep the traffic moving and also help to prevent bunching.',
        options: {
          A: 'By using higher speed limits',
          B: 'By using advisory speed limits',
          C: 'By using minimum speed limits',
          D: 'By using variable speed limits',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2839: {
        id: 'AB2839',
        version: 'v2',
        question: 'What helps to reduce traffic bunching on a motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'A',
        additionalInfo:
          'Congestion can be reduced by keeping traffic at a constant speed. At busy times, maximum speed limits are displayed on overhead gantries. These can be varied quickly, depending on the amount of traffic. By keeping to a constant speed on busy sections of motorway, overall journey times are normally improved.',
        options: {
          A: 'Variable speed limits',
          B: 'Contraflow systems',
          C: 'National speed limits',
          D: 'Lane closures',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2843: {
        id: 'AB2843',
        version: 'v2',
        question: 'When may you stop on a motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'D',
        additionalInfo:
          "You shouldn't normally stop on a motorway, but there may be occasions when you need to do so. If your vehicle breaks down or there's an emergency, stop on the hard shoulder and use the emergency telephones to call for assistance.",
        options: {
          A: 'If you have to read a map',
          B: "When you're tired and need a rest",
          C: 'If your mobile phone rings',
          D: 'In an emergency or breakdown',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2845: {
        id: 'AB2845',
        version: 'v2',
        question:
          "What's the national speed limit for a car or motorcycle on a motorway?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'C',
        additionalInfo:
          'The national speed limit for a car or motorcycle on a motorway is 70 mph. Lower speed limits may be in force; for example, at roadworks. Variable speed limits also operate in some areas when the motorway is very busy. Cars or motorcycles towing trailers are subject to a lower speed limit.',
        options: {A: '50 mph', B: '60 mph', C: '70 mph', D: '80 mph'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2846: {
        id: 'AB2846',
        version: 'v2',
        question:
          "You stop on the hard shoulder of a motorway and use the emergency telephone. Where's the best place to wait for help to arrive?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'B',
        additionalInfo:
          "When you're on the hard shoulder, you're at risk of being injured by motorway traffic. The safest place to wait is away from the carriageway, but near enough to see the emergency services arriving.",
        options: {
          A: 'Next to the phone',
          B: 'Well away from the carriageway',
          C: 'With your vehicle',
          D: 'On the hard shoulder',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2852: {
        id: 'AB2852',
        version: 'v2',
        question:
          "You're on a motorway. What must you do if there's a red cross showing above every lane?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: {
          fileName: 'Gantry_cross_all_lanes.png',
          width: 800,
          height: 537,
          size: 45364,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FGantry_cross_all_lanes.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "A red cross signal above all lanes means you must stop and wait. Don't change lanes and don't try to continue any further along the motorway.",
        options: {
          A: 'Pull onto the hard shoulder',
          B: 'Slow down and watch for further signals',
          C: 'Leave at the next exit',
          D: 'Stop and wait',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2856: {
        id: 'AB2856',
        version: 'v2',
        question:
          "You're on a smart motorway. What does it mean if a red cross is showing above the hard shoulder and mandatory speed limits above all other lanes?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: {
          fileName: 'TS4749.png',
          width: 800,
          height: 537,
          size: 50718,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4749.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "A red cross above the hard shoulder shows that it's closed as a running lane and should only be used for emergencies or breakdowns. On a smart motorway, the hard shoulder may be used as a running lane at busy times. This will be shown by a mandatory speed limit on the gantry above the hard shoulder.",
        options: {
          A: 'The hard shoulder can be used as a rest area if you feel tired',
          B: 'The hard shoulder is for emergency or breakdown use only',
          C: 'The hard shoulder can be used as a normal running lane',
          D: 'The hard shoulder has a speed limit of 50 mph',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2857: {
        id: 'AB2857',
        version: 'v2',
        question: 'On a smart motorway, what does this sign mean?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: {
          fileName: 'TS4750.png',
          width: 800,
          height: 537,
          size: 49009,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4750.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'You must obey mandatory speed-limit signs above motorway lanes, including the hard shoulder. In this case, you can use the hard shoulder as a running lane but you should look for any vehicles that may have broken down and may be blocking the hard shoulder.',
        options: {
          A: 'Use any lane except the hard shoulder',
          B: 'Use the hard shoulder only',
          C: 'Use the three right-hand lanes only',
          D: 'Use all the lanes, including the hard shoulder',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2882: {
        id: 'AB2882',
        version: 'v2',
        question:
          "Where should you stop to rest if you feel tired while you're travelling along a motorway?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'B',
        additionalInfo:
          "If you feel tired, stop at the nearest service area. If that's too far away, leave the motorway at the next exit and find a safe place to stop. You mustn't stop on the carriageway or hard shoulder of a motorway except in an emergency, when in a traffic queue, or when signalled to do so by a police officer, a traffic officer or traffic signals. Plan your journey so that you have regular rest stops.",
        options: {
          A: 'On the hard shoulder',
          B: 'At the nearest service area',
          C: 'On a slip road',
          D: 'On the central reservation',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1193: {
        id: 'BB1193',
        version: 'v2',
        question:
          "What's the speed limit for a car towing a trailer on a motorway?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'C',
        additionalInfo:
          "If you're towing a small, light trailer, it won't reduce your vehicle's performance by very much and it may not be visible in your mirrors. However, strong winds or buffeting from large vehicles might cause the trailer to snake from side to side. Be aware of your speed and don't exceed the reduced speed limit imposed on vehicles towing trailers.",
        options: {A: '40 mph', B: '50 mph', C: '60 mph', D: '70 mph'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1197: {
        id: 'BB1197',
        version: 'v2',
        question: 'When should you use the left-hand lane of a motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'D',
        additionalInfo:
          'You should drive in the left-hand lane whenever possible. Only use the other lanes for overtaking or when directed to do so by signals. Using other lanes when the left-hand lane is empty can frustrate drivers behind you.',
        options: {
          A: 'When your vehicle breaks down',
          B: "When you're overtaking slower traffic in the other lanes",
          C: "When you're making a phone call",
          D: 'When the road ahead is clear',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1198: {
        id: 'BB1198',
        version: 'v2',
        question:
          "You're driving on a motorway and have to slow down suddenly due to a hazard ahead. How can you warn drivers behind of the hazard?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'A',
        additionalInfo:
          'Using your hazard warning lights, as well as your brake lights, will give the traffic behind an extra warning of the problem ahead. Only use them for long enough for your warning to be seen.',
        options: {
          A: 'Switch on your hazard warning lights',
          B: 'Switch on your headlights',
          C: 'Sound your horn',
          D: 'Flash your headlights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1199: {
        id: 'BB1199',
        version: 'v2',
        question:
          "Your car gets a puncture while you're driving on the motorway. What should you do when you've stopped on the hard shoulder?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'B',
        additionalInfo:
          "Park as far to the left as you can and leave the vehicle by the nearside door. Don't attempt even simple repairs. Instead, walk to an emergency telephone on your side of the road and phone for help. While waiting for help to arrive, stay by your car, keeping well away from the carriageway and hard shoulder.",
        options: {
          A: 'Carefully change the wheel yourself',
          B: 'Use an emergency telephone and call for help',
          C: 'Try to wave down another vehicle for help',
          D: 'Only change the wheel if you have a passenger to help you',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1204: {
        id: 'BB1204',
        version: 'v2',
        question:
          "What should you do if you're driving on a motorway and you miss the exit that you wanted to take?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'B',
        additionalInfo:
          "It's illegal to reverse, cross the central reservation or drive against the traffic flow on a motorway. If you miss your exit, carry on until you reach the next one. Ask yourself why you missed your exit - if you think that your concentration is fading, take a break before continuing your journey.",
        options: {
          A: 'Carefully reverse along the hard shoulder',
          B: 'Carry on to the next exit',
          C: 'Carefully reverse in the left-hand lane',
          D: 'Make a U-turn at the next gap in the central reservation',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1481: {
        id: 'BB1481',
        version: 'v2',
        question:
          "Your vehicle has broken down on a motorway. What should you do if you aren't able to get onto the hard shoulder?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'A',
        additionalInfo:
          "If you can't get your vehicle onto the hard shoulder, use your hazard warning lights to warn others. Leave your vehicle only when you can safely get clear of the carriageway. Don't try to repair the vehicle or attempt to place any warning device on the carriageway.",
        options: {
          A: 'Switch on your hazard warning lights',
          B: 'Stop the traffic behind and ask for help',
          C: 'Attempt to repair your vehicle quickly',
          D: 'Stand behind your vehicle to warn others',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1520: {
        id: 'BB1520',
        version: 'v2',
        question:
          'Why is it particularly important to check your vehicle before making a long motorway journey?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'D',
        additionalInfo:
          "Before you start your journey, make sure that your vehicle can cope with the demands of high-speed driving. You should check a number of things; the main ones being fuel, oil, water and tyres. You also need to plan rest stops if you're making a long journey.",
        options: {
          A: "You'll have to do more harsh braking on motorways",
          B: "Motorway services areas don't deal with breakdowns",
          C: 'The road surface will wear down the tyres faster',
          D: 'Continuous high speeds increase the risk of your vehicle breaking down',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1523: {
        id: 'BB1523',
        version: 'v2',
        question:
          "You're driving on a motorway. What does it mean if the car in front shows its hazard warning lights for a short time?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'C',
        additionalInfo:
          "If the vehicle in front shows its hazard warning lights, there may be an incident, stopped traffic or queuing traffic ahead. By keeping a safe distance from the vehicle in front, you're able to look beyond it and see any hazards well ahead.",
        options: {
          A: 'The driver wants you to overtake',
          B: 'The other car is going to change lanes',
          C: 'Traffic ahead is slowing or stopping suddenly',
          D: "There's a police speed check ahead",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1525: {
        id: 'BB1525',
        version: 'v2',
        question:
          "You're driving on the motorway. Which lane should you get into well before you reach your exit?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'B',
        additionalInfo:
          "You'll see the first advance direction sign one mile from a motorway exit. If you're travelling at 60 mph in the right-hand lane, you'll only have about 50 seconds before you reach the countdown markers. There'll be another sign at the half-mile point. Don't cut across lanes of traffic at the last moment - move to the left-hand lane in good time.",
        options: {
          A: 'The middle lane',
          B: 'The left-hand lane',
          C: 'The hard shoulder',
          D: 'The right-hand lane',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1618: {
        id: 'BB1618',
        version: 'v2',
        question:
          'What restrictions apply to people who have a provisional driving licence?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'C',
        additionalInfo:
          "You won't be able to drive unaccompanied until you've passed your practical driving test. If you haven't driven on the motorway while you were learning, ask your instructor to take you for a lesson on the motorway when you've passed your test. Alternatively, you could take part in the Pass Plus scheme. This has been created for new drivers and includes motorway driving. Ask your instructor for details.",
        options: {
          A: "They can't drive over 30 mph",
          B: "They can't drive at night",
          C: "They can't drive unaccompanied",
          D: "They can't drive with more than one passenger",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1688: {
        id: 'BB1688',
        version: 'v2',
        question:
          'Your vehicle breaks down on a motorway and you manage to stop on the hard shoulder. What should you do if you use your mobile phone to call for help?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'D',
        additionalInfo:
          "You should use an emergency telephone when you break down on the motorway; only use your mobile if this isn't possible. The emergency services need to know your exact location so they can reach you as quickly as possible. Look for a number on the nearest marker post beside the hard shoulder. Give this number when you call the emergency services.",
        options: {
          A: 'Stand at the rear of the vehicle while making the call',
          B: 'Phone a friend and ask them to come and collect you',
          C: 'Wait in the car for the emergency services to arrive',
          D: 'Check your location from the nearest marker posts beside the hard shoulder',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1689: {
        id: 'BB1689',
        version: 'v2',
        question:
          "You're towing a trailer along a three-lane motorway. When may you use the right-hand lane?",
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'A',
        additionalInfo:
          "If you're towing a caravan or trailer, you mustn't use the right-hand lane of a motorway with three or more lanes except in certain specified circumstances, such as when lanes are closed.",
        options: {
          A: 'When there are lane closures',
          B: "When there's slow-moving traffic",
          C: 'When you can maintain a high speed',
          D: 'When large vehicles are in the left and centre lanes',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1690: {
        id: 'BB1690',
        version: 'v2',
        question:
          'What would you expect to find at a contraflow system on a motorway?',
        questionSetId: 'MOTORWAY-RULES',
        questionSetTitle: 'Motorway Driving',
        image: null,
        answer: 'B',
        additionalInfo:
          'When approaching a contraflow system, reduce speed in good time and obey all speed limits. You may be travelling in a narrower lane than normal, with no permanent barrier between you and the oncoming traffic. Be aware that the hard shoulder may be used for traffic and the road ahead could be obstructed by slow-moving or broken-down vehicles.',
        options: {
          A: 'Temporary traffic lights',
          B: 'Lower speed limits',
          C: 'Wider lanes than normal',
          D: 'Road humps',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2246: {
        id: 'AB2246',
        version: 'v2',
        question: "What's the meaning of this sign?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: 'TS4675.png',
          width: 400,
          height: 400,
          size: 16859,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4675.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "This sign doesn't tell you the speed limit in figures. You should know the speed limit for the type of road that you're on and the type of vehicle that you're driving. Study your copy of The Highway Code.",
        options: {
          A: 'Local speed limit applies',
          B: 'No waiting on the carriageway',
          C: 'National speed limit applies',
          D: 'No entry for vehicles',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2248: {
        id: 'AB2248',
        version: 'v2',
        question:
          "What's the national speed limit for cars and motorcycles on a dual carriageway?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          "Make sure that you know the speed limit for the road that you're on. The speed limit on a dual carriageway or motorway is 70 mph for cars and motorcycles, unless signs indicate otherwise. The speed limits for different types of vehicle are listed in The Highway Code.",
        options: {A: '30 mph', B: '50 mph', C: '60 mph', D: '70 mph'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2249: {
        id: 'AB2249',
        version: 'v2',
        question:
          'There are no speed-limit signs on the road. How is a 30 mph limit generally indicated?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'B',
        additionalInfo:
          "There's a 30 mph speed limit where there are street lights unless signs show another limit.",
        options: {
          A: 'By hazard warning lines',
          B: 'By street lighting',
          C: 'By pedestrian islands',
          D: 'By double or single yellow lines',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2250: {
        id: 'AB2250',
        version: 'v2',
        question:
          'What will the speed limit usually be where you can see street lights but no speed-limit signs?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "The presence of street lights generally indicates that there's a 30 mph speed limit, unless signs tell you otherwise.",
        options: {A: '30 mph', B: '40 mph', C: '50 mph', D: '60 mph'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2254: {
        id: 'AB2254',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: 'TS4698.png',
          width: 800,
          height: 800,
          size: 101870,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4698.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'The red slash through the sign indicates that the restriction has ended. In this case, the restriction was a minimum speed limit of 30 mph.',
        options: {
          A: 'Minimum speed 30 mph',
          B: 'End of maximum speed',
          C: 'End of minimum speed',
          D: 'Maximum speed 30 mph',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2255: {
        id: 'AB2255',
        version: 'v2',
        question:
          "What should you do if you want to overtake a tractor but aren't sure that it's safe?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          "Following a tractor can be frustrating, but never overtake if you're unsure whether it's safe. Ask yourself: 'Can I see far enough down the road to ensure that I can complete the manoeuvre safely?' It's better to be delayed for a minute or two than to take a chance that may cause a collision.",
        options: {
          A: 'Follow another vehicle as it overtakes the tractor',
          B: 'Sound your horn to make the tractor driver pull over',
          C: 'Speed past, flashing your lights at oncoming traffic',
          D: "Stay behind it if you're in any doubt",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2256: {
        id: 'AB2256',
        version: 'v2',
        question:
          'Which vehicle is most likely to take an unusual course at a roundabout?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          'Long vehicles might have to take a slightly different position when approaching the roundabout or going around it. This is to stop the rear of the vehicle cutting in and mounting the kerb.',
        options: {
          A: 'Estate car',
          B: 'Milk float',
          C: 'Delivery van',
          D: 'Long vehicle',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2262: {
        id: 'AB2262',
        version: 'v2',
        question: 'When may you stop on a clearway?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "Clearways are in place so that traffic can flow without the obstruction of parked vehicles. Just one parked vehicle can cause an obstruction for all other traffic. You mustn't stop where a clearway is in force, not even to pick up or set down passengers.",
        options: {
          A: 'Never',
          B: "When it's busy",
          C: 'In the rush hour',
          D: 'During daylight hours',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2264: {
        id: 'AB2264',
        version: 'v2',
        question: "What's the meaning of this sign?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: 'TS4690.png',
          width: 400,
          height: 400,
          size: 22739,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4690.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "This sign indicates that there are waiting restrictions. It's normally accompanied by details of when the restrictions are in force.\r\nDetails of most signs in common use are shown in The Highway Code. For more comprehensive coverage, see Know Your Traffic Signs.",
        options: {
          A: 'No entry',
          B: 'Waiting restrictions',
          C: 'National speed limit',
          D: 'School crossing patrol',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2268: {
        id: 'AB2268',
        version: 'v2',
        question:
          "You're looking for somewhere to park at night. When may you park on the right-hand side of the road?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "Red rear reflectors show up when headlights shine on them. These are useful when you're parked at night, but they'll only reflect if you park in the same direction as the traffic flow. Normally you should park on the left, but in a one-way street you may also park on the right-hand side of the road.",
        options: {
          A: "When you're in a one-way street",
          B: 'When you have your sidelights on',
          C: "When you're more than 10 metres (32 feet) from a junction",
          D: "When you're under a lamppost",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2269: {
        id: 'AB2269',
        version: 'v2',
        question:
          'When should you use the right-hand lane of a three-lane dual carriageway?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'B',
        additionalInfo:
          "You should normally use the left-hand lane on any dual carriageway unless you're overtaking or turning right. \r\nWhen overtaking on a dual carriageway, look for vehicles ahead that are turning right. They may be slowing or stopped. You need to see them in good time so that you can take appropriate action.",
        options: {
          A: "When you're overtaking only",
          B: "When you're overtaking or turning right",
          C: "When you're using cruise control",
          D: "When you're turning right only",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2272: {
        id: 'AB2272',
        version: 'v2',
        question:
          "You're approaching a busy junction. What should you do when, at the last moment, you realise you're in the wrong lane?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "There are times when road markings are obscured by queuing traffic, or you're unsure which lane to use. If, at the last moment, you find you're in the wrong lane, don't cut across or bully other drivers to let you in. Follow the lane you're in and find somewhere safe to turn around and rejoin your route.",
        options: {
          A: 'Continue in that lane',
          B: 'Force your way into the lane you need',
          C: 'Stop until the area has cleared',
          D: 'Use arm signals to help you change lane',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2273: {
        id: 'AB2273',
        version: 'v2',
        question: 'Where may you overtake on a one-way street?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          "You can overtake other traffic on either side when travelling in a one-way street. Make full use of your mirrors and ensure it's clear all around before you attempt to overtake. Look for signs and road markings, and use the most suitable lane for your destination.",
        options: {
          A: 'Only on the left-hand side',
          B: "Overtaking isn't allowed",
          C: 'Only on the right-hand side',
          D: 'On either the right or the left',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2275: {
        id: 'AB2275',
        version: 'v2',
        question:
          "What signal should you give when you're going straight ahead at a roundabout?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "When going straight ahead at a roundabout, don't signal as you approach it. Signal left just after passing the exit before the one you wish to take.",
        options: {
          A: 'Signal left before leaving the roundabout',
          B: "Don't signal at any time",
          C: "Signal right when you're approaching the roundabout",
          D: "Signal left when you're approaching the roundabout",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2276: {
        id: 'AB2276',
        version: 'v2',
        question:
          'Which vehicle might have to take a different course from normal at a roundabout?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          "A long vehicle may have to straddle lanes either on or approaching a roundabout so that the rear wheels don't mount the kerb. \r\nIf you're following a long vehicle, stay well back and give it plenty of room.",
        options: {
          A: 'Sports car',
          B: 'Van',
          C: 'Estate car',
          D: 'Long vehicle',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2281: {
        id: 'AB2281',
        version: 'v2',
        question: 'When may you enter a box junction?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: '85BW1216.jpg',
          width: 800,
          height: 532,
          size: 104636,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW1216.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Yellow box junctions are marked on the road to prevent the road becoming blocked. Don't enter the box unless your exit road is clear. You may wait in the box if you want to turn right and your exit road is clear but oncoming traffic or other vehicles waiting to turn right are preventing you from making the turn.",
        options: {
          A: 'When there are fewer than two vehicles ahead',
          B: 'When signalled by another road user',
          C: 'When your exit road is clear',
          D: 'When traffic signs direct you',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2282: {
        id: 'AB2282',
        version: 'v2',
        question: 'When may you stop and wait in a box junction?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: '85BW1216.jpg',
          width: 800,
          height: 532,
          size: 104636,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW1216.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "The purpose of yellow box markings is to keep junctions clear of queuing traffic. You may only wait in the marked area when you're turning right and your exit lane is clear but you can't complete the turn because of oncoming traffic or other traffic waiting to turn right.",
        options: {
          A: 'When oncoming traffic prevents you from turning right',
          B: "When you're in a queue of traffic turning left",
          C: "When you're in a queue of traffic going ahead",
          D: "When you're on a roundabout",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2284: {
        id: 'AB2284',
        version: 'v2',
        question: 'Who is authorised to signal you to stop?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'C',
        additionalInfo:
          'You must obey signals to stop given by police and traffic officers, traffic wardens and school crossing patrols. Failure to do so is an offence and could lead to prosecution.',
        options: {
          A: 'A motorcyclist',
          B: 'A pedestrian',
          C: 'A police officer',
          D: 'A bus driver',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2288: {
        id: 'AB2288',
        version: 'v2',
        question:
          'What should you do if you see a pedestrian waiting at a zebra crossing?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'C',
        additionalInfo:
          "By standing on the pavement, the pedestrian is showing an intention to cross. By looking well ahead, you'll give yourself time to see the pedestrian, check your mirrors and respond safely.",
        options: {
          A: 'Go on quickly before they step onto the crossing',
          B: 'Stop before you reach the zigzag lines and let them cross',
          C: 'Be ready to slow down or stop to let them cross',
          D: "Ignore them as they're still on the pavement",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2290: {
        id: 'AB2290',
        version: 'v2',
        question: 'Which road users benefit from toucan crossings?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'B',
        additionalInfo:
          "Toucan crossings are similar to pelican crossings but there's no flashing amber phase. Cyclists share the crossing with pedestrians and are allowed to cycle across when the green cycle symbol is shown.",
        options: {
          A: 'Car drivers and motorcyclists',
          B: 'Cyclists and pedestrians',
          C: 'Bus and lorry drivers',
          D: 'Tram and train drivers',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2292: {
        id: 'AB2292',
        version: 'v2',
        question:
          "You're waiting at a pelican crossing. What does it mean when the red light changes to flashing amber?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "This light allows pedestrians already on the crossing to get to the other side in their own time, without being rushed. Don't rev your engine or start to move off while they're still crossing.",
        options: {
          A: 'Give way to pedestrians on the crossing',
          B: 'Move off immediately without any hesitation',
          C: 'Wait for the green light before moving off',
          D: 'Get ready and go when the continuous amber light shows',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2528: {
        id: 'AB2528',
        version: 'v2',
        question:
          'You see these double white lines along the centre of the road. When may you park on the left?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: 'IMG_1873.jpg',
          width: 800,
          height: 533,
          size: 87432,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1873.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "You mustn't park or stop on a road marked with double white lines (even where one of the lines is broken) except to pick up or set down passengers.",
        options: {
          A: 'If the line nearest to you is broken',
          B: 'When there are no yellow lines',
          C: 'To pick up or set down passengers',
          D: 'During daylight hours only',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2577: {
        id: 'AB2577',
        version: 'v2',
        question:
          "You're turning right at a crossroads. An oncoming driver is also turning right. What's the advantage of turning behind the oncoming vehicle?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "When turning right at a crossroads where oncoming traffic is also turning right, it's generally safer to turn behind the approaching vehicle. This allows you a clear view of approaching traffic and is called 'turning offside to offside'. However, some junctions, usually controlled by traffic-light filters - are marked for vehicles to turn nearside to nearside.",
        options: {
          A: "You'll have a clearer view of any approaching traffic",
          B: "You'll use less fuel because you can stay in a higher gear",
          C: "You'll have more time to turn",
          D: "You'll be able to turn without stopping",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2614: {
        id: 'AB2614',
        version: 'v2',
        question:
          "You're travelling along a residential street. There are parked vehicles on the left-hand side. Why should you keep your speed down?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          'Travel slowly and carefully near parked vehicles. Beware of\r\n• vehicles pulling out, especially bicycles and motorcycles\r\n• pedestrians, especially children, who may run out from between cars\r\n• drivers opening their doors.',
        options: {
          A: 'So that oncoming traffic can see you more clearly',
          B: 'You may set off car alarms',
          C: 'There may be delivery lorries on the street',
          D: 'Children may run out from between the vehicles',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2615: {
        id: 'AB2615',
        version: 'v2',
        question:
          "What should you do when there's an obstruction on your side of the road?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'B',
        additionalInfo:
          "Take care if you have to pass an obstruction, such as a parked vehicle, on your side of the road. Give way to oncoming traffic if there isn't enough room for you both to continue safely.",
        options: {
          A: 'Carry on, as you have priority',
          B: 'Give way to oncoming traffic',
          C: 'Wave oncoming vehicles through',
          D: 'Accelerate to get past first',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2616: {
        id: 'AB2616',
        version: 'v2',
        question:
          'When would you use the right-hand lane of a two-lane dual carriageway?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "Normally you should travel in the left-hand lane and only use the right-hand lane for overtaking or turning right. Move back into the left lane as soon as it's safe but don't cut in across the path of the vehicle you've just passed.",
        options: {
          A: "When you're turning right or overtaking",
          B: "When you're passing a side road on the left",
          C: "When you're staying at the minimum allowed speed",
          D: "When you're travelling at a constant high speed",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2617: {
        id: 'AB2617',
        version: 'v2',
        question: 'Who has priority at an unmarked crossroads?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'B',
        additionalInfo:
          "Practise good observation in all directions before you emerge or make a turn. Proceed only when you're sure it's safe to do so.",
        options: {
          A: 'The larger vehicle',
          B: 'No-one has priority',
          C: 'The faster vehicle',
          D: 'The smaller vehicle',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2628: {
        id: 'AB2628',
        version: 'v2',
        question: "What's the nearest you may park to a junction?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "Don't park within 10 metres (32 feet) of a junction (unless in an authorised parking place). This is to allow drivers emerging from, or turning into, the junction a clear view of the road they're joining. It also allows them to see hazards such as pedestrians or cyclists at the junction.",
        options: {
          A: '10 metres (32 feet)',
          B: '12 metres (39 feet)',
          C: '15 metres (49 feet)',
          D: '20 metres (66 feet)',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2629: {
        id: 'AB2629',
        version: 'v2',
        question:
          "You're looking for somewhere to safely park your vehicle. Where would you choose to park?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'B',
        additionalInfo:
          "It may be tempting to park where you shouldn't while you run a quick errand. Careless parking is a selfish act and could endanger other road users.",
        options: {
          A: 'At or near a bus stop',
          B: 'In a designated parking space',
          C: 'Near the brow of a hill',
          D: 'On the approach to a level crossing',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2630: {
        id: 'AB2630',
        version: 'v2',
        question:
          "You're waiting at a level crossing. What must you do if a train passes but the lights keep flashing?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "If the lights at a level crossing keep flashing after a train has passed, you should continue to wait, because another train might be coming. Time seems to pass slowly when you're held up in a queue. Be patient and wait until the lights stop flashing.",
        options: {
          A: 'Carry on waiting',
          B: 'Phone the signal operator',
          C: 'Edge over the stop line and look for trains',
          D: 'Park and investigate',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2669: {
        id: 'AB2669',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: '664.png',
          width: 800,
          height: 1280,
          size: 91058,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F664.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "This sign shows that you're leaving a controlled parking zone and those restrictions no longer apply.",
        options: {
          A: 'No through road',
          B: 'End of traffic-calming zone',
          C: 'Free-parking zone ends',
          D: 'End of controlled parking zone',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2698: {
        id: 'AB2698',
        version: 'v2',
        question:
          'What must you do if you come across roadworks that have a temporary speed limit displayed?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "Where there are extra hazards, such as at roadworks, it's often necessary to slow traffic by imposing a lower speed limit. These speed limits aren't advisory; they must be obeyed.",
        options: {
          A: 'Obey the speed limit',
          B: 'Obey the limit, but only during rush hour',
          C: 'Ignore the displayed limit',
          D: 'Use your own judgment; the limit is only advisory',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2754: {
        id: 'AB2754',
        version: 'v2',
        question:
          "You're in a built-up area at night and the road is well lit. Why should you use dipped headlights?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          "You may be difficult to see when you're travelling at night, even on a well-lit road. If you use dipped headlights rather than sidelights, other road users should be able to see you more easily.",
        options: {
          A: 'So that you can see further along the road',
          B: 'So that you can go at a much faster speed',
          C: 'So that you can switch to main beam quickly',
          D: 'So that you can be easily seen by others',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2755: {
        id: 'AB2755',
        version: 'v2',
        question:
          "You're turning right onto a dual carriageway. What should you do if the central reservation is too narrow to contain your vehicle?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'B',
        additionalInfo:
          'When the central reservation is narrow, it may not be able to contain your vehicle. In this case, you should treat a dual carriageway as one road. Wait until the road is clear in both directions before emerging to turn right. If you try to treat it as two separate roads and wait in the middle, your vehicle will stick out and cause an obstruction that may lead to a collision.',
        options: {
          A: 'Proceed to the central reservation and wait',
          B: 'Wait until the road is clear in both directions',
          C: 'Stop in the first lane so that other vehicles give way',
          D: 'Emerge slightly to show your intentions',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2824: {
        id: 'AB2824',
        version: 'v2',
        question:
          "What's the national speed limit on a single carriageway road for cars and motorcycles?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'C',
        additionalInfo:
          "Exceeding the speed limit is dangerous and can result in you receiving penalty points on your licence. It isn't worth it. You should know the speed limit for the road that you're on by observing the road signs. Different speed limits apply if you're towing a trailer.",
        options: {A: '30 mph', B: '50 mph', C: '60 mph', D: '70 mph'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2825: {
        id: 'AB2825',
        version: 'v2',
        question:
          'What should you do when you park at night on a road that has a 40 mph speed limit?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'B',
        additionalInfo:
          'You must use parking lights when parking at night on a road or in a lay-by on a road with a speed limit greater than 30 mph. You must also park in the direction of the traffic flow and not close to a junction.',
        options: {
          A: 'Park facing the traffic',
          B: 'Leave parking lights switched on',
          C: 'Leave dipped headlights switched on',
          D: 'Park near a street light',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2826: {
        id: 'AB2826',
        version: 'v2',
        question: 'Where will you see these red and white markers?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: 'TS4059.png',
          width: 800,
          height: 397,
          size: 24527,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4059.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "If there's a bend just before a level crossing, you may not be able to see the level-crossing barriers or waiting traffic. These signs give you an early warning that you may find these hazards just around the bend.",
        options: {
          A: 'Approaching the end of a motorway',
          B: 'Approaching a concealed level crossing',
          C: 'Approaching a concealed speed-limit sign',
          D: 'Approaching the end of a dual carriageway',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2832: {
        id: 'AB2832',
        version: 'v2',
        question:
          "You're travelling on a motorway in England. When must you stop your vehicle?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'B',
        additionalInfo:
          "You'll find traffic officers on motorways and some primary routes in England and Wales. They work in partnership with the police, helping to keep traffic moving and helping to make your journey as safe as possible. It's an offence not to comply with the directions given by a traffic officer.",
        options: {
          A: 'When signalled to stop by a roadworks supervisor',
          B: 'When signalled to stop by a traffic officer',
          C: 'When signalled to stop by a pedestrian on the hard shoulder',
          D: 'When signalled to stop by a driver who has broken down',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2884: {
        id: 'AB2884',
        version: 'v2',
        question:
          "How should you signal if you're going straight ahead at a roundabout?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          "To go straight ahead at a roundabout, you should normally approach in the left-hand lane, but check the road markings. At some roundabouts, the left lane on approach is marked 'left turn only', so make sure you use the correct lane to go ahead. You won't normally need to signal as you approach, but signal before you leave the roundabout, as other road users need to know your intentions.",
        options: {
          A: 'Signal right on the approach and then left to leave the roundabout',
          B: 'Signal left after you leave the roundabout and enter the new road',
          C: 'Signal right on the approach to the roundabout and keep the signal on',
          D: "Signal left just after you pass the exit before the one you're going to take",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1206: {
        id: 'BB1206',
        version: 'v2',
        question: 'When may you drive over a pavement?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          "It's illegal to drive on or over a pavement, except to gain access to a property. If you need to cross a pavement, give priority to pedestrians.",
        options: {
          A: 'To overtake slow-moving traffic',
          B: 'When the pavement is very wide',
          C: 'If there are no pedestrians nearby',
          D: 'To gain access to a property',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1207: {
        id: 'BB1207',
        version: 'v2',
        question:
          "A single carriageway road has this sign. What's the maximum permitted speed for a car towing a trailer?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: 'TS4675.png',
          width: 400,
          height: 400,
          size: 16859,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4675.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "When you're towing a trailer, a reduced speed limit also applies on dual carriageways and motorways. These lower speed limits apply to vehicles pulling all sorts of trailers, including caravans and horse boxes.",
        options: {A: '30 mph', B: '40 mph', C: '50 mph', D: '60 mph'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1209: {
        id: 'BB1209',
        version: 'v2',
        question:
          "What's the speed limit for a car towing a caravan on a dual carriageway?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          'The speed limit for cars towing caravans or trailers on dual carriageways or motorways is 60 mph. Due to the increased weight and size of the combination, you should plan further ahead. Take care in windy weather, as a strong side wind can make a caravan or large trailer unstable.',
        options: {A: '50 mph', B: '40 mph', C: '70 mph', D: '60 mph'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1213: {
        id: 'BB1213',
        version: 'v2',
        question:
          'You want to park and you see this sign. What should you do on the days and times shown?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: 'TS4694.png',
          width: 676,
          height: 1144,
          size: 41885,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4694.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'Parking restrictions apply in a variety of places and situations. Make sure you know the rules and understand where and when restrictions apply. Controlled parking areas will be indicated by signs and road markings. Parking in the wrong place could cause an obstruction and danger to other traffic. It can also result in a fine.',
        options: {
          A: 'Park in a bay and not pay',
          B: 'Park on yellow lines and pay',
          C: 'Park on yellow lines and not pay',
          D: 'Park in a bay and pay',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1220: {
        id: 'BB1220',
        version: 'v2',
        question:
          'A cycle lane, marked by a solid white line, is in operation. What does this mean for car drivers?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: 'IMG_1149.jpg',
          width: 800,
          height: 533,
          size: 85024,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1149.jpg?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "While it's in operation, other vehicles must not use this part of the carriageway except to pick up or set down passengers. At other times, when the lane isn't in operation, you should still be aware that there may be cyclists using the lane. Give them plenty of room as you pass and allow for their movement from side to side, especially in windy weather or on a bumpy road.",
        options: {
          A: 'They may park in the lane',
          B: 'They may drive in the lane at any time',
          C: 'They may use the lane when necessary',
          D: "They mustn't drive along the lane",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1225: {
        id: 'BB1225',
        version: 'v2',
        question:
          "You're going to turn left from a main road into a minor road. What should you do as you approach the junction?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          "Your road position can help other road users to anticipate your actions. Keep to the left as you approach a left turn and don't swing out into the centre of the road in order to make the turn easier. This could endanger oncoming traffic and may cause other road users to misunderstand your intentions.",
        options: {
          A: 'Keep just left of the middle of the road',
          B: 'Keep in the middle of the road',
          C: 'Swing out to the right just before turning',
          D: 'Keep well to the left of the road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1226: {
        id: 'BB1226',
        version: 'v2',
        question:
          "You're waiting at a level crossing. What should you do if the red warning lights continue to flash after a train has passed by?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: 'TS4507.png',
          width: 800,
          height: 699,
          size: 26856,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4507.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'At a level crossing, flashing red lights mean you must stop. If the train passes but the lights keep flashing, wait. Another train may be coming.',
        options: {
          A: 'Get out and investigate',
          B: 'Telephone the signal operator',
          C: 'Continue to wait',
          D: 'Drive across carefully',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1227: {
        id: 'BB1227',
        version: 'v2',
        question:
          "What should you do if the amber lights come on and a warning sounds while you're driving over a level crossing?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: 'TS4507.png',
          width: 800,
          height: 699,
          size: 26856,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4507.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Keep going; don't stop on the crossing. If the warning sounds and the amber lights come on as you're approaching the crossing, you must stop unless it's unsafe to do so. Red flashing lights together with the audible warning mean you must stop.",
        options: {
          A: 'Get everyone out of the vehicle immediately',
          B: 'Stop and reverse back to clear the crossing',
          C: 'Keep going and clear the crossing',
          D: 'Stop immediately and use your hazard warning lights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1228: {
        id: 'BB1228',
        version: 'v2',
        question:
          "You're driving on a busy main road. What should you do if you find that you're driving in the wrong direction?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          "Don't turn around in a busy street or reverse from a side road into a main road. Find a quiet side road and choose a place to turn around where you won't obstruct an entrance or exit. Look out for pedestrians and cyclists as well as other traffic.",
        options: {
          A: 'Turn into a side road on the right and reverse into the main road',
          B: 'Make a U-turn in the main road',
          C: "Make a 'three-point' turn in the main road",
          D: 'Turn around in a side road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1229: {
        id: 'BB1229',
        version: 'v2',
        question: 'When may you drive without wearing your seat belt?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "You may remove your seat belt while you're carrying out a manoeuvre that includes reversing. However, you must remember to put it back on again before you resume driving.",
        options: {
          A: "When you're carrying out a manoeuvre that includes reversing",
          B: "When you're moving off on a hill",
          C: "When you're testing your brakes",
          D: "When you're driving slowly in queuing traffic",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1230: {
        id: 'BB1230',
        version: 'v2',
        question: 'How far are you allowed to reverse?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "You mustn't reverse further than is necessary. You may decide to turn your vehicle around by reversing into an opening or side road. When you reverse, always look all around you and watch for pedestrians. Don't reverse from a side road into a main road.",
        options: {
          A: 'No further than is necessary',
          B: "No more than a car's length",
          C: 'As far as it takes to reverse around a corner',
          D: 'The length of a residential street',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1232: {
        id: 'BB1232',
        version: 'v2',
        question:
          "What should you do when you're unsure whether it's safe to reverse your vehicle?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'C',
        additionalInfo:
          "A small child could be hidden directly behind you, so, if you can't see all around your vehicle, get out and have a look. You could also ask someone reliable outside the vehicle to guide you.",
        options: {
          A: 'Sound your horn',
          B: 'Rev your engine',
          C: 'Get out and check',
          D: 'Reverse slowly',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1233: {
        id: 'BB1233',
        version: 'v2',
        question:
          'Why could it be dangerous to reverse from a side road into a main road?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'B',
        additionalInfo:
          "Don't reverse into a main road from a side road because your view will be restricted. The main road is likely to be busy and the traffic on it moving quickly.",
        options: {
          A: 'Your reverse sensors will beep',
          B: 'Your view will be restricted',
          C: 'Your reversing lights will be hidden',
          D: 'Your mirrors will need adjusting',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1355: {
        id: 'BB1355',
        version: 'v2',
        question:
          "You want to turn right at a box junction. What should you do if there's oncoming traffic?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "You can wait in the box junction as long as your exit is clear. At some point there'll be a gap in the oncoming traffic, or the traffic lights will change, allowing you to proceed.",
        options: {
          A: 'Wait in the box junction if your exit is clear',
          B: "Wait before the junction until it's clear of all traffic",
          C: "Drive on; you can't turn right at a box junction",
          D: 'Drive slowly into the box junction when signalled by oncoming traffic',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1381: {
        id: 'BB1381',
        version: 'v2',
        question:
          "You're reversing into a side road. When would your vehicle be the greatest hazard to passing traffic?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          'Always check in all directions before reversing. Keep a good lookout throughout the manoeuvre and remember that the front will swing out as you reverse into the side road. Act on what you see and wait if necessary.',
        options: {
          A: "After you've completed the manoeuvre",
          B: 'Just before you begin to manoeuvre',
          C: "After you've entered the side road",
          D: 'When the front of your vehicle swings out',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1435: {
        id: 'BB1435',
        version: 'v2',
        question: "Where's the safest place to park your vehicle at night?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "If you have a garage, use it. Your vehicle is less likely to be a victim of car crime if it's in a garage. Also, in winter, the windows will be kept free from ice and snow.",
        options: {
          A: 'In a garage',
          B: 'On a busy road',
          C: 'In a quiet car park',
          D: 'Near a red route',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1527: {
        id: 'BB1527',
        version: 'v2',
        question: 'When may you stop on an urban clearway?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          'Urban clearways have their times of operation clearly signed. You may only stop to pick up or set down passengers.',
        options: {
          A: 'To set down and pick up passengers',
          B: 'To use a mobile telephone',
          C: 'To ask for directions',
          D: 'To load or unload goods',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1528: {
        id: 'BB1528',
        version: 'v2',
        question:
          "You're looking for somewhere to park your vehicle. What should you do if the only free spaces are marked for disabled drivers?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          "It's illegal to park in a space reserved for disabled drivers unless you're permitted to do so. These spaces are provided for people with limited mobility, who may need extra space to get in and out of their vehicle.",
        options: {
          A: 'Use one of these spaces',
          B: 'Park in one of these spaces but stay with your vehicle',
          C: 'Use one of the spaces as long as one is kept free',
          D: 'Wait for a regular parking space to become free',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1554: {
        id: 'BB1554',
        version: 'v2',
        question:
          "You're on a road that's only wide enough for one vehicle. What should you do if a car is coming towards you?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          'Pull into the nearest passing place on the left if you meet another vehicle on a narrow road. If the nearest passing place is on the right, wait opposite it.',
        options: {
          A: 'Pull into a passing place on your right',
          B: 'Force the other driver to reverse',
          C: 'Pull into a passing place if your vehicle is wider',
          D: 'Pull into a passing place on your left',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1566: {
        id: 'BB1566',
        version: 'v2',
        question:
          "You're driving at night with your headlights on main beam. A vehicle is overtaking you. When should you dip your headlights?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          'On main beam, your lights could dazzle the driver in front. Dip your headlights as soon as the driver passes you and drop back so that the dipped beam falls short of the vehicle in front.',
        options: {
          A: 'Some time after the vehicle has passed you',
          B: 'Before the vehicle starts to pass you',
          C: 'Only if the other driver dips their headlights',
          D: 'As soon as the vehicle passes you',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1581: {
        id: 'BB1581',
        version: 'v2',
        question: 'When may you drive a car in this bus lane?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: 'TS4565.png',
          width: 515,
          height: 516,
          size: 41241,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4565.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'Some bus lanes operate only during peak hours and other vehicles may use them outside these hours. Make sure you check the sign for the hours of operation before driving in a bus lane.',
        options: {
          A: 'Outside its hours of operation',
          B: 'To get to the front of a traffic queue',
          C: 'You may not use it at any time',
          D: 'To overtake slow-moving traffic',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1622: {
        id: 'BB1622',
        version: 'v2',
        question:
          'Other than direction indicators, how can you give signals to other road users?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "Your brake lights will give an indication to traffic behind that you're slowing down. Good anticipation will allow you time to check your mirrors before slowing.",
        options: {
          A: 'By using brake lights',
          B: 'By using sidelights',
          C: 'By using fog lights',
          D: 'By using interior lights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1692: {
        id: 'BB1692',
        version: 'v2',
        question:
          "You're parked in a busy high street. What's the safest way to turn your vehicle around so you can drive in the opposite direction?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "Make sure you carry out the manoeuvre without causing a hazard to other vehicles. Choose a place to turn that's safe and considers other road users.",
        options: {
          A: 'Turn around in a quiet side road',
          B: 'Drive into a side road and reverse out into the main road',
          C: 'Ask someone to stop the traffic',
          D: 'Carry out a U-turn',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1693: {
        id: 'BB1693',
        version: 'v2',
        question: 'Where should you park your vehicle at night?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'D',
        additionalInfo:
          "When you're parking at night, park in a well-lit area. This can help deter criminals from targeting your vehicle.",
        options: {
          A: 'Near a police station',
          B: 'In a quiet road',
          C: 'On a red route',
          D: 'In a well-lit area',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1694: {
        id: 'BB1694',
        version: 'v2',
        question:
          "You're driving in the right-hand lane of a dual carriageway. What should you do if you see a sign showing that the right-hand lane is closed 800 yards ahead?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: 'TS4050n1.png',
          width: 350,
          height: 434,
          size: 38368,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4050n1.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "Keep a lookout for traffic signs. If you're directed to change lanes, do so in good time. Don't\r\n• push your way into traffic in another lane\r\n• try to gain advantage by delaying changing lanes.",
        options: {
          A: 'Keep in that lane until you reach the queue',
          B: 'Move to the left immediately',
          C: 'Wait and see which lane is moving faster',
          D: 'Move to the left in good time',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1747: {
        id: 'BB1747',
        version: 'v2',
        question:
          "You're driving on a road that has a cycle lane. What does it mean if the lane is marked by a broken white line?",
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: {
          fileName: 'DSCF2385.jpg',
          width: 800,
          height: 533,
          size: 72745,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FDSCF2385.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Cycle lanes are marked with either a solid or a broken white line. If the line is solid, you should check the times of operation shown on the signs, and not drive or park in the lane during those times. If the line is broken, you shouldn't drive or park in the lane unless it's unavoidable.",
        options: {
          A: "You shouldn't drive in the lane unless it's unavoidable",
          B: "There's a reduced speed limit for motor vehicles using the lane",
          C: 'Cyclists can travel in both directions in that lane',
          D: 'The lane must be used by motorcyclists in heavy traffic',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1784: {
        id: 'BB1784',
        version: 'v2',
        question:
          'When are you allowed to park in a parking bay for disabled drivers?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "Don't park in a space reserved for disabled people unless you or your passenger are a Blue Badge holder. The badge must be displayed on the dashboard or facia panel, where it can be clearly read through the front windscreen.",
        options: {
          A: 'When you have a Blue Badge',
          B: 'When you have a wheelchair',
          C: 'When you have an advanced driver certificate',
          D: 'When you have an adapted vehicle',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1794: {
        id: 'BB1794',
        version: 'v2',
        question: 'When must you stop your vehicle?',
        questionSetId: 'RULES-OF-THE-ROAD',
        questionSetTitle: 'Rules of the road',
        image: null,
        answer: 'A',
        additionalInfo:
          "You must stop your vehicle when signalled to do so by a \r\n• police, DVSA or traffic officer\r\n• traffic warden\r\n• school crossing patrol \r\n• red traffic light. \r\nYou must also stop if you're involved in an incident that causes damage or injury to any other person, vehicle, animal or property.",
        options: {
          A: "If you're involved in an incident that causes damage or injury",
          B: "At a junction where there are 'give way' lines",
          C: 'At the end of a one-way street',
          D: 'Before merging onto a motorway',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2293: {
        id: 'AB2293',
        version: 'v2',
        question: 'How can you identify traffic signs that give orders?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'D',
        additionalInfo:
          "There are three basic types of traffic sign: those that warn, those that inform and those that give orders. Generally, triangular signs warn, rectangular signs give information or directions and circular signs give orders. An exception is the eight-sided 'stop' sign.",
        options: {
          A: "They're rectangular with a yellow border",
          B: "They're triangular with a blue border",
          C: "They're square with a brown border",
          D: "They're circular with a red border",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2294: {
        id: 'AB2294',
        version: 'v2',
        question: 'What shape are traffic signs giving orders?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'D',
        additionalInfo:
          "Road signs in the shape of a circle give orders. Those with a red circle are mostly prohibitive. The 'stop' sign is octagonal to give it greater prominence. Signs giving orders must always be obeyed.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4073b.png',
            width: 400,
            height: 161,
            size: 6217,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4073b.png?alt=media',
          },
          B: {
            fileName: 'TS4073d.png',
            width: 400,
            height: 323,
            size: 3466,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4073d.png?alt=media',
          },
          C: {
            fileName: 'TS4629.png',
            width: 400,
            height: 354,
            size: 11347,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4629.png?alt=media',
          },
          D: {
            fileName: 'TS4668.png',
            width: 400,
            height: 400,
            size: 16415,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4668.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2295: {
        id: 'AB2295',
        version: 'v2',
        question: 'Which type of sign tells you what you must not do?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'A',
        additionalInfo:
          "Signs in the shape of a circle give orders. A sign with a red circle means that you aren't allowed to do something. Study Know Your Traffic Signs to ensure that you understand what the different traffic signs mean.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4668.png',
            width: 400,
            height: 400,
            size: 16415,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4668.png?alt=media',
          },
          B: {
            fileName: 'TS4003.png',
            width: 400,
            height: 400,
            size: 29436,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4003.png?alt=media',
          },
          C: {
            fileName: 'TS4004.png',
            width: 400,
            height: 242,
            size: 6747,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4004.png?alt=media',
          },
          D: {
            fileName: 'TS4005.png',
            width: 400,
            height: 243,
            size: 7909,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4005.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2296: {
        id: 'AB2296',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4677.png',
          width: 800,
          height: 1446,
          size: 102391,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4677.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "If you're in a place where there are likely to be pedestrians (for example, outside a school, near a park, in a residential area or in a shopping area), you should be cautious and keep your speed down. \r\nMany local authorities have taken steps to slow traffic down by creating traffic-calming measures such as road humps. They're there for a reason; slow down.",
        options: {
          A: 'Maximum speed limit with traffic calming',
          B: 'Minimum speed limit with traffic calming',
          C: "'20 cars only' parking zone",
          D: 'Only 20 cars allowed at any one time',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2299: {
        id: 'AB2299',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4678.png',
          width: 700,
          height: 990,
          size: 45125,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4678.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "Where you see this sign, the 20 mph restriction ends and a 30 mph restriction starts. Check all around for possible hazards and only increase your speed if it's safe to do so.",
        options: {
          A: 'New speed limit 20 mph',
          B: 'No vehicles over 30 tonnes',
          C: 'Minimum speed limit 30 mph',
          D: 'End of 20 mph zone',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2301: {
        id: 'AB2301',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4669.png',
          width: 400,
          height: 400,
          size: 30575,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4669.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "A sign will indicate which types of vehicles are prohibited from certain roads. Make sure that you know which signs apply to the vehicle you're using.",
        options: {
          A: 'No overtaking',
          B: 'No motor vehicles',
          C: 'Clearway (no stopping)',
          D: 'Cars and motorcycles only',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2302: {
        id: 'AB2302',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4667.png',
          width: 400,
          height: 400,
          size: 24069,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4667.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "'No entry' signs are used in places such as one-way streets to prevent vehicles driving against the traffic. To ignore one would be dangerous, both for yourself and for other road users, as well as being against the law.",
        options: {
          A: 'No parking',
          B: 'No road markings',
          C: 'No through road',
          D: 'No entry',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2303: {
        id: 'AB2303',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4665.png',
          width: 800,
          height: 800,
          size: 51905,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4665.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "The 'no right turn' sign may be used to warn road users that there's a 'no entry' prohibition on a road to the right ahead.",
        options: {
          A: 'Bend to the right',
          B: 'Road on the right closed',
          C: 'No traffic from the right',
          D: 'No right turn',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2304: {
        id: 'AB2304',
        version: 'v2',
        question: "Which sign means 'no entry'?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'D',
        additionalInfo:
          'Look for and obey traffic signs. Disobeying or not seeing a sign could be dangerous. It may also be an offence for which you could be prosecuted.',
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4691.png',
            width: 400,
            height: 400,
            size: 21379,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4691.png?alt=media',
          },
          B: {
            fileName: 'TS4690.png',
            width: 400,
            height: 400,
            size: 22739,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4690.png?alt=media',
          },
          C: {
            fileName: 'TS4675.png',
            width: 400,
            height: 400,
            size: 16859,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4675.png?alt=media',
          },
          D: {
            fileName: 'TS4667.png',
            width: 400,
            height: 400,
            size: 24069,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4667.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2305: {
        id: 'AB2305',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4085.png',
          width: 381,
          height: 609,
          size: 26194,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4085.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Avoid blocking tram routes. Trams are fixed on their route and can't manoeuvre around other vehicles or pedestrians. Modern trams travel quickly and are quiet, so you might not hear them approaching.",
        options: {
          A: 'Route for trams only',
          B: 'Route for buses only',
          C: 'Parking for buses only',
          D: 'Parking for trams only',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2306: {
        id: 'AB2306',
        version: 'v2',
        question: 'Which type of vehicle does this sign apply to?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4673.png',
          width: 800,
          height: 800,
          size: 59450,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4673.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'The triangular shapes above and below the dimensions indicate a height restriction that applies to the road ahead.',
        options: {
          A: 'Wide vehicles',
          B: 'Long vehicles',
          C: 'High vehicles',
          D: 'Heavy vehicles',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2307: {
        id: 'AB2307',
        version: 'v2',
        question: 'Which sign means no motor vehicles allowed?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'B',
        additionalInfo:
          "This sign is used to enable pedestrians to walk free from traffic. It's often found in shopping areas.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4674.png',
            width: 400,
            height: 400,
            size: 22762,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4674.png?alt=media',
          },
          B: {
            fileName: 'TS4669.png',
            width: 400,
            height: 400,
            size: 30575,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4669.png?alt=media',
          },
          C: {
            fileName: 'TS4670.png',
            width: 400,
            height: 400,
            size: 25476,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4670.png?alt=media',
          },
          D: {
            fileName: 'TS4672.png',
            width: 400,
            height: 400,
            size: 24849,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4672.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2308: {
        id: 'AB2308',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4674.png',
          width: 400,
          height: 400,
          size: 22762,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4674.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "Road signs that prohibit overtaking are placed in locations where passing the vehicle in front is dangerous. If you see this sign, don't attempt to overtake. The sign is there for a reason; you must obey it.",
        options: {
          A: 'You have priority',
          B: 'No motor vehicles',
          C: 'Two-way traffic',
          D: 'No overtaking',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2311: {
        id: 'AB2311',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4690.png',
          width: 400,
          height: 400,
          size: 22739,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4690.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "There'll be a plate or additional sign to tell you when the restrictions apply.",
        options: {
          A: 'Waiting restrictions apply',
          B: 'Waiting permitted',
          C: 'National speed limit applies',
          D: 'Clearway (no stopping)',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2313: {
        id: 'AB2313',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4676.png',
          width: 800,
          height: 1285,
          size: 103978,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4676.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "Even though you've left the restricted area, make sure that you park where you won't endanger other road users or cause an obstruction.",
        options: {
          A: 'End of restricted speed area',
          B: 'End of restricted parking area',
          C: 'End of clearway',
          D: 'End of cycle route',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2314: {
        id: 'AB2314',
        version: 'v2',
        question: "Which sign means 'no stopping'?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'B',
        additionalInfo:
          'Stopping where this clearway restriction applies is likely to cause congestion. Allow the traffic to flow by obeying the signs.',
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4674.png',
            width: 400,
            height: 400,
            size: 22762,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4674.png?alt=media',
          },
          B: {
            fileName: 'TS4691.png',
            width: 400,
            height: 400,
            size: 21379,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4691.png?alt=media',
          },
          C: {
            fileName: 'TS4675.png',
            width: 400,
            height: 400,
            size: 16859,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4675.png?alt=media',
          },
          D: {
            fileName: 'TS4670.png',
            width: 400,
            height: 400,
            size: 25476,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4670.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2316: {
        id: 'AB2316',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4691.png',
          width: 400,
          height: 400,
          size: 21379,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4691.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "This is the sign for a clearway. Clearways are stretches of road where you aren't allowed to stop unless it's an emergency. Stopping where these restrictions apply may be dangerous and is likely to cause an obstruction. Restrictions might apply for several miles and this may be indicated on the sign.",
        options: {
          A: 'National speed limit applies',
          B: 'Waiting restrictions apply',
          C: 'No stopping',
          D: 'No entry',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2317: {
        id: 'AB2317',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4709.png',
          width: 642,
          height: 776,
          size: 21581,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4709.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'If you intend to stop and rest, this sign allows you time to reduce speed and pull over safely.',
        options: {
          A: 'Distance to parking place ahead',
          B: 'Distance to public telephone ahead',
          C: 'Distance to public house ahead',
          D: 'Distance to passing place ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2318: {
        id: 'AB2318',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4695.png',
          width: 650,
          height: 1001,
          size: 21053,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4695.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "In order to keep roads free from parked cars, there are some areas where you're allowed to park on the verge. Only do this where you see the sign. Parking on verges or footways anywhere else could lead to a fine.",
        options: {
          A: 'Vehicles may not park on the verge or footway',
          B: 'Vehicles may park on the left-hand side of the road only',
          C: 'Vehicles may park fully on the verge or footway',
          D: 'Vehicles may park on the right-hand side of the road only',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2319: {
        id: 'AB2319',
        version: 'v2',
        question: 'What does this traffic sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4666.png',
          width: 400,
          height: 400,
          size: 20187,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4666.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "Priority signs are normally shown where the road is narrow and there isn't enough room for two vehicles to pass. Examples are narrow bridges, roadworks and where there's a width restriction. \r\nMake sure you know who has priority; don't force your way through. Show courtesy and consideration to other road users.",
        options: {
          A: 'No overtaking allowed',
          B: 'Give priority to oncoming traffic',
          C: 'Two-way traffic',
          D: 'One-way traffic only',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2320: {
        id: 'AB2320',
        version: 'v2',
        question: "What's the meaning of this traffic sign?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4699.png',
          width: 400,
          height: 457,
          size: 14346,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4699.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Don't force your way through. Show courtesy and consideration to other road users. Although you have priority, make sure oncoming traffic is going to give way before you continue.",
        options: {
          A: 'End of two-way road',
          B: 'Give priority to vehicles coming towards you',
          C: 'You have priority over vehicles coming towards you',
          D: 'Bus lane ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2324: {
        id: 'AB2324',
        version: 'v2',
        question: "What shape is a 'stop' sign?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'D',
        additionalInfo:
          "To make it easy to recognise, the 'stop' sign is the only sign of this shape. You must stop and take effective observation before proceeding.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4668.png',
            width: 400,
            height: 400,
            size: 16415,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4668.png?alt=media',
          },
          B: {
            fileName: 'TS4629a.png',
            width: 400,
            height: 354,
            size: 11741,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4629a.png?alt=media',
          },
          C: {
            fileName: 'TS4002.png',
            width: 400,
            height: 240,
            size: 3745,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4002.png?alt=media',
          },
          D: {
            fileName: 'TS4031.png',
            width: 400,
            height: 400,
            size: 11235,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4031.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2325: {
        id: 'AB2325',
        version: 'v2',
        question:
          'In winter, road signs can become covered by snow. What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4558.png',
          width: 767,
          height: 605,
          size: 32962,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4558.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "The 'stop' sign is the only road sign that's octagonal. This is so that it can be recognised and obeyed even if it's obscured (for example, by snow).",
        options: {A: 'Crossroads', B: 'Give way', C: 'Stop', D: 'Turn right'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2327: {
        id: 'AB2327',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4535.png',
          width: 475,
          height: 475,
          size: 34136,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4535.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'This sign is shown where slow-moving vehicles would impede the flow of traffic; for example, in tunnels. However, if you need to slow down or even stop to avoid an incident or potential collision, you should do so.',
        options: {
          A: 'Service area 30 miles ahead',
          B: 'Maximum speed 30 mph',
          C: 'Minimum speed 30 mph',
          D: 'Lay-by 30 miles ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2331: {
        id: 'AB2331',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4526.png',
          width: 215,
          height: 215,
          size: 25673,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4526.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "These signs are often seen in one-way streets that have more than one lane. When you see this sign, use the route that's the most convenient and doesn't require a late change of direction.",
        options: {
          A: 'Give way to oncoming vehicles',
          B: 'Approaching traffic passes you on both sides',
          C: 'Turn off at the next available junction',
          D: 'Pass either side to get to the same destination',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2332: {
        id: 'AB2332',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4085.png',
          width: 381,
          height: 609,
          size: 26194,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4085.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'Take extra care when you encounter trams. Look out for road markings and signs that alert you to them. Modern trams are very quiet and you may not hear them approaching.',
        options: {
          A: 'Route for trams',
          B: 'Give way to trams',
          C: 'Route for buses',
          D: 'Give way to buses',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2333: {
        id: 'AB2333',
        version: 'v2',
        question:
          'What messages are given by circular traffic signs that have a blue background?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4003.png',
          width: 400,
          height: 400,
          size: 29436,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4003.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'Signs with blue circles generally give mandatory instruction. These are often found in urban areas and include signs for mini-roundabouts and directional arrows.',
        options: {
          A: 'They give temporary directions during a diversion',
          B: 'They give directions to car parks',
          C: 'They give motorway information',
          D: 'They give mandatory instructions',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2335: {
        id: 'AB2335',
        version: 'v2',
        question: 'Where would you see a contraflow bus lane?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4708.png',
          width: 800,
          height: 362,
          size: 22854,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4708.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'The traffic permitted to use a contraflow lane travels in the opposite direction to traffic in the other lanes on the road.',
        options: {
          A: 'On a dual carriageway',
          B: 'On a roundabout',
          C: 'On an urban motorway',
          D: 'On a one-way street',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2336: {
        id: 'AB2336',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4708.png',
          width: 800,
          height: 362,
          size: 22854,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4708.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "There will also be markings on the road surface to indicate the bus lane. You mustn't use this lane for parking or overtaking.",
        options: {
          A: 'Bus station on the right',
          B: 'Contraflow bus lane',
          C: 'With-flow bus lane',
          D: 'Give way to buses',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2338: {
        id: 'AB2338',
        version: 'v2',
        question: 'What does a sign with a brown background show?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4010.png',
          width: 326,
          height: 226,
          size: 14077,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4010.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Signs with a brown background give directions to places of interest. They're often seen on a motorway, directing you along the easiest route to the attraction.",
        options: {
          A: 'Tourist directions',
          B: 'Primary roads',
          C: 'Motorway routes',
          D: 'Minor roads',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2339: {
        id: 'AB2339',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4686.png',
          width: 771,
          height: 432,
          size: 23792,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4686.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "These signs indicate places of interest and are designed to guide you by the easiest route. They're particularly useful when you're unfamiliar with the area.",
        options: {
          A: 'Tourist attraction',
          B: 'Beware of trains',
          C: 'Level crossing',
          D: 'Beware of trams',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2340: {
        id: 'AB2340',
        version: 'v2',
        question: "What's the purpose of triangular shaped signs?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4629.png',
          width: 400,
          height: 354,
          size: 11347,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4629.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Triangular signs warn you of hazards ahead. Make sure you look at each sign that you pass on the road, so that you don't miss any vital instructions or information.",
        options: {
          A: 'To give warnings',
          B: 'To give information',
          C: 'To give orders',
          D: 'To give directions',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2341: {
        id: 'AB2341',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4632.png',
          width: 400,
          height: 354,
          size: 12411,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4632.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "This type of sign warns you of hazards ahead. Make sure you look at each sign and road marking that you pass, so that you don't miss any vital instructions or information. This sign shows there's a T-junction with priority over vehicles from the right.",
        options: {
          A: 'Turn left ahead',
          B: 'T-junction',
          C: 'No through road',
          D: 'Give way',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2342: {
        id: 'AB2342',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4651.png',
          width: 800,
          height: 707,
          size: 42440,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4651.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "It will take up to 10 times longer to stop when it's icy. Where there's a risk of icy conditions, you need to be aware of this and take extra care. If you think the road may be icy, don't brake or steer harshly, as your tyres could lose their grip on the road.",
        options: {
          A: 'Multi-exit roundabout',
          B: 'Risk of ice',
          C: 'Six roads converge',
          D: 'Place of historical interest',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2343: {
        id: 'AB2343',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4631.png',
          width: 800,
          height: 707,
          size: 30396,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4631.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'The priority through the junction is shown by the broader line. You need to be aware of the hazard posed by traffic crossing or pulling out onto a major road.',
        options: {
          A: 'Crossroads',
          B: 'Level crossing with gate',
          C: 'Level crossing without gate',
          D: 'Ahead only',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2344: {
        id: 'AB2344',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4634.png',
          width: 400,
          height: 354,
          size: 17860,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4634.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'As you approach a roundabout, look well ahead and check all signs. Decide which exit you wish to take and move into the correct position as you approach the roundabout, signalling as required.',
        options: {
          A: 'Ring road',
          B: 'Mini-roundabout',
          C: 'No vehicles',
          D: 'Roundabout',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2345: {
        id: 'AB2345',
        version: 'v2',
        question: 'What information would be shown in a triangular road sign?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4629.png',
          width: 400,
          height: 354,
          size: 11347,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4629.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Warning signs are there to make you aware of potential hazards on the road ahead. Take note of the signs so you're prepared and can take whatever action is necessary.",
        options: {
          A: 'Road narrows',
          B: 'Ahead only',
          C: 'Keep left',
          D: 'Minimum speed',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2346: {
        id: 'AB2346',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4683.png',
          width: 800,
          height: 707,
          size: 50730,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4683.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Where there's a cycle route ahead, a sign will show a bicycle in a red warning triangle. Watch out for children on bicycles and cyclists rejoining the main road.",
        options: {
          A: 'Cyclists must dismount',
          B: "Cycles aren't allowed",
          C: 'Cycle route ahead',
          D: 'Cycle in single file',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2347: {
        id: 'AB2347',
        version: 'v2',
        question:
          'Which sign means that pedestrians may be walking along the road?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'A',
        additionalInfo:
          'When you pass pedestrians in the road, leave plenty of room. You might have to use the right-hand side of the road, so look well ahead, as well as in your mirrors, before pulling out. Take great care if a bend in the road obscures your view ahead.',
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4647.png',
            width: 400,
            height: 354,
            size: 20764,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4647.png?alt=media',
          },
          B: {
            fileName: 'TS4648.png',
            width: 400,
            height: 354,
            size: 20441,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4648.png?alt=media',
          },
          C: {
            fileName: 'TS4646.png',
            width: 400,
            height: 354,
            size: 20427,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4646.png?alt=media',
          },
          D: {
            fileName: 'TS4649.png',
            width: 400,
            height: 354,
            size: 21149,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4649.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2351: {
        id: 'AB2351',
        version: 'v2',
        question: "Which sign means there's a double bend ahead?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'B',
        additionalInfo:
          "Triangular signs give you a warning of hazards ahead. They're there to give you time to prepare for the hazard; for example, by adjusting your speed.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4577.png',
            width: 400,
            height: 96,
            size: 4059,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4577.png?alt=media',
          },
          B: {
            fileName: 'TS4637.png',
            width: 400,
            height: 354,
            size: 14176,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4637.png?alt=media',
          },
          C: {
            fileName: 'TS4636.png',
            width: 400,
            height: 354,
            size: 13863,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4636.png?alt=media',
          },
          D: {
            fileName: 'TS4635.png',
            width: 400,
            height: 354,
            size: 13937,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4635.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2352: {
        id: 'AB2352',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4088.png',
          width: 205,
          height: 403,
          size: 25004,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4088.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Obey the 'give way' signs. Trams are unable to steer around you if you misjudge when it's safe to enter the junction.",
        options: {
          A: 'Wait at the barriers',
          B: 'Wait at the crossroads',
          C: 'Give way to trams',
          D: 'Give way to farm vehicles',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2353: {
        id: 'AB2353',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4602.png',
          width: 800,
          height: 707,
          size: 30678,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4602.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "These humps have been put in place to slow the traffic down. They're usually found in residential areas. Slow down to an appropriate speed.",
        options: {
          A: 'Hump bridge',
          B: 'Humps in the road',
          C: 'Entrance to tunnel',
          D: 'Soft verges',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2357: {
        id: 'AB2357',
        version: 'v2',
        question: 'Which sign means the end of a dual carriageway?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'D',
        additionalInfo:
          "If you're overtaking, make sure you move back safely into the left-hand lane before you reach the end of the dual carriageway.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4638.png',
            width: 400,
            height: 354,
            size: 13687,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4638.png?alt=media',
          },
          B: {
            fileName: 'TS4639.png',
            width: 400,
            height: 354,
            size: 12987,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4639.png?alt=media',
          },
          C: {
            fileName: 'TS4642.png',
            width: 400,
            height: 354,
            size: 13002,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4642.png?alt=media',
          },
          D: {
            fileName: 'TS4640.png',
            width: 400,
            height: 354,
            size: 13529,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4640.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2358: {
        id: 'AB2358',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4640.png',
          width: 400,
          height: 354,
          size: 13529,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4640.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Don't wait until the last moment before moving into the left-hand lane. Plan ahead and don't rely on other traffic letting you in.",
        options: {
          A: 'End of dual carriageway',
          B: 'Tall bridge',
          C: 'Road narrows',
          D: 'End of narrow bridge',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2360: {
        id: 'AB2360',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4518.png',
          width: 400,
          height: 353,
          size: 15872,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4518.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'A warning sign with a picture of a windsock indicates that there may be strong side winds. This sign is often found on exposed roads.',
        options: {
          A: 'Side winds',
          B: 'Road noise',
          C: 'Airport',
          D: 'Adverse camber',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2361: {
        id: 'AB2361',
        version: 'v2',
        question: 'What does this traffic sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4517.png',
          width: 400,
          height: 354,
          size: 16017,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4517.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'This sign is there to alert you to the likelihood of danger ahead. It may be accompanied by a plate indicating the type of hazard. Be ready to reduce your speed and take avoiding action.',
        options: {
          A: 'Slippery road ahead',
          B: 'Tyres liable to punctures ahead',
          C: 'Danger ahead',
          D: 'Service area ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2362: {
        id: 'AB2362',
        version: 'v2',
        question:
          "You're about to overtake. What should you do when you see this sign?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4549.png',
          width: 512,
          height: 763,
          size: 26326,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4549.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "You won't be able to see any hazards that might be hidden in the dip. As well as oncoming traffic, the dip may conceal\r\n• cyclists\r\n• horse riders\r\n• parked vehicles\r\n• pedestrians\r\nin the road.",
        options: {
          A: 'Overtake the other driver as quickly as possible',
          B: 'Move to the right to get a better view',
          C: 'Switch your headlights on before overtaking',
          D: 'Hold back until you can see clearly ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2363: {
        id: 'AB2363',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4679.png',
          width: 800,
          height: 707,
          size: 34216,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4679.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Some crossings have gates but no attendant or signals. You should stop, look both ways, listen and make sure that no train is approaching. If there's a telephone, contact the signal operator to make sure it's safe to cross.",
        options: {
          A: 'Level crossing with gate or barrier',
          B: 'Gated road ahead',
          C: 'Level crossing without gate or barrier',
          D: 'Cattle grid ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2364: {
        id: 'AB2364',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4680.png',
          width: 800,
          height: 707,
          size: 38410,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4680.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "This sign tells you to beware of trams. If you don't usually drive in a town where there are trams, remember to look out for them at junctions and look for tram rails, signs and signals.",
        options: {
          A: 'No trams ahead',
          B: 'Oncoming trams',
          C: 'Trams crossing ahead',
          D: 'Trams only',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2365: {
        id: 'AB2365',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4643.png',
          width: 800,
          height: 707,
          size: 45138,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4643.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'This sign gives you an early warning that the road ahead will slope downhill. Prepare to alter your speed and gear. Looking at the sign from left to right will show you whether the road slopes uphill or downhill.',
        options: {
          A: 'Adverse camber',
          B: 'Steep hill downwards',
          C: 'Uneven road',
          D: 'Steep hill upwards',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2367: {
        id: 'AB2367',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4516.png',
          width: 537,
          height: 475,
          size: 22398,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4516.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'This sign is found where a shallow stream crosses the road. Heavy rainfall could increase the flow of water. If the water looks too deep or the stream has spread over a large distance, stop and find another route.',
        options: {
          A: 'Uneven road surface',
          B: 'Bridge over the road',
          C: 'Road ahead ends',
          D: 'Water across the road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2369: {
        id: 'AB2369',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4701.png',
          width: 703,
          height: 600,
          size: 20209,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4701.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "This sign shows you that you can't get through to another route by turning left at the junction ahead.",
        options: {
          A: 'Turn left for parking area',
          B: 'No through road on the left',
          C: 'No entry for traffic turning left',
          D: 'Turn left for ferry terminal',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2370: {
        id: 'AB2370',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4700.png',
          width: 400,
          height: 398,
          size: 7765,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4700.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "You won't be able to find a through route to another road. Use this road only for access.",
        options: {
          A: 'T-junction',
          B: 'No through road',
          C: 'Telephone box ahead',
          D: 'Toilet ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2375: {
        id: 'AB2375',
        version: 'v2',
        question: 'Which is the sign for a ring road?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'C',
        additionalInfo:
          'Ring roads are designed to relieve congestion in towns and city centres.',
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4663.png',
            width: 400,
            height: 400,
            size: 37881,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4663.png?alt=media',
          },
          B: {
            fileName: 'TS4634.png',
            width: 400,
            height: 354,
            size: 17860,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4634.png?alt=media',
          },
          C: {
            fileName: 'TS4685.png',
            width: 400,
            height: 480,
            size: 14741,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4685.png?alt=media',
          },
          D: {
            fileName: 'TS4691.png',
            width: 400,
            height: 400,
            size: 21379,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4691.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2378: {
        id: 'AB2378',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4057.png',
          width: 400,
          height: 329,
          size: 10481,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4057.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'Yellow-and-black temporary signs may be used to inform you about roadworks or lane restrictions. Look well ahead. If you have to change lanes, do so in good time.',
        options: {
          A: 'The right-hand lane ahead is narrow',
          B: 'Right-hand lane for buses only',
          C: 'Right-hand lane for turning right',
          D: 'The right-hand lane is closed',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2379: {
        id: 'AB2379',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4578.png',
          width: 616,
          height: 729,
          size: 44927,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4578.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "If you use the right-hand lane in a contraflow system, you'll be travelling with no permanent barrier between you and the oncoming traffic. Observe speed limits and keep a good distance from the vehicle ahead.",
        options: {
          A: 'Change to the left-hand lane',
          B: 'Leave at the next exit',
          C: 'Contraflow system',
          D: 'One-way street',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2381: {
        id: 'AB2381',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4503.png',
          width: 783,
          height: 682,
          size: 30864,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4503.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "Where there's a long, steep, uphill gradient on a motorway, a crawler lane may be provided. This helps the traffic to flow by diverting the slower heavy vehicles into a dedicated lane on the left.",
        options: {
          A: 'Leave motorway at next exit',
          B: 'Lane for heavy and slow vehicles',
          C: 'All lorries use the hard shoulder',
          D: 'Rest area for lorries',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2384: {
        id: 'AB2384',
        version: 'v2',
        question: 'What does a red traffic light mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'C',
        additionalInfo:
          "Whatever light is showing, you should know which light is going to appear next and be able to take appropriate action. For example, when amber is showing on its own, you'll know that red will appear next. This should give you ample time to anticipate and respond safely.",
        options: {
          A: 'You should stop unless turning left',
          B: "Stop, if you're able to brake safely",
          C: 'You must stop and wait behind the stop line',
          D: 'Proceed with care',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2385: {
        id: 'AB2385',
        version: 'v2',
        question:
          'At traffic lights, what does it mean when the amber light shows on its own?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'D',
        additionalInfo:
          "When the amber light is showing on its own, the red light will follow next. The amber light means stop, unless you've already crossed the stop line or you're so close to it that stopping may cause a collision.",
        options: {
          A: 'Prepare to go',
          B: 'Go if the way is clear',
          C: 'Go if no pedestrians are crossing',
          D: 'Stop at the stop line',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2388: {
        id: 'AB2388',
        version: 'v2',
        question:
          "You're at a junction controlled by traffic lights. When should you wait at a green light?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'B',
        additionalInfo:
          'As you approach the traffic lights, look into the road you wish to take. Only proceed if your exit road is clear. If the road is blocked, hold back, even if you have to wait for the next green signal.',
        options: {
          A: 'When pedestrians are waiting to cross',
          B: 'When your exit from the junction is blocked',
          C: 'When you think the lights may be about to change',
          D: 'When you intend to turn right',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2389: {
        id: 'AB2389',
        version: 'v2',
        question:
          "You're in the left-hand lane at traffic lights, waiting to turn left. Which signal means you must wait?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'A',
        additionalInfo:
          "At some junctions, there may be separate signals for different lanes. These are called 'filter' lights. They're designed to help traffic flow at major junctions. Make sure that you're in the correct lane and proceed if the way is clear and the green light shows for your lane.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4014.png',
            width: 400,
            height: 522,
            size: 20379,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4014.png?alt=media',
          },
          B: {
            fileName: 'TS4015.png',
            width: 400,
            height: 522,
            size: 20319,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4015.png?alt=media',
          },
          C: {
            fileName: 'TS4013.png',
            width: 400,
            height: 522,
            size: 20953,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4013.png?alt=media',
          },
          D: {
            fileName: 'TS4016.png',
            width: 400,
            height: 522,
            size: 22108,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4016.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2390: {
        id: 'AB2390',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'Out_of_order_tlights.png',
          width: 410,
          height: 682,
          size: 34355,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FOut_of_order_tlights.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'You might see this sign where traffic lights are out of order. Proceed with caution, as nobody has priority at the junction.',
        options: {
          A: 'Traffic lights out of order',
          B: 'Amber signal out of order',
          C: 'Temporary traffic lights ahead',
          D: 'New traffic lights ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2392: {
        id: 'AB2392',
        version: 'v2',
        question: 'Who has priority when traffic lights are out of order?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'C',
        additionalInfo:
          "When traffic lights are out of order, you should treat the junction as an unmarked crossroads. Be cautious, as you may need to give way or stop. Look for traffic attempting to cross the junction, unaware that it doesn't have priority.",
        options: {
          A: 'Traffic going straight on',
          B: 'Traffic turning right',
          C: 'Nobody',
          D: 'Traffic turning left',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2393: {
        id: 'AB2393',
        version: 'v2',
        question: 'Where would you find these flashing red light signals?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4507b.png',
          width: 800,
          height: 495,
          size: 27826,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4507b.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'These signals are found at level crossings, swing or lifting bridges, some airfields and emergency access sites. The flashing red lights mean stop whether or not the way seems to be clear.',
        options: {
          A: 'Pelican crossings',
          B: 'Motorway exits',
          C: 'Zebra crossings',
          D: 'Level crossings',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2394: {
        id: 'AB2394',
        version: 'v2',
        question: 'What do these zigzag white lines mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'DSCF2954.jpg',
          width: 800,
          height: 533,
          size: 88726,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FDSCF2954.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "The approach to, and exit from, a pedestrian crossing is marked with zigzag lines. You mustn't park on them or overtake the leading vehicle when approaching the crossing. Parking here would block the view for pedestrians and approaching traffic.",
        options: {
          A: 'No parking at any time',
          B: 'Parking allowed only for a short time',
          C: 'Slow down to 20 mph',
          D: "Sounding horns isn't allowed",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2396: {
        id: 'AB2396',
        version: 'v2',
        question:
          'When may you cross a double solid white line in the middle of the road?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'IMG_1871.jpg',
          width: 800,
          height: 533,
          size: 101958,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1871.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "You may cross the solid white line to pass a stationary vehicle or to pass a pedal cycle, horse or road maintenance vehicle if it's travelling at 10 mph or less. You may also cross the solid white line to enter a side road or access a property.",
        options: {
          A: "To pass traffic that's queuing back at a junction",
          B: 'To pass a car signalling to turn left ahead',
          C: 'To pass a road maintenance vehicle travelling at 10 mph or less',
          D: "To pass a vehicle that's towing a trailer",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2398: {
        id: 'AB2398',
        version: 'v2',
        question: 'What does this road marking mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'IMG_4674.jpg',
          width: 800,
          height: 533,
          size: 86240,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_4674.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "A single broken line along the centre of the road, with long markings and short gaps, is a hazard warning line. Don't cross it unless you can see that the road is clear well ahead.",
        options: {
          A: "Don't cross the line",
          B: 'No stopping allowed',
          C: "You're approaching a hazard",
          D: 'No overtaking allowed',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2400: {
        id: 'AB2400',
        version: 'v2',
        question: 'Where would you see this road marking?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4076.png',
          width: 301,
          height: 390,
          size: 9947,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4076.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "Because the road has a dark colour, changes in level aren't easily seen. White triangles painted on the road surface give you an indication of where there are road humps.",
        options: {
          A: 'At traffic lights',
          B: 'On road humps',
          C: 'Near a level crossing',
          D: 'At a box junction',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2401: {
        id: 'AB2401',
        version: 'v2',
        question: 'Which diagram shows a hazard warning line?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'A',
        additionalInfo:
          "You need to know the difference between the normal centre line and a hazard warning line. If there's a hazard ahead, the markings are longer and the gaps shorter. This gives you advance warning of an unspecified hazard.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4045.png',
            width: 400,
            height: 213,
            size: 55983,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4045.png?alt=media',
          },
          B: {
            fileName: 'TS4046.png',
            width: 400,
            height: 230,
            size: 41732,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4046.png?alt=media',
          },
          C: {
            fileName: 'TS4047.png',
            width: 400,
            height: 199,
            size: 50118,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4047.png?alt=media',
          },
          D: {
            fileName: 'TS4048.png',
            width: 400,
            height: 200,
            size: 50641,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4048.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2402: {
        id: 'AB2402',
        version: 'v2',
        question:
          "Why does this junction have a 'stop' sign and a stop line on the road?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'IMG_1286.jpg',
          width: 800,
          height: 518,
          size: 81985,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1286.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Where emerging traffic has a very restricted view of the main road, you may find a 'stop' sign and a solid white stop line. You must stop at the line and then check carefully before you emerge.",
        options: {
          A: 'Speed on the major road is derestricted',
          B: "It's a busy junction",
          C: 'Visibility along the major road is restricted',
          D: 'The junction is on a downhill gradient',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2403: {
        id: 'AB2403',
        version: 'v2',
        question:
          'What does this line across the road at the entrance to a roundabout mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'IMG_0874.jpg',
          width: 800,
          height: 533,
          size: 73367,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_0874.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Slow down as you approach the roundabout and check for traffic from the right. If you need to stop and give way, stay behind the broken line until it's safe to emerge onto the roundabout.",
        options: {
          A: 'Give way to traffic from the right',
          B: 'Traffic from the left has right of way',
          C: 'You have right of way',
          D: 'Stop at the line',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2405: {
        id: 'AB2405',
        version: 'v2',
        question:
          'How will a police officer in a patrol vehicle signal for you to stop?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'A',
        additionalInfo:
          "You must obey signals given by the police. If a police officer in a patrol vehicle wants you to pull over, they'll indicate this without causing danger to you or other traffic.",
        options: {
          A: 'Flash the headlights, indicate left and point to the left',
          B: 'Overtake and give a slowing down arm signal',
          C: 'Use the siren, overtake, cut in front and stop',
          D: 'Pull alongside you, use the siren and wave you to stop',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2407: {
        id: 'AB2407',
        version: 'v2',
        question:
          "You're approaching a junction where the traffic lights aren't working. What should you do when a police officer gives this signal?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: '85BW0961.jpg',
          width: 800,
          height: 800,
          size: 59199,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW0961.jpg?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "When a police officer or traffic warden is directing traffic, you must obey them. They'll use the arm signals shown in The Highway Code. Learn what these signals mean and obey them.",
        options: {
          A: 'Turn left only',
          B: 'Turn right only',
          C: 'Continue ahead only',
          D: 'Stop at the stop line',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2408: {
        id: 'AB2408',
        version: 'v2',
        question: 'What does this arm signal mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'IMG_055.jpg',
          width: 400,
          height: 262,
          size: 12885,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_055.jpg?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "There might be an occasion where another driver uses an arm signal. This may be because the vehicle's indicators are obscured by other traffic. In order for such signals to be effective, all drivers should know their meaning. Be aware that the 'left turn' signal might look similar to the 'slowing down' signal.",
        options: {
          A: 'The driver is slowing down',
          B: 'The driver intends to turn right',
          C: 'The driver wishes to overtake',
          D: 'The driver intends to turn left',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2418: {
        id: 'AB2418',
        version: 'v2',
        question: 'What does this motorway sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4025.png',
          width: 800,
          height: 241,
          size: 24142,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4025.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'On the motorway, signs sometimes show temporary warnings due to traffic or weather conditions. They may be used to indicate\r\n• lane closures\r\n• temporary speed limits\r\n• weather warnings.',
        options: {
          A: 'Change to the lane on your left',
          B: 'Leave the motorway at the next exit',
          C: 'Change to the opposite carriageway',
          D: 'Pull up on the hard shoulder',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2419: {
        id: 'AB2419',
        version: 'v2',
        question: 'What does this motorway sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4028.png',
          width: 618,
          height: 760,
          size: 35665,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4028.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "Look out for signs above your lane or on the central reservation. These will give you important information or warnings about the road ahead. To allow for the high speed of motorway traffic, these signs may light up some distance from any hazard. Don't ignore the signs just because the road looks clear to you.",
        options: {
          A: 'Temporary minimum speed 50 mph',
          B: 'No services for 50 miles',
          C: 'Obstruction 50 metres (164 feet) ahead',
          D: 'Temporary maximum speed 50 mph',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2420: {
        id: 'AB2420',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4030.png',
          width: 400,
          height: 492,
          size: 40968,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4030.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "You should change lanes as directed by the sign. Here, the right-hand lane is closed but the left-hand and centre lanes are available. Merging in turn is recommended when it's safe and traffic is going slowly; for example, at roadworks or a road traffic incident. When vehicles are travelling at speed, this isn't advisable and you should move into the appropriate lane in good time.",
        options: {
          A: 'Through traffic to use left lane',
          B: 'Right-hand lane T-junction only',
          C: 'Right-hand lane closed ahead',
          D: '11 tonne weight limit',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2422: {
        id: 'AB2422',
        version: 'v2',
        question: "What does '25' mean on this motorway sign?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4011.png',
          width: 800,
          height: 637,
          size: 51854,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4011.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'Before you set out on your journey, use a road map to plan your route. When you see an advance warning of your junction, make sure you get into the correct lane in plenty of time. Last-minute harsh braking and cutting across lanes at speed is extremely hazardous.',
        options: {
          A: 'The distance to the nearest town',
          B: 'The route number of the road',
          C: 'The number of the next junction',
          D: 'The speed limit on the slip road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2423: {
        id: 'AB2423',
        version: 'v2',
        question:
          'How should the right-hand lane of a three-lane motorway be used?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'B',
        additionalInfo:
          "You should stay in the left-hand lane of a motorway unless you're overtaking another vehicle. The right-hand lane of a motorway is an overtaking lane; it isn't the 'fast lane'. After overtaking, move back to the left when it's safe to do so.",
        options: {
          A: 'As a high-speed lane',
          B: 'As an overtaking lane',
          C: 'As a right-turn lane',
          D: 'As an acceleration lane',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2424: {
        id: 'AB2424',
        version: 'v2',
        question: 'Where can you find reflective amber studs on a motorway?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'C',
        additionalInfo:
          'At night or in poor visibility, reflective studs on the road help you to judge your position on the carriageway.',
        options: {
          A: 'Separating the slip road from the motorway',
          B: 'On the left-hand edge of the road',
          C: 'On the right-hand edge of the road',
          D: 'Separating the lanes',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2425: {
        id: 'AB2425',
        version: 'v2',
        question: 'Where would you find green reflective studs on a motorway?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'C',
        additionalInfo:
          'Knowing the colours of the reflective studs on the road will help you judge your position, especially at night, in foggy conditions or when visibility is poor.',
        options: {
          A: 'Separating driving lanes',
          B: 'Between the hard shoulder and the carriageway',
          C: 'At slip-road entrances and exits',
          D: 'Between the carriageway and the central reservation',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2426: {
        id: 'AB2426',
        version: 'v2',
        question:
          'What should you do when you see this sign as you travel along a motorway?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4029.png',
          width: 618,
          height: 760,
          size: 30583,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4029.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "You'll see this sign if the motorway is closed ahead. Pull into the left-hand lane as soon as it's safe to do so. Don't wait until the last moment before you move across, because the lane may be busy and you'll have to rely on another driver making room for you.",
        options: {
          A: 'Leave the motorway at the next exit',
          B: 'Turn left immediately',
          C: 'Change lane',
          D: 'Move onto the hard shoulder',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2428: {
        id: 'AB2428',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4590.png',
          width: 683,
          height: 679,
          size: 26371,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4590.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'When you leave the motorway, make sure that you check your speedometer. You may be going faster than you realise. Slow down and look for speed-limit signs.',
        options: {
          A: 'No motor vehicles',
          B: 'End of motorway',
          C: 'No through road',
          D: 'End of bus lane',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2429: {
        id: 'AB2429',
        version: 'v2',
        question: 'Which sign means that the national speed limit applies?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'D',
        additionalInfo:
          "You should know the speed limit for the road on which you're travelling and for your vehicle. The different speed limits are shown in The Highway Code.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4667.png',
            width: 400,
            height: 400,
            size: 24069,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4667.png?alt=media',
          },
          B: {
            fileName: 'TS4668.png',
            width: 400,
            height: 400,
            size: 16415,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4668.png?alt=media',
          },
          C: {
            fileName: 'TS4691.png',
            width: 400,
            height: 400,
            size: 21379,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4691.png?alt=media',
          },
          D: {
            fileName: 'TS4675.png',
            width: 400,
            height: 400,
            size: 16859,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4675.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2430: {
        id: 'AB2430',
        version: 'v2',
        question:
          "What's the national speed limit on a single carriageway road?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'B',
        additionalInfo:
          "If you're travelling on a dual carriageway that becomes a single carriageway road, reduce your speed gradually so that you aren't exceeding the limit as you enter. There might not be a sign to remind you of the limit, so make sure you know the speed limits for different types of road and vehicle.",
        options: {A: '50 mph', B: '60 mph', C: '40 mph', D: '70 mph'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2529: {
        id: 'AB2529',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4086.png',
          width: 435,
          height: 529,
          size: 11828,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4086.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "Temporary restrictions on motorways are shown on signs that have flashing amber lights. At the end of the restriction, you'll see this sign without any flashing lights.",
        options: {
          A: 'End of motorway',
          B: 'End of restriction',
          C: 'Lane ends ahead',
          D: 'Free recovery ends',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2530: {
        id: 'AB2530',
        version: 'v2',
        question: 'What does this sign indicate?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4572.png',
          width: 410,
          height: 363,
          size: 16038,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4572.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'When a diversion route has been put in place, drivers are advised to follow a symbol, which may be a black triangle, square, circle or diamond shape on a yellow background.',
        options: {
          A: 'A diversion route',
          B: 'A picnic area',
          C: 'A pedestrian zone',
          D: 'A cycle route',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2578: {
        id: 'AB2578',
        version: 'v2',
        question: 'What does this traffic sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: '50_mph_roadworks.png',
          width: 800,
          height: 696,
          size: 68517,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F50_mph_roadworks.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "The sign gives you an early warning of a speed restriction. If you're travelling at a higher speed, slow down in good time. You could come across queuing traffic due to roadworks or a temporary obstruction.",
        options: {
          A: 'Compulsory maximum speed limit',
          B: 'Advisory maximum speed limit',
          C: 'Compulsory minimum speed limit',
          D: 'Advised separation distance',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2579: {
        id: 'AB2579',
        version: 'v2',
        question: 'What should you do when you see this sign at a crossroads?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'Out_of_order_tlights.png',
          width: 410,
          height: 682,
          size: 34355,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FOut_of_order_tlights.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'When traffic lights are out of order, treat the junction as an unmarked crossroads. Be very careful and be prepared to stop; no-one has priority.',
        options: {
          A: 'Maintain the same speed',
          B: 'Carry on with great care',
          C: 'Find another route',
          D: 'Telephone the police',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2591: {
        id: 'AB2591',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4671.png',
          width: 800,
          height: 800,
          size: 62378,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4671.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          "You must comply with all traffic signs and be especially aware of those signs that apply specifically to the type of vehicle you're using.",
        options: {
          A: 'Motorcycles only',
          B: 'No cars',
          C: 'Cars only',
          D: 'No motorcycles',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2592: {
        id: 'AB2592',
        version: 'v2',
        question:
          "You're on a motorway. A lorry has stopped in the right-hand lane. What should you do when you see this sign on the lorry?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4058.png',
          width: 517,
          height: 582,
          size: 22152,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4058.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          'Sometimes work is carried out on the motorway without closing the lanes. When this happens, signs are mounted on the back of lorries to warn other road users of the roadworks ahead.',
        options: {
          A: 'Move into the right-hand lane',
          B: 'Stop behind the flashing lights',
          C: 'Pass the lorry on the left',
          D: 'Leave the motorway at the next exit',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2623: {
        id: 'AB2623',
        version: 'v2',
        question:
          "You're on a motorway. What should you do if there's a red cross showing on the signs above your lane only?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'B',
        additionalInfo:
          'A red cross above your lane shows that your lane is closed. You should move into another lane as soon as you can do so safely.',
        options: {
          A: 'Continue in that lane and look for further information',
          B: "Don't continue in that lane",
          C: 'Pull onto the hard shoulder',
          D: 'Stop and wait for an instruction to proceed',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2634: {
        id: 'AB2634',
        version: 'v2',
        question: "When may you sound your vehicle's horn?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'C',
        additionalInfo:
          "Never sound your vehicle's horn aggressively. You mustn't sound it in a built-up area between 11.30 pm and 7.00 am, or when you're stationary, unless another road user poses a danger. Don't scare animals by sounding your horn.",
        options: {
          A: 'To give you right of way',
          B: "To attract a friend's attention",
          C: 'To warn others of your presence',
          D: 'To make slower drivers move over',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2635: {
        id: 'AB2635',
        version: 'v2',
        question: 'Your vehicle is stationary. When may you use its horn?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'A',
        additionalInfo:
          "When your vehicle is stationary, only sound the horn if you think there's a risk of danger from another road user. Don't use it just to attract someone's attention. This causes unnecessary noise and could be misleading.",
        options: {
          A: 'When another road user poses a danger',
          B: 'When the road is blocked by queuing traffic',
          C: "When it's used only briefly",
          D: "When signalling that you've just arrived",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2670: {
        id: 'AB2670',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4692.png',
          width: 800,
          height: 527,
          size: 75239,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4692.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'Urban clearways are provided to keep traffic flowing at busy times. You may stop only briefly to set down or pick up passengers. Times of operation will vary from place to place, so always check the signs.',
        options: {
          A: 'You can park on the days and times shown',
          B: 'No parking on the days and times shown',
          C: 'No parking at all from Monday to Friday',
          D: 'End of the urban clearway restrictions',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2671: {
        id: 'AB2671',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4652.png',
          width: 800,
          height: 707,
          size: 56891,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4652.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'You should be careful in these locations, as the road surface is likely to be wet and slippery. There may be a steep drop to the water, and there may not be a barrier along the edge of the road.',
        options: {
          A: 'Quayside or river bank',
          B: 'Steep hill downwards',
          C: 'Uneven road surface',
          D: 'Road liable to flooding',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2674: {
        id: 'AB2674',
        version: 'v2',
        question: 'Which sign means you have priority over oncoming vehicles?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'C',
        additionalInfo:
          "Even though you have priority, be prepared to give way if other drivers don't. This will help to avoid congestion, confrontation or even a collision.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4057.png',
            width: 400,
            height: 329,
            size: 10481,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4057.png?alt=media',
          },
          B: {
            fileName: 'TS4509.png',
            width: 400,
            height: 356,
            size: 14865,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4509.png?alt=media',
          },
          C: {
            fileName: 'TS4699.png',
            width: 400,
            height: 457,
            size: 14346,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4699.png?alt=media',
          },
          D: {
            fileName: 'TS4666.png',
            width: 400,
            height: 400,
            size: 20187,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4666.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2676: {
        id: 'AB2676',
        version: 'v2',
        question:
          'What do the long white lines along the centre of the road mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'IMG_4674.jpg',
          width: 800,
          height: 533,
          size: 86240,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_4674.jpg?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "The centre of the road is usually marked by a broken white line, with lines that are shorter than the gaps. When the lines become longer than the gaps, this is a hazard warning line. Look well ahead for these, especially when you're planning to overtake or turn off.",
        options: {
          A: 'Bus lane',
          B: 'Hazard warning',
          C: 'Give way',
          D: 'Lane marking',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2681: {
        id: 'AB2681',
        version: 'v2',
        question:
          "What's the reason for the hatched area along the centre of this road?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'IMG_1165.jpg',
          width: 800,
          height: 533,
          size: 161650,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1165.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Areas of 'hatched markings' such as these separate traffic streams that could be a danger to each other. They're often seen on bends or where the road becomes narrow. If the area is bordered by a solid white line, you mustn't enter it except in an emergency.",
        options: {
          A: 'It separates traffic flowing in opposite directions',
          B: 'It marks an area to be used by overtaking motorcyclists',
          C: "It's a temporary marking to warn of the roadworks",
          D: 'It separates the two sides of the dual carriageway',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2693: {
        id: 'AB2693',
        version: 'v2',
        question:
          "Other drivers may sometimes flash their headlights at you. What's the official meaning of this signal?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'C',
        additionalInfo:
          "If other drivers flash their headlights, this isn't a signal to show priority. The flashing of headlights has the same meaning as sounding the horn: it's a warning of their presence.",
        options: {
          A: "There's a radar speed trap ahead",
          B: "They're giving way to you",
          C: "They're warning you of their presence",
          D: "There's a fault with your vehicle",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2738: {
        id: 'AB2738',
        version: 'v2',
        question: 'What does this signal mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4072.png',
          width: 800,
          height: 1042,
          size: 46608,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4072.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "The white light shows that trams must stop. The green light shows that other vehicles can go if the way is clear. Trams are being introduced into more cities, so you're likely to come across them and you should learn which signs apply to them.",
        options: {
          A: 'Cars must stop',
          B: 'Trams must stop',
          C: 'Both trams and cars must stop',
          D: 'Both trams and cars can continue',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2756: {
        id: 'AB2756',
        version: 'v2',
        question: 'Where would you find these road markings?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4054.png',
          width: 731,
          height: 703,
          size: 25007,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4054.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'These markings show the direction in which the traffic should go at a mini-roundabout.',
        options: {
          A: 'At a railway crossing',
          B: 'At a mini-roundabout',
          C: 'On a motorway',
          D: 'On a pedestrian crossing',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2757: {
        id: 'AB2757',
        version: 'v2',
        question:
          'A police car is following you. What should you do if the police officer flashes the headlights and points to the left?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'B',
        additionalInfo:
          "You must pull up on the left as soon as it's safe to do so and switch off your engine.",
        options: {
          A: 'Turn left at the next junction',
          B: 'Pull up on the left',
          C: 'Stop immediately',
          D: 'Move over to the left',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2758: {
        id: 'AB2758',
        version: 'v2',
        question:
          'You see this amber traffic light ahead. Which light, or lights, will come on next?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: '85BW0508n1.jpg',
          width: 800,
          height: 1203,
          size: 56089,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW0508n1.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "At junctions controlled by traffic lights, you must stop behind the white line until the lights change to green. A red light, an amber light, and red and amber lights showing together all mean stop.\r\nYou may proceed when the light is green unless your exit road is blocked or pedestrians are crossing in front of you.\r\nIf you're approaching traffic lights that are visible from a distance and the light has been green for some time, be ready to slow down and stop, because the lights are likely to change.",
        options: {
          A: 'Red alone',
          B: 'Red and amber together',
          C: 'Green and amber together',
          D: 'Green alone',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2760: {
        id: 'AB2760',
        version: 'v2',
        question: 'What does it mean if you see this signal on the motorway?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4026.png',
          width: 800,
          height: 241,
          size: 25649,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4026.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "You'll see this sign if there has been an incident ahead and the motorway is closed. You must obey the sign. Make sure that you prepare to leave in good time. \r\nDon't cause drivers to take avoiding action by cutting in at the last moment.",
        options: {
          A: 'Leave the motorway at the next exit',
          B: 'All vehicles use the hard shoulder',
          C: 'Sharp bend to the left ahead',
          D: 'Stop: all lanes ahead closed',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2762: {
        id: 'AB2762',
        version: 'v2',
        question: 'What must you do when you see this sign?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4657.png',
          width: 800,
          height: 800,
          size: 45894,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4657.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "'Stop' signs are situated at junctions where visibility is restricted or where there's heavy traffic. They must be obeyed: you must stop. Look carefully before moving off.",
        options: {
          A: 'Stop only if traffic is approaching',
          B: 'Stop even if the road is clear',
          C: 'Stop only if children are waiting to cross',
          D: 'Stop only if a red light is showing',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2763: {
        id: 'AB2763',
        version: 'v2',
        question: "Which shape is used for a 'give way' sign?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'D',
        additionalInfo:
          "Other warning signs are the same shape and colour, but the 'give way' triangle points downwards. When you see this sign, you must give way to traffic on the road that you're about to enter.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4629a.png',
            width: 400,
            height: 354,
            size: 11741,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4629a.png?alt=media',
          },
          B: {
            fileName: 'TS4668.png',
            width: 400,
            height: 400,
            size: 16415,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4668.png?alt=media',
          },
          C: {
            fileName: 'TS4031.png',
            width: 400,
            height: 400,
            size: 11235,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4031.png?alt=media',
          },
          D: {
            fileName: 'TS4630.png',
            width: 400,
            height: 354,
            size: 11615,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4630.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2764: {
        id: 'AB2764',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4663.png',
          width: 400,
          height: 400,
          size: 37881,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4663.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "When you see this sign, look out for any direction signs and judge whether you need to signal your intentions. Do this in good time so that other road users approaching the roundabout know what you're planning to do.",
        options: {
          A: 'Buses turning',
          B: 'Ring road',
          C: 'Mini-roundabout',
          D: 'Keep right',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2765: {
        id: 'AB2765',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4642.png',
          width: 400,
          height: 354,
          size: 13002,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4642.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'Be prepared for traffic approaching from junctions on either side of you. Try to avoid unnecessary changing of lanes just before the junction.',
        options: {
          A: 'Two-way traffic straight ahead',
          B: 'Two-way traffic crosses a one-way road',
          C: 'Two-way traffic over a bridge',
          D: 'Two-way traffic crosses a two-way road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2766: {
        id: 'AB2766',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4641.png',
          width: 400,
          height: 354,
          size: 13523,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4641.png?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "This sign may be at the end of a dual carriageway or a one-way street. It's there to warn you of oncoming traffic.",
        options: {
          A: 'Two-way traffic crosses a one-way road',
          B: 'Traffic approaching you has priority',
          C: 'Two-way traffic straight ahead',
          D: 'Motorway contraflow system ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2767: {
        id: 'AB2767',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4644.png',
          width: 800,
          height: 707,
          size: 33304,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4644.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "You'll need to slow down. At hump bridges, your view ahead will be restricted and the road will often be narrow. If the bridge is very steep, sound your horn to warn others of your approach. Going over the bridge too fast is highly dangerous to other road users and could even cause your wheels to leave the road, with a resulting loss of control.",
        options: {
          A: 'Hump bridge',
          B: 'Traffic-calming hump',
          C: 'Low bridge',
          D: 'Uneven road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2768: {
        id: 'AB2768',
        version: 'v2',
        question:
          "Which sign informs you that you're coming to a 'no through road'?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'C',
        additionalInfo:
          'This sign is found at the entrance to a road that can only be used for access.',
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4632.png',
            width: 400,
            height: 354,
            size: 12411,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4632.png?alt=media',
          },
          B: {
            fileName: 'TS4682.png',
            width: 400,
            height: 312,
            size: 7366,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4682.png?alt=media',
          },
          C: {
            fileName: 'TS4700.png',
            width: 400,
            height: 398,
            size: 7765,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4700.png?alt=media',
          },
          D: {
            fileName: 'TS4030.png',
            width: 400,
            height: 492,
            size: 40968,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4030.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2769: {
        id: 'AB2769',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4070.png',
          width: 683,
          height: 250,
          size: 16456,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4070.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'To ease the congestion in town centres, some cities and towns provide park-and-ride schemes. These allow you to park in a designated area and ride by bus into the centre. \r\nPark-and-ride schemes are usually cheaper and easier than car parking in the town centre.',
        options: {
          A: 'Direction to park-and-ride car park',
          B: 'No parking for buses or coaches',
          C: 'Direction to bus and coach park',
          D: 'Parking area for cars and coaches',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2771: {
        id: 'AB2771',
        version: 'v2',
        question:
          "What should you do when you're approaching traffic lights that have red and amber showing together?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'C',
        additionalInfo:
          'Be aware that other traffic might still be clearing the junction as you approach. A green light means you may go on, but only if the way is clear.',
        options: {
          A: 'Pass the lights if the road is clear',
          B: "Take care because there's a fault with the lights",
          C: 'Wait for the green light',
          D: 'Stop because the lights are changing to red',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2778: {
        id: 'AB2778',
        version: 'v2',
        question:
          "You've stopped at a railway level crossing. What should you do if the red lights continue to flash after a train has gone by?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'C',
        additionalInfo:
          'You must always obey red flashing stop lights. If a train passes but the lights continue to flash, another train will be passing soon. Cross only when the lights go off and the barriers open.',
        options: {
          A: 'Phone the signal operator',
          B: 'Alert drivers behind you',
          C: 'Wait',
          D: 'Proceed with caution',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2841: {
        id: 'AB2841',
        version: 'v2',
        question:
          "You're in a tunnel and you see this sign. What does it mean?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4687.png',
          width: 800,
          height: 439,
          size: 26040,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4687.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "If you have to leave your vehicle and get out of a tunnel by an emergency exit, do so as quickly as you can. Follow the signs directing you to the nearest exit point. If there are several people using the exit, don't panic but try to leave in a calm and orderly manner.",
        options: {
          A: 'Direction to an emergency pedestrian exit',
          B: 'Beware of pedestrians: no footpath ahead',
          C: 'No access for pedestrians',
          D: 'Beware of pedestrians crossing ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2885: {
        id: 'AB2885',
        version: 'v2',
        question: "Which sign shows that you're entering a one-way system?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'B',
        additionalInfo:
          "If the road has two lanes, you can use either lane and overtake on either side. Use the lane that's more convenient for your destination unless signs or road markings indicate otherwise.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4604.png',
            width: 400,
            height: 400,
            size: 25545,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4604.png?alt=media',
          },
          B: {
            fileName: 'TS4693.png',
            width: 400,
            height: 599,
            size: 13040,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4693.png?alt=media',
          },
          C: {
            fileName: 'TS4666.png',
            width: 400,
            height: 400,
            size: 20187,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4666.png?alt=media',
          },
          D: {
            fileName: 'TS4699.png',
            width: 400,
            height: 457,
            size: 14346,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4699.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2886: {
        id: 'AB2886',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4757.png',
          width: 589,
          height: 337,
          size: 27288,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4757.png?alt=media',
        },
        answer: 'A',
        additionalInfo:
          "Buses and cycles can travel in this lane. In this example, they'll flow in the same direction as other traffic. If it's busy, they may be passing you on the left, so watch out for them. Times on the sign will show the lane's hours of operation; if no times are shown, or there's no sign at all, this means the lane is in operation 24 hours a day. In some areas, other vehicles, such as taxis and motorcycles, are allowed to use bus lanes. The sign will show if this is the case.",
        options: {
          A: 'With-flow bus and cycle lane',
          B: 'Contraflow bus and cycle lane',
          C: 'No buses and cycles allowed',
          D: 'No waiting for buses and cycles',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2887: {
        id: 'AB2887',
        version: 'v2',
        question: 'Which of these signs warns you of a zebra crossing?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'A',
        additionalInfo:
          'Look well ahead and check the pavements and surrounding areas for pedestrians. Look for anyone walking towards the crossing. Check your mirrors for traffic behind, in case you have to slow down or stop.',
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4646.png',
            width: 400,
            height: 354,
            size: 20427,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4646.png?alt=media',
          },
          B: {
            fileName: 'TS4647.png',
            width: 400,
            height: 354,
            size: 20764,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4647.png?alt=media',
          },
          C: {
            fileName: 'TS4648.png',
            width: 400,
            height: 354,
            size: 20441,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4648.png?alt=media',
          },
          D: {
            fileName: 'TS4649.png',
            width: 400,
            height: 354,
            size: 21149,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4649.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2889: {
        id: 'AB2889',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4646.png',
          width: 400,
          height: 354,
          size: 20427,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4646.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'Look well ahead and be ready to stop for any pedestrians crossing, or about to cross, the road. Also check the pavements for anyone who looks like they might step or run into the road.',
        options: {
          A: 'School crossing patrol',
          B: 'No pedestrians allowed',
          C: 'Pedestrian zone - no vehicles',
          D: 'Zebra crossing ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2890: {
        id: 'AB2890',
        version: 'v2',
        question:
          'Which sign means there will be two-way traffic crossing your route ahead?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'B',
        additionalInfo:
          'This sign is found in or at the end of a one-way system. It warns you that traffic will be crossing your path from both directions.',
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4666.png',
            width: 400,
            height: 400,
            size: 20187,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4666.png?alt=media',
          },
          B: {
            fileName: 'TS4642.png',
            width: 400,
            height: 354,
            size: 13002,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4642.png?alt=media',
          },
          C: {
            fileName: 'TS4641.png',
            width: 400,
            height: 354,
            size: 13523,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4641.png?alt=media',
          },
          D: {
            fileName: 'TS4662.png',
            width: 400,
            height: 400,
            size: 34908,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4662.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2892: {
        id: 'AB2892',
        version: 'v2',
        question:
          "Which arm signal tells you that the car you're following is going to pull up?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'C',
        additionalInfo:
          'There may be occasions when drivers need to give an arm signal to confirm their intentions. This could include in bright sunshine, at a complex road layout, when stopping at a pedestrian crossing or when turning right just after passing a parked vehicle. You should understand what each arm signal means. If you give arm signals, make them clear, correct and decisive.',
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'IMG_055.jpg',
            width: 400,
            height: 262,
            size: 12885,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_055.jpg?alt=media',
          },
          B: {
            fileName: 'IMG_055b.jpg',
            width: 400,
            height: 262,
            size: 11750,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_055b.jpg?alt=media',
          },
          C: {
            fileName: 'IMG_055c.jpg',
            width: 400,
            height: 262,
            size: 12771,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_055c.jpg?alt=media',
          },
          D: {
            fileName: 'IMG_055a.jpg',
            width: 400,
            height: 262,
            size: 12431,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_055a.jpg?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2897: {
        id: 'AB2897',
        version: 'v2',
        question: 'Which sign means turn left ahead?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'B',
        additionalInfo:
          'Blue circles tell you what you must do and this sign gives a clear instruction to turn left ahead. You should be looking out for signs at all times and know what they mean.',
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4523.png',
            width: 400,
            height: 400,
            size: 29376,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4523.png?alt=media',
          },
          B: {
            fileName: 'TS4524.png',
            width: 400,
            height: 400,
            size: 30185,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4524.png?alt=media',
          },
          C: {
            fileName: 'TS4525.png',
            width: 400,
            height: 400,
            size: 30943,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4525.png?alt=media',
          },
          D: {
            fileName: 'TS4526.png',
            width: 215,
            height: 215,
            size: 25673,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4526.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2899: {
        id: 'AB2899',
        version: 'v2',
        question:
          "What should you be aware of if you've just passed this sign?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4693.png',
          width: 400,
          height: 599,
          size: 13040,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4693.png?alt=media',
        },
        answer: 'D',
        additionalInfo:
          'In a one-way system, traffic may pass you on either side. Always be aware of all traffic signs and understand their meaning. Look well ahead and react to them in good time.',
        options: {
          A: 'This is a single-track road',
          B: "You can't stop on this road",
          C: 'Only one lane is in use',
          D: 'All traffic is going one way',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2909: {
        id: 'AB2909',
        version: 'v2',
        question:
          "You're approaching traffic lights and the red light is showing. What signal will show next?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'A',
        additionalInfo:
          'If you know which light is going to show next, you can plan your approach accordingly. This can help prevent excessive braking or hesitation at the junction.',
        options: {
          A: 'Red and amber',
          B: 'Green alone',
          C: 'Amber alone',
          D: 'Green and amber',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2912: {
        id: 'AB2912',
        version: 'v2',
        question: 'What does this sign mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'TS4548.png',
          width: 800,
          height: 708,
          size: 50709,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4548.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          'When approaching a tunnel, switch on your dipped headlights. Be aware that your eyes might need to adjust to the sudden darkness. You may need to reduce your speed.',
        options: {
          A: 'Low bridge ahead',
          B: 'Tunnel ahead',
          C: 'Ancient monument ahead',
          D: 'Traffic danger spot ahead',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1235: {
        id: 'BB1235',
        version: 'v2',
        question:
          'What does the white line along the side of the road indicate?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'DSCF2715.jpg',
          width: 800,
          height: 533,
          size: 102426,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FDSCF2715.jpg?alt=media',
        },
        answer: 'A',
        additionalInfo:
          'A continuous white line is used on many roads to indicate the edge of the carriageway. This can be useful when visibility is restricted. The line is discontinued at junctions, lay-bys, and entrances to or exits from private drives.',
        options: {
          A: 'The edge of the carriageway',
          B: 'The approach to a hazard',
          C: 'No parking',
          D: 'No overtaking',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1236: {
        id: 'BB1236',
        version: 'v2',
        question: 'What does this white arrow on the road mean?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'IMG_1887.jpg',
          width: 800,
          height: 533,
          size: 99238,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_1887.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "The arrow indicates the direction in which to pass hatch markings or double white lines. If you're overtaking, you must return to the left-hand side of the road.",
        options: {
          A: 'Entrance on the left',
          B: 'All vehicles turn left',
          C: 'Return to your side of the road',
          D: 'Road bends to the left',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1237: {
        id: 'BB1237',
        version: 'v2',
        question: 'How should you give an arm signal to turn left?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'C',
        additionalInfo:
          'There may be occasions when other road users are unable to see your indicator, such as in bright sunlight or at a busy, complicated junction. In these cases, an arm signal will help others to understand your intentions.',
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'IMG_055a.jpg',
            width: 400,
            height: 262,
            size: 12431,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_055a.jpg?alt=media',
          },
          B: {
            fileName: 'IMG_055c.jpg',
            width: 400,
            height: 262,
            size: 12771,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_055c.jpg?alt=media',
          },
          C: {
            fileName: 'IMG_055.jpg',
            width: 400,
            height: 262,
            size: 12885,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_055.jpg?alt=media',
          },
          D: {
            fileName: 'IMG_055b.jpg',
            width: 400,
            height: 262,
            size: 11750,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FIMG_055b.jpg?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      BB1239: {
        id: 'BB1239',
        version: 'v2',
        question:
          "You're waiting at a T-junction. What should you do if a vehicle is coming from the right, with its left indicator flashing?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: {
          fileName: 'R8013.png',
          width: 550,
          height: 390,
          size: 63721,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FR8013.png?alt=media',
        },
        answer: 'B',
        additionalInfo:
          "Other road users may give misleading signals. When you're waiting at a junction, don't emerge until you're sure of their intentions.",
        options: {
          A: 'Move out and accelerate hard',
          B: 'Wait until the vehicle starts to turn in',
          C: 'Pull out before the vehicle reaches the junction',
          D: 'Move out slowly',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1240: {
        id: 'BB1240',
        version: 'v2',
        question:
          "When may you use hazard warning lights while you're driving?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'B',
        additionalInfo:
          "When there's queuing traffic ahead and you have to slow down or even stop, briefly showing your hazard warning lights will help alert the traffic behind to the hazard.",
        options: {
          A: 'Instead of sounding the horn in a built-up area between 11.30 pm and 7.00 am',
          B: 'On a motorway or unrestricted dual carriageway, to warn of a hazard ahead',
          C: 'On rural routes, after a sign warning of animals',
          D: 'On the approach to toucan crossings, where cyclists are waiting to cross',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1582: {
        id: 'BB1582',
        version: 'v2',
        question:
          'Why should you make sure that your indicators are cancelled after turning at a junction?',
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'B',
        additionalInfo:
          "Leaving your indicators on could confuse other road users and may even lead to a crash. Be aware that if you haven't turned sharply, your indicators may not self-cancel and you'll need to turn them off manually.",
        options: {
          A: 'To avoid flattening the battery',
          B: 'To avoid misleading other road users',
          C: 'To avoid dazzling other road users',
          D: 'To avoid damage to the indicator relay',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1583: {
        id: 'BB1583',
        version: 'v2',
        question:
          "You're driving in busy traffic. You want to pull up just after a junction on the left. When should you signal?",
        questionSetId: 'ROAD-AND-TRAFFIC-SIGNS',
        questionSetTitle: 'Road and traffic signs',
        image: null,
        answer: 'A',
        additionalInfo:
          "You need to signal to let other drivers know your intentions. However, if you indicate too early, they may think you're turning left into the junction. Correct timing of the signal is very important to avoid misleading others.",
        options: {
          A: "As you're passing or just after the junction",
          B: 'Just before you reach the junction',
          C: 'Well before you reach the junction',
          D: 'It would be better not to signal at all',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2436: {
        id: 'AB2436',
        version: 'v2',
        question: 'For how long is an MOT certificate normally valid?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'C',
        additionalInfo:
          'Some garages will remind you that your vehicle is due for its annual MOT test, but not all do. To ensure continuous cover, you may take your vehicle for its MOT up to one month before its existing MOT certificate expires. The expiry date on the new certificate will be 12 months after the expiry date on the old certificate.',
        options: {
          A: 'Three years after the date it was issued',
          B: '10,000 miles',
          C: 'One year after the date it was issued',
          D: '30,000 miles',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2536: {
        id: 'AB2536',
        version: 'v2',
        question: "What's a cover note?",
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'B',
        additionalInfo:
          'Sometimes an insurance company will issue a temporary insurance certificate called a cover note. It gives you the same insurance cover as your certificate but lasts for a limited period, usually one month.',
        options: {
          A: 'A document issued before you receive your driving licence',
          B: 'A document issued before you receive your insurance certificate',
          C: 'A document issued before you receive your registration document',
          D: 'A document issued before you receive your MOT certificate',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2682: {
        id: 'AB2682',
        version: 'v2',
        question:
          "You've just passed your first practical driving test. What will you have to do if you get six penalty points on your licence in the next two years?",
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'B',
        additionalInfo:
          'If you accumulate six or more penalty points within two years of gaining your first full licence, it will be revoked. The six or more points include any gained due to offences you committed before passing your test. If this happens, you may only drive as a learner until you pass both the theory and practical tests again.',
        options: {
          A: 'Retake only your theory test',
          B: 'Retake your theory and practical tests',
          C: 'Retake only your practical test',
          D: 'Reapply for your full licence immediately',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2782: {
        id: 'AB2782',
        version: 'v2',
        question:
          'For how long is a Statutory Off-Road Notification (SORN) valid?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'A',
        additionalInfo:
          'A SORN allows you to keep a vehicle off-road and untaxed. SORN will end when the vehicle is taxed, sold or scrapped.',
        options: {
          A: 'Until the vehicle is taxed, sold or scrapped',
          B: "Until the vehicle is insured and MOT'd",
          C: 'Until the vehicle is repaired or modified',
          D: 'Until the vehicle is used on the road',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2783: {
        id: 'AB2783',
        version: 'v2',
        question: "What's a Statutory Off-Road Notification (SORN)?",
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'C',
        additionalInfo:
          "If you want to keep a vehicle untaxed and off the public road, you must make a SORN. It's an offence not to do so. Your SORN is valid until your vehicle is taxed, sold or scrapped.",
        options: {
          A: "A notification to tell DVSA that a vehicle doesn't have a current MOT",
          B: 'Information kept by the police about the owner of a vehicle',
          C: "A notification to tell DVLA that a vehicle isn't being used on the road",
          D: 'Information held by insurance companies to check a vehicle is insured',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2819: {
        id: 'AB2819',
        version: 'v2',
        question:
          "What's the maximum fine for driving or riding without insurance?",
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'A',
        additionalInfo:
          'Driving or riding without insurance is a serious offence. As well as an unlimited fine, you may be disqualified or incur penalty points.',
        options: {A: 'Unlimited', B: '£500', C: '£1000', D: '£5000'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2838: {
        id: 'AB2838',
        version: 'v2',
        question:
          "Who's legally responsible for ensuring that a vehicle registration certificate (V5C) is updated?",
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'A',
        additionalInfo:
          "It's your legal responsibility to keep the details on your vehicle registration certificate (V5C) up to date. You should tell the licensing authority about any changes. These include your name, address or vehicle details. If you don't do this, you may have problems when you try to sell your vehicle.",
        options: {
          A: 'The registered vehicle keeper',
          B: 'The vehicle manufacturer',
          C: 'Your insurance company',
          D: 'The licensing authority',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2874: {
        id: 'AB2874',
        version: 'v2',
        question:
          'Your insurer will issue you with an insurance certificate. When must you produce this document for inspection?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'C',
        additionalInfo:
          "You must produce a valid insurance certificate when requested by a police officer. If you can't do this immediately, you may be asked to take it to a police station. Other documents you may be asked to produce are your driving licence and the vehicle's MOT certificate.",
        options: {
          A: 'When making a SORN',
          B: 'When buying or selling a vehicle',
          C: 'When a police officer asks you for it',
          D: 'When your vehicle is having an MOT test',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2875: {
        id: 'AB2875',
        version: 'v2',
        question: 'When must your vehicle have valid insurance cover?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'D',
        additionalInfo:
          'Your vehicle must have valid insurance cover before you can tax it. If required, it will also need to have a valid MOT certificate. You can tax your vehicle online, by phone or at certain post offices.',
        options: {
          A: 'Before you can make a SORN',
          B: 'Before you can sell the vehicle',
          C: 'Before you can scrap the vehicle',
          D: 'Before you can tax the vehicle',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2877: {
        id: 'AB2877',
        version: 'v2',
        question:
          'What do you need before you can legally use a motor vehicle on the road?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'A',
        additionalInfo:
          "Using a motor vehicle on the road illegally carries a heavy fine and can lead to penalty points on your driving licence. You must\r\n• hold a valid driving licence for the class of vehicle you're using\r\n• be insured to drive the vehicle.\r\nIf required, the vehicle must have a current MOT test certificate and be taxed for use on the road.",
        options: {
          A: 'An appropriate driving licence',
          B: 'Breakdown cover',
          C: 'Proof of your identity',
          D: 'A vehicle handbook',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2878: {
        id: 'AB2878',
        version: 'v2',
        question:
          'What must you have when you apply to renew your vehicle tax?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'A',
        additionalInfo:
          'You can renew your vehicle tax online, at post offices and by phone using the DVLA vehicle tax service. When applying, make sure you have all the relevant valid documents, including a valid MOT test certificate where applicable.',
        options: {
          A: 'Valid insurance',
          B: "The vehicle's chassis number",
          C: 'The handbook',
          D: 'A valid driving licence',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2901: {
        id: 'AB2901',
        version: 'v2',
        question:
          "A police officer asks to see your documents. You don't have them with you. How many days do you have to produce them at a police station?",
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'B',
        additionalInfo:
          "You don't have to carry your vehicle's documents wherever you go. If a police officer asks to see them and you don't have them with you, you may be asked to produce them at a police station within 7 days.",
        options: {A: '5 days', B: '7 days', C: '14 days', D: '21 days'},
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2948: {
        id: 'AB2948',
        version: 'v2',
        question:
          'When should you update your vehicle registration certificate?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'B',
        additionalInfo:
          "As the registered keeper of a vehicle, it's up to you to inform DVLA of any changes in your details; for example, your name or address. You do this by completing and sending off the relevant section of the registration certificate.",
        options: {
          A: 'When you pass your driving test',
          B: 'When you move house',
          C: 'When your vehicle needs an MOT',
          D: 'When you have a collision',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1243: {
        id: 'BB1243',
        version: 'v2',
        question:
          "What must you check before you drive someone else's vehicle?",
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'C',
        additionalInfo:
          "Driving a vehicle without insurance cover is illegal, so be sure that, whoever's car you drive, you're insured - whether on their policy or on your own. If you need to take out insurance, it's worth comparing several quotes before you decide which insurance provider best meets your needs.",
        options: {
          A: 'That the vehicle owner has third-party insurance cover',
          B: 'That your own vehicle has insurance cover',
          C: 'That the vehicle is insured for your use',
          D: 'That the insurance documents are in the vehicle',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1245: {
        id: 'BB1245',
        version: 'v2',
        question:
          'Your car needs to pass an MOT test. What may be invalidated if you drive the car without a current MOT certificate?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'B',
        additionalInfo:
          "If your vehicle requires an MOT certificate, it's illegal to drive it without one and your insurance may be invalid if you do so. The only exceptions are that you may drive to a pre-arranged MOT test appointment, or to a garage for repairs required for the test.",
        options: {
          A: 'The vehicle service record',
          B: 'The vehicle insurance',
          C: 'The vehicle tax',
          D: 'The vehicle registration document',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1404: {
        id: 'BB1404',
        version: 'v2',
        question:
          'What legal requirement must be met by a newly qualified driver?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'D',
        additionalInfo:
          "It's your responsibility to make sure you're properly insured for the vehicle you're driving. This is the case regardless of whether you're a newly qualified driver or one with more experience.",
        options: {
          A: 'They must display green L plates',
          B: 'They must have a new photograph taken for their full licence',
          C: 'They must be accompanied on their first motorway journey',
          D: 'They must have valid motor insurance',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1506: {
        id: 'BB1506',
        version: 'v2',
        question: "What's covered by third-party insurance?",
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'D',
        additionalInfo:
          "Third-party insurance doesn't cover damage to your own vehicle or injury to yourself. If you have a crash and your vehicle is damaged, you might have to carry out the repairs at your own expense.",
        options: {
          A: 'Damage to your vehicle',
          B: 'Fire damage to your vehicle',
          C: 'Flood damage to your vehicle',
          D: 'Damage to other vehicles',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1533: {
        id: 'BB1533',
        version: 'v2',
        question: "Who's responsible for paying the vehicle tax?",
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'B',
        additionalInfo:
          'The registered keeper of the vehicle is responsible for paying the vehicle tax or making a Statutory Off-Road Notification (SORN) if the vehicle is to be kept untaxed and off the road.',
        options: {
          A: 'The driver of the vehicle',
          B: 'The registered keeper of the vehicle',
          C: 'The car dealer',
          D: 'The Driver and Vehicle Licensing Agency (DVLA)',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1537: {
        id: 'BB1537',
        version: 'v2',
        question:
          'What information is found on a vehicle registration document?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'A',
        additionalInfo:
          "Every vehicle used on the road has a registration document. This shows the vehicle's details, including date of first registration, registration number, registered keeper, previous keeper, make of vehicle, engine size, chassis number, year of manufacture and colour.",
        options: {
          A: 'The registered keeper',
          B: 'The type of insurance cover',
          C: 'The service history details',
          D: 'The date of the MOT',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1538: {
        id: 'BB1538',
        version: 'v2',
        question:
          'When must you contact the Driver and Vehicle Licensing Agency (DVLA)?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'B',
        additionalInfo:
          "DVLA needs to keep its records up to date. It sends out a reminder when a vehicle's tax is due for renewal. To do this, it needs to know the name and address of the registered keeper. Every vehicle in the country is registered, so it's possible to trace its history.",
        options: {
          A: 'When you get a parking ticket',
          B: 'When you change your vehicle',
          C: 'When you use your vehicle for work',
          D: "When your vehicle's insurance is due",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1539: {
        id: 'BB1539',
        version: 'v2',
        question:
          'What circumstances require you to notify the Driver and Vehicle Licensing Agency (DVLA)?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'A',
        additionalInfo:
          'DVLA holds the records of all vehicles, drivers and riders in Great Britain and Northern Ireland. They need to know if you have a medical condition that might affect your ability to drive safely. You must tell them if your health deteriorates and you become unfit to drive.',
        options: {
          A: 'When your health affects your driving',
          B: 'When you have to work abroad',
          C: 'When you lend your vehicle to someone',
          D: 'When your vehicle needs an MOT certificate',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1599: {
        id: 'BB1599',
        version: 'v2',
        question: 'When could the cost of your insurance be reduced?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'D',
        additionalInfo:
          "The cost of insurance varies with your age and how long you've been driving. Usually, the younger you are, the more expensive it is, especially if you're under 25.\r\nPass Plus provides additional training to newly qualified drivers. The scheme is recognised by many insurance companies, and taking this extra training could give you reduced insurance premiums, as well as improving your skills and experience.",
        options: {
          A: "When you're under 25 years old",
          B: "When you don't wear glasses",
          C: 'When you pass the driving test first time',
          D: 'When you complete the Pass Plus scheme',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1696: {
        id: 'BB1696',
        version: 'v2',
        question:
          'In order to supervise a learner driver you need to have held a full driving licence for the same category of vehicle, for at least three years. What other requirement must you meet?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'B',
        additionalInfo:
          'Learner drivers benefit by combining professional driving lessons with private practice. However, you need to be at least 21 years old and have held your driving licence for at least 3 years before you can supervise a learner driver.',
        options: {
          A: 'To have a car with dual controls',
          B: 'To be at least 21 years old',
          C: 'To be an approved driving instructor',
          D: 'To hold an advanced driving certificate',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1697: {
        id: 'BB1697',
        version: 'v2',
        question:
          'Your car requires an MOT certificate. When is it legal to drive it without an MOT certificate?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'D',
        additionalInfo:
          'When a car is three years old (four years old in Northern Ireland), it must pass an MOT test and have a valid MOT certificate before it can be used on the road. Exceptionally, you may \r\n•  drive to a pre-arranged test appointment or to a garage for repairs required for the test\r\n•  drive vehicles that are more than 40 years old without an MOT test, but they must be in a roadworthy condition before being used on the road. See GOV.UK for more details.',
        options: {
          A: 'Up to seven days after the old certificate has run out',
          B: 'When driving to an MOT centre to arrange an appointment',
          C: "When driving the car with the owner's permission",
          D: 'When driving to an appointment at an MOT centre',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1698: {
        id: 'BB1698',
        version: 'v2',
        question: 'When will a new car need its first MOT test?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'B',
        additionalInfo:
          "The vehicle you drive must be roadworthy and in good condition. If it's over three years old, it must pass an MOT test to remain in use on the road (unless it's exempt from the MOT test - see GOV.UK).",
        options: {
          A: "When it's one year old",
          B: "When it's three years old",
          C: "When it's five years old",
          D: "When it's seven years old",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1713: {
        id: 'BB1713',
        version: 'v2',
        question: 'What does third-party insurance cover?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'B',
        additionalInfo:
          "Third-party insurance cover is usually cheaper than comprehensive cover. However, it doesn't cover any damage caused to your own vehicle or property. It only covers damage and injury you cause to others.",
        options: {
          A: 'Damage to your vehicle',
          B: 'Damage to other vehicles',
          C: 'Injury to yourself',
          D: 'All damage and injury',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1714: {
        id: 'BB1714',
        version: 'v2',
        question:
          "What's the legal minimum insurance cover you must have to drive on public roads?",
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'C',
        additionalInfo:
          "The minimum insurance required by law is third-party cover. This covers your liability to others involved in a collision but not damage to your vehicle. Basic third-party insurance also won't cover theft or fire damage. Ask your insurance company for advice on the best cover for you and make sure that you read the policy carefully.",
        options: {
          A: 'Third party, fire and theft',
          B: 'Comprehensive',
          C: 'Third party only',
          D: 'Personal injury cover',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1715: {
        id: 'BB1715',
        version: 'v2',
        question:
          'What does it mean if your insurance policy has an excess of £500?',
        questionSetId: 'ESSENTIAL-DOCUMENTS',
        questionSetTitle: 'Essential documents',
        image: null,
        answer: 'D',
        additionalInfo:
          "Having an excess on your policy will help to keep the premium down. However, if you make a claim, you'll have to pay the excess yourself - in this case, £500.",
        options: {
          A: 'The insurance company will pay the first £500 of any claim',
          B: "You'll be paid £500 if you don't claim within one year",
          C: "Your vehicle is insured for a value of £500 if it's stolen",
          D: "You'll have to pay the first £500 of the cost of any claim",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2618: {
        id: 'AB2618',
        version: 'v2',
        question: 'When are you allowed to use hazard warning lights?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'A',
        additionalInfo:
          "You mustn't use hazard warning lights while moving, except to warn traffic behind when you slow suddenly on a motorway or unrestricted dual carriageway.\r\nNever use hazard warning lights to excuse dangerous or illegal parking.",
        options: {
          A: 'When stopped and temporarily obstructing traffic',
          B: 'When travelling during darkness without headlights',
          C: 'When parked on double yellow lines to visit a shop',
          D: "When travelling slowly because you're lost",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2690: {
        id: 'AB2690',
        version: 'v2',
        question:
          "What should you do if you have to stop while you're going through a congested tunnel?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'C',
        additionalInfo:
          "It's important to keep a safe distance from the vehicle in front at all times. This still applies in congested tunnels, even if you're moving very slowly or have stopped. If the vehicle in front breaks down, you may need room to manoeuvre past it.",
        options: {
          A: 'Pull up very close to the vehicle in front to save space',
          B: "Ignore any message signs, as they're never up to date",
          C: 'Keep a safe distance from the vehicle in front',
          D: 'Make a U-turn and find another route',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2855: {
        id: 'AB2855',
        version: 'v2',
        question:
          'You arrive at the scene of a crash where someone is bleeding heavily from a wound in their arm. Nothing is embedded in the wound. What could you do to help?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'D',
        additionalInfo:
          "If possible, lay the casualty down. Protect yourself from exposure to blood and, when you're sure there's nothing in the wound, apply firm pressure using clean material.",
        options: {
          A: 'Walk them around and keep them talking',
          B: 'Dab the wound',
          C: 'Get them a drink',
          D: 'Apply pressure over the wound',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2862: {
        id: 'AB2862',
        version: 'v2',
        question:
          "You're at an incident. What could you do to help an unconscious casualty?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "If a casualty is unconscious, you need to check that they're breathing normally. Look for chest movements, look and listen for breathing, and feel for breath on your cheek.",
        options: {
          A: 'Take photographs of the scene',
          B: "Check that they're breathing normally",
          C: 'Move them to somewhere more comfortable',
          D: 'Splash their face with cool water',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2865: {
        id: 'AB2865',
        version: 'v2',
        question:
          "A casualty isn't breathing normally and needs CPR. At what rate should you press down and release on the centre of their chest?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "If a casualty isn't breathing normally, cardiopulmonary resuscitation (CPR) may be needed to maintain circulation. Place two hands on the centre of the chest and press down hard and fast -  around 5-6 centimetres and about twice a second.",
        options: {
          A: '10 times per minute',
          B: '120 times per minute',
          C: '60 times per minute',
          D: '240 times per minute',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2867: {
        id: 'AB2867',
        version: 'v2',
        question:
          'Following a collision, a person has been injured. What would be a warning sign for shock?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'D',
        additionalInfo:
          'The effects of shock may not be immediately obvious. Warning signs to look for include\r\n•  a rapid pulse\r\n•  sweating\r\n•  pale grey skin\r\n•  rapid shallow breathing.',
        options: {
          A: 'Flushed complexion',
          B: 'Warm dry skin',
          C: 'Slow pulse',
          D: 'Rapid shallow breathing',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2870: {
        id: 'AB2870',
        version: 'v2',
        question:
          "An injured person has been placed in the recovery position. They're unconscious but breathing normally. What else should be done?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'D',
        additionalInfo:
          "After a casualty has been placed in the recovery position, make sure their airway remains open and monitor their condition until medical help arrives. Where possible, don't move a casualty unless there's further danger.",
        options: {
          A: 'Press firmly between their shoulders',
          B: 'Place their arms by their side',
          C: 'Give them a hot sweet drink',
          D: 'Check their airway remains open',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2879: {
        id: 'AB2879',
        version: 'v2',
        question:
          "An injured motorcyclist is lying unconscious in the road. The traffic has stopped and there's no further danger. What could you do to help?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "If someone has been injured, the sooner proper medical attention is given the better. Ask someone to phone for help or do it yourself. An injured person should only be moved if they're in further danger. An injured motorcyclist's helmet shouldn't be removed unless it's essential.",
        options: {
          A: 'Remove their safety helmet',
          B: 'Seek medical assistance',
          C: 'Move the person off the road',
          D: 'Remove their leather jacket',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2891: {
        id: 'AB2891',
        version: 'v2',
        question:
          'What should you do if you see a large box fall from a lorry onto the motorway?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'A',
        additionalInfo:
          "Lorry drivers can be unaware of objects falling from their vehicles. If you see something fall onto a motorway, look to see if the driver pulls over. If they don't stop, don't attempt to retrieve the object yourself. Pull onto the hard shoulder near an emergency telephone and report the hazard.",
        options: {
          A: 'Go to the next emergency telephone and report the hazard',
          B: "Catch up with the lorry and try to get the driver's attention",
          C: 'Stop close to the box until the police arrive',
          D: 'Pull over to the hard shoulder, then remove the box',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2900: {
        id: 'AB2900',
        version: 'v2',
        question:
          "You're going through a long tunnel. What will warn you of congestion or an incident ahead?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'C',
        additionalInfo:
          'Follow the instructions given by the signs or by tunnel officials. In congested tunnels, a minor incident can soon turn into a major one, with serious or even fatal results.',
        options: {
          A: 'Hazard warning lines',
          B: 'Other drivers flashing their lights',
          C: 'Variable message signs',
          D: 'Areas with hatch markings',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2905: {
        id: 'AB2905',
        version: 'v2',
        question:
          "An adult casualty isn't breathing. To maintain circulation, CPR should be given. What's the correct depth to press down on their chest?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "An adult casualty isn't breathing normally. To maintain circulation, place two hands on the centre of the chest. Then press down hard and fast - around 5-6 centimetres and about twice a second.",
        options: {
          A: '1 to 2 centimetres',
          B: '5 to 6 centimetres',
          C: '10 to 15 centimetres',
          D: '15 to 20 centimetres',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2914: {
        id: 'AB2914',
        version: 'v2',
        question:
          "You're the first person to arrive at an incident where people are badly injured. You've switched on your hazard warning lights and checked all engines are stopped. What else should you do?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'A',
        additionalInfo:
          "If you're the first to arrive at a crash scene, the first concerns are the risk of further collision and fire. Ensuring that vehicle engines are switched off will reduce the risk of fire. Use hazard warning lights so that other traffic knows there's a need for caution. Make sure the emergency services are contacted; don't assume it's already been done.",
        options: {
          A: 'Make sure that an ambulance has been called',
          B: 'Stop other cars and ask the drivers for help',
          C: 'Try and get people who are injured to drink something',
          D: 'Move the people who are injured clear of their vehicles',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2915: {
        id: 'AB2915',
        version: 'v2',
        question:
          'You arrive at the scene of a motorcycle crash. The rider is injured. When should their helmet be removed?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'A',
        additionalInfo:
          "Don't remove a motorcyclist's helmet unless it's essential. Remember they may be suffering from shock. Don't give them anything to eat or drink, but do reassure them confidently.",
        options: {
          A: "Only when it's essential",
          B: 'Always straight away',
          C: 'Only when the motorcyclist asks',
          D: "Always, unless they're in shock",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2917: {
        id: 'AB2917',
        version: 'v2',
        question:
          "You arrive at an incident. There's no danger from fire or further collisions and the emergency services have been called. What's your first priority when attending to an unconscious motorcyclist?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'A',
        additionalInfo:
          "At the scene of an incident, always be aware of danger from further collisions or fire. The first priority when dealing with an unconscious person is to ensure they can breathe. This may involve clearing their airway if you can see an obstruction or if they're having difficulty breathing.",
        options: {
          A: "Check whether they're breathing normally",
          B: "Check whether they're bleeding",
          C: 'Check whether they have any broken bones',
          D: 'Check whether they have any bruising',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2920: {
        id: 'AB2920',
        version: 'v2',
        question:
          'At an incident, someone is unconscious and you want to help. What would be the first thing to check?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'D',
        additionalInfo:
          'Remember this procedure by saying DR ABC. This stands for Danger, Response, Airway, Breathing, Circulation. Give whatever first aid you can and stay with the injured person until a medical professional takes over.',
        options: {
          A: 'Whether their vehicle is insured',
          B: 'Whether they have any allergies',
          C: "Whether they're comfortable",
          D: 'Whether their airway is open',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2921: {
        id: 'AB2921',
        version: 'v2',
        question: 'What could you do to help injured people at an incident?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'A',
        additionalInfo:
          "There are a number of things you can do to help, even without expert training. Be aware of further danger from other traffic and fire; make sure the area is safe. People may be in shock. Don't give them anything to eat or drink. Keep them warm and comfortable and reassure them. Don't move injured people unless there's a risk of further danger.",
        options: {
          A: 'Keep them warm and comfortable',
          B: 'Give them something to eat',
          C: 'Keep them on the move by walking them around',
          D: 'Give them a warm drink',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2924: {
        id: 'AB2924',
        version: 'v2',
        question:
          "There's been a collision. How can you help a driver who's suffering from shock?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "A casualty suffering from shock may have injuries that aren't immediately obvious. Call the emergency services, then stay with the person in shock, offering reassurance until the experts arrive.",
        options: {
          A: 'Give them a drink',
          B: 'Reassure them confidently',
          C: 'Ask who caused the incident',
          D: 'Offer them a cigarette',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2926: {
        id: 'AB2926',
        version: 'v2',
        question:
          "You arrive at the scene of a motorcycle crash. No other vehicle is involved. The rider is unconscious and lying in the middle of the road. What's the first thing you should do at the scene?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "The motorcyclist is in an extremely vulnerable position, exposed to further danger from traffic. Approaching vehicles need advance warning in order to slow down and safely take avoiding action or stop. Don't put yourself or anyone else at risk. Use the hazard warning lights on your vehicle to alert other road users to the danger.",
        options: {
          A: 'Move the rider out of the road',
          B: 'Warn other traffic',
          C: 'Clear the road of debris',
          D: 'Give the rider reassurance',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2927: {
        id: 'AB2927',
        version: 'v2',
        question:
          "At an incident, how could you help a small child who isn't breathing?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "If a young child has stopped breathing, first check that their airway is open and then begin CPR. With a young child, you may only need to use one hand and you shouldn't press down as far as you would with an adult. Continue the procedure until the child is breathing again or until a medical professional takes over.",
        options: {
          A: "Find their parents and explain what's happening",
          B: 'Open their airway and begin CPR',
          C: 'Put them in the recovery position and slap their back',
          D: 'Talk to them confidently until an ambulance arrives',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2928: {
        id: 'AB2928',
        version: 'v2',
        question:
          "At an incident, a casualty isn't breathing. What should you do while helping them to start breathing again?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'D',
        additionalInfo:
          "It's important to ensure that the airway is open before you start CPR. To open the casualty's airway, place your fingers under their chin and lift it forward.",
        options: {
          A: 'Put their arms across their chest',
          B: 'Shake them firmly',
          C: 'Roll them onto their side',
          D: 'Open their airway',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2930: {
        id: 'AB2930',
        version: 'v2',
        question:
          'At an incident, someone is suffering from severe burns. How could you help them?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'D',
        additionalInfo:
          "Your priority is to cool the burns with clean, cool water. Its coolness will help take the heat out of the burns and relieve the pain. Keep the wound doused for at least 20 minutes. If blisters appear, don't attempt to burst them, as this could lead to infection.",
        options: {
          A: 'Apply lotions to the injury',
          B: 'Burst any blisters',
          C: 'Remove anything sticking to the burns',
          D: 'Douse the burns with clean, cool water',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2931: {
        id: 'AB2931',
        version: 'v2',
        question:
          "You arrive at an incident. A pedestrian is bleeding heavily from a leg wound. The leg isn't broken and there's nothing in the wound. How could you help?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'D',
        additionalInfo:
          'You should protect yourself from exposure to blood, and then apply firm pressure over the wound to stem the flow of blood. As soon as practical, fasten a pad to the wound with a bandage or length of cloth. Use the cleanest material available.',
        options: {
          A: 'Dab the wound to stop the bleeding',
          B: "Keep the casualty's legs flat on the ground",
          C: 'Give them a warm drink',
          D: 'Apply firm pressure over the wound',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2932: {
        id: 'AB2932',
        version: 'v2',
        question:
          'At an incident, a casualty is unconscious but breathing. When should you move them?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'C',
        additionalInfo:
          "Don't move a casualty unless there's further danger; for example, from other traffic or fire. They may have unseen or internal injuries. Moving them unnecessarily could cause further injury. Don't remove a motorcyclist's helmet unless it's essential.",
        options: {
          A: 'When an ambulance is on its way',
          B: 'When bystanders tell you to move them',
          C: "When there's a risk of further danger",
          D: 'When bystanders offer to help you',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2934: {
        id: 'AB2934',
        version: 'v2',
        question:
          "At an incident, it's important to look after any casualties. What should you do with them when the area is safe?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'D',
        additionalInfo:
          "When the area is safe and there's no danger from other traffic or fire, it's better not to move casualties. Moving them may cause further injury.",
        options: {
          A: 'Move them away from the vehicles',
          B: 'Ask them how it happened',
          C: 'Give them something to eat',
          D: 'Keep them where they are',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2935: {
        id: 'AB2935',
        version: 'v2',
        question: 'Which sign shows that a tanker is carrying dangerous goods?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "Tankers will display a hazard warning plate on the side and rear of the vehicle. Details of hazard warning symbols are given in The Highway Code. If a tanker is involved in a collision, you may need to report the tanker's hazard labelling to the emergency services.",
        options: {A: '', B: '', C: '', D: ''},
        optionImages: {
          A: {
            fileName: 'TS4055.png',
            width: 400,
            height: 168,
            size: 10249,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4055.png?alt=media',
          },
          B: {
            fileName: 'TS4051.png',
            width: 242,
            height: 265,
            size: 21379,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4051.png?alt=media',
          },
          C: {
            fileName: 'TS4627.png',
            width: 400,
            height: 185,
            size: 6941,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4627.png?alt=media',
          },
          D: {
            fileName: 'TS4517.png',
            width: 400,
            height: 354,
            size: 16017,
            src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2FTS4517.png?alt=media',
          },
        },
        questionType: 'multiple-choice',
      },
      AB2936: {
        id: 'AB2936',
        version: 'v2',
        question:
          "Which document may the police ask you to produce after you've been involved in a collision?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "You must stop if you've been involved in a collision that results in injury or damage. The police may ask to see your driving licence and insurance details at the time or later at a police station.",
        options: {
          A: 'Your vehicle registration document',
          B: 'Your driving licence',
          C: 'Your theory test certificate',
          D: 'Your vehicle service record',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2937: {
        id: 'AB2937',
        version: 'v2',
        question:
          'After a collision, someone is unconscious in their vehicle. When should you call the emergency services?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "It's important to make sure that the emergency services arrive as soon as possible. When a person is unconscious, they could have serious injuries that aren't immediately obvious.",
        options: {
          A: 'Only as a last resort',
          B: 'As soon as possible',
          C: "After you've woken them up",
          D: 'After checking for broken bones',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2945: {
        id: 'AB2945',
        version: 'v2',
        question:
          "A collision has just happened. An injured person is lying in a busy road. What's the first thing you should do?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "The most immediate danger is further collisions and fire. You could warn other traffic by switching on hazard warning lights, displaying an advance warning triangle or sign (but not on a motorway), or by any other means that doesn't put you or others at risk.",
        options: {
          A: 'Treat the person for shock',
          B: 'Warn other traffic',
          C: 'Place them in the recovery position',
          D: 'Make sure the injured person is kept warm',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2946: {
        id: 'AB2946',
        version: 'v2',
        question:
          'At an incident, how could you help a casualty who has stopped breathing?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          'The DR ABC code has been devised by medical experts to give the best outcome until the emergency services arrive and take care of casualties.',
        options: {
          A: 'Keep their head tilted forwards as far as possible',
          B: 'Follow the DR ABC code',
          C: 'Raise their legs to help with circulation',
          D: 'Try to give them something to drink',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2947: {
        id: 'AB2947',
        version: 'v2',
        question:
          "You're at the scene of an incident. How could you help someone who's suffering from shock?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'A',
        additionalInfo:
          "If someone is suffering from shock, try to keep them warm and as comfortable as you can. Don't give them anything to eat or drink but reassure them confidently and try not to leave them alone.",
        options: {
          A: 'Reassure them confidently',
          B: 'Offer them a cigarette',
          C: 'Give them a warm drink',
          D: 'Offer them some food',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2949: {
        id: 'AB2949',
        version: 'v2',
        question:
          "There's been a collision. A motorcyclist is lying injured and unconscious. Why should you only remove their helmet if it's essential?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "When someone is injured, any movement that isn't absolutely necessary should be avoided, since it could make the injuries worse. Unless it's essential to remove a motorcyclist's helmet, it's generally safer to leave it in place.",
        options: {
          A: 'They might not want you to remove it',
          B: 'Removing it could make any injuries worse',
          C: 'Removing it could let them get cold',
          D: 'You could scratch the helmet as you remove it',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB4422: {
        id: 'AB4422',
        version: 'v2',
        question:
          "You're on a motorway. When can you use hazard warning lights?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "Briefly using your hazard warning lights will warn the traffic behind you that there's a hazard ahead. Turn them off again when following drivers have seen and responded to your signal.",
        options: {
          A: 'When a vehicle is following too closely',
          B: 'When you slow down quickly because of danger ahead',
          C: "When you're being towed by another vehicle",
          D: "When you're riding on the hard shoulder",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1252: {
        id: 'BB1252',
        version: 'v2',
        question:
          "You've broken down on a two-way road. You have a warning triangle. At least how far from your vehicle should you place the warning triangle?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: {
          fileName: '85BW1961.jpg',
          width: 800,
          height: 532,
          size: 64946,
          src: 'https://firebasestorage.googleapis.com/v0/b/driving-theory-content-dev/o/2022-02-23T18:58:40.871Z%2Fquestions%2Fimages%2F85BW1961.jpg?alt=media',
        },
        answer: 'C',
        additionalInfo:
          "Advance warning triangles fold flat and don't take up much room. Use one to warn other road users if your vehicle has broken down or if there has been an incident. Place it at least 45 metres (147 feet) behind your vehicle (or the incident), on the same side of the road or verge. Place it further back if the scene is hidden by, for example, a bend, hill or dip in the road. Don't use warning triangles on motorways.",
        options: {
          A: '5 metres (16 feet)',
          B: '25 metres (82 feet)',
          C: '45 metres (147 feet)',
          D: '100 metres (328 feet)',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1254: {
        id: 'BB1254',
        version: 'v2',
        question:
          "Your car breaks down on a level crossing. What's the first thing you should do?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "If your vehicle breaks down on a level crossing, your first priority is to get everyone out of the vehicle and clear of the crossing. Then use the railway telephone, if there is one, to tell the signal operator. If you have time before the train arrives, move the vehicle clear of the crossing, but only do this if the alarm signals aren't activated.",
        options: {
          A: "Tell drivers behind what's happened",
          B: 'Leave your vehicle and get everyone clear',
          C: 'Walk down the track and signal the next train',
          D: "Stay in your car until you're told to move",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1256: {
        id: 'BB1256',
        version: 'v2',
        question: "What should you do if a tyre bursts while you're driving?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'C',
        additionalInfo:
          "A tyre bursting can lead to a loss of control, especially if you're travelling at high speed. Using the correct procedure should help you to stop the vehicle safely.",
        options: {
          A: 'Pull on the parking brake',
          B: 'Brake as quickly as possible',
          C: 'Pull up slowly at the side of the road',
          D: 'Continue on at a normal speed',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1259: {
        id: 'BB1259',
        version: 'v2',
        question:
          'What should you do if your vehicle has a puncture on a motorway?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'C',
        additionalInfo:
          "Pull up on the hard shoulder or in an emergency refuge area and call for assistance. \r\nDon't attempt to repair your vehicle while it's on the hard shoulder, because of the risk posed by traffic passing at high speeds.",
        options: {
          A: 'Drive slowly to the next service area to get assistance',
          B: 'Pull up on the hard shoulder or in an emergency refuge area. Change the wheel as quickly as possible',
          C: 'Pull up on the hard shoulder or in an emergency refuge area and call for assistance',
          D: 'Switch on your hazard warning lights. Stop in your lane',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1363: {
        id: 'BB1363',
        version: 'v2',
        question:
          "Your vehicle has stalled in the middle of a level crossing. What should you do if the warning bells start to ring while you're trying to restart the engine?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'A',
        additionalInfo:
          'If the warning bells ring, leave the vehicle and get any passengers well clear of the crossing immediately.',
        options: {
          A: 'Get out of the car and clear of the crossing',
          B: 'Run down the track to warn the signal operator',
          C: 'Carry on trying to restart the engine',
          D: 'Push the vehicle clear of the crossing',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1611: {
        id: 'BB1611',
        version: 'v2',
        question: 'What should you do before driving into a tunnel?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "If you're wearing sunglasses, you should remove them before driving into a tunnel. If you don't, your vision will be restricted, even in tunnels that appear to be well lit.",
        options: {
          A: 'Switch off your radio',
          B: 'Take off your sunglasses',
          C: 'Close your sunroof',
          D: 'Switch on your windscreen wipers',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1612: {
        id: 'BB1612',
        version: 'v2',
        question:
          "Which lights should you use when you're driving in a tunnel?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'C',
        additionalInfo:
          "Before entering a tunnel, you should switch on your dipped headlights, as this will allow you to see and be seen. In many tunnels, it's a legal requirement. \r\nDon't wear sunglasses while you're driving in a tunnel.",
        options: {
          A: 'Sidelights',
          B: 'Front spotlights',
          C: 'Dipped headlights',
          D: 'Rear fog lights',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1623: {
        id: 'BB1623',
        version: 'v2',
        question:
          'What should you do to reduce the risk of your vehicle catching fire?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "The fuel in your vehicle can be a dangerous fire hazard. If you smell fuel, check out where it's coming from. Never\r\n• use a naked flame near the vehicle if you can smell fuel\r\n• smoke when refuelling your vehicle.",
        options: {
          A: 'Keep water levels above maximum',
          B: 'Check out any strong smell of fuel',
          C: 'Avoid driving with a full tank of fuel',
          D: 'Use fuel additives',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1649: {
        id: 'BB1649',
        version: 'v2',
        question:
          "You're driving on the motorway. What should you do if luggage falls from your vehicle?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'A',
        additionalInfo:
          "If any object falls onto the motorway carriageway from your vehicle, pull onto the hard shoulder near an emergency telephone and call for assistance. Don't stop on the carriageway or attempt to retrieve anything.",
        options: {
          A: 'Stop at the next emergency telephone and report the incident',
          B: 'Stop on the motorway and switch on hazard warning lights while you pick it up',
          C: 'Walk back up the motorway to pick it up',
          D: 'Pull up on the hard shoulder and wave traffic down',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1700: {
        id: 'BB1700',
        version: 'v2',
        question:
          "What should you do if an instrument panel warning light comes on while you're driving?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'D',
        additionalInfo:
          "Make sure you know what the different warning lights mean. An illuminated warning light could mean that your car is unsafe to drive.  If you aren't sure about the problem, get a qualified mechanic to check it.",
        options: {
          A: 'Continue if the engine sounds all right',
          B: "Hope that it's just a temporary electrical fault",
          C: "Deal with the problem when there's more time",
          D: 'Check out the problem quickly and safely',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1704: {
        id: 'BB1704',
        version: 'v2',
        question: 'What should you do if your vehicle breaks down in a tunnel?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'D',
        additionalInfo:
          'A broken-down vehicle in a tunnel can cause serious congestion and danger to other road users. If your vehicle breaks down, get help without delay. Switch on your hazard warning lights, then go to an emergency telephone to call for help.',
        options: {
          A: 'Stay in your vehicle and wait for the police',
          B: 'Stand in the lane behind your vehicle to warn others',
          C: 'Stand in front of your vehicle to warn oncoming drivers',
          D: 'Switch on hazard warning lights, then go and call for help',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1721: {
        id: 'BB1721',
        version: 'v2',
        question:
          "What should you do if your vehicle catches fire while you're driving through a tunnel?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'D',
        additionalInfo:
          "If it's possible, and you can do so without causing further danger, it may be safer to drive a vehicle that's on fire out of a tunnel. The greatest danger in a tunnel fire is smoke and suffocation.",
        options: {
          A: 'Leave it where it is, with the engine running',
          B: 'Pull up, then walk to an emergency telephone',
          C: 'Park it away from the carriageway',
          D: "Drive it out of the tunnel if it's safe to do so",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1785: {
        id: 'BB1785',
        version: 'v2',
        question:
          'What should you do first if your vehicle has broken down on an automatic railway level crossing?',
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'A',
        additionalInfo:
          "First, get yourself and anyone else well away from the crossing. If there's a railway telephone, use that to get instructions from the signal operator. Then, if there's time, move the vehicle clear of the crossing.",
        options: {
          A: 'Get everyone out of the vehicle and clear of the crossing',
          B: 'Telephone your vehicle recovery service to move it',
          C: 'Walk along the track to give warning to any approaching trains',
          D: 'Try to push the vehicle clear of the crossing as soon as possible',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1788: {
        id: 'BB1788',
        version: 'v2',
        question:
          "What's the first thing you must do if you have a collision while you're driving your car?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'C',
        additionalInfo:
          "If you're in a collision that causes damage or injury to any other person, vehicle, animal or property, by law you must stop. Give your name, the vehicle owner's name and address, and the vehicle's registration number to anyone who has reasonable grounds for requesting them.",
        options: {
          A: 'Stop only if someone waves at you',
          B: 'Call the emergency services',
          C: 'Stop at the scene of the incident',
          D: 'Call your insurance company',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1790: {
        id: 'BB1790',
        version: 'v2',
        question:
          "What information should you share if you're involved in a collision that causes damage to another vehicle?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'B',
        additionalInfo:
          "Try to keep calm and don't rush. Make sure that you've shared all the relevant details with the other driver before you leave the scene. If possible, take pictures and note the positions of all the vehicles involved.",
        options: {
          A: 'Your occupation and reason for your journey',
          B: 'Your name, address and vehicle registration number',
          C: 'Your national insurance number',
          D: 'Your internet service provider',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1792: {
        id: 'BB1792',
        version: 'v2',
        question:
          "You lose control of your car and damage a garden wall. What must you do if the property owner isn't available?",
        questionSetId: 'INCIDENTS,-ACCIDENTS-AND-EMERGENCIES',
        questionSetTitle: 'Incidents, accidents and emergencies',
        image: null,
        answer: 'A',
        additionalInfo:
          "If the property owner isn't available at the time, you must inform the police about the incident. This should be done as soon as possible, and in any case within 24 hours.",
        options: {
          A: 'Report the incident to the police within 24 hours',
          B: 'Go back to tell the house owner the next day',
          C: 'Report the incident to your insurance company when you get home',
          D: 'Find someone in the area to tell them about it immediately',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      AB2488: {
        id: 'AB2488',
        version: 'v2',
        question:
          "What restrictions apply if you're towing a trailer on a three-lane motorway?",
        questionSetId: 'VEHICLE-LOADING',
        questionSetTitle: 'Vehicle loading',
        image: null,
        answer: 'D',
        additionalInfo:
          "The motorway regulations for towing a trailer state that you mustn't \r\n•  use the right-hand lane of a three-lane motorway unless directed to do so (for example, at roadworks or due to a lane closure)\r\n•  exceed 60 mph.",
        options: {
          A: "You mustn't exceed 50 mph",
          B: "You mustn't overtake",
          C: 'You must have a stabiliser fitted',
          D: "You mustn't use the right-hand lane",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1271: {
        id: 'BB1271',
        version: 'v2',
        question:
          "What should you do if you're towing a trailer and it starts to swing from side to side?",
        questionSetId: 'VEHICLE-LOADING',
        questionSetTitle: 'Vehicle loading',
        image: null,
        answer: 'A',
        additionalInfo:
          "Strong winds or buffeting from large vehicles can cause a trailer or caravan to swing from side to side ('snake'). If this happens, ease off the accelerator. Don't brake harshly, steer sharply or increase your speed.",
        options: {
          A: 'Ease off the accelerator to reduce your speed',
          B: 'Let go of the steering wheel and let it correct itself',
          C: 'Brake hard and hold the pedal down',
          D: 'Accelerate until it stabilises',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1364: {
        id: 'BB1364',
        version: 'v2',
        question:
          "When would you increase the pressure in your tyres so that it's above the normal value?",
        questionSetId: 'VEHICLE-LOADING',
        questionSetTitle: 'Vehicle loading',
        image: null,
        answer: 'D',
        additionalInfo:
          "Check the vehicle handbook. This should give you guidance on the correct tyre pressures for your vehicle and when you may need to adjust them. If you're carrying a heavy load, you may need to adjust the headlights as well. Most cars have a switch on the dashboard to do this.",
        options: {
          A: 'When the roads are slippery',
          B: 'When the vehicle is fitted with anti-lock brakes',
          C: 'When the tyre tread is worn below 2 mm',
          D: 'When carrying a heavy load',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1365: {
        id: 'BB1365',
        version: 'v2',
        question:
          "How will a heavy load on your roof rack affect your vehicle's handling?",
        questionSetId: 'VEHICLE-LOADING',
        questionSetTitle: 'Vehicle loading',
        image: null,
        answer: 'D',
        additionalInfo:
          'A heavy load on your roof rack will reduce the stability of the vehicle because it moves the centre of gravity away from that designed by the manufacturer. Be aware of this when you drive round bends and corners. If you change direction at speed, your vehicle and/or load could become unstable and you could lose control.',
        options: {
          A: 'It will improve the road holding',
          B: 'It will reduce the stopping distance',
          C: 'It will make the steering lighter',
          D: 'It will reduce stability',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1543: {
        id: 'BB1543',
        version: 'v2',
        question:
          'What would be affected if you carry a very heavy load on your vehicle?',
        questionSetId: 'VEHICLE-LOADING',
        questionSetTitle: 'Vehicle loading',
        image: null,
        answer: 'C',
        additionalInfo:
          'Any load will have an effect on the handling of your vehicle, and this becomes worse as you increase the load. You need to be aware of this when carrying passengers or heavy loads, fitting a roof rack or towing a trailer.',
        options: {
          A: "The vehicle's gearbox",
          B: "The vehicle's ventilation",
          C: "The vehicle's handling",
          D: "The vehicle's battery",
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1544: {
        id: 'BB1544',
        version: 'v2',
        question:
          "Who's responsible for making sure that a vehicle isn't overloaded?",
        questionSetId: 'VEHICLE-LOADING',
        questionSetTitle: 'Vehicle loading',
        image: null,
        answer: 'A',
        additionalInfo:
          "Carrying heavy loads will affect control and the vehicle's handling characteristics. If the vehicle you're driving is overloaded, you'll be held responsible.",
        options: {
          A: 'The driver of the vehicle',
          B: 'The owner of the items being carried',
          C: 'The person who loaded the vehicle',
          D: 'The licensing authority',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1706: {
        id: 'BB1706',
        version: 'v2',
        question:
          "You're planning to tow a caravan. What will help the handling of the combination?",
        questionSetId: 'VEHICLE-LOADING',
        questionSetTitle: 'Vehicle loading',
        image: null,
        answer: 'D',
        additionalInfo:
          "Towing a caravan or trailer affects the way the towing vehicle handles. A stabiliser device isn't designed to overcome instability caused by incorrect loading but it can give added security in side winds and from buffeting caused by large vehicles.",
        options: {
          A: 'A jockey wheel fitted to the tow bar',
          B: 'Power steering fitted to the towing vehicle',
          C: 'Anti-lock brakes fitted to the towing vehicle',
          D: 'A stabiliser fitted to the tow bar',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1707: {
        id: 'BB1707',
        version: 'v2',
        question:
          "Are passengers allowed to ride in a caravan that's being towed?",
        questionSetId: 'VEHICLE-LOADING',
        questionSetTitle: 'Vehicle loading',
        image: null,
        answer: 'B',
        additionalInfo:
          'Riding in a towed caravan is highly dangerous. The safety of the entire unit is dependent on the stability of the trailer. Moving passengers would make the caravan unstable and could cause loss of control.',
        options: {
          A: "Yes, if they're over 14",
          B: 'No, not at any time',
          C: 'Only if all the seats in the towing vehicle are full',
          D: 'Only if a stabiliser is fitted',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1708: {
        id: 'BB1708',
        version: 'v2',
        question:
          'What safety device must be fitted to a trailer braking system?',
        questionSetId: 'VEHICLE-LOADING',
        questionSetTitle: 'Vehicle loading',
        image: null,
        answer: 'D',
        additionalInfo:
          'In the event that the trailer becomes detached from the towing vehicle, the breakaway cable activates the trailer brakes before snapping. This allows the towing vehicle to get free of the trailer and out of danger.',
        options: {
          A: 'Stabiliser',
          B: 'Jockey wheel',
          C: 'Corner steadies',
          D: 'Breakaway cable',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1735: {
        id: 'BB1735',
        version: 'v2',
        question:
          "You wish to tow a trailer. Where would you find the maximum noseweight for your vehicle's tow hitch?",
        questionSetId: 'VEHICLE-LOADING',
        questionSetTitle: 'Vehicle loading',
        image: null,
        answer: 'A',
        additionalInfo:
          "You must know how to load your trailer or caravan so that the hitch exerts an appropriate downward force on the tow ball. Information about the maximum permitted noseweight can be found in your vehicle handbook or obtained from your vehicle manufacturer's agent.",
        options: {
          A: 'In the vehicle handbook',
          B: 'In The Highway Code',
          C: 'In your vehicle registration certificate',
          D: 'In your licence documents',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1745: {
        id: 'BB1745',
        version: 'v2',
        question: 'How should a load be carried on your roof rack?',
        questionSetId: 'VEHICLE-LOADING',
        questionSetTitle: 'Vehicle loading',
        image: null,
        answer: 'A',
        additionalInfo:
          'Any load must be securely fastened to the vehicle. The safest way to carry items on the roof is in a specially designed roof box. This will help to keep your luggage secure and dry, and it also has less wind resistance than loads carried exposed on a roof rack.',
        options: {
          A: 'Securely fastened with suitable restraints',
          B: 'Loaded towards the rear of the vehicle',
          C: 'Visible in your exterior mirror',
          D: 'Covered with plastic sheeting',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
      BB1754: {
        id: 'BB1754',
        version: 'v2',
        question:
          "You're carrying a child under three years old in your car. Which restraint is suitable for a child of this age?",
        questionSetId: 'VEHICLE-LOADING',
        questionSetTitle: 'Vehicle loading',
        image: null,
        answer: 'A',
        additionalInfo:
          "It's your responsibility to ensure that all children in your car are secure. Suitable restraints include a child seat, baby seat, booster seat or booster cushion. It's essential that any restraint used is suitable for the child's size and weight, and fitted according to the manufacturer's instructions.",
        options: {
          A: 'A child seat',
          B: 'An adult holding a child',
          C: 'An adult seat belt',
          D: 'An adult lap belt',
        },
        optionImages: {A: null, B: null, C: null, D: null},
        questionType: 'multiple-choice',
      },
    },
  };
}
