import {useMemo} from 'react';
import _orderBy from 'lodash/orderBy';

import {GamePlayers} from '../../../../shared/shared-game/SharedGame.types';
import {asDate} from '../../../../shared/shared-game-setup';
import {RoadSignRodeoLogo} from '../components/RoadSignRodeoLogo';

interface IGameLobby {
  gameLink?: string;
  gamePlayers?: GamePlayers;
  waitingForMorePlayers: boolean;
  isGameCreator: boolean;
  onCancel?: () => void;
  onStartGame: () => void;
}

export function GameLobby({
  gameLink,
  gamePlayers,
  waitingForMorePlayers,
  isGameCreator,
}: IGameLobby) {
  const orderedGamePlayers = useMemo(() => {
    if (!gamePlayers) {
      return [];
    }
    return _orderBy(
      Object.values(gamePlayers),
      [({joinedGameAt}) => asDate(joinedGameAt)],
      ['asc'],
    );
  }, [gamePlayers]);

  return (
    <div className="flex flex-col justify-center items-center">
      <h1 className="text-4xl font-bold mb-6 mt-4">Road Sign Rodeo</h1>
      <RoadSignRodeoLogo />
      <h2 className="text-2xl font-bold mt-8">Players</h2>
      <div className="flex gap-4 py-8">
        {orderedGamePlayers.map(({username}, index) => {
          return (
            <div key={index} className="rounded-lg bg-slate-600 py-2 px-4">
              <p>{username}</p>
            </div>
          );
        })}
      </div>
      {!!waitingForMorePlayers && <p>Waiting for other players to join</p>}
      {!waitingForMorePlayers && !isGameCreator && (
        <p>Waiting for game creator to start game</p>
      )}
    </div>
  );
}
