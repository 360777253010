import {init} from './module/firebase';
import {useAuth} from './store/hooks';
import {Game} from './feature/Game';

import {useUrlParams} from './hooks';

init();

function App() {
  const {user} = useAuth();
  const {id: gameId} = useUrlParams(['id']);

  return (
    <div className="container mx-auto px-4 py-4 max-w-[600px]">
      {user?.id && gameId && <Game userId={user.id} gameId={gameId} />}
    </div>
  );
}

export default App;
